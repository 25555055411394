import React from 'react';
import './btnPrimary.css'
import Img from './arrow.png'
const BtnPrimary = () => {
    return (
        <>
            <button className='buttonPrimaryCharts'>  <img className='imgButtonFlotante' src={Img} alt="boton flotante" /></button>
        </>
    );
}

export default BtnPrimary;
