import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { AppContext } from '../../Context/Context';
import Select from 'react-select';
import axiosInterceptor from '../../AxiosInterceptor/AxiosInterceptor';
import MensajeExito from '../../systemPages/mensajeDeExito/mensajeDeExito';
import MensajeDeErrorAPI from '../../systemPages/mensajeDeErrorAPI/mensajeDeErrorAPI';
import './Configuracion.css';

const Configuracion = () => {
  const { country, items } = useContext(AppContext);
  const [value, setValue] = useState({});
  const [cursor, setCursor] = useState('');
  const [xlsxData, setXlsxData] = useState([]);
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState({ selecrut: '1' });

  const [columns, setColumns] = useState([]);
  const [options, setOptions] = useState([]);
  const [configError, setConfigError] = useState(false)
  const [msjExito, setMsjExito] = useState(false);


  const handleInput = (event, label) => {
    const value = event.target.value;
    setValue((prevValue) => ({
      ...prevValue,
      [label]: value,
    }));
  };


  useEffect(() => {
    const xlsxData = JSON.parse(localStorage.getItem('xlsx'));
    const enrData = localStorage.getItem('enrData');
    if (xlsxData) {
      setCursor(xlsxData.cursor);
    }
    if (enrData) {
      setXlsxData(JSON.parse(enrData));
    }
  }, []);

  useEffect(() => {
    setSelectedRut('1');
  }, [country, items]);

  useEffect(() => {
    setSelectedData(getDefaultSelectedData());
  }, [columns, options]);

  useEffect(() => {
    const storedXlsx = localStorage.getItem('xlsx');
    if (storedXlsx) {
      setColumns(JSON.parse(storedXlsx));
    }
  }, []);

  useEffect(() => {
    if (columns.columnas) {
      setOptions(columns.select_columns);
    }
  }, [columns]);

  const getDefaultSelectedData = () => {
    const defaultSelectedData = {};
    if (columns.columnas) {
      columns.columnas.forEach((propiedad) => {
        const option = options.find((option) => option.value === propiedad.value);
        const valor = option ? option.value : (options.length > 0 ? options[0].value : '');
        defaultSelectedData[propiedad] = valor;
        setValue((prevValue) => ({
          ...prevValue,
          [propiedad.value]: valor,
        }));
      });
    }
    return defaultSelectedData;
  };


  const handleOptionChangeRut = (selectedOption) => {
    setSelectedRut(selectedOption.value);

    if (items === 43 && selectedOption.value === '') {
      setValue((prevState) => ({
        ...prevState,
        selecrut: '1',
      }));
    } else {
      setValue((prevState) => ({
        ...prevState,
        selecrut: selectedOption.value,
      }));
    }
  };

  const [selectedRut, setSelectedRut] = useState('1');
  const getLabelForOption = (selectedValue) => {
    const selectedOption = options.find((option) => option.value === selectedValue);
    return selectedOption ? selectedOption.text : '';
  };

  const handleInvoice = () => {
    if (items === 43) {
      // Modifica la asignación del valor de selecrut
      value.selecrut = selectedRut;
    }
    value.fname = xlsxData.fname;
    value.mapear = 'Upload';

    value.organization = localStorage.getItem('companyFilter')

    if (xlsxData.segment === "Natural") {
      value.check_segment = xlsxData.check_segment;

    } else {
      value.segment = xlsxData.segment;
      value.industry = xlsxData.industry;
    }
    axiosInterceptor.post(
      `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/enrichment/${localStorage.getItem('lmnkknn')}`,
      value,) .then((response) => {
        localStorage.setItem('columnsSelection', JSON.stringify(response.data));
        localStorage.setItem('csrf', response.data.csrf_token);
        setMsjExito(true);

      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 422) {
          localStorage.clear();
          navigate('/iniciar-sesion')
        } else if (error.response.status === 302) {
          navigate('/no-credits')
        }
        setConfigError(true);
      });
  };

  useEffect(() => {
    if (msjExito === true) {
      setTimeout(() => {
        navigate('/buyer-persona-confirmacion');
      }, 1000);
    }
  }, [msjExito]);
  const getLabelForRut = (rutValue) => {
    switch (rutValue) {
      case '1':
        return '12345678-9 (Con guion y con dígito verificador)';
      case '2':
        return '12345678 (Sin guion y sin dígito verificador)';
      case '3':
        return '123456789 (Sin guion y con dígito verificador)';
      default:
        return '';
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
    }),

    option: (provided, state) => ({
      ...provided,
      color: "#475467",
      fontSize: "12px",
      fontFamily: "Inter",
      fontWeight: "400",
      backgroundColor: "white", // Cambia el color de fondo al hacer hover
      "&:active": {
        backgroundColor: "white", // Evita que haya un color de fondo al hacer clic en la opción
      },
      "&:hover": {
        backgroundColor: "#EFF8FF", // Cambia el color de fondo al hacer hover
      },

    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#475467',
      fontSize: '12px',
      fontWeight: "400",
      fontFamily: "Inter",
      padding: "0px 8px"
    }),
  };

  return (
    <>
      {msjExito && <MensajeExito />}
      {configError ? <MensajeDeErrorAPI /> :
        <div className='Configuracion borde-radius-gris'>
          <div className='Configuracion_FormContainer'>
            {(items === 43) && (
              <>
                <div className='selectConfigText'>
                  <h1 className='buyerPersona-title'>Configura el RUT</h1>
                  <p>Indícanos cuál es el formato de RUT</p>
                  <Select
                    className='SelectConfig '
                    value={{
                      value: selectedRut,
                      label: getLabelForRut(selectedRut),
                    }}
                    onChange={handleOptionChangeRut}
                    options={[
                      { value: '1', label: '12345678-9 (Con guion y con dígito verificador)' },
                      { value: '2', label: '12345678 (Sin guion y sin dígito verificador)' },
                      { value: '3', label: '123456789 (Sin guion y con dígito verificador)' },
                    ]}
                    styles={customStyles}
                  />


                </div>
              </>)}
            {columns.columnas &&
              columns.columnas.map((e) => {
                return (
                  <div key={e}>
                    {(options.length > 0 && options[0].value )&& (
                      <>
                        <div className='selectConfigText'>
                          <p>{e.text}</p>
                          <Select
                            className='SelectConfig '
                            value={{ value: value[e], label: getLabelForOption(value[e.value]) }}
                            defaultValue={{ value: options[0].value, label: options[0].text }}

                            onChange={(selectedOption) => handleInput({ target: { value: selectedOption.value } }, e.value)}
                            options={options.map((option) => ({
                              value: option.value,
                              label: option.text,
                            }))}
                            styles={customStyles}
                          />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}


          </div>

          <div className='Configuracion_botones'>
            <button onClick={() => { navigate('/buyer-persona-carga-archivo') }} className={'btnSecondary'} >Atrás</button>
            <button className={'btnPrimaryBuyerPersona'} onClick={() => { handleInvoice() }}>Siguiente</button>
          </div>
        </div>
      }



    </>
  );
};

export default Configuracion;
