import React, { useEffect, useState } from "react";
import Select from "react-select";
import IconPlus from "./assets/IconoAgregar.svg";
import IconDelete from "./assets/IconoEliminar.svg";
import "./CardDatosGeograficos.css";

const CardDatosGeograficos = ({ region, onDataGenerated, percent }) => {
  const [campoArray, setCampoArray] = useState([
    {
      selectedRegion: "",
      selectedComuna: "",
      porcentaje: 0,
    },
  ]);
  const [data, setData] = useState([]);
  const [isPercentageValid, setIsPercentageValid] = useState(true);
  const [selectedComunas, setSelectedComunas] = useState(new Set());

  useEffect(() => {
    const textValues = Object.values(region).map((item) => item.text);
    setData(textValues);
  }, [region]);

  const handleRegionChange = (selectedOption, index) => {
    const newCampoArray = [...campoArray];
    newCampoArray[index].selectedRegion = selectedOption.value;
    if (selectedOption.value === "") {
      newCampoArray[index].selectedComuna = "";
    }
    setCampoArray(newCampoArray);
  };

  const handleComunaChange = (selectedOption, index) => {
    const newCampoArray = [...campoArray];
    const selectedComuna = selectedOption.value;

    if (!selectedComunas.has(selectedComuna)) {
      newCampoArray[index].selectedComuna = selectedComuna;
      setSelectedComunas(new Set([...selectedComunas, selectedComuna]));
    } else {
      // Muestra un mensaje de error o realiza alguna acción aquí
    }

    setCampoArray(newCampoArray);
  };

  const handlePorcentajeChange = (e, index) => {
    const newCampoArray = [...campoArray];
    newCampoArray[index].porcentaje = e.target.value;
    setCampoArray(newCampoArray);
  };

  const addNewCampo = () => {
    const newCampo = campoArray[campoArray.length - 1];
    if (
      newCampo.selectedRegion &&
      newCampo.selectedComuna &&
      newCampo.porcentaje !== ""
    ) {
      setCampoArray([
        ...campoArray,
        {
          selectedRegion: "",
          selectedComuna: "",
          porcentaje: 0,
        },
      ]);
    }
  };

  const removeCampo = (index) => {
    const removedComuna = campoArray[index].selectedComuna;
    setSelectedComunas((prevComunas) => {
      const updatedComunas = new Set(prevComunas);
      updatedComunas.delete(removedComuna);
      return updatedComunas;
    });
    const newCampoArray = campoArray.filter((_, i) => i !== index);
    setCampoArray(newCampoArray);
  };

  useEffect(() => {
    generateDataObject();
  }, [campoArray]);

  const generateDataObject = () => {
    const result = campoArray
      .map((campo) => ({
        name: campo.selectedComuna,
        data: [`${campo.porcentaje}%`],
      }))
      .filter((campo) => campo.name !== "");

    const totalPercentage = campoArray.reduce(
      (total, campo) => total + parseFloat(campo.porcentaje),
      0
    );

    const allDefaultValues = campoArray.every(
      (campo) =>
        campo.selectedRegion === "" &&
        campo.selectedComuna === "" &&
        campo.porcentaje === 0
    );

    setIsPercentageValid(totalPercentage === 100);
    onDataGenerated(result);

    if (totalPercentage === 100 || allDefaultValues) {
      percent(true);
    } else {
      percent(false);
    }
  };

  const getComunasByText = (text) => {
    for (const key in region) {
      if (region.hasOwnProperty(key)) {
        if (region[key].text.toLowerCase() === text.toLowerCase()) {
          return region[key].comunas;
        }
      }
    }
    return [];
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      display: "flex",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "stretch",
      background: "#FFF",
      cursor: "pointer",
      height: "38px",
      width: "182px",
      minHeight: "24px",
      borderRadius: "8px",
      color: "#707477",
      fontFamily: "Inter",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 300,
      letterSpacing: "0.24px",
      borderColor: state.isFocused ? "#F18F49" : "#E2E4E3",
      boxShadow: state.isFocused ? "0 0 0 1px #F18F49" : "none",
      "&:hover": {
        borderColor: "#F18F49",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      color: "#475467",
      fontSize: "12px",
      fontFamily: "Inter",
      fontWeight: "400",
      backgroundColor: "white", // Cambia el color de fondo al hacer hover
      "&:active": {
        backgroundColor: "white", // Evita que haya un color de fondo al hacer clic en la opción
      },
      "&:hover": {
        backgroundColor: "#EFF8FF", // Cambia el color de fondo al hacer hover
      },

    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#475467',
      fontSize: '12px',
      fontWeight: "400",
      fontFamily: "Inter",
      padding: "0px 8px"
    }),
  };


  return (
    <div className="cardDatosGeo">
      <h2 className="cardTitleDatosGeo">Datos geográficos</h2>
      <p className="cardTextDatosGeo">
        Acota la búsqueda por comuna para mejores resultados.
      </p>
      <div className="cardContentDatosGeo">
        {campoArray.map((campo, index) => (
          <div key={index} className="campoAdicional">
            <div className="formDatosGeo region">
              {index === 0 && (
                <label className="labelDatosGeo" htmlFor={`region${index}`}>
                  Región
                </label>
              )}
              <Select
                className="selectDatosGeo region"
                id={`region${index}`}
                value={campo.selectedRegion ? { label: campo.selectedRegion, value: campo.selectedRegion } : null}
                options={data.sort().map((e) => ({ label: e, value: e }))}
                onChange={(selectedOption) => handleRegionChange(selectedOption, index)}
                placeholder="Selecciona una región"
                styles={customStyles}
              />

            </div>
            <div className="formDatosGeo comuna">
              {index === 0 && (
                <label className="labelDatosGeo" htmlFor={`comuna${index}`}>
                  Comuna
                </label>
              )}
              <Select
                className="selectDatosGeo comuna"
                id={`comuna${index}`}
                value={campo.selectedComuna ? { label: campo.selectedComuna, value: campo.selectedComuna } : null}
                options={getComunasByText(campo.selectedRegion).sort((a, b) => a.text.localeCompare(b.text))
                  .map((comuna) => ({ label: comuna.text, value: comuna.value }))}
                onChange={(selectedOption) => handleComunaChange(selectedOption, index)}
                placeholder="Selecciona una comuna"
                styles={customStyles}
              />

            </div>
            <div className="formDatosGeo Porcentaje">
              {index === 0 && (
                <label className="labelDatosGeo Porcentaje" htmlFor={`porcentaje${index}`}>
                  Porcentaje
                </label>
              )}
              <div className="inputContainerDatosGeo">
                <input
                  type="number"
                  className={`inputDatosGeo borde-inputs-generales ${!isPercentageValid &&
                    !campoArray.some(
                      (campo) => campo.selectedComuna === ""
                    ) && "invalid"
                    }`}
                  id={`porcentaje${index}`}
                  onChange={(e) => handlePorcentajeChange(e, index)}
                  onWheel={(e) => e.target.blur()}
                  value={campo.selectedComuna === "" ? "" : campo.porcentaje}
                  disabled={campo.selectedComuna === ""}
                />
                <p>%</p>
                <div className="buttonNuevosCamposContenedor">
                  <button className="buttonNuevosCampos" onClick={addNewCampo}>
                    <img className="imgNuevoCampos" src={IconPlus} alt="Add" />
                  </button>
                  {campoArray.length > 1 && (
                    <button className=" buttonNuevosCampos" onClick={() => removeCampo(index)}>
                      <img className="imgNuevoCampos" src={IconDelete} alt="Delete" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {!isPercentageValid && !campoArray.some(
        (campo) => campo.selectedComuna === ""
      ) && (
          <p className="warningData">*el total de los porcentajes debe sumar 100%</p>
        )}
    </div>
  );
};

export default CardDatosGeograficos;
