import React, { useEffect, useState, useContext, useRef } from "react";
import "./cardCantidadBuyerPersona.css";
import ImgPerfil1 from "./assets/rectangle-violet.svg";
import ImgPerfil2 from "./assets/rectangle-red.svg";
import ImgPerfil3 from "./assets/rectangle-blue.svg";
import ImgPerfil4 from "./assets/rectangle-green.svg";
import ImgPerfil5 from "./assets/rectangle-pink.svg";
import IconExito from "../../cardsSolicitudClones/assets/check_circle.svg";
import IconAtencion from "./assets/iconAtencion.svg";
import { AppContext } from "../../../../Context/Context";
import separadorDeMiles from "../../../../SeparadorDeMiles/SeparadorDeMiles";

const CardCantidadBP = ({ profiles, onInputChange, dataProfiles, buttonStatus }) => {
  const [inputRefs, setInputRefs] = useState(profiles.map(() => React.createRef()));
  const { setPerfiles } = useContext(AppContext);
  const [personas, setPersonas] = useState([]);
  const [userModified, setUserModified] = useState(false);
  const [porcentajeIsEmpty, setPorcentajeIsEmpty] = useState(true);

  useEffect(() => {
    dataProfiles(personas);
  }, [personas]);

  const [inputStates, setInputStates] = useState(profiles.map(() => ({ isInputFocused: false, inputWidth: "10px" })));

  const handleChange = (index, value) => {
    const numberValue = value.replace(/\./g, ''); // Remover solo los puntos
    if (numberValue === "" || (numberValue >= 0 && numberValue <= 999999)) {
      const newPersonas = [...personas];
      newPersonas[index] = numberValue !== "" ? parseInt(numberValue, 10) : "";
      setPersonas(newPersonas);
      setUserModified(true);
      setPerfiles(newPersonas);
      setPorcentajeIsEmpty(numberValue === "");

      setInputStates((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...newState[index],
          /*   inputWidth: `${value.length * 8 + 0}px`, */
        };
        return newState;
      });
    }
  };

  const handleInputFocus = (index) => {
    setInputStates((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        isInputFocused: true,
      };
      return newState;
    });
  };

  const handleInputBlur = (index) => {
    setInputStates((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        isInputFocused: false,
      };
      return newState;
    });
  };

  useEffect(() => {
    if (profiles.length > 0) {
      const initialPersonas = profiles.map(() => "");
      setPersonas(initialPersonas);
      onInputChange(initialPersonas);
    }
  }, [profiles]);

  const totalPersonas = personas.reduce(
    (total, current) => total + (current === "" ? 0 : parseInt(current)),
    0
  );
  const isTotalGreaterThan6000 = userModified && totalPersonas >= 5000;

  buttonStatus(isTotalGreaterThan6000);

  return (

    <div className="amplifyCardBuyerPersona">
      {profiles.length > 0 &&
        profiles.map((e, i) => (
          <div className="amplifyCardContentBuyerPersona" key={i} onFocus={() => onInputChange(i, e)}
            onClick={() => onInputChange(i, e)}
          >
            <div className="amplifyCardTitleBuyerPersona">
              {i + 1 === 1 && <img src={ImgPerfil1} alt="Buyer Persona 1" />}
              {i + 1 === 2 && <img src={ImgPerfil2} alt="Buyer Persona 2" />}
              {i + 1 === 3 && <img src={ImgPerfil3} alt="Buyer Persona 3" />}
              {i + 1 === 4 && <img src={ImgPerfil4} alt="Buyer Persona 4" />}
              {i + 1 === 5 && <img src={ImgPerfil5} alt="Buyer Persona 5" />}
              <p>{"Buyer persona" + " " + (i + 1)}</p>
            </div>
            <div className="amplifyCardTitleBuyerPersona" onClick={() => inputRefs[i].current.focus()}>
              <div className={`input-containerPerfiles borde-inputs-generales ${inputStates && inputStates[i] && inputStates[i].isInputFocused ? 'focused' : ''}`}>
                <input
                  type="text"
                  value={personas[i] !== "" ? separadorDeMiles(personas[i]) : ""}
                  onChange={(event) => {
                    const formattedValue = event.target.value.replace(/[^\d]/g, ''); // Eliminar todo excepto los dígitos
                    handleChange(i, formattedValue);
                  }}
                  onWheel={(e) => e.target.blur()}
                  onClick={() => onInputChange(i, e)}
                  placeholder="0"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  className="custom-input"
                  onFocus={() => { if (i !== undefined) { handleInputFocus(i) } }}
                  onBlur={() => { if (i !== undefined) { handleInputBlur(i) } }}
                  ref={inputRefs[i]}
                />

                <span className="personas-text">personas</span>
              </div>
            </div>
          </div>
        ))}

      <div className={`inputTotalContainer ${userModified ? (isTotalGreaterThan6000 ? "completoConExito" : "completoConAtencion") : "default"}`}>
        <p className="inputTotalTextPrincipal">Total </p>
        <p className="inputTotalText">
          <strong className="strong">{separadorDeMiles(totalPersonas)}</strong>
          personas{" "}

          {userModified ? (isTotalGreaterThan6000 ? <img src={IconExito} alt="Éxito" /> : <img src={IconAtencion} alt="Atención" />) : <img className="iconDefault" src={IconExito} alt="Éxito" />}
        </p>
      </div>
      {!porcentajeIsEmpty && userModified && !isTotalGreaterThan6000 ? (
        <p className="atencionText">Cantidad mínima a solicitar: 5.000</p>
      ) : null}
    </div>

  );
};

export default CardCantidadBP;
