import React  from 'react';
import ImgOne from '../assets/imgOneWalktrough.png';
import './walkthrough.css';

const WalkthroughSlider = ({ handlePagination }) => {
let pagination = ()=>{
    handlePagination(4)
}  

    return (
        <div className='walkthrough borde-radius-gris'>
            <img src={ImgOne} alt="imagen de hombre preocupado" />
            <div className='walkthrough_text_container'>
                <h2>¡Somos Tbrein!</h2>
                <p> Y podemos ayudarte a <strong className='strongW'>potenciar</strong> tu empresa usando nuestra <strong className='strongW'>Inteligencia Artificial</strong> aplicada <br /> a tu base de datos.
                </p>
            </div>
            <div className='walkthrough_btn_container'>
                <div className="dotsContainerW">
                    <div className="dotsW active"></div>
                    <div className="dotsW"></div>
                    <div className="dotsW"></div>
                </div>
                <button className='OmitirPrimaryWalktrough' onClick={()=>{ pagination()}}>Omitir</button>
            </div>
        </div>
    );
}

export default WalkthroughSlider;
