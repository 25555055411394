import React, { useState, useEffect } from "react";
import isologo from "../../assets/image/isologo.svg";
import ImagePassword from './Achievement-amico.png'
import "./ForgotPassword.css";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../AxiosInterceptor/AxiosInterceptor";

function ForgotPassword() {
  const [classname, setClass] = useState('login__formRecupero');
  const [timeLeft, setTimeLeft] = useState(0);
  const [mail, setMail] = useState('');
  const [showResendButton, setShowResendButton] = useState(false);
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();
  
  // Al cargar el componente, intenta obtener el tiempo restante almacenado localmente
  useEffect(() => {
    const storedTimeLeft = localStorage.getItem('timeLeft');
    const storedShowResendButton = localStorage.getItem('showResendButton');

    if (storedTimeLeft && storedShowResendButton) {
      setTimeLeft(parseInt(storedTimeLeft, 10));
      setShowResendButton(storedShowResendButton === 'true');
    }
  }, []);

  // Al cambiar el tiempo restante o el estado del botón de reenvío, actualiza el almacenamiento local
  useEffect(() => {
    localStorage.setItem('timeLeft', timeLeft);
    localStorage.setItem('showResendButton', showResendButton);
  }, [timeLeft, showResendButton]);

  useEffect(() => {
    let intervalId;
    if (timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
      }, 1000);
    } else {
      setShowResendButton(true);
    }

    return () => clearInterval(intervalId);
  }, [timeLeft]);



  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  function oldForm() {
    setClass('login__formRecupero');
    document.querySelector(".form_titlesRecupero").style.display = "flex";
    document.getElementById("mostrar__screen--Fp").style.display = "none";
    document.querySelector(".form__inputsRecupero").style.display = "flex";
    document.querySelector(".fp__btn--div").style.display = "flex";
    document.querySelector(".btns__final").style.display = "none";
  }
  function resendCode() {
    setTimeLeft(10);
    setShowResendButton(false);
  }
  function send() {
    axiosInstance.post(
      `${process.env.REACT_APP_URL}/api/user/recover`,
      {
        "email": mail,
      }
    )
      .then((response) => {
        setClass('login__formRecupero--password');
        document.querySelector(".form_titlesRecupero").style.display = "none";
        document.getElementById("mostrar__screen--Fp").style.display = "flex";
        document.querySelector(".form__inputsRecupero").style.display = "none";
        document.querySelector(".fp__btn--div").style.display = "none";
        document.querySelector(".btns__final").style.display = "flex";
        setEmailError('');
      })
      .catch((error) => {
        setEmailError('Por favor, introduce un correo electrónico válido.');
        if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 422)) {
          localStorage.clear();
          navigate("/iniciar-sesion");
        }
      });
  }


  return (
    <div className="login">
      <div className="login__container borde-radius-gris">
        <img src={isologo} alt="" className="login__isologo" />

        <div className={classname}>
          {/* Componente uno */}
          <div className="form_titlesRecupero">
            <div>
              <h1 className="titles">Restablecer contraseña</h1>
            </div>
            <div>
              <p className="form__titles--textRecupero">
                A continuación ingresa tu email. Te llegará un correo electrónico con un token de seguridad para poder restablecer tu cuenta.
              </p>
            </div>
          </div>

          <div className="form__inputsRecupero">
            <label htmlFor="">Email</label>
            <input
              onChange={(e) => { setMail(e.target.value) }}
              type="email"
              placeholder="Ingresa tu correo electrónico"
              className="form__input--texts"
            />
            {emailError && <p className="error__message--forgotPassword">{emailError}</p>}
          </div>
          <div className="fp__btn--div">
            <button onClick={(e) => { if (mail && validateEmail(mail)) { send() } }} className={mail && validateEmail(mail) ? "btnPrimary" : "fp__btn"}>
              Enviar
            </button>
            <p onClick={() => { navigate('/iniciar-sesion') }}>volver</p>
          </div>

          {/* Componente dos */}
          <div className="form__titlesRecupero" id="mostrar__screen--Fp">
            <div className="form__second__container">
              <img src={ImagePassword} alt="imagen de exito" />
              <h1 className="titles">¡Te hemos enviado un mail!</h1>
              <p className="form__titles--textRecupero">
                Por favor continua desde allí.
              </p>
            </div>
          </div>
          <div className="btns__final">
            {showResendButton && (
              <button onClick={() => { navigate('/olvide-contraseña'); resendCode() }} className="fp__btn--enviar--codigo">
                Volver a enviar link
              </button>
            )}
            {!showResendButton && (
              <p>Podrás volver a enviar el código en {timeLeft} segundos</p>
            )}
            <p onClick={oldForm}>Volver</p>

          </div>

        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
