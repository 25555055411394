import React, { useState, useEffect } from "react";
import isologo from '../../assets/image/isologo.svg';
import { useNavigate, useParams } from "react-router-dom";
import Eye from "../Login/eye-visible.svg";
import EyeClose from "../Login/eye-no-visible.svg";
import axiosInstance from "../AxiosInterceptor/AxiosInterceptor";
import './CodeVerification.css'

function ForgotPassword() {
    const { id } = useParams()
    const [classname, setClass] = useState('login__formRecupero');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [eyeState, setEyeState] = useState("password");
    const [image, setImage] = useState(EyeClose);
    const [eyeStateBottom, setEyeStateBottom] = useState("password");
    const [imageBottom, setImageBottom] = useState(EyeClose);
    
  
    const seePassword = (position) => {
        if (position === 'top') {
            setEyeState(eyeState === "password" ? "text" : "password");
            setImage(eyeState === "password" ? Eye : EyeClose);
        } else {
            setEyeStateBottom(eyeStateBottom === "password" ? "text" : "password");
            setImageBottom(eyeStateBottom === "password" ? Eye : EyeClose);
        }
    };
    const handleSubmit = (event) => {
        if (password === confirmPassword) {
            if (validatePassword(password)) {
                axiosInstance.post(
                    `${process.env.REACT_APP_URL}/api/user/recover/${id}`,
                    {
                        "password": password,
                    }
                )
                    .then((response) => {
                        navigate('/iniciar-sesion')

                    })
                    .catch((error) => {
                        setErrorMessage(error.response.data.detail);

                    });
            } else {
                setErrorMessage('La contraseña no cumple con los criterios de validación');
            }
        } else {
            setErrorMessage('Las contraseñas no coinciden');
        }
    }

    const validatePassword = (password) => {
        // Validar que la contraseña tenga al menos una minúscula, una mayúscula, un número y un mínimo de 8 caracteres
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[^a-zA-Z0-9]/; // cualquier carácter que no sea alfanumérico
        const minLength = 8;

        return (
            lowercaseRegex.test(password) &&
            uppercaseRegex.test(password) &&
            specialCharRegex.test(password) &&
            numberRegex.test(password) &&
            password.length >= minLength
        );
    }

    return (
        <div className="login codeVerfication">
            <div className="login__container borde-radius-gris">
                <img src={isologo} alt="" className="login__isologo" />

                <div className={classname}>

                    <div className="form_titlesRecupero">
                        <div className="CodeVerificationTitle">
                            <h3 className="CodeVerificationTitle">¡Hola de nuevo!</h3>
                            <h1 className="titles">Introduce tu nueva contraseña</h1>
                        </div>

                    </div>

                    <div className="form__inputsRecupero">
                        <label htmlFor="">Contraseña</label>
                        
                        <input
                            type={eyeState}
                            placeholder="Ingresa tu nueva contraseña"
                            className= {errorMessage && errorMessage !== 'Token expirado' ? "form__input--texts--error form__input--texts" : "form__input--texts"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                          <div className="CodeVerficationEye">
                                    <img
                                        src={image}
                                        alt=""
                                        // className="EyeAdmin"
                                        onClick={()=>{seePassword('top')}}
                                    />
                                </div>
                        <input
                            type={eyeStateBottom}
                            placeholder="Repite tu nueva contraseña"
                            className={ errorMessage && errorMessage !== 'Token expirado' ? "form__input--texts--error form__input--texts" : "form__input--texts"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                          <div className="CodeVerficationEyeBottom" >
                                    <img
                                        src={imageBottom}
                                        alt=""
                                        // className="EyeAdmin"
                                        onClick={()=>{seePassword('bottom')}}
                                    />
                                </div>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}

                    </div>
                    <div className="VerificationCodeVerification">
                        <p className="form__titles--textRecupero--CodeVerification">
                            La nueva contraseña debe tener al menos</p>
                        <ul className="CodeVerificationUl">
                            <li type="disc">Una minúscula</li>
                            <li type="disc">Una mayúscula</li>
                            <li type="disc">Un número</li>
                            <li type="disc">Un carácter especial</li>
                            <li type="disc">Un mínimo de 8 caracteres</li>
                        </ul>
                    </div>
                    <div className="fp__btn--div">
                        <button className="btnPrimary" onClick={() => { handleSubmit() }}>
                            Enviar
                        </button>

                        <p onClick={() => { navigate('/iniciar-sesion') }}>Volver</p>
                    </div>

                </div>
            </div>
        </div>
    );
}


export default ForgotPassword;
