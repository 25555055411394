import React, { useState } from "react";
import "./textoPerfiles.css";
import ImgPerfil1 from "./assets/rectangle-violet.svg";
import ImgPerfil2 from "./assets/rectangle-red.svg";
import ImgPerfil3 from "./assets/rectangle-blue.svg";
import ImgPerfil4 from "./assets/rectangle-green.svg";
import ImgPerfil5 from "./assets/rectangle-pink.svg";
import ImgBarChart from "./assets/bar_chart.svg";

const TextoPerfiles = ({ profileSelected, dataProfileSelected, profiles }) => {
  return (
    <>
        {dataProfileSelected === undefined ? (
          <div className="buyerPersonaCardContent">
            <div className="buyerPersonaContent">
            <div className="buyerPersonaTextTitleOne">
              <img src={ImgPerfil1} alt="" />
              <p>Buyer persona 1 </p>
            </div>
            <div className="buyerPersonaTextTitleImg">
              <img src={ImgBarChart} alt="" />
              <p><span>{profiles.peso + "%"}</span> tiene este perfil</p>
            </div>
          </div>
          <div className="buyerPersonaTextTitle">
            <div className="buyerPersonaText">
              <h2 >¿Cómo es éste perfil?</h2>
              <p>{profiles.perfil_ampliado}</p>
            </div>
          </div>
          </div>
        ) : (
          <div className="buyerPersonaTextTitle">
            <div className="buyerPersonaContent">
              <div className="buyerPersonaTextTitleOne">
                {profileSelected + 1 === 1 && (
                  <img src={ImgPerfil1} alt="Buyer Persona 1" />
                )}
                {profileSelected + 1 === 2 && (
                  <img src={ImgPerfil2} alt="Buyer Persona 2" />
                )}
                {profileSelected + 1 === 3 && (
                  <img src={ImgPerfil3} alt="Buyer Persona 3" />
                )}
                {profileSelected + 1 === 4 && (
                  <img src={ImgPerfil4} alt="Buyer Persona 4" />
                )}
                {profileSelected + 1 === 5 && (
                  <img src={ImgPerfil5} alt="Buyer Persona 5" />
                )}
                <p>Buyer Persona {profileSelected + 1}</p>
              </div>
              <div className="buyerPersonaTextTitleImg">
                <img src={ImgBarChart} alt="" />
                <p><span>{dataProfileSelected.peso + "%"}</span> tiene este perfil</p>
              </div>
            </div>
            <div className="buyerPersonaText">
              <h2 className="buyerPersonaTextTitleCard">¿Cómo es éste perfil?</h2>
              <p>{dataProfileSelected.perfil_ampliado}</p>
            </div>
          </div>
        )}
   
    </>
  );
};

export default TextoPerfiles;
