import React, { useContext, useState } from "react";
import { AppContext } from "../../Context/Context";
import Navbar from "../../navbar/menuVertical/navbar";
import HomeEmptyImg from "./homeEmpty.png"
import "./homeEmptyAutomatico.css";
import { useNavigate } from "react-router-dom";
const HomeEmptyAuto = () => {
  const navigate = useNavigate();
  const [navbarData, setNavbarData] = useState(false);

  useContext(AppContext);
  const estadoMenu = (data) => {
    setNavbarData(data);
  };
  return (
    <>
      <div className="home">
        <Navbar estado={estadoMenu} />
        <div
          className={`home_body_container ${navbarData ? "navbar-open" : ""}`}
          style={{ marginLeft: navbarData ? "228px" : "80px" }}
        >
          {/*     <header className="headerHome"></header> */}
          {/* Remplazar + por la imagen del proto */}
          <main className="mainHome">
            <div className="userContainer">
              <h1>
                Amplify              </h1>
            </div>

            <div className="cardHomeEmptyAuto">
              <div className="homeEmptyImg">
                <img src={HomeEmptyImg} alt="" />
              </div>
              <div className="cardHomeEmptyContent">
                <div className="cardHomeEmptyContentContainer">
                  <p className="cardHomeEmptyTitle">Encuentra personas para tus campañas </p>
                  <p className="cardHomeEmptyText">
                    Consigue nuevos clientes en base a tus Buyer persona haciendo un Amplify automático, o selecciona características personalizadas mediante un Amplify manual.
                  </p>

                </div>
                <div className="cardHomeEmptyButtons">
                  <button className="btnSecondary bntSecondaryHE" onClick={() => { if (localStorage.getItem('CLONES_MANUALES') !== 'false') { navigate('/amplify/2') } else { navigate('/acceso-denegado') } }}> Manual</button>
                  <button className="createNewBuyerPersona" onClick={() => { if (localStorage.getItem('CLONES') !== 'false') { navigate('/amplify/1') } else { navigate('/acceso-denegado') } }}>Autómatico</button>

                </div>

              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default HomeEmptyAuto;
