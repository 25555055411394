import React from 'react';
import Chart from 'react-apexcharts';

const SplineChart = ({ chartData }) => {


    return (
        <div className="doughnut-chart">
            <Chart options={chartData.options} series={chartData.series} type="area" height={350} />
        </div>
    );
};

export default SplineChart;
