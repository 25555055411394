import React from 'react';
import './btnPrimary.css'
const BtnPrimary = ({value}) => {
    return (
        <>
            <button className="buttonPrimaryLoader">{value}</button>
        </>
    );
}

export default BtnPrimary;
