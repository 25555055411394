import React from 'react';
import "./icon.css"
const Icon = ({value, description, classNameValue}) => {
    return (
        <>
            <img src={value} alt={description} className={classNameValue && classNameValue} />
        </>
    );
}

export default Icon;
