import React, { useState } from "react";
import Navbar from "../../navbar/menuVertical/navbar";
import CardFAQs from "./CardFAQs/CardFAQs";
import "./FAQs.css";
const FAQsContainer = () => {
  const [navbarData, setNavbarData] = useState(false);
  const estadoMenu = (data) => {
    setNavbarData(data);
  };
  return (
    <>
      <Navbar estado={estadoMenu} />
      <div
        className={`FAQs_container ${navbarData ? "navbar-open" : ""}`}
        style={{ marginLeft: navbarData ? "228px" : "80px" }}
      >
        <header className="FAQs_header">
          <h2>Preguntas Frecuentes</h2>
        </header>
        <main className="FAQs_body">
          <CardFAQs />
        </main>
      </div>
    </>
  );
};

export default FAQsContainer;
