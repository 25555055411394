import React from "react";
import Img from "./procesoExitoso.png";
import "./procesoExitoso.css";
const ProcesoExitoso = () => {
  return (
    <>
      <div className="procesoExitosoContainer">
        <div className="procesoExitosoCard">
        <img src={Img} alt="" />
          <div className="procesoExitosoTexto"> 
            <h1>Tu cuenta se ha creado con éxito.</h1>
            <p>Ahora puedes comenzar a disfrutar de nuestra plataforma.</p>
          </div>
          <button className="procesoExitosoButton">Iniciar Sesión</button>
        </div>
      
      </div>
    </>
  );
};

export default ProcesoExitoso;
