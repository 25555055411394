import React, { useEffect, useState } from "react";
import ChartGraphClient from "./ChartsGraphics/ChartsGraphics";
import ChartDataDoughnut from "./ChartsDobleDonut/ChartsDobleDonut";
import SplineChart from "./SplineChart/SplineChart";
import Pie from "./PieCharts/PieCharts";
import { useParams } from "react-router-dom";
import Bar from "./Bar/Bar";
import Btn from "./buttons/btnPrimary";
import Navbar from "../navbar/menuVertical/navbar";
import "./Charts.css";
import axiosInstance from "../AxiosInterceptor/AxiosInterceptor";

const Charts = () => {

  let { id } = useParams();
  const [values, setValues] = useState([]);
  const [barsGender, setBarsGender] = useState([]);
  const [barsGenderM, setBarsGenderM] = useState([]);
  const [chartDataNse, setChartDataNse] = useState({});
  const [chartDataSexo, setChartDataSexo] = useState({});
  const [chartDataBenchmark, setChartDataBenchmark] = useState({});
  const [lineAge, setLineAge] = useState({});
  const [colonia, setColonia] = useState("GUADALUPE");
  const [select, setSelect] = useState("");
  const [rightSpineGraph, setRightSpineGraph] = useState({});
  const [selectGraph, setSelectGraph] = useState({});
  const [spineChart, setSpineChart] = useState({});
  const [education, setEducation] = useState({});
  const [retailPie, setRetailPie] = useState({});
  const [servicePie, setServicePie] = useState({});
  const [profile, setProfile] = useState({});
  const [segmentacionGse, setSegmentacionGse] = useState({});
  const [buyerPerson, setBuyerPerson] = useState("");
  const [canastaSearch, setCanastaSearch] = useState([]);
  const [stackedColumn, setStackedColumn] = useState({});
  const [chartData, setChartData] = useState({});
  const [chartDataDoughnut, setChartDataDoughnut] = useState({});
  const [navbarData, setNavbarData] = useState(false);
  const estadoMenu = (data) => {setNavbarData(data);};

  const varPieMx = [ /* Modifica: Ingreso Promedio Mensual, Pie Educacion, Retail, Servicios */
    "#051F63",/* v1 */
    "#2B3589",/* v2 */
    "#6F42C1",/* v3 */
    "#B54AA6",/* v4 */
    "#ED448A",/* v5 */
    "#ff5563",/* v6 */
    "#ff7e46",/* v7 */
    "#FFA902",/* v8 */
    "#FFC700"/* v9 */
  ]
  const varCategoriesNSE = [ /* Modifica: Nivel Socioeconomico (Caracteristicas Socio demograficas), Distribucion por colonia, Distribucion por edad (El primero, el otro no tiene variables) , Distribucion por municipio, segmentacion de gastos segun NSE  */
    "#2D2E83",/* AB */
    "#3BBAFF",/* C */
    "#34CE73",/* C- */
    "#FF9D0B",/* D */
    "#FF005C",/* E */
  ]

  const varGenderBenchmark =[ /* Modifica: Genero y Benchmark */
    "#FF49D7",/* Femenino */
     "#0791F9", /* Masculino */]

  useEffect(() => {
    axiosInstance.get(`${process.env.REACT_APP_URL}/api/role/2/enrichment/summary/${id}`,
    
    )
      .then((e) => {
        setValues(e.data);
        setColonia(e.data.gse_colonia[0].nmpio);
      })
      .catch((e) => { });
  }, []);

  const varGenderNSE =[ /* Modifica: Genero y Benchmark */
  "#2D2E83",/* AB */
  "#3B5FFF",/* C+ */
  "#3BBAFF",/* C */
  "#34CE73",/* C- */
  "#FFEA30",/* D+ */
  "#FF9D0B",/* D */
  "#FF005C",/* E */]

  const varDispoGasto = [ /* Modifica: Disposicion de gastos */
  "#00E5B1",/* Alto */
  "#FEB019",/* Medio */
  "#FF4560",/* Bajo */
  
]
const varBuyerPerson =[
  "#00E5B1" /* Buyer Person 1 */,
   "#9361FE", /* Buyer Person 2 */
   "#ED448A", /* Buyer Person 3 */
   "#2D2E83", /* Buyer Person 4 */
  ]

  const varWhiteColor = [
    "#ffffff" ,
     "#ffffff", 
     "#ffffff", 
     "#ffffff", 
     "#ffffff", 
     "#ffffff", 
     "#ffffff", 
     
    ]
  useEffect(() => {
    if (values.completitud) {



      const objetoSinModidicar = values.completitud[0]

      const objeto = {};
      for (const propiedad in objetoSinModidicar) {
        if (Object.prototype.hasOwnProperty.call(objetoSinModidicar, propiedad)) {
          const palabrasPropiedad = propiedad.split("_");
          const nombrePropiedad = palabrasPropiedad[1];
          objeto[nombrePropiedad] = objetoSinModidicar[propiedad];
        }
      }


      const nombresPropiedades = Object.keys(objeto).sort((a, b) => objeto[b] - objeto[a]);
      const valoresPropiedades = nombresPropiedades.map(nombrePropiedad => objeto[nombrePropiedad]);
      const nuevoArreglo = nombresPropiedades.map(elemento => elemento.replace(/^\w/, letra => letra.toUpperCase()));



      Object.keys(objeto).map(clave => clave.split('_')[1]);

      setChartData({
        options: {
          chart: {
            id: "barchart",
            // Aquí se define el color de fondo del gráfico
          },
          xaxis: {
            categories: nuevoArreglo,
          },
          grid: {
            show: false, // Oculta las líneas de fondo
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              horizontal: true,
            },
          },

          colors: ["#8338EC"],
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },
            },
          },
          dataLabels: {
            position: "center",
            offsetX: 0,
            offsetY: 0,
            style: {
              fontSize: "13px",
              fontFamily: 'Inter',
              fontWeight: "400",
              lineHeight: "18px",
            },
            formatter: function (val) {
              if (val > 9) {
                return val.toFixed(2) + "%";
              } else {
                return " ";
              }
            },
            dropShadow: {
              enabled: true,
              color: "5px 5px 5px #c2bec2",
            },
          },
        },
        series: [
          {
            name: "Porcentaje",
            data: valoresPropiedades,
          },
        ],
      });

      let list = [];
      let peso = [];
      values.renta.map((i) => {
        peso.push(i.peso);
        list.push(i.sueldo_promedio_rango);
      });
      setChartDataDoughnut({
        options: {
          colors: 
           varPieMx
          ,
          chart: {
            id: "doughnut",
            toolbar: {
              show: true,
            },
          },
          plotOptions: {
            pie: {
              donut: {
                size: "60%",
              },
              dataLabels: {
                enabled: true,
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              if (val > 5) {
                return val.toFixed(2) + "%";
              } else {
                return " ";
              }
            },
            style: {
              fontSize: "13px",
              fontFamily: 'Inter', 
              fontWeight: "400",
              lineHeight: "18px",
              colors: ["#ffffff"],
              textShadow: true,
              dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 1,
                opacity: 0.5,
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            offsetY: 0,
          },

          labels: list,
          stroke: {
            width: 0, // Establecemos el ancho del borde en cero
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },
            },
          },
        },
        series: peso,
      });

      // Crear arrays separados para cada género
      const femaleData = values.sexo_gse.filter((d) => d.GENERO === "F");
      const maleData = values.sexo_gse.filter((d) => d.GENERO === "M");

      // Sumar el peso de cada ingreso por categoría de ingresos para cada género
      const femaleTotals = {
        AB: 0,
        "C+": 0,
        C: 0,
        "C-": 0,
        D: 0,
        "D+": 0,
        E: 0,
      };
      femaleData.forEach((d) => {
        femaleTotals[d.income_level] += d.peso;
      });

      const maleTotals = {
        AB: 0,
        "C+": 0,
        C: 0,
        "C-": 0,
        D: 0,
        "D+": 0,
        E: 0,
      };
      maleData.forEach((d) => {
        maleTotals[d.income_level] += d.peso;
      });

      // Crear los datos del gráfico de pie
      const pieData = {
        labels: ["AB", "C+", "C", "C-", "D", "D+", "E"],
        series: [
          {
            name: "M",
            data: Object.values(maleTotals),
          },
          {
            name: "F",
            data: Object.values(femaleTotals),
          },
        ],
      };


      let searchGse = values.sexo_gse.filter((e) => e.GENERO === "F");
      let searchGseM = values.sexo_gse.filter((e) => e.GENERO === "M");
      let weightF = [];
      let weightM = [];
      searchGse.map((e) => {
        weightF.push(e.peso);
      });
      searchGseM.map((e) => {
        weightM.push(e.peso);
      });

      setBarsGender({
        options: {
          colors: varGenderNSE,
          dataLabels: {
            style: {
              fontSize: "13px",
              fontFamily:'Inter',
              fontWeight:"400",
              colors:varWhiteColor,
            },
            formatter: function (val) {
              if (val > 9) {
                return val.toFixed(2) + "%";
              } else {
                return " ";
              }
            },

            dropShadow: {
              enabled: true,
              color: "5px 5px 5px #c2bec2",
            },
          },
          legend: {
            show: false,
          },
          title: {
            text: "Femenino",
            style: {
              fontSize: "15px",
              fontWeight: "bold",
              color: "#333",
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: "100%",
              borderRadius: 10,
              borderRadiusApplication: "end",

            },
          },
          grid: {
            show: false, // Oculta las líneas de fondo
          },
          xaxis: {
            categories: [""],
            labels: {
              show: false,
            },
          },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            maxHeight: "1em",
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },
            },
          },
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
            dropShadow: {
              enabled: false,
              enabledOnSeries: true,
              top: 3,
              left: 3,
              blur: 3,
              color: "#000",
              opacity: 6,
            },
          },
        },
        series: [
          {
            name: "AB",
            data: [weightF[0]],
          },
          {
            name: "C+",
            data: [weightF[1]],
          },
          {
            name: "C",
            data: [weightF[2]],
          },
          {
            name: "C-",
            data: [weightF[3]],
          },
          {
            name: "D",
            data: [weightF[4]],
          },
          {
            name: "D+",
            data: [weightF[5]],
          },
          {
            name: "E",
            data: [weightF[6]],
          },
        ],
      });


      setBarsGenderM({
        options: {
          colors: varGenderNSE,
          dataLabels: {
            style: {
              fontSize: "13px",
              fontFamily:'Inter',
              fontWeight:"400",
              colors:varWhiteColor,
              textShadow: true,
              dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 1,
                opacity: 0.5,
              },
            },
            formatter: function (val) {
              if (val > 9) {
                return val.toFixed(2) + "%";
              } else {
                return " ";
              }
            },
            dropShadow: {
              enabled: true,
              color: "5px 5px 5px #c2bec2",
            },
          },
          legend: {
            show: true,
          },
          title: {
            text: "Masculino",

            style: {
              fontSize: "15px",
              fontWeight: "bold",
              color: "#333",
            },
          },
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: "100%",
              borderRadius: 10,
              borderRadiusApplication: "end",
              borderRadiusWhenStacked: "last",
            },
            pie: {
              startAngle: 90,
            },
          },
          grid: {
            show: false, // Oculta las líneas de fondo
          },

          xaxis: {
            categories: [""],
            labels: {
              show: false,
            },
          },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            maxHeight: "1em",
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },
            },
          },
        },
        series: [
          {
            name: "AB",
            data: [weightM[0]],
          },
          {
            name: "C+",
            data: [weightM[1]],
          },
          {
            name: "C",
            data: [weightM[2]],
          },
          {
            name: "C-",
            data: [weightM[3]],
          },
          {
            name: "D",
            data: [weightM[4]],
          },
          {
            name: "D+",
            data: [weightM[5]],
          },
          {
            name: "E",
            data: [weightM[6]],
          },
        ],
      });

      setChartDataSexo({
        series: [values.sexo[0].peso, values.sexo[1].peso],
        options: {
          chart: {
            width: 380,
            type: "pie",
          },

          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            offsetY: 0,
          },
          colors: varGenderBenchmark,
          labels: ["Femenino", "Masculino"],
          dataLabels: {
            formatter: function (val) {
              return val.toFixed(2) + "%";
            },
            style: {
              fontSize: "13px",
              fontFamily: 'Inter', 
              fontWeight: "400",
              colors: ["#ffffff", "#ffffff", , "black", "#ffffff"],
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                let valor = val * 100;

                return valor.toFixed(2) + "%";
              },
            },
          },
          stroke: {
            width: 0, // Establecemos el ancho del borde en cero
          },
          plotOptions: {
            pie: {
              startAngle: 180,
              endAngle: -180,
            }
          }
        },
      });

      setChartDataBenchmark({
        series: [values.sexo_benchmark[0].peso, values.sexo_benchmark[1].peso],
        options: {
          chart: {
            width: 380,
            type: "pie",
          },

          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            offsetY: 0,
          },
          colors: varGenderBenchmark,
          labels: ["Femenino", "Masculino"],
          dataLabels: {

            formatter: function (val) {
              return val.toFixed(2) + "%";
            },
            style: {
              fontSize: "13px",
              fontFamily: 'Inter', 
              fontWeight: "400",
              lineHeight: "18px",
            }
          },

          tooltip: {
            y: {
              formatter: function (val) {
                let valor = val * 100;
                return valor.toFixed(2) + "%";
              },
            },
          },
          stroke: {
            width: 0, // Establecemos el ancho del borde en cero
          },
          plotOptions: {
            pie: {
              startAngle: 180,
              endAngle: -180,
            }
          }

        },


      });
      setChartDataNse({
        options: {
          colors: varCategoriesNSE,
          chart: {
            id: "doughnut",
            toolbar: {
              show: true,
            },
          },
          plotOptions: {
            donut: {
              size: "10%",
            },

          },
          dataLabels: {
            enabled: true,
            maxWidth: 100,
            formatter: function (val) {
              let valor = JSON.stringify(val + 1).substring(0, 2);
              if (val > 5) {
                return valor + ".00%";
              } else {
                return " ";
              }
            },
            style: {
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: "400",
              lineHeight: "18px",
              colors: varWhiteColor,

              textShadow: "none",
              dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                opacity: 0.5,
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            offsetY: 0,
          },

          labels: [
            values.gse_pie[0].income_level,
            values.gse_pie[1].income_level,
            values.gse_pie[2].income_level,
            values.gse_pie[3].income_level,
            values.gse_pie[4].income_level,
          ],
          tooltip: {
            y: {
              formatter: function (val) {
                let valor = val * 100;
                return valor.toFixed(2) + "%";
              },
            },
          },
          stroke: {
            width: 0, // Establecemos el ancho del borde en cero
          },
        },
        series: [
          values.gse_pie[0].peso,
          values.gse_pie[1].peso,
          values.gse_pie[2].peso,
          values.gse_pie[3].peso,
          values.gse_pie[4].peso,
        ],
      });

      let gseAgeAB = [];
      let gseAgeC = [];
      let gseAgeCC = [];
      let gseAgeD = [];
      let gseAgeE = [];

      const ageLine = values.gse_edad.reduce((acc, { rango }) => {
        if (!acc.includes(rango)) {
          acc = acc.concat(rango);
        }
        return acc;
      }, []);
      ageLine.sort(function (a, b) {
        const aMin = parseInt(a.split("-")[0]);
        const bMin = parseInt(b.split("-")[0]);
        return aMin - bMin;
      });

      values.gse_edad.map((e) => {
        if (e.income_level === "AB") {
          gseAgeAB.push(e.contador);
        } else if (e.income_level === "C") {
          gseAgeC.push(e.contador);
        } else if (e.income_level === "C-") {
          gseAgeCC.push(e.contador);
        } else if (e.income_level === "D") {
          gseAgeD.push(e.contador);
        } else if (e.income_level === "E") {
          gseAgeE.push(e.contador);
        }
      });

      setLineAge({
        options: {
          id: "spline-chart",
          dataLabels: {
            enabled: false,
          },
          zoom: {
            enabled: false, // Deshabilita el zoom
          },
          pan: {
            enabled: false, // Deshabilita el pan
          },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 150,
            },
          },
          stroke: {
            curve: "straight",
          },
          chart: {
            zoom: {
              enabled: false,
            },
          },
          fill: {
            type: "solid",
            opacity: 1,
          },
          grid: {
            show: false, // Oculta las líneas de fondo
          },
          yaxis: {
            title: {
              text: 'Cantidad',
              rotate: 270,
              offsetY: 10,
            },
            labels: {
              formatter: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              },
            },

          },
          xaxis: {

            categories: ageLine,
            labels: {
              show: true,
              offsetY: 0, // Espacio entre las categorías y el eje X
            },
            title: {
              text: 'Edad',
              offsetY: -10,
            },
          },
        },

        series: [
          {
            name: "AB",
            data: gseAgeAB,
            color: "#2D2E83",
          },
          {
            name: "C",
            data: gseAgeC,
            color: "#3BBAFF",
          },
          {
            name: "C-",
            data: gseAgeCC,
            color: "#34CE73",
          },
          {
            name: "D",
            data: gseAgeD,
            color: "#FF9D0B",
          },
          {
            name: "E",
            data: gseAgeE,
            color: "#FF005C",
          },
        ],
      });

      let gseComunaAB = [];
      let gseComunaC = [];
      let gseComunaCC = [];
      let gseComunaD = [];
      let gseComunaE = [];
      let colonias = [];

      values.gse_municipio.map((e) => {
        colonias.push(e.colonia);

        if (e.income_lvl === "AB") {
          gseComunaAB.push(e.perfil_ampliado);
        } else if (e.income_lvl === "C") {
          gseComunaC.push(e.perfil_ampliado);
        } else if (e.income_lvl === "C-") {
          gseComunaCC.push(e.perfil_ampliado);
        } else if (e.income_lvl === "D") {
          gseComunaD.push(e.perfil_ampliado);
        } else if (e.income_lvl === "E") {
          gseComunaE.push(e.perfil_ampliado);
        }
      });

      const uniqueNmpios = values.gse_municipio.reduce((acc, { nmpio }) => {
        if (!acc.includes(nmpio)) {
          acc = acc.concat(nmpio);
        }
        return acc;
      }, []);

      setRightSpineGraph({
        options: {
          id: "spline-chart",
          dataLabels: {
            enabled: false,
          },
          zoom: {
            enabled: false, // Deshabilita el zoom
          },
          pan: {
            enabled: false, // Deshabilita el pan
          },

          colors: varCategoriesNSE,
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 150,
            },
          },

          stroke: {
            curve: "straight",
          },
          chart: {
            zoom: {
              enabled: false,
            },
          },
          fill: {
            type: "solid",
            opacity: 1,
          },
          grid: {
            show: false, // Oculta las líneas de fondo
          },

          xaxis: {
            categories: uniqueNmpios.map((city) => city.substring(0, 3)), // muestra solo las primeras 3 letras de cada ciudad

            labels: {
              style: {
                fontSize: "7px", // Establece aquí el tamaño de fuente deseado
              },

              rotate: -70,
              show: true,
              offsetY: 0, // Espacio entre las categorías y el eje X
            },
            title: {
              text: 'Municipio',
              offsetY: 0,
            },

          },
          yaxis: {
            title: {
              text: 'Cantidad',
              rotate: 270,
              offsetY: 10,
            },
            labels: {
              formatter: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              },
            },
          },

          tooltip: {
            x: {
              formatter: function (val, i) {
                return uniqueNmpios[val - 1]; // Retorna la etiqueta completa al hacer hover
              },
            },
          },
         
        },

        series: [
          {
            name: "AB",
            data: gseComunaAB,
          },
          {
            name: "C",
            data: gseComunaC,
          },

          {
            name: "C-",
            data: gseComunaCC,
          },
          {
            name: "D",
            data: gseComunaD,
          },
          {
            name: "E",
            data: gseComunaE,
          },
        ],
      });

      let selectList = [];

      values.gse_colonia.map((e) => {
        selectList.push(e.nmpio);
      });
      const unicos = selectList.filter((valor, indice) => {
        return selectList.indexOf(valor) === indice;
      });

      const graphFilter = values.gse_colonia.filter((e) => {
        return e.nmpio === colonia;
      });

      let ABfilter = [];
      let Cfilter = [];
      let CCfilter = [];
      let Dfilter = [];
      let Efilter = [];
      let comunasfilter = [];
      graphFilter.map((e) => {
        comunasfilter.push(e.colonia);

        if (e.income_level === "AB") {
          ABfilter.push(e.perfil_ampliado);
        } else if (e.income_level === "C") {
          Cfilter.push(e.perfil_ampliado);
        } else if (e.income_level === "C-") {
          CCfilter.push(e.perfil_ampliado);
        } else if (e.income_level === "D") {
          Dfilter.push(e.perfil_ampliado);
        } else if (e.income_level === "E") {
          Efilter.push(e.perfil_ampliado);
        }
      });

      setSelect(unicos);

      setSelectGraph({
        options: {
          id: "spline-chart",
          dataLabels: {
            enabled: false,
          },
          zoom: {
            enabled: false, // Deshabilita el zoom
          },
          pan: {
            enabled: false, // Deshabilita el pan
          },

          colors: varCategoriesNSE,
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 150,
            },
          },

          stroke: {
            curve: "straight",
          },
          chart: {
            zoom: {
              enabled: false,
            },
          },
          fill: {
            type: "solid",
            opacity: 1,
          },
          grid: {
            show: false, // Oculta las líneas de fondo
          },
          xaxis: {
            categories: comunasfilter
              .slice(0, 9)
              .map((city) => city.substring(0, 3)), // muestra solo las primeras 3 letras de cada ciudad

            labels: {
              style: {
                fontSize: "7px", // Establece aquí el tamaño de fuente deseado
              },
              rotate: -70,
              show: true,
              offsetY: 0, // Espacio entre las categorías y el eje X
            },

            title: {
              text: 'Colonia',
              offsetY: 0,
            },
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              },
            },

            title: {
              text: 'Cantidad',
              rotate: 270,
              offsetY: 10,
            },
          },
          tooltip: {
            x: {
              formatter: function (val, i) {
                return comunasfilter.slice(0, 9)[val - 1]; // Retorna la etiqueta completa al hacer hover
              },
            },
          },
        },

        series: [
          {
            name: "AB",
            data: ABfilter.slice(0, 9),
          },
          {
            name: "C",
            data: Cfilter.slice(0, 9),
          },

          {
            name: "C-",
            data: CCfilter.slice(0, 9),
          },
          {
            name: "D",
            data: Dfilter.slice(0, 9),
          },
          {
            name: "E",
            data: Efilter.slice(0, 9),
          },
        ],
      });

      let gseComAB = [];
      let gseComC = [];
      let gseComCC = [];
      let gseComD = [];
      let gseComE = [];
      let rango = [];
      rango.sort(function (a, b) {
        const aMin = parseInt(a.split("-")[0]);
        const bMin = parseInt(b.split("-")[0]);
        return aMin - bMin;
      });

      let filerColoniaAge = values.gse_colonia_age.filter((e) => {
        return e.nmpio === colonia;
      });

      filerColoniaAge.map((e) => {
        if (e.income_level === "AB") {
          gseComAB.push(e.perfil_ampliado);
        } else if (e.income_level === "C") {
          gseComC.push(e.perfil_ampliado);
        } else if (e.income_level === "C-") {
          gseComCC.push(e.perfil_ampliado);
        } else if (e.income_level === "D") {
          gseComD.push(e.perfil_ampliado);
        } else if (e.income_level === "E") {
          gseComE.push(e.perfil_ampliado);
        }
        rango.push(e.rango);
      });

      let series = [];

      if (gseComAB !== undefined) {
        series.push({
          name: "AB",
          data: gseComAB,
        });
      }

      if (gseComC !== undefined) {
        series.push({
          name: "C",
          data: gseComC,
        });
      }

      if (gseComCC !== undefined) {
        series.push({
          name: "C-",
          data: gseComCC,
        });
      }

      if (gseComD !== undefined) {
        series.push({
          name: "D",
          data: gseComD,
        });
      }

      if (gseComE !== undefined) {
        series.push({
          name: "E",
          data: gseComE,
        });
      }
      setSpineChart({
        options: {
          id: "spline-chart",
          dataLabels: {
            enabled: false,
          },
          zoom: {
            enabled: false, // Deshabilita el zoom
          },
          pan: {
            enabled: false, // Deshabilita el pan
          },

          colors: varCategoriesNSE,
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 150,
            },
          },

          stroke: {
            curve: "straight",
          },
          chart: {
            zoom: {
              enabled: false,
            },
          },
          fill: {
            type: "solid",
            opacity: 1,
          },
          grid: {
            show: false, // Oculta las líneas de fondo
          },
          plotOptions: {
            line: {
              dataLabels: {
                position: 'bottom',
                horizontalAlign: 'center',

              },
            },
          },
          yaxis: {
            title: {
              text: 'Cantidad',
              rotate: 270,
              offsetY: 10,
            },
            labels: {
              formatter: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              },
            },

          },
          xaxis: {
            categories: rango,
            labels: {
              style: {
                fontSize: "7px", // Establece aquí el tamaño de fuente deseado
              },
              rotate: -70,
              show: true,
              offsetY: 0, // Espacio entre las categorías y el eje X
            },
            title: {
              text: 'Edad',
              offsetY: 0,
            },
          },
        },


        series: series,
      });
      let educationalArray = [];
      let educationalArrayTitle = [];

      values.educacional.map((i) => {
        educationalArray.push(i.peso);
        educationalArrayTitle.push(i.Educacion);
      });
      setEducation({
        series: educationalArray,
        options: {
          colors: varPieMx,
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },
            },
          },
          chart: {
            width: 380,
            type: "pie",
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              if (val > 7) {
                return val.toFixed(2) + "%";
              } else {
                return " ";
              }
            },
            style: {
              fontSize: "13px",
              fontFamily: 'Inter', 
              fontWeight: "400",
              lineHeight: "18px",
            }
          },

          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            offsetY: 0,
          },

          labels: educationalArrayTitle.map((palabra) => {
            palabra = palabra.toLowerCase();
            palabra = palabra.charAt(0).toUpperCase() + palabra.slice(1);
            return palabra;
          })
          ,
          stroke: {
            width: 0, // Establecemos el ancho del borde en cero
          },
        },
      });

      let retail = [];
      let retailTitles = [];

      values.gasto_retail.map((i) => {
        retail.push(parseInt(i.Peso));
        retailTitles.push(i.Retail);
      });

      setRetailPie({
        series: retail,
        options: {
          colors: varPieMx,
          tooltip: {
            y: {
              formatter: function (val) {

                return parseInt(val).toFixed(2) + "%";
              },
            },
            value: true
          },

          chart: {
            height: 1000,
            width: 1000,
            type: "pie",
          },
          dataLabels: {
            enabled: true,

            formatter: function (val) {


              if (val > 4) {
                return parseInt(val).toFixed(2) + "%";
              } else if (val < 5) {
                return " ";
              }
            },
            value: true,
            style: {
              fontSize: "13px",
              fontFamily: 'Inter', 
              fontWeight: "400",
              lineHeight: "18px",
            }
          },

          legend: {/* Modifica referencia educacion */
            show: true,
            position: "right",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "10px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            offsetY: 10,
          },

          labels: retailTitles,
          stroke: {
            width: 0, // Establecemos el ancho del borde en cero
          },
        },
      });
      let servicio = [];
      let servicioTitle = [];

      values.gasto_servicio.map((i) => {
        servicio.push(i.Peso);
        servicioTitle.push(i.Servicio);
      }); 
      setServicePie({
        series: servicio,
        options: {
          colors: varPieMx ,
          tooltip: {
            y: {
              formatter: function (val) {

                return parseInt(val).toFixed(2) + "%";
              },
            },
          },

          chart: {
            height: 1000,
            width: 1000,
            type: "pie",
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {


              if (val > 4) {
                return parseInt(val).toFixed(2) + "%";
              } else if (val < 5) {
                return " ";
              }
            },
            style: {
              fontSize: "13px",
              fontFamily: 'Inter', 
              fontWeight: "400",
              lineHeight: "18px",
            }
          },

          legend: {
            show: true,
            position: "right",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "10px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            offsetY: 10,
          },

          labels: servicioTitle,
          stroke: {
            width: 0, // Establecemos el ancho del borde en cero
          },
        },
      });
      let tiltleProfile = [];
      let textsProfile = [];
      let valuesPerfil = [];
      values.perfil.map((i) => {
        valuesPerfil.push(i.Perfil);
        textsProfile.push(i.perfil_ampliado);
        tiltleProfile.push(i.peso);
      });

      setProfile({
        options: {
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },
            },
          },
          chart: {
            id: "doughnut",
            toolbar: {
              show: true,
            },
          },
          plotOptions: {
            pie: {
              donut: {
                size: "60%",
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              if (val > 5) {
                return val.toFixed(2) + "%";
              } else {
                return " ";
              }
            },
            style: {
              fontSize: "13px",
              fontFamily: 'Inter', 
              fontWeight: "400",
              colors: varWhiteColor,
              labels: {
                colors: "white",
                useSeriesColors: false,
              },
              textShadow: "none",
              dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                opacity: 0.5,
              },
            },
          },
          legend: {
            show: true,
            position: "right",
            floating: false,
            verticalAlign: "middle  ",

            offsetY: 0,
            offsetX: 0,

            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,

            labels: {
              colors: "white",
              useSeriesColors: false,
            },
          },
          colors: varBuyerPerson,
          labels: valuesPerfil,
          stroke: {
            width: 0,
          },
        },
        series: tiltleProfile,
      });

      let preescolar = [];
      let primaria = [];
      let secundaria = [];
      let superior = [];
      let universidad = [];

      values.educacion_gse.map((e) => {
        if (e.income_level === "AB") {
          preescolar.push(e.peso);
        } else if (e.income_level === "C") {
          primaria.push(e.peso);
        } else if (e.income_level === "C-") {
          secundaria.push(e.peso);
        } else if (e.income_level === "D") {
          superior.push(e.peso);
        } else if (e.income_level === "E") {
          universidad.push(e.peso);
        }
      });

      setSegmentacionGse({
        options: {
          colors: varCategoriesNSE,
          dataLabels: {
            style: {
              fontSize: "13px",
              fontFamily: 'Inter', 
              fontWeight: "400",
              lineHeight: "18px",
            },
            enabled: true,
            formatter: function (val) {
              let valor = val * 100;

              if (val > 0.07) {
                return valor.toFixed(2) + "%";
              } else {
                return " ";
              }
            },

            dropShadow: {
              enabled: true,
              color: "5px 5px 5px #c2bec2",
            },
          },
          title: {
            text: "Segmentación de gasto según Nivel Socioeconómico",

            style: {
              fontSize: "15px",
              fontWeight: "bold",
              color: "#333",
            },
          },
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: "100%",
              borderRadius: 10, // establecer el valor de borde redondeado
              borderRadiusApplication: "end",
              borderRadiusWhenStacked: "last",

            },
          },
          grid: {
            show: false, // Oculta las líneas de fondo
          },

          xaxis: {
            categories: [
              "Preescolar",
              "Primaria",
              "Secundaria",
              "Superior",
              "Universidad",
            ],
          },
          yaxis: {
            min: 0,
            max: 0.99,
            tickAmount: 5,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                let valor = val * 100;
                return valor.toFixed(2) + "%";
              },
            },
          },
        },
        series: [
          {
            name: "AB",
            data: preescolar,
          },
          {
            name: "C",
            data: secundaria,
          },
          {
            name: "C-",
            data: primaria,
          },
         
          {
            name: "D",
            data: superior,
          },
          {
            name: "E",
            data: universidad,
          },
        ],
      });

      let canasta = [];

      values.canasta.map((e) => {
        let search = canasta.find((element) => element === e.gasto);
        if (search === undefined) {
          canasta.push(e.gasto);
        }
        if (buyerPerson !== canasta[0]) {
          setBuyerPerson(canasta[0]);
        }
      });

      setCanastaSearch(canasta);

      let canastaAB = [];
      let canastaC = [];
      let canastaCC = [];

      let filtroCanasta = values.canasta.filter((e) => e.gasto === buyerPerson);

      filtroCanasta.map((e) => {
        if (e.nivel === "Alto") {
          canastaAB.push(e.peso);
        } else if (e.nivel === "Medio") {
          canastaC.push(e.peso);
        } else if (e.nivel === "Bajo") {
          canastaCC.push(e.peso);
        }
      });

      setStackedColumn({
        series: [
          {
            name: "Alto",
            data: canastaAB,
          },
          {
            name: "Medio",
            data: canastaC,
          },
          {
            name: "Bajo",
            data: canastaCC,
          },
        ],
        options: {
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },
            },
          },
          colors: varDispoGasto,
          dataLabels: {
            formatter: function (val) {
              return val.toFixed(2) + "%";
            },
            dropShadow: {
              enabled: true,
              color: "5px 5px 5px #c2bec2",
            },
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
            style: {
              fontSize: "13px",
              fontFamily: 'Inter', 
              fontWeight: "400",
              lineHeight: "18px",
            }
          },
          chart: {
            type: "bar",
            height: 350,
            stacked: true,

            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },

          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 0,
            },
          },
          legend: {
            position: "top",
            offsetY: 4,
          },
          fill: {
            opacity: 1,
            pattern: "none",
          },
          xaxis: {
            categories: ["AB", "C+", "C", "C-", "D", "D+", "E"],
          },
          yaxis: {
            show: false,
          },
          grid: {
            show: false, // Oculta las líneas de fondo
          },
        },
      });
    }
  }, [values.length, colonia, buyerPerson]);



  return (
    <>
      {chartData.series && (
        <>
          <div className="containerCharts">
          <div>
          <Navbar estado={estadoMenu} />
         </div>
         <div className={`amplify_body_container ${navbarData ? 'navbar-open' : ''}`} style={{ marginLeft: navbarData ? '150px' : '10px' }}>
            <div className="dashboardVerticalMenuContainer">
              {/* <div className="verticalMenu"><NavDefault /></div> */}
              <div className="Dashboard">
                {values.df_log && (
                  <h2 className="DashboardTitle">
                    Tablero {values.df_log[0].namelist}
                  </h2>
                )}
                <div className="caracteristicasSocioDemograficas">
                  {chartData.series && (
                    <ChartGraphClient options={chartData} data={values} />
                  )}</div>
                <div className="dobleDoughnutContainer">
                  <div className="dobleDoughnutContentText">
                    <h2 className="DashboardTitle">
                      Características Sociodemográficas
                    </h2>
                    <p className="dobleDoughnutText">
                      A partir del enrichment Tbrein en los clientes de
                      <strong>{values.df_log[0].organization} </strong> es
                      posible obtener las principales características
                      sociodemográficas en relación a nivel socioeconómicos,
                      distribución de género, ingreso promedio mensual, edad,
                      lugar de residencia y estilos de consumo más relevantes
                      dentro del presupuesto mensual de los clientes. De esta
                      manera se obtiene un perfil de clientes que permite
                      seleccionar grupos objetivos que calcen con la oferta que
                      se desea potenciar.
                    </p>
                  </div>
                  <div className="dobleDoughnut">
                    <div className="dobleDoughnutdiv">
                      <div className="dobleDoughnutContent">
                        <h3>Nivel socioeconómico (NSE)</h3>
                        <p>
                          De acuerdo con la distribución obtenida se observa una
                          predominancia de clientes en el segmento
                          <strong>
                            {" "}
                            {values.textos.text_gse_max_income_lvl}.
                          </strong>
                        </p>
                      </div>
                      {chartDataNse.series && (
                        <>
                          <ChartDataDoughnut chartData={chartDataNse} />
                          <p className="chartDataNseText">
                            Completitud de un{" "}
                            <strong>{values.completitud[0].comp_renta}%</strong>{" "}
                            de los datos cargados.
                          </p>
                        </>
                      )}
                    </div>
                    <div className="dobleDoughnutdiv">
                      <div className="dobleDoughnutContent">
                        <h3>Ingreso promedio mensual</h3>
                        <p>
                          Los clientes de
                          <strong> {values.df_log[0].organization}</strong>{' '}
                          concentran un ingreso promedio mensual entre los
                          <strong> {values.textos.text_renta_2}</strong>, con
                          una participación de un
                          <strong> {values.textos.text_renta_1}%</strong> con
                          respecto a la base total de registros válidos.
                        </p>
                      </div>
                      {chartDataDoughnut.series &&
                        <ChartDataDoughnut chartData={chartDataDoughnut} />
                      }<p className="chartDataNseText">
                        Completitud de un{" "}
                        <strong>{values.completitud[0].comp_gse_gse}%</strong>{" "}
                        de los datos cargados.
                      </p>
                    </div>
                  </div>

                  {/*  <div className="dobleSpineChartsContainerWithTitle"> */}
                  <div className="dobleSpineChartsWithTitle">
                    <div className="dobleDoughnutdivWithTitle">
                      <div className="divDoughnutGénero">
                        <h3>Género</h3>


                        {values.textos.text_genero_max && (
                          <p>
                            Un{" "}
                            <strong>
                              {values.textos.text_genero_max.peso}
                            </strong>{" "}
                            de los clientes de
                            <strong>{values.df_log.organization}</strong>se
                            concentran en el género{" "}
                            <strong>
                              {values.textos.text_genero_max.genero}
                            </strong>{" "}
                            Si este dato se apertura según Nivel socioeconómico,
                            se observa que en el género Masculino predominan los
                            segmentos
                            <strong>
                              {" "}
                              <>{
                                values.textos.text_genero_gse_3_max_segment_M[0]
                              },    {" "}
                                {values.textos.text_genero_gse_3_max_segment_M[1]} y     {" "}
                                {values.textos.text_genero_gse_3_max_segment_M[2]}

                              </>
                              {/*   {values.textos.text_genero_gse_3_max_segment_M.map(
                                (i) => {
                                  return <> {i}, </>;
                                }
                              )} */}
                            </strong>{" "}
                            y en el caso del género Femenino{" "}
                            <strong>
                              <>{
                                values.textos.text_genero_gse_3_max_segment_F[0]
                              },    {" "}
                                {values.textos.text_genero_gse_3_max_segment_F[1]} y     {" "}
                                {values.textos.text_genero_gse_3_max_segment_F[2]}

                              </>
                              {/*    {values.textos.text_genero_gse_3_max_segment_F.map(
                                (i) => {
                                  return <> {i} </>;
                                }
                              )} */}
                              .
                            </strong>
                          </p>
                        )}

                      </div>{" "}
                      {chartDataSexo.series && (
                        <Pie chartData={chartDataSexo} />
                      )}
                    </div>
                    <div className="dobleDoughnutdivWithTitle">
                      <div className="dobleDoughnutdivContent">
                        <h3>Benchmark</h3>
                        <p>
                          En el Benchmark utilizado como punto de referencia,
                          predomina el segmento
                          <strong>
                            {" "}
                            {values.textos.text_genero_benchmark_max.GENERO}{" "}
                          </strong>
                          con una participación del{" "}
                          <strong>
                            {" "}
                            {values.textos.text_genero_benchmark_max.peso}.{" "}
                          </strong>{" "}
                        </p>
                      </div>
                      {chartDataBenchmark.series && (
                        <Pie chartData={chartDataBenchmark} />
                      )}
                      {values.textos.texto_genero_benchmark_bajada && (
                        <p className="chartDataNseText">{values.textos.texto_genero_benchmark_bajada}</p>
                      )}{" "}
                    </div>
                  </div>

                  {/*   </div> */}
                  <div className="barsBenchamarkGeneroConTitulo">
                    <h3>Segmentación de Género según Nivel Socioeconómico</h3>
                    <div className="barsBenchamarkGenero">

                      <div className="barTitle">
                        {barsGender.series && (
                          <div className='ChartsGraphics'>

                            <Bar options={barsGender} height={110} width={600} />
                          </div>
                        )}
                        {barsGenderM.series && (
                          <div className='ChartsGraphicsMale'>

                            <Bar options={barsGenderM} height={130} />

                          </div>

                        )}
                      </div>
                      {/*
                                               <div className='barTitle'>


                                                   <Bar options={bar} height={100} />
                                                   <Bar options={barM} height={100} />
                                               </div> */}
                    </div>

                    <p className="statusCarga">
                      Completitud del<strong> {values.completitud[0].comp_genero}%</strong> de
                      los datos cargados.
                    </p>
                  </div>
                </div>

                <div className="dobleSpineChartsContainerWithTitle">
                  <h2>Distribución por nivel socioeconómico</h2>
                  <div className="dobleSpineChartsWithTitle">
                    <div className="dobleDoughnutdivWithTitle">
                      <div className="dobleDoughnutContent">
                        <h3>Distribución por edad</h3>
                        <p>
                          Es el tramo etario entre los
                          <strong>{values.textos.texto_gse_edad_segmento}</strong>
                          dónde predominan los clientes de
                          <strong>{values.df_log[0].organization}</strong>,
                          pertenecientes principalmente al segmento
                          <strong>
                            {" "}
                            {values.textos.texto_gse_municipio_segmento}
                          </strong>.
                        </p>
                      </div>
                      {lineAge.series && <SplineChart chartData={lineAge} />}

                      <p className="chartDataNseText">
                        Completitud de un <strong>    {values.completitud[0].comp_edad_gse &&
                          values.completitud[0].comp_edad_gse}%
                        </strong>  de los datos cargados.
                      </p>

                    </div>
                    <div className="dobleDoughnutdivWithTitle">
                      <div className="dobleDoughnutContent">
                        <h3>Nivel socioeconómico por municipio</h3>
                        <p>
                          A nivel de Municipios, el{" "}
                          <strong>{values.textos.texto_gse_municipio_p}%</strong>{" "}
                          de los clientes de
                          <strong>{values.df_log[0].organization}</strong> se
                          concentran en 9 municipios, en los cuales predominan los
                          segmentos
                          <strong>
                            {" "}
                            {values.textos.texto_gse_municipio_segmento}
                          </strong>.
                        </p>
                      </div>
                      {rightSpineGraph.options && (
                        <SplineChart chartData={rightSpineGraph} />
                      )}
                      <p className="chartDataNseText">
                        Completitud de un{" "}
                        <strong>   {values.completitud[0].comp_gse_gse &&
                          values.completitud[0].comp_gse_gse}%</strong>
                        de los datos cargados.
                      </p>
                    </div>
                  </div>

                  <div className="dobleSpineChartsContainer">
                    <div className="dobleDoughnutdiv distribucionporColonia">
                      <div className="dobleDoughnutdivTextAndTitle">
                        <h3>Distribución por colonia</h3>
                        <p>
                          De acuerdo con la dirección de residencia registrada por
                          los clientes de
                          <strong> {values.df_log[0].organization}, </strong> es
                          posible identificar dónde se encuentran las mayores
                          concentraciones a nivel de Colonia/Municipio, lo cual
                          permite determinar acciones a nivel geográfico para
                          penetrar el mercado.
                        </p>
                      </div>
                      <div className="selectMunicipioContainer">
                        <p className="seleccionarMunicipio">
                          <strong>Seleccionar Municipio</strong>
                        </p>
                        {select.length > 1 && (
                          <select
                            className="chartsSelect"
                            name="Colonia"
                            onChange={(e) => {
                              setColonia(e.target.value);
                            }}
                          >
                            {select.map((e) => {
                              return (
                                <option key={e + "Colonia"} value={e}>
                                  {e}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                      {selectGraph.series && (
                        <SplineChart chartData={selectGraph} />
                      )}
                    </div>
                    <div className="dobleDoughnutdiv distribucionporColonia">
                      <div className="dobleDoughnutdivTextAndTitle">
                        <h3>Distribución por edad</h3>
                        <p>
                          Otro perfil de interés corresponde a la apertura de
                          Nivel socioeconómico y tramo etario, de esta manera se
                          aplican estrategias dirigidas en combinación de estas
                          variables.
                        </p>
                      </div>
                      {spineChart.series && (
                        <SplineChart chartData={spineChart} />
                      )}
                    </div>
                  </div>
                </div>

                <div className="dobleSpineChartsContainerWithTitle">
                  <div className="consumoContainer">
                    <h2>CONSUMOS</h2>
                    <p className="dobleSpineChartsContainerText">
                      Los consumos en los principales ejes de gasto para un
                      grupo familiar como lo son Educación, Retail y Servicios
                      permiten determinar preferencias y comportamientos de
                      gasto según nivel socioeconómico.
                    </p>
                  </div>
                  <div className="educationContainer">
                    <div className="educationContainerText">
                      <h3>Educación</h3>
                      <p className="dobleSpineChartsContainerText">
                        Al observar los consumos en educación es posible inferir
                        las características del grupo familiar, el registro del
                        gasto promedio mensual que va desde preescolar hasta
                        universitario identifica una tendencia en la composición
                        del grupo familiar.
                      </p>
                      {values.textos.texto_educacion[0].peso && (
                        <p className="dobleSpineChartsContainerText">
                          Del grupo de clientes analizado para
                          <strong> {values.df_log[0].organization}</strong> el
                          gasto se concentra en un{" "}
                          <strong>
                            {" "}
                            {values.textos.texto_educacion[0].peso}%
                          </strong>{" "}
                          de{" "}
                          <strong>
                            {" "}
                            {values.textos.texto_educacion[0].Edudacion.toLowerCase()}
                          </strong>
                          . En cuanto a los segmentos socioeconómicos se observa
                          que es el nivel
                          <strong> {values.textos.texto_gse_educacion}</strong> el
                          cual tiene el mayor gasto en esta categoría.
                        </p>
                      )}
                    </div>

                    <div className="dobleSpineChartsWithTitle">
                      <div className="dobleDoughnutdivWithTitle">
                        {education.series && <Pie chartData={education} />}
                      </div>
                      <div className="dobleDoughnutdivWithTitle">
                        <div className="barDivContainer">

                          <div className="barDivContainerGraph">
                            {segmentacionGse.series && (
                              <Bar options={segmentacionGse} height={250} />
                            )}
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="retailContainer">
                    <div className="retailContainerText">
                      <h3>Retail</h3>
                      <p>
                        El gasto mensual en retail para los clientes de
                        <strong> {values.df_log[0].organization}</strong> facilita
                        la definición del mix de productos que el grupo familiar
                        consume, entregando un mayor entendimiento en la conducta de
                        gasto de los clientes.
                      </p>
                      <p>
                        Del grupo de clientes analizado para
                        <strong> {values.df_log[0].organization}</strong> el gasto
                        se concentra en un
                        <strong>
                          {" "}
                          {(values.textos.texto_gasto_retail[0].Peso / 100).toFixed(2)}% {" "}
                        </strong>
                        en
                        <strong>
                          {" "}
                          {values.textos.texto_gasto_retail[0].Retail}.
                        </strong>
                      </p>
                    </div>
                    <div className="dobleSpineChartsWithTitleReverse">
                      <div className="dobleDoughnutdivWithTitle">
                        {retailPie.series && <Pie chartData={retailPie} />}
                      </div>
                      {/* <div className='dobleDoughnutdivWithTitle'>


                                           <Bar options={bars} height={200} />


                                       </div> */}
                    </div>
                  </div>
                  <div className="serviciosContainer">
                    <div className="serviciosContainerText">
                      <h3>Servicios</h3>
                      <p>
                        El gasto en servicios, es otro eje que permite identificar
                        la tendencia que siguen los clientes de
                        <strong> {values.df_log[0].organization}</strong>, en cuanto
                        a cómo priorizan los distintos niveles socioeconómicos las
                        categorías contempladas.
                      </p>
                      <p>
                        Del grupo de clientes analizado para
                        <strong> {values.df_log[0].organization} </strong> el gasto
                        se concentra en un
                        <strong>
                          {" "}
                          {(values.textos.texto_gasto_servicio[0].Peso / 100).toFixed(2)}%
                          {values.textos.texto_gasto_servicio[0].peso} {" "}
                        </strong>
                        de gasto en{" "}
                        <strong>
                          {" "}
                          {values.textos.texto_gasto_servicio[0].Servicio}
                        </strong>
                        .
                      </p>
                    </div>
                    <div className="dobleSpineChartsWithTitle">
                      <div className="dobleDoughnutdivWithTitle">
                        {servicePie.series && <Pie chartData={servicePie} />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="verticalBar">
                  <div className="verticalBarText">
                    {values.df_log[0].expenses && (
                      <h2>
                        Disposición de gasto en la industria{" "}
                        {values.df_log[0].industry} SEGMENTO {" "}
                        {values.df_log[0].expenses}
                      </h2>
                    )}
                    <p>
                      Se determinaron los gastos más importantes correspondientes
                      a la industria y segmento seleccionado, desagregado según
                      Nivel Socioeconómico.
                    </p>
                  </div>
                  <div className="chartsSelectContainer">
                    {/* select */}
                    <select
                      className="chartsSelectTwo borde-inputs-generales"
                      name="BuyerPerson"
                      onChange={(e) => {
                        setBuyerPerson(e.target.value);
                      }}
                    >
                      {canastaSearch.map((e) => {
                        return (
                          <option key={e + "Buyer"} value={e}>
                            {e}
                          </option>
                        );
                      })}
                    </select>
                    {stackedColumn.series && (
                      <div className='ChartsGraphics'>
                        <Bar options={stackedColumn} height={450} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="finalGraph">
                  <div className="buyerPersonText">
                    <h2>DETALLE DE Buyer Person</h2>
                    <p>
                      Una vez completado el perfil de clientes de{" "}
                      <strong style={{ color: "white" }}>
                        {" "}
                        {values.df_log[0].organization}
                      </strong>
                      , se obtienen los Buyer Person correspondientes con sus
                      principales características de consumo, el cual permite
                      generar una audiencia conectada con este perfil y aumentar
                      la conversión.
                    </p>
                  </div>
                  <div className="buyerPerson">
                    {profile.series && (
                      <ChartDataDoughnut
                        className="buyerPersonGraph"
                        chartData={profile}
                      />
                    )}
                  </div>

                  {profile.series && (
                    <>
                      {values.perfil.map((i) => {
                        return (
                          < div key={i.Perfil + 'finalGraphText'}>
                            <div className="finalGraphText">
                              <p>
                                <strong style={{ color: "white",fontFamily:"Inter", fontWeight:"600", fontSize:"16px" }}>
                                  {i.Perfil}
                                </strong>
                              </p>
                              <p>{i.perfil_ampliado}</p>
                            </div>
                          </div>
                        );
                      })}
                      <div className="buttonContainer">
                        <button
                          onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        >
                          <Btn value="Volver arriba"></Btn>
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          </div>
        </>
      )}
      {!values.resumen_carga && (
        <div className="loaderContainer">
          <div className="loader1Charts"></div>
          <p>Estamos procesando tus datos</p>
        </div>

      )}
    </>
  );
};

export default Charts;
