import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "./tooltips/Tooltip";
import { AppContext } from "../../Context/Context";
import { useLocation } from "react-router-dom";
import ProfileIcon from "./navbarAssets/IconPerfilNav.svg";
import LogOut from "./navbarAssets/IconCerrarSesionNav.svg";
import IsoPositivo from "./navbarAssets/positivo";
import IsoPositivoClosed from "./navbarAssets/brandSimple.svg";
import NavbarButtonExpanded from "./navbarAssets/navbarButtonExpanded.svg";
import NavbarButtonClosed from "./navbarAssets/navbarButtonClosed.svg";
import BoxNavbar from "./boxNavbar/boxNavbar";
import BanderaChile from "./boxNavbar/boxNavbarAssets/banderaChile.png";
import BanderaMexico from "./boxNavbar/boxNavbarAssets/banderaMexico.png";
import Icon from "./navbarAssets/icon";
import EnterpriceList from "./EnterpriseList/EnterpriceList";
import FlechaNavbar from "./navbarAssets/flechanavbar.svg";
import Administrador from './navbarAssets/admin_panel_settings.png'
import AdministradorOrange from './navbarAssets/admin_panel_settingsOrange.png'

import "./navbar.css";

const Navbar = ({ estado }) => {
  const { items } = useContext(AppContext);
  const [expanded, setExpanded] = useState(true);
  const [adminExpanded, setAdminExpanded] = useState(false);
  const [listExpanded, setListExpanded] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [icon, setIcon] = useState(false);


  const boxNavbarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  // Función para ajustar el estado del navbar en función del ancho de la pantalla
  const updateNavbarState = () => {
    if (window.innerWidth >= 1440) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  useEffect(() => {
    updateNavbarState();
    window.addEventListener("resize", updateNavbarState);

    return () => {
      window.removeEventListener("resize", updateNavbarState);
    };
  }, []);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  const toggleAdmin = () => {
    setAdminExpanded(!adminExpanded);
  };
  const toggleList = () => {
    setListExpanded(!listExpanded);

  };

  useEffect(() => {
    const closeListOnClickOutside = (event) => {
      if (
        boxNavbarRef.current &&
        !boxNavbarRef.current.contains(event.target)
      ) {
        setListExpanded(false);
      }
    };

    document.addEventListener("click", closeListOnClickOutside);

    return () => {
      document.removeEventListener("click", closeListOnClickOutside);
    }
  }, [boxNavbarRef]);


  const LogoIconExpanded = () => <IsoPositivo />;
  const LogoIconClosed = () => <img className="brandSimpleNavbar" src={IsoPositivoClosed} alt="Logo" />;
  const logoIcon = expanded ? <LogoIconExpanded /> : <LogoIconClosed />;
  const contentClass = expanded ? "contentExpanded" : "contentClosed";
  const menuPrincipalClass = expanded
    ? "menuPrincipalAbierto"
    : "menuPrincipal";
  const menuSecundarioClass = expanded
    ? "menuSecundarioAbierto"
    : "menuSecundario";
  const toggleButtonClass = expanded ? "toggleButtonOpen" : "toggleButton";

  useEffect(() => {
    estado(expanded);
  }, [expanded]);

  useEffect(() => {
    setSelectedLink(location.pathname);
  }, [location]);

  function capitalizarTexto(cadena) {
    const palabrasExcepcion = ["de", "organización"];
    const palabras = cadena.toLowerCase().split(" ");

    const resultado = palabras.map((palabra) => {
      if (palabra === "s.a.") {
        return "S.A.";
      } else if (palabra === "org") {
        return "Org.";
      } else if (!palabrasExcepcion.includes(palabra)) {
        return palabra.charAt(0).toUpperCase() + palabra.slice(1);
      } else {
        return palabra;
      }
    });

    return resultado.join(" ");
  }
  return (
    <>
      <div className={`navbar ${expanded ? "expanded" : ""}`}>
        <div className="boxBrandButton">
          <div className="boxBrandNav">{logoIcon}</div>
          <>
            <button className={toggleButtonClass} onClick={toggleNavbar}>
              {expanded ? (
                <img src={NavbarButtonExpanded} alt="icon navbar" />
              ) : (
                <img src={NavbarButtonClosed} alt="icon navbar" />
              )}
            </button>
          </>
        </div>

        <div className={`menuContentContainer ${contentClass}`}>
          <div className={menuPrincipalClass}>
            {/* <Link className="navbarLink" to="/inicio/buyer-persona">
              {expanded ? (
                <Icon value={HomeIcon} />
              ) : (
                <Tooltip text={"Inicio"}>
                  <Icon value={HomeIcon} />
                </Tooltip>
              )}
              {expanded && <span>Inicio</span>}
            </Link> */}
            {/* bp */}
            <div
              className={` navbarLinkContainer ${selectedLink === "/inicio/buyer-persona" ? "itemNavLinkselected" : ""}`}
            >
              <div className="navbarRectangleDefault navbarRectangleHover navbarRectangleSelected"></div>
              <Link
                className={`navbarLink ${selectedLink === "/inicio/buyer-persona" ? "selected" : ""
                  }`}
                to="/inicio/buyer-persona"
              >
                {expanded ? (
                  <svg
                    className="BPIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M8.17766 13.2532L7.5 15.625L6.82234 13.2532C6.4664 12.0074 5.4926 11.0336 4.24682 10.6777L1.875 10L4.24683 9.32234C5.4926 8.9664 6.4664 7.9926 6.82234 6.74682L7.5 4.375L8.17766 6.74683C8.5336 7.9926 9.5074 8.9664 10.7532 9.32234L13.125 10L10.7532 10.6777C9.5074 11.0336 8.5336 12.0074 8.17766 13.2532Z"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.2157 7.26211L15 8.125L14.7843 7.26212C14.5324 6.25444 13.7456 5.46764 12.7379 5.21572L11.875 5L12.7379 4.78428C13.7456 4.53236 14.5324 3.74556 14.7843 2.73789L15 1.875L15.2157 2.73788C15.4676 3.74556 16.2544 4.53236 17.2621 4.78428L18.125 5L17.2621 5.21572C16.2544 5.46764 15.4676 6.25444 15.2157 7.26211Z"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.0785 17.1394L13.75 18.125L13.4215 17.1394C13.2348 16.5795 12.7955 16.1402 12.2356 15.9535L11.25 15.625L12.2356 15.2965C12.7955 15.1098 13.2348 14.6705 13.4215 14.1106L13.75 13.125L14.0785 14.1106C14.2652 14.6705 14.7045 15.1098 15.2644 15.2965L16.25 15.625L15.2644 15.9535C14.7045 16.1402 14.2652 16.5795 14.0785 17.1394Z"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <Tooltip
                    text={"Buyer persona"}
                    className={"tooltip-containerBP"}
                  >
                    <svg
                      className="BPIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M8.17766 13.2532L7.5 15.625L6.82234 13.2532C6.4664 12.0074 5.4926 11.0336 4.24682 10.6777L1.875 10L4.24683 9.32234C5.4926 8.9664 6.4664 7.9926 6.82234 6.74682L7.5 4.375L8.17766 6.74683C8.5336 7.9926 9.5074 8.9664 10.7532 9.32234L13.125 10L10.7532 10.6777C9.5074 11.0336 8.5336 12.0074 8.17766 13.2532Z"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.2157 7.26211L15 8.125L14.7843 7.26212C14.5324 6.25444 13.7456 5.46764 12.7379 5.21572L11.875 5L12.7379 4.78428C13.7456 4.53236 14.5324 3.74556 14.7843 2.73789L15 1.875L15.2157 2.73788C15.4676 3.74556 16.2544 4.53236 17.2621 4.78428L18.125 5L17.2621 5.21572C16.2544 5.46764 15.4676 6.25444 15.2157 7.26211Z"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.0785 17.1394L13.75 18.125L13.4215 17.1394C13.2348 16.5795 12.7955 16.1402 12.2356 15.9535L11.25 15.625L12.2356 15.2965C12.7955 15.1098 13.2348 14.6705 13.4215 14.1106L13.75 13.125L14.0785 14.1106C14.2652 14.6705 14.7045 15.1098 15.2644 15.2965L16.25 15.625L15.2644 15.9535C14.7045 16.1402 14.2652 16.5795 14.0785 17.1394Z"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Tooltip>
                )}
                {expanded && <span>Buyer persona</span>}
              </Link>
            </div>
            {/* amplify */}
            {items === 43 && (
            <div
              className={` navbarLinkContainer ${selectedLink === "/inicio/amplify" ? "itemNavLinkselected" : ""
                }`}
            >
              <div className="navbarRectangleDefault navbarRectangleHover navbarRectangleSelected"></div>
              
                <Link
                  className={`navbarLink ${selectedLink === "/inicio/amplify" ? "selected" : ""
                    }`}
                  to="/inicio/amplify"
                >
                  {expanded ? (
                    <svg className="AmplifyIcon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M1.875 15L7.5 9.37502L11.0887 12.9637C12.0918 10.9901 13.7535 9.33527 15.9335 8.36464L18.2174 7.3478M18.2174 7.3478L13.2664 5.44727M18.2174 7.3478L16.3169 12.2989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  ) : (
                    <Tooltip text={"Amplify"}>
                      <svg className="AmplifyIcon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M1.875 15L7.5 9.37502L11.0887 12.9637C12.0918 10.9901 13.7535 9.33527 15.9335 8.36464L18.2174 7.3478M18.2174 7.3478L13.2664 5.44727M18.2174 7.3478L16.3169 12.2989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Tooltip>
                  )}
                  {expanded && <span>Amplify</span>}
                </Link>
          
            </div>
                )}
            <div className=" dividerNavPrincipal"></div>
          </div>
          {/* perfil secundario */}
          <div className={menuSecundarioClass}>
            {localStorage.getItem('privilege') === "true" &&
            <div
              className={` navbarLinkContainer ${selectedLink && ( selectedLink.startsWith("/administrador"))
                ? "itemNavLinkselected"
                : ""
                }`}
            >
              <div className="navbarRectangleDefault navbarRectangleHover"></div>
              <button
                className="navbarLink"
                onClick={() => {
                  navigate("/administrador");
                }}
                onMouseOver={()=>{
                  setIcon(true)
                }}
                onMouseLeave={()=>{
                  setIcon(false)
                }}
              >
                {expanded ? (
                  selectedLink && selectedLink.startsWith("/administrador")  ? (
                    <Icon value={AdministradorOrange} />
                  ) : (
                    <Icon value={Administrador} />

                  )
                ) : (
                  <Tooltip text={"Administrador"}>
                    {   selectedLink && selectedLink.startsWith("/administrador") ? (
                      <Icon value={AdministradorOrange} />
                    ) : (
                      <Icon value={Administrador} />
                    )}
                  </Tooltip>
                )}

                {expanded && <span style={{marginLeft:'10px'}}>Administrador</span>}
              </button>
            </div>
            }
            {/* organizacion */}

            <div
              className={`navbarLinkContainer navbarRectangleHover navOrganizacion `}
            >

              <div className="navbarRectangleDefault navbarRectangleHover "></div>

              <Link
                className={`navbarLink ${boxNavbarRef.current !== null ? "active" : ""
                  }`}
                onClick={() => { toggleList() }}
              >
                {expanded ? (
                  <>
                    {items === 43 ? (
                      <Icon value={BanderaChile} classNameValue={'navBarFlagCountry'}/>
                    ) : (
                      <Icon value={BanderaMexico} classNameValue={'navBarFlagCountry'}/>
                    )}
                  </>
                ) : (
                  <Tooltip
                    className="tooltip-menu-secundario"
                    text={"Organización"}
                  >
                    <>
                      {items === 43 ? (
                        <Icon value={BanderaChile} classNameValue={'navBarFlagCountry'}/>
                      ) : (
                        <Icon value={BanderaMexico} classNameValue={'navBarFlagCountry'} />
                      )}
                    </>
                  </Tooltip>
                )}


                {expanded && (
                  <div className="separaciónTextoImagen">
                    {(localStorage.getItem('companyFilter') !== null && localStorage.getItem('companyFilter').length > 13) ? (
                      <span style={{marginLeft:'3px'}}>{capitalizarTexto(localStorage.getItem('companyFilter')).slice(0, 13)}...</span>
                    ) : (
                      <span style={{marginLeft:'3px'}}>{capitalizarTexto(localStorage.getItem('companyFilter'))}</span>
                    )}
                    <img
                      src={FlechaNavbar}
                      alt="flecha desplegable"
                      className="ImagenSeparacionNavbar"
                    />
                  </div>
                )}
              </Link>

            </div>
            {listExpanded && (
              <EnterpriceList ref={boxNavbarRef} expanded={expanded} />
            )}

            {/* perfil */}
            <div
              className={` navbarLinkContainer ${selectedLink === ""
                ? "itemNavLinkselected"
                : ""
                }`}
            >
              <div className="navbarRectangleDefault navbarRectangleHover navbarRectangleSelected" style={{marginLeft:'-1px'}}></div>
              <Link
                className={`navbarLink ${boxNavbarRef.current !== null ? "active" : ""
                  }`}
                onClick={toggleAdmin}
              >
                {expanded ? (
                  <Icon value={ProfileIcon} />
                ) : (
                  <Tooltip className="tooltip-menu-secundario" text={"Perfil"}>
                    <Icon value={ProfileIcon} />
                  </Tooltip>
                )}
                {expanded && <span style={{marginLeft:'2px'}}>Perfil</span>}
              </Link>
              {adminExpanded && (
                <BoxNavbar ref={boxNavbarRef} expanded={expanded} />
              )}
            </div>
            {/* FAQS */}
            {/* <div
              className={` navbarLinkContainer ${
                selectedLink === "/preguntas-frecuentes"
                  ? "itemNavLinkselected"
                  : ""
              }`}
            >
              <div className="navbarRectangleDefault navbarRectangleHover navbarRectangleSelected"></div>
            <Link
              className={`navbarLink ${
                selectedLink === "/preguntas-frecuentes" ? "selected" : ""
              }`}
              to="/preguntas-frecuentes"
            >
              {expanded ? (
               <svg className="helpIcon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM11.3564 6.73606C10.6157 6.08798 9.38466 6.08798 8.64399 6.73606C8.38421 6.96337 7.98936 6.93704 7.76206 6.67727C7.53476 6.4175 7.56108 6.02264 7.82086 5.79534C9.03281 4.73489 10.9676 4.73489 12.1795 5.79534C13.4404 6.89862 13.4404 8.72638 12.1795 9.82966C11.9656 10.0168 11.7301 10.1701 11.4817 10.2903C10.9181 10.563 10.6252 10.9351 10.6252 11.25V11.875C10.6252 12.2202 10.3454 12.5 10.0002 12.5C9.65501 12.5 9.37519 12.2202 9.37519 11.875V11.25C9.37519 10.1841 10.2579 9.49387 10.9372 9.16514C11.0894 9.09148 11.2304 8.99916 11.3564 8.88894C12.0481 8.28367 12.0481 7.34133 11.3564 6.73606ZM10 15C10.3452 15 10.625 14.7202 10.625 14.375C10.625 14.0298 10.3452 13.75 10 13.75C9.65482 13.75 9.375 14.0298 9.375 14.375C9.375 14.7202 9.65482 15 10 15Z" />
             </svg>
              ) : (
                <Tooltip className="tooltip-menu-secundario" text={"Ayuda"}>
                  <Icon value={Help} />
                </Tooltip>
              )}
              {expanded && <span>Ayuda</span>}
            </Link>
            </div> */}
            <div className="dividerNavPrincipal"></div>
            <div
              className={` navbarLinkContainer ${selectedLink === ""
                ? "itemNavLinkselected"
                : ""
                }`}
            >
              <div className="navbarRectangleDefault navbarRectangleHover"></div>
              <button
                className="navbarLink"
                onClick={() => {
                  localStorage.clear();
                  navigate("/iniciar-sesion");
                }}
              >
                {expanded ? (
                  <Icon value={LogOut} />
                ) : (
                  <Tooltip text={"Cerrar sesión"}>
                    <Icon value={LogOut} />
                  </Tooltip>
                )}
                {expanded && <span>Cerrar sesión</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
