import React from 'react';
import './badged.css'
const Badged = ({value, style}) => {
    return (
        <div className={style}>
            {value}
        </div>
    );
}

export default Badged;
