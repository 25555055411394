import React, { useState } from 'react';
import downArrow from '../acordeonText/downArrow.png'
import './AcordeonText.css';
import './AcordeonTextList.css';

const AcordeonList = ({ listItems }) => {
  return (
    <>
      <div className="acordeonListContainer">
        <ul>
          {listItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </>
  );
};

const AcordeonTextList = ({ title, optionalText, listItems }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="cardAcordeon">
      <div className="card-header">
        <h2>{title}</h2>
        <button onClick={() => setOpen(!open)}>
          <img src={downArrow} alt="Fecha abajo" />
        </button>
      </div>
      <div className="card-body" hidden={!open}>
        <p>{optionalText}</p>
        <AcordeonList listItems={listItems} />
      </div>
    </div>
  );
};

export default AcordeonTextList;
