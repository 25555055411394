import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../Context/Context";
import Table from "./tableContainer/tableContainer";
import AutomaticoImg from "./icons/automatico.svg";
import ManualImg from "./icons/manual.svg";
import MasImg from "./icons/mas.svg";
import Navbar from "../navbar/menuVertical/navbar";
import BuyerPersona from "./estadoDatos/cardBuyerPersonaHome";
import Amplify from "./estadoDatos/cardAmplifyHome";
import "./homeProcessExecuted.css";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from '../AxiosInterceptor/AxiosInterceptor';

const Home = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { country, setContadoresHome, items, setPercent } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [dataAmplify, setDataAmplify] = useState([]);
  const [loading, setLoading] = useState(true);
  const [navbarData, setNavbarData] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [amplifyReady, setAmplifyReady] = useState(false);
  const [bpReady, setBpReady] = useState(false);
  const [cardready, setCardReady] = useState(false);
  const [nameUser, setNameUser] = useState("");
  const [card, setCard] = useState();
  const [date, setDate] = useState();
  const [Amplifydate, setAmplifyDate] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [completeDate, setCompleteDate] = useState(0)
  const [amplifyCompleteDate, setAmplifyDateComplete] = useState(0)

  const estadoMenu = (data) => {
    setNavbarData(data);
  };
  const handleImageLoad = () => {
    setImagesLoaded(true);
  };


  const getCard = async () => {
    if (country !== undefined || localStorage.getItem("selectedCountry")) {
      const link = country !== undefined ? country : localStorage.getItem("selectedCountry");
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/index`,
        );
      
        localStorage.setItem("name", response.data.user_setting.username);
        setNameUser(response.data.user_setting.username);
        let obj = {
          consumidoBp: response.data.consumo.consumido_enrichment,
          planBp: response.data.consumo.enrichment_plan,
          consumidoCl: response.data.consumo.consumido_clon,
          planCl: response.data.consumo.clon_plan,
          percentBp: response.data.consumo["consumido_enrichment_%"] * 100,
          percentCl: response.data.consumo["consumido_clon_%"] * 100,
          user_name: response.data.user_setting.username,
          clon_balance: response.data.consumo.clon_balance,
          enrichment_balance: response.data.consumo.enrichment_balance,
        }
        setCard(obj);
        setContadoresHome(obj);
        localStorage.setItem("lmnkknn", response.data.cursor.token);
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.status &&
          (error.request && error.request.status && error.response.status === 401 || error.request && error.request.status && error.response.status === 422)
        ) {
          localStorage.clear();
          navigate("/iniciar-sesion");
        }
      }
      setCardReady(true);

    } else {
      navigate("/iniciar-sesion");
    }
  };

  const abrirModal = (event) => {
    event.stopPropagation(); // Evita la propagación del evento
    setModalVisible((prevVisible) => !prevVisible);
  };

  const getAmplify = async () => {
    if (country !== undefined || localStorage.getItem("selectedCountry")) {
      const link = localStorage.getItem('selectedCountry')
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_URL}/api/role/${link}/clones`);
        setDataAmplify(response.data.df_profile.reverse());
        localStorage.setItem("lmnkknn", response.data.cursor.token);
      } catch (error) {
        if (error.status || error.request) {
          if (error.response && error.response.status && error.response.status === 401 || error.response && error.response.status && error.response.status === 422) {
            localStorage.clear();
            navigate("/iniciar-sesion");
          }
        }
      }
      setAmplifyReady(true);

    } else {
      navigate("/iniciar-sesion");
    }
  };

  const getEnrichment = async () => {
    if (country !== undefined || localStorage.getItem("selectedCountry")) {
      let link = country !== undefined ? country  :localStorage.getItem("selectedCountry")
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/enrichment/list`,
        );
        setData(response.data.df_profile.reverse());
        localStorage.setItem("lmnkknn", response.data.cursor.token);
      } catch (error) {
        if (error.response && error.response.status && error.response.status === 401 || error.response && error.response.status && error.response.status === 422) {
          localStorage.clear();
          navigate("/iniciar-sesion");
        }
      }
      setBpReady(true);

    } else {
      navigate("/iniciar-sesion");
    }

  };

  useEffect(() => {
    if (cardready && amplifyReady && bpReady) {
        setLoading(false);
    }
  }, [cardready, amplifyReady, bpReady]);

  useEffect(() => {
    const selectedCountry = localStorage.getItem("selectedCountry");
    const companyFilter = localStorage.getItem("companyFilter");
    const selectedItems = localStorage.getItem("selectedItems");
    const token = localStorage.getItem("token");
    const cty = localStorage.getItem("cty");
    const flag = localStorage.getItem("flag");
    const name = localStorage.getItem("name");
    const companies = localStorage.getItem("companies");

    if (selectedCountry && companyFilter && selectedItems && token && cty && flag && name && companies) {
     if(country !== undefined && items !== undefined){

      getEnrichment();
      getAmplify();
      getCard();}
    }
  }, [country, items, localStorage.getItem('companyFilter')]);
  

  useEffect(() => {
    if (!loading) {
      const intervalId = setInterval(() => {
        getEnrichment();
        getAmplify();
        getCard();
      }, 10000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  useEffect(() => {
    if (card && card.percentBp) {
      setPercent({ BP: 100 - card.percentBp, AM: 100 - card.percentCl });
    }
    localStorage.setItem("card", JSON.stringify(card));
  }, [card]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const modal = document.querySelector(".modalAmplifyConteiner");
      if (modal && !modal.contains(event.target)) {
        setModalVisible(false);
      }
    };

    if (modalVisible) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [modalVisible]);


  function sumarMontosDelMes(elementos, mes, año) {
    const total = elementos.reduce((acumulador, elemento) => {
      let fecha = undefined;
      if (elemento.created_at) {
        fecha = new Date(elemento.created_at);
      } else {
        fecha = new Date(elemento.fecha_solicitud);
      }

      if (elemento.action_ !== undefined) {

        if (fecha.getMonth() === mes && fecha.getFullYear() === año && (elemento.action_ === 5 || elemento.action_ === 4)) {
          return acumulador + elemento.amount;
        }
        return acumulador;
      } else if (elemento.procesado !== undefined) {

        if (fecha.getMonth() === mes && fecha.getFullYear() === año && (elemento.procesado === 5 || elemento.procesado === 4 || elemento.procesado === 2)) {
          return acumulador + elemento.amount;
        }
        return acumulador;
      }
    }, 0);

    return total;
  }
  function sumarMontos(elementos) {

    const total = elementos.reduce((acumulador, elemento) => {
      if (elemento.action_ !== undefined) {

        if ((elemento.action_ === 5 || elemento.action_ === 4)) {
          return acumulador + elemento.amount;
        }
        return acumulador;
      } else if (elemento.procesado !== undefined) {

        if (elemento.procesado === 5 || elemento.procesado === 4 || elemento.procesado === 2) {
          return acumulador + elemento.amount;
        }
        return acumulador;
      }
    }, 0);

    return total;
  }

  useEffect(() => {
    const fechaActual = new Date();
    const month = fechaActual.getMonth();
    const year = fechaActual.getFullYear();

    let selectedCompanyData = data.slice().filter((item) => item.organization === localStorage.getItem('companyFilter'))
    let selectedCompanyAmplify = dataAmplify.slice().filter((item) => item.org === localStorage.getItem('companyFilter'))

    if (data.length > 0) {
      const totalAmount = sumarMontosDelMes(selectedCompanyData, month, year);
      const totalAmountComplete = sumarMontos(selectedCompanyData);

      setDate(totalAmount);
      setCompleteDate(totalAmountComplete)
    }

    if (dataAmplify.length > 0) {
      const totalAmountAmplify = sumarMontosDelMes(selectedCompanyAmplify, month, year);
      const totalAmountAmComplete = sumarMontos(selectedCompanyAmplify);
      setAmplifyDate(totalAmountAmplify);
      setAmplifyDateComplete(totalAmountAmComplete);
    }

  }, [data, dataAmplify]);

  if (!imagesLoaded) {
    return (
      <div>
        <img src={AutomaticoImg} onLoad={handleImageLoad} alt="icono Automatico" />
        <img src={ManualImg} onLoad={handleImageLoad} alt="icono Manuales" />
      </div>
    );
  }
  return (
    <>
      <div className="home">
        <Navbar estado={estadoMenu} />

        <div
          className={`home_body_container ${navbarData ? "navbar-open" : ""}`}
          style={{ marginLeft: navbarData ? "228px" : "80px" }}
        >
          {loading ? <div className={`spinner_container_homeProcess ${navbarData ? "navbar-open" : ""}`}>
            <div className="spinner__circle_homeProcess">
              <div className="spinner__circle-gradient_homeProcess"></div>
              <div className="spinner__circle-inner_homeProcess"></div>
            </div>
            <p className="loader-text_homeProcess">Estamos procesando tus datos...</p>
          </div> :
            <>
              <header className="headerHome"></header>

              <main className="mainHome">
                <div className="userContainer">
                {id === "buyer-persona" ?
                  <h1>
                    Buyer persona
                  </h1> :
                  <h1>
                  Amplify
                </h1> }
                  {id === "buyer-persona" ? (
                    <button
                      onClick={() => {
                        if(localStorage.getItem('ENRICHMENT') !== 'false'){
                        navigate("/buyer-persona-carga-archivo")
                        }else{
                          navigate('/acceso-denegado')
                      }}}
                      className="createNewBuyerPersona"
                    >
                      {" "}
                      <img src={MasImg} alt="" /> Buyer persona
                    </button>
                  ) : (
                    <button
                      className="createNewBuyerPersona"
                      onClick={(e) => abrirModal(e)}
                    >
                      <img src={MasImg} alt="" />
                      Amplify
                    </button>
                  )}
                </div>
                {card && id === "buyer-persona" ? (
                  <BuyerPersona card={card} data={date} complete={completeDate} />
                ) : card && id === "amplify" ? (
                  <Amplify card={card} data={Amplifydate} complete={amplifyCompleteDate} />
                ) : (
                  <></>
                )}
                <div className="tableContainerProcessExecuted">
                  <Table
                    param={id}
                    data={data}
                    amplify={dataAmplify}
                    value={() => {
                      getAmplify();
                    }}
                    reload={() => {
                      getEnrichment();
                      getAmplify();
                    }}
                    loading={loading}
                  />
                </div>
                {modalVisible && (
                  <div className="modalAmplify">
                    <div className="modalAmplifyConteiner">
                      <div className="modalAmplifyContent">
                        <div className="modalAmplifyContentItem">
                          <img src={AutomaticoImg} onLoad={handleImageLoad}
                            alt="icono amplify automatico" />
                          <button onClick={() => {if(localStorage.getItem('CLONES') !== 'false'){
navigate("/amplify/1")                      }else{
                          navigate('/acceso-denegado')
                      }  }}>Automático</button>
                        </div>
                        <div className="modalAmplifyContentItem">
                          <img src={ManualImg} onLoad={handleImageLoad}
                            alt="icono amplify manual" />
                          <button onClick={() => {   if(localStorage.getItem('CLONES_MANUALES') !== 'false'){
navigate("/amplify/2")                         }else{
                          navigate('/acceso-denegado')
                      }}}>Manual</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </main>
            </>
          }
        </div>

      </div>
    </>
  );
};

export default Home;
