import { useState, useEffect } from "react";
import Select, { components } from "react-select";
import './SelectCheckBox.css';

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(isSelected);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "10px",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
    // closeMenuOnSelect: false,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
      // closeMenuOnSelect={false}
    >
      {/* <label className="custom-checkbox-label"> */}
        {children}
        <input
          type="checkbox"
          checked={isSelected}
          className="custom-checkbox"
          readOnly
        />
      {/* </label> */}
    </components.Option>
  );
};

export default function SelectCheckBox({ handleSelect, optionsCheckBox, seleccionados, onChangeSeleccionados }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions(seleccionados);
  }, [seleccionados]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    if (typeof onChangeSeleccionados === "function") {
      onChangeSeleccionados(selectedOptions);
      // Llama a la función handleSelect del padre para reflejar las selecciones en el objeto filtro
      handleSelect(selectedOptions);
    }
  };

  useEffect(() => {
    const nuevosSeleccionados = selectedOptions.filter((opt) =>
      optionsCheckBox.some((o) => o.value === opt.value)
    );

    setSelectedOptions(nuevosSeleccionados);
  }, [optionsCheckBox]);

  return (
    <div className="SelectedCheckBox">
      <Select
        placeholder="Selecciona una opción..." 
        value={selectedOptions}
        isMulti={true}
        closeMenuOnSelect={false}
        // menuIsOpen={true} 
        hideSelectedOptions={false} 
        onChange={(e) => {handleSelectChange(e); handleSelect(e) }}
        options={optionsCheckBox.sort((a, b) => {
          if (a.label < b.label) {
              return -1;
          }
          if (a.label > b.label) {
              return 1;
          }
          return 0;
      })}
        components={{
          Option: InputOption,
        }}
        classNamePrefix="selectCheckBox"
      />
    </div>
  );
}