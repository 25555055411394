import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Provider from "./Components/Context/Context";
import Login from "./Components/Login/Login";
import { ProtectedRoute } from "./Components/LoginMiddleware/LoginMiddleware";
import NewAccount from "./Components/NewAccount/NewAccount";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import ConnectionError from "./Components/systemPages/connectionError/connectionError";
import InMaintenance from "./Components/systemPages/maintenance/inMaintenance";
import Error404 from "./Components/systemPages/error404/error404";
import FAQs from "./Components/systemPages/FAQs/FAQs";
import Charts from "./Components/Charts/Charts";
import ChartCL from "./Components/Charts CL/ChartsCL";
import CarouselWalkthrough from "./Components/walkthrough/CarouselWalkthrough";
import Loader from "./Components/systemPages/loader/loader";
import TerminosyCondiciones from "./Components/systemPages/terminos-y-condiciones/terminos-y-condiciones";
import { Redirect } from "./Components/Redirect/RedirectLogin";
import HomeProcessExecuted from "./Components/home/homeProcessExecuted";
import BuyerPersonaConfiguracion from "./Components/BuyerPersona/BuyerPersonaConfiguracion";
import BuyerPersonaConfirmacion from "./Components/BuyerPersona/BuyerPersonaConfirmacion";
import BuyerPersonaCargaArchivo from "./Components/BuyerPersona/BuyerPersonaCargaArchivo";
import ProcesoExitoso from "./Components/systemPages/procesoExitoso/procesoExitoso";
import RecuperoExitoso from "./Components/systemPages/recuperoExitoso/recuperoExitoso";
import AmplifyAutomatico from "./Components/Amplify/AmplifyAutomatico/amplifyAutomatico";
import AmplifySolicitudClones from "./Components/Amplify/AmplifyAutomatico/amplifySolicitudClones";
import AmplifyTablaDetalle from "./Components/Amplify/AmplifyAutomatico/amplifyTablaDetalle";
import AmplifyAutomaticoDescarga from "./Components/Amplify/AmplifyAutomatico/amplifyDescarga";
import LoginError from "./Components/systemPages/loginError/loginError";
import NoCredits from "./Components/Amplify/AmplifyContenedor/modales/NoCredits/NoCredits";
import TagManager from 'react-gtm-module';
import Confirmacion from "./Components/BuyerPersona/Confirmacion/Confirmacion";
import AmplifyCardNoEncontrados from "./Components/Amplify/AmplifyContenedor/cardsSolicitudClones/cardNoEncontrados";
import AmplifyPersonaResumen from './Components/Amplify/Resumen/AmplifyResumen'
import HomeEmptyBP from "./Components/home/homeEmpty/homeEmptyBP"
import HomeEmptyA from "./Components/home/homeEmpty/homeEmptyA"
import SinPermiso from './Components/systemPages/sinPermiso/sinPermiso'
import CodeVerification from './Components/CodeVerfication/CodeVerification'
import Admin from "./Components/Admin/Admin";
import AdminTable from "./Components/AdminTable/AdminTable";
import { ProtectedRouteAdmin } from "./Components/AdminMiddleware/AdminMiddleware";

function App() {
  // GOOGLE TAG MANAGER
  const tagManagerArgs = {
    gtmId: 'GTM-PRC5GFD7'
}

TagManager.initialize(tagManagerArgs)
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const handleConnectionChange = () => {
    setIsOnline(navigator.onLine);
  };
  window.addEventListener("online", handleConnectionChange);
  window.addEventListener("offline", handleConnectionChange);
  // Eliminar los listeners cuando el componente se desmonte
  useEffect(() => {
    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  if (!isOnline) {
    return <ConnectionError />;
  } else return (
    <Provider>
      <Routes>
        <Route exact path="/" element={<ProtectedRoute><Redirect /></ProtectedRoute>} />
        <Route exact path="/iniciar-sesion" element={<Login />} />
        <Route exact path="/crear-cuenta" element={<NewAccount />} />
        <Route exact path="/olvide-contraseña" element={<ForgotPassword />} />
        <Route exact path="/user/recover/:id" element={<CodeVerification />} />
        <Route exact path="/sin-conexion" element={<ProtectedRoute><ConnectionError /></ProtectedRoute>} />
        <Route exact path="/error404" element={<ProtectedRoute><Error404 /></ProtectedRoute>} />
        <Route exact path="/en-mantenimiento" element={<ProtectedRoute><InMaintenance /></ProtectedRoute>} />
        <Route exact path="/loader" element={<ProtectedRoute><Loader /></ProtectedRoute>} />
        <Route exact path="/preguntas-frecuentes" element={<ProtectedRoute><FAQs /></ProtectedRoute>} />
        <Route exact path='/tablero-2/:id' element={<ProtectedRoute><Charts /></ProtectedRoute>} />
        <Route exact path='/tablero-1/:id' element={<ProtectedRoute><ChartCL /></ProtectedRoute>} />
        <Route exact path="/comenzar" element={<ProtectedRoute><CarouselWalkthrough /></ProtectedRoute>} />
        <Route exact path="/buyer-persona-carga-archivo" element={<ProtectedRoute><BuyerPersonaCargaArchivo /></ProtectedRoute>} />
        <Route exact path="/buyer-persona-configuracion" element={<ProtectedRoute><BuyerPersonaConfiguracion /></ProtectedRoute>} />
        <Route exact path="/buyer-persona-confirmacion" element={<ProtectedRoute><BuyerPersonaConfirmacion /></ProtectedRoute>} />
        <Route exact path="/amplify/:id" element={<ProtectedRoute><AmplifyAutomatico/></ProtectedRoute>} /> 
        <Route exact path="/amplify-solicitud-clones" element={<ProtectedRoute><AmplifySolicitudClones/></ProtectedRoute>} />
        <Route exact path="/amplify-detalle" element={<ProtectedRoute><AmplifyTablaDetalle/></ProtectedRoute>} />
        <Route exact path="/amplify-resumen" element={<ProtectedRoute><AmplifyPersonaResumen/></ProtectedRoute>} />
        <Route exact path="/amplify-descarga" element={<ProtectedRoute><AmplifyAutomaticoDescarga/></ProtectedRoute>} />
        <Route exact path="*" element={<Error404 />} />
        <Route exact path="/no-credits" element={<ProtectedRoute><NoCredits/></ProtectedRoute>}/>
        <Route exact path="/proceso-exitoso" element={<ProtectedRoute><ProcesoExitoso/></ProtectedRoute>}/>
        <Route exact path="/terminos-y-condiciones" element={<ProtectedRoute><TerminosyCondiciones/></ProtectedRoute>} />      
        <Route exact path="/recupero-exitoso" element={<ProtectedRoute><RecuperoExitoso/></ProtectedRoute>}/>
        <Route exact path="/iniciar-sesion-error" element={<LoginError/>} />     
        <Route exact path="/recupero-exitoso" element={<ProtectedRoute><RecuperoExitoso/></ProtectedRoute>}/>
        <Route exact path="/resumen" element={<ProtectedRoute><Confirmacion/></ProtectedRoute>}/>
        <Route exact path="/oops" element={<ProtectedRoute><AmplifyCardNoEncontrados/></ProtectedRoute>}/>
        <Route exact path="/inicio/:id" element={<ProtectedRoute><HomeProcessExecuted/></ProtectedRoute>}/>
        <Route exact path="/administrador" element={<ProtectedRouteAdmin><Admin/></ProtectedRouteAdmin>}/>
        <Route exact path="/administrador/:name/:id/:country" element={<ProtectedRouteAdmin><AdminTable/></ProtectedRouteAdmin>}/>
        <Route exact path="/inicio-nuevo-buyer-persona" element={<ProtectedRoute><HomeEmptyBP/></ProtectedRoute>}/>
        <Route exact path="/inicio-nuevo-amplify" element={<ProtectedRoute><HomeEmptyA/></ProtectedRoute>}/>
        <Route exact path="/acceso-denegado" element={<ProtectedRoute><SinPermiso/></ProtectedRoute>}/>
      </Routes>
    </Provider>
  );
}

export default App;
