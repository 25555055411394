import React from "react";
import Img from "./procesoExitoso.png";
import "./recuperoExitoso.css";
const RecuperoExitoso = () => {
  return (
    <>
      <div className="recuperoExitosoContainer">
        <div className="recuperoExitosoCard">
        <img src={Img} alt="" />
          <div className="recuperoExitosoTexto"> 
            <h1>Se restableció su contraseña con éxito</h1>
            <p>Vuelva a iniciar sesión.</p>
          </div>
          <button className="recuperoExitosoButton">Iniciar Sesión</button>
        </div>
      
      </div>
    </>
  );
};

export default RecuperoExitoso;
