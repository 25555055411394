import React from 'react';
import OhNoImage from './ohNOimg'
import ButtonLink from '../../../ComponentesMoleculas/ButtonLink';
import './card404.css'

const Card404 = () => {
    return (
            <div className='cardError404'>
                <OhNoImage className='img'/>
                <div className='text_container'>
                    <h1>Algo salió mal</h1>
                    <p className='textError404'>
                        Esta página no existe o ha sido removida.
                        Te sugerimos refrescar la página o volver al inicio.
                    </p>
                </div>
                <ButtonLink route={'/'} cName={'btnPrimary'} value={"Volver al inicio"} />
        </div>

    );
}

export default Card404;
