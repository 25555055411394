import React, { useEffect, useState } from 'react';
import './ContenedorWalkthrough.css';
import ArrowLeft from './assets/u_angle-left.png';
import ArrowRight from './assets/u_angle-right.png';
const ContenedorWalkthrough = ({ components, skip }) => {
  const [currentScreen, setCurrentScreen] = useState(0);

  useEffect(() => { if (skip !== undefined) { setCurrentScreen(skip) } }, [skip])

  const handleArrowClick = (direction) => {
    if (direction === 'left') {
      setCurrentScreen((prevScreen) => Math.max(prevScreen - 1, 0));
    } else if (direction === 'right') {
      setCurrentScreen((prevScreen) =>
        Math.min(prevScreen + 1, components.length - 1)
      );
    }
  };

  const setNextScreen = () => {
    setCurrentScreen((prevScreen) => Math.min(prevScreen + 1, components.length - 1));
  };

  const isFirstScreen = currentScreen === 0;
  const isLastScreen = currentScreen === components.length - 1;

  return (
    <div className='mainWalkthrough'>
      <img
        src={ArrowLeft}
        alt=''
        onClick={() => handleArrowClick('left')}
        className={`arrow ${isFirstScreen ? 'hidden' : ''}`}
      />
      <div className='cardContainerWalkthrough'>{React.cloneElement(components[currentScreen], { setNextScreen })}</div>
      <img
        src={ArrowRight}
        alt=''
        onClick={() => handleArrowClick('right')}
        className={`arrow ${isLastScreen ? 'hidden' : ''}`}
      />
    </div>
  );


};

export default ContenedorWalkthrough;
