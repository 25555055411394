import React from 'react';
import OhNoIMG from './en_mantenimiento.png'
import './ohnoimg.css'

const OhNOimg = () => {
    return (
        <>
            <img className='imgInMaintenance' src={OhNoIMG} alt="imagen de hombre preocupado" />
        </>
    );
}

export default OhNOimg;
