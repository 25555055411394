import axios from 'axios';

// Crear una instancia de Axios
const axiosInstance = axios.create();

// Configurar el interceptor para Authorization
axiosInstance.interceptors.request.use(
  (config) => {
    // Obtener el token almacenado en localStorage
    const token = localStorage.getItem('token');

    // Verificar si hay un token y adjuntarlo a los encabezados
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
