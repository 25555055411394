import React, { useState, useEffect, useContext } from "react";
import './ProgressBarCardResumen.css'

const ProgressBarCardResumen = ({cardResumen, ultimaCard}) => {
  return (
   <>
<div className="vertical-progress-bar">
      <div className="dot-line">
        <div className="dot"></div>
        <div className="line"></div>
      </div>
      <div className="dot-line">
        <div className="dot"></div>
        <div className="line"></div>
      </div>
      <div className="dot-line">
        {(ultimaCard && cardResumen > 4999) &&
             <div className="dot dotGreen"></div> 
      }
       {(ultimaCard && cardResumen <= 4999) &&
             <div className="dot dotOrange"></div> 
      }
         {!ultimaCard &&
             <div className="dot"></div> 
      }
      </div>
    </div>
   </>
  );
};

export default ProgressBarCardResumen;
