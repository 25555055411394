import React, { useState, useEffect } from "react";
import Navbar from "../navbar/menuVertical/navbar";
import Arrow from './assets/keyboard_double_arrow_right.svg'
import "./AdminTable.css";
import BanderaChile from "../navbar/menuVertical/boxNavbar/boxNavbarAssets/banderaChile.png";
import BanderaMexico from "../navbar/menuVertical/boxNavbar/boxNavbarAssets/banderaMexico.png";
import Expand from "../home/tableContainer/tableAssets/expand_more.svg"
import Edit from '../home/tableContainer/tableAssets/edit.png'
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import PopUpAdmin from "./PopUpAdmin/PopUpAdmin";
import axiosInstance from "../AxiosInterceptor/AxiosInterceptor";
import SpinnerProcesando from "../ComponentesMoleculas/Loader/SpinnerProcesando";

const placeholder = (provided, state) => ({
    ...provided,
    color: "#6D7A8FFF",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0.28px",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    cursor: "pointer",
    borderRadius: "8px",
    borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
})
const container = (provided, state) => ({
    ...provided,
    width: "140px",
    height: "40px",
    fontFamily: "Inter",
    fontSize: "12px",
})
const containerWidth = (provided, state) => ({
    ...provided,
    width: "125px",
    height: "40px",
    fontFamily: "Inter",
    fontSize: "12px",
})
const controlWidth = (provided, state) => ({
    ...provided,
    fontFamily: "Inter",
    fontSize: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    background: "#FFF",
    cursor: "pointer",
    width: "125px",
    borderRadius: "8px",
    borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
    boxShadow: state.isFocused ? "0 0 0 1px #F5A973" : "none",

    "&:hover": {
        borderColor: "#F18F49",
    },
})
const control = (provided, state) => ({
    ...provided,
    fontFamily: "Inter",
    fontSize: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    background: "#FFF",
    cursor: "pointer",
    width: "140px",
    borderRadius: "8px",
    borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
    boxShadow: state.isFocused ? "0 0 0 1px #F5A973" : "none",

    "&:hover": {
        borderColor: "#F18F49",
    },
})
const indicatorSeparator = (provided) => ({
    ...provided,
    display: "none",
})
const menu = (provided) => ({
    ...provided,
    display: "block",
    width: "140px",
    height: "auto",
    padding: "8px",
    gap: "8px",
    borderRadius: "8px",
    cursor: "pointer",
})
const menuWidth = (provided) => ({
    ...provided,
    display: "block",
    width: "125px",
    height: "105px",
    padding: "0px",
    gap: "0px",
    borderRadius: "8px",
    cursor: "pointer",
})
const optionWidth = (provided, state) => ({
    ...provided,
    color: "#6D7A8FFF",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: "400",
    backgroundColor: "white",
paddingLeft: '2px',
    "&:active": {
        backgroundColor: "white", // Evita que haya un color de fondo al hacer clic en la opción
    },
    "&:hover": {
        backgroundColor: "#EFF8FF", // Cambia el color de fondo al hacer hover
    },
})
const option = (provided, state) => ({
    ...provided,
    color: "#6D7A8FFF",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: "400",
    backgroundColor: "white",

    "&:active": {
        backgroundColor: "white", // Evita que haya un color de fondo al hacer clic en la opción
    },
    "&:hover": {
        backgroundColor: "#EFF8FF", // Cambia el color de fondo al hacer hover
    },
})
const singleValueWidth = (provided, state) => ({
    ...provided,
    color: "#6D7A8FFF",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: "400",
    backgroundColor: "white",


})
const singleValue = (provided, state) => ({
    ...provided,
    color: "#6D7A8FFF",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: "400",
    backgroundColor: "white",
    paddingLeft: '5px',

})

const customStyles = {
    placeholder: placeholder,
    container: container,
    control: control,
    indicatorSeparator: indicatorSeparator,
    menu: menu,
    option: option,
    singleValue: singleValue
}
const customStylesSelect = {
    placeholder: placeholder,
    container: containerWidth,
    control: controlWidth,
    indicatorSeparator: indicatorSeparator,
    menu: menuWidth,
    option: optionWidth,
    singleValue: singleValueWidth
}

const AdminTable = () => {
    const { id, country, name } = useParams();
    const navigate = useNavigate()
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false); // Estado para controlar la habilitación/deshabilitación del botón de guardar
    const [navbarData, setNavbarData] = useState(false);
    const [InputStateP, setInputStateP] = useState({ state: false, index: 0 });
    const [estado, setEstado] = useState(false)
    const [userList, setUserList] = useState([])
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(true)
    const [tableChanges, setTableChanges] = useState([]);
    const [orgName, setOrgName] = useState(name)
    const [passwordErrors, setPasswordErrors] = useState({});
    const [dropdownStates, setDropdownStates] = useState({});
    const [enrichmentBalanceEdit, setEnrichmentBalanceEdit] = useState({});
    const [clonBalanceEdit, setClonBalanceEdit] = useState({});

    const toggleDropdown = (id) => {
        setDropdownStates(prevState => ({
            ...prevState,
            [id]: !prevState[id] // Invierte el estado del desplegable correspondiente al ID
        }));
    };
    const estadoMenu = (data) => { setNavbarData(data); }
    const handleUserChange = (userId, updatedData) => {
        setTableChanges(prevChanges => {
            // Buscar si ya existe un cambio para el usuario
            const existingChangeIndex = prevChanges.findIndex(change => change.id === userId);

            if (existingChangeIndex !== -1) {
                // Si ya existe, mantener los cambios existentes y agregar los nuevos
                const updatedChanges = [...prevChanges];
                Object.entries(updatedData).forEach(([key, value]) => {
                    if (key === 'password') {
                        updatedChanges[existingChangeIndex][key] = value[0];
                    } else if (key === 'active') {
                        updatedChanges[existingChangeIndex][key] = value;
                    } else if (key === 'clon_balance' || key === 'enrichment_balance') {
                        updatedChanges[existingChangeIndex][key] = Array.isArray(value) ? value[0] : value
                    } else if (key !== 'role') {
                        updatedChanges[existingChangeIndex][key] = Number(value[0]);
                    } else {
                        updatedChanges[existingChangeIndex][key] = value;
                    }
                });
                return updatedChanges;
            } else {
                // Si no existe, agregar un nuevo cambio para cada clave y valor
                const newChanges = Object.entries(updatedData).map(([key, value]) => ({
                    id: userId,
                    [key]: value
                }));
                return [...prevChanges, ...newChanges];
            }
        });
    };




    // Función para cambiar el estado de activación/inactivación de un usuario

    const saveChanges = () => {
        let ArrayData = tableChanges
        if (name !== orgName && orgName !== undefined) {
            axiosInstance.post(
                `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/organization/update/${id}`,
                {
                    "name": orgName,
                    "country_id": Number(country)
                }
            )
                .then((response) => {
                    navigate('/administrador')
                })
                .catch((error) => {

                    if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 422)) {
                        localStorage.clear();
                        navigate("/iniciar-sesion");
                    }
                });
        }
        if (ArrayData.length > 0) {
            axiosInstance.post(
                `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/user/update`,
                {
                    "user_list": ArrayData
                }
            )
                .then((response) => {
                    navigate('/administrador')

                })
                .catch((error) => {

                    if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 422)) {
                        localStorage.clear();
                        navigate("/iniciar-sesion");
                    }
                });
        } else {
            navigate('/administrador')
        }

    }
    const getAdminState = () => {
        axiosInstance.get(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/list`,
        )
            .then((e) => {
                setOptions(e.data.role_data_list.filter(a => a.country_id === Number(country)))
            })
            .catch((e) => {


                if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
                    localStorage.clear();
                    navigate('/iniciar-sesion')
                } else if ((e.responese && e.responese.status && e.response.status === 302)) {
                    navigate('/acceso-denegado')
                }

            })
    }
    const getListUSers = () => {
        axiosInstance.get(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/organization/${id}/${country}/list`,
        )
            .then((e) => {
                setUserList(e.data.user_data_list)
                setLoading(false)
            })
            .catch((e) => {

                if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
                    localStorage.clear();
                    navigate('/iniciar-sesion')
                } else if ((e.responese && e.responese.status && e.response.status === 302)) {
                    navigate('/acceso-denegado')
                }

            })
    }



    useEffect(() => {
        getListUSers();
        getAdminState()
    }, [])



    const handleClick = (e) => {
        setEstado(false)
        getListUSers()
    }
    const validatePass = (id, pass) => {
        // Verificar si el campo está vacío o contiene solo espacios en blanco
        if (!pass.trim()) {
            setPasswordErrors(prevErrors => ({
                ...prevErrors,
                [id]: null
            }));
            return;
        }

        // Validar el formato de la contraseña
        const validRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/;
        const isValid = validRegex.test(pass);
        const errorMessage = (isValid && pass.length > 7 && pass.length < 30) ? null : "La contraseña debe tener de 8 a 30 caracteres, una letra mayúscula,una minúscula, un número y un carácter especial.";
        setPasswordErrors(prevErrors => ({
            ...prevErrors,
            [id]: errorMessage
        }));

    }
    useEffect(() => {
        // Verifica si hay algún error en passwordErrors
        const hasErrors = Object.values(passwordErrors).some(error => error !== null);
        // Actualiza el estado del botón de guardar en función de la presencia de errores
        setSaveButtonDisabled(hasErrors);
    }, [passwordErrors]);
    // Función para formatear el número con puntos como separador de miles
    const formatNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    // Función para parsear el valor introducido por el usuario, eliminando los puntos y convirtiéndolo a número
    const parseNumber = (value) => {
        return parseFloat(value.replace(/\./g, "").replace(/,/g, ""));
    };



    return (
        <>
            <div className="containerAdmin">
                <Navbar estado={estadoMenu} />

                {estado &&
                    <PopUpAdmin PopUpAdminRol={options} userList={userList} selectstyles={customStyles} handleClick={() => { handleClick() }} />
                }
                <div
                    className={`admin_body_container ${navbarData ? "navbar-open" : ""}`}
                    style={{ marginLeft: navbarData ? "228px" : "80px" }}
                >

                    <header className="headerAdmin">
                        <div className="adminBreadCrums"><p className="firstTextHeaderAdmin" onClick={() => { navigate('/administrador') }}>organizaciones</p><img src={Arrow} alt="flecha" /> <p>{localStorage.getItem('companyFilter') !== 'undefined' ? localStorage.getItem('companyFilter').toUpperCase() : ""}</p>  </div>
                        <div className="adminTitle"><p>{name.toUpperCase()}</p></div>
                    </header>

                    <main className="mainAdminUser"
                        onClick={() => { if (InputStateP.state) { setInputStateP({ state: false, index: 0 }) } }}>
                        <div className="headerMainAdmin">
                            <div className="divAdminUserName"> <p>Nombre</p> <input onChange={(e) => { setOrgName(e.target.value) }} type="text" className="headerMainAdminInput" defaultValue={name} /></div>
                            <div className="divContainerAdminCountry"> <p>Pais</p><div className="divAdminCountry"> <img src={localStorage.getItem('selectedItems') === '43' ? BanderaChile : BanderaMexico} alt={localStorage.getItem('selectedItems') === '43' ? "BanderaChile" : "BanderaMexico"} /><p>{localStorage.getItem('selectedItems') === '43' ? 'Chile' : "Mexico"}</p></div> </div>
                        </div>

                        <p className="mainAdminUserTitle">Usuarios</p>
                        {!loading ?
                            <>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="thAdminNameUser">
                                                <div
                                                    className={`thHeader`}
                                                >
                                                    <p className="thFilter">
                                                        Correo electrónico
                                                    </p>
                                                </div>
                                            </th>
                                            <th className="thAdminEstadoTableAdmin">
                                                <div
                                                    className={`thHeader`}
                                                >
                                                    <p className="thFilter">Estado</p>
                                                </div>
                                            </th>
                                            <th className="thFechaDeCreacionAdminUser">
                                                <div
                                                    className={`thHeader `}
                                                >
                                                    <p className="thFilter">Rol</p>
                                                </div>
                                            </th>

                                            <th className="thAdminBuyerPerson">
                                                <div
                                                    className={`thHeader`}
                                                >
                                                    <p className="thFilter">Créditos Buyer</p>
                                                </div>
                                            </th>
                                            <th className="thAdminUserAmplify">
                                                <div
                                                    className={`thHeader`}
                                                >
                                                    <p className="thFilter">Créditos Amplify</p>
                                                </div>
                                            </th>
                                            <th className="thAdminTableUserActive">
                                                <div
                                                    className={`thHeader`}
                                                >
                                                    <p className="thFilter">Contraseña</p>
                                                </div>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody className="tbody">
                                        {userList.map((e, i) => (
                                            <tr>
                                                <td>
                                                    <p className="tdName"   >{e.email}</p>
                                                </td>
                                                <td
                                                    className='tdShortPadding' >
                                                    {/* <div className={`${userList[i].active ? "activeUserOrganization" : "inactiveUserOrganization"}`} onClick={() => toggleDropdown(e.id)}>
                                                        <p>{userList[i].active ? "Activo" : "Inactivo"}</p>
                                                        <img src={Expand} alt="flecha" srcSet="" />
                                                    </div>
                                                    {dropdownStates[e.id] && (
                                                        <div className={`${!userList[i].active ? "activeUserOrganizationPopUp" : "inactiveUserOrganizationPopUp"}`} style={{
                                                            position: "absolute", paddingLeft: '2px', paddingRight: "7px",
                                                        }} onClick={() => {
                                                            userList[i].active = !userList[i].active; // Cambia el estado de active
                                                            handleUserChange(e.id, { active: userList[i].active }); // Llama a handleUserChange con el nuevo estado
                                                        }}>
                                                            <p>{!userList[i].active ? "Activar" : "Desactivar"}</p>
                                                        </div>
                                                    )} */}
                                                    <Select
                                                        className="userOrganizationSelect"
                                                        styles={customStylesSelect}
                                                        defaultValue={{
                                                            value: userList[i].active, label: (
                                                                <div className={`${userList[i].active ? "activeUserOrganization" : "inactiveUserOrganization"}`}>
                                                                    <p>{userList[i].active ? "Activo" : "Inactivo"}</p>
                                                                 
                                                                </div>
                                                            )
                                                        }}
                                                        options={[{  // e was missing as the argument here
                                                            value:true,
                                                            label: (<div className="activeUserOrganization ">
                                                                <p> Activo</p>
                                                            </div>)
                                                        }, {  // e was missing as the argument here
                                                            value: false,
                                                            label: (<div className=" inactiveUserOrganization ">
                                                                <p> Inactivo</p>
                                                            </div>)
                                                        },]}
                                                        isSearchable={false}
                                                        onChange={(x) => {
                                                            userList[i].active = x.value;
                                                            handleUserChange(e.id, { active: userList[i].active })
                                                        }}
                                                    />
                                                </td>


                                                <td className="tdShortPadding">


                                                    <Select
                                                        onChange={(a) => {
                                                            const selectedRoleId = userList[i].roles.find(role => role.country_id !== Number(country));
                                                            const newRoleIds = [a.value];
                                                            if (selectedRoleId) {
                                                                newRoleIds.push(selectedRoleId.id);
                                                            }
                                                            handleUserChange(e.id, {
                                                                role: newRoleIds
                                                            });
                                                        }}
                                                        className="userOrganizationSelect"
                                                        styles={customStyles}
                                                        defaultValue={userList[i].roles.find(a => a.country_id === Number(country)) !== undefined ? { label: userList[i].roles.find(a => a.country_id === Number(country)).nombre, value: userList[i].roles.find(a => a.country_id === Number(country)).id } : { label: "Sin Rol", value: " " }}
                                                        options={options.map((e) => ({
                                                            value: e.id,
                                                            label: e.nombre
                                                        }))}
                                                        isSearchable={false}
                                                    />

                                                </td>
                                                <td className="verticalIcon">
                                                    <input
                                                        type="text"
                                                        className="AdminInputBPAM"
                                                        value={enrichmentBalanceEdit[e.id] || (e.enrichment_balance !== null ? formatNumber(Array.isArray(e.enrichment_balance) ? e.enrichment_balance[0] : e.enrichment_balance) : '')}
                                                        onChange={(event) => {
                                                            const parsedValue = parseNumber(event.target.value);
                                                            setEnrichmentBalanceEdit(prevState => ({
                                                                ...prevState,
                                                                [e.id]: isNaN(parsedValue) ? '' : formatNumber(parsedValue)
                                                            }));
                                                        }}
                                                        onBlur={(event) => {
                                                            const parsedValue = parseNumber(event.target.value);
                                                            handleUserChange(e.id, { enrichment_balance: (isNaN(parsedValue) ? (Array.isArray(e.enrichment_balance) ? e.enrichment_balance[0] : e.enrichment_balance) : parsedValue) });
                                                        }}
                                                    />
                                                </td>
                                                <td className="verticalIcon">
                                                    <input
                                                        type="text"
                                                        className="AdminInputBPAM"
                                                        value={clonBalanceEdit[e.id] || (e.clon_balance !== null ? formatNumber(Array.isArray(e.clon_balance) ? e.clon_balance[0] : e.clon_balance) : '')}
                                                        onChange={(event) => {
                                                            const parsedValue = parseNumber(event.target.value);
                                                            setClonBalanceEdit(prevState => ({
                                                                ...prevState,
                                                                [e.id]: isNaN(parsedValue) ? '' : formatNumber(parsedValue)
                                                            }));
                                                        }}
                                                        onBlur={(event) => {
                                                            const parsedValue = parseNumber(event.target.value);
                                                            handleUserChange(e.id, { clon_balance: (isNaN(parsedValue) ? (Array.isArray(e.clon_balance) ? e.clon_balance[0] : e.clon_balance) : parsedValue) });
                                                        }}

                                                    />
                                                </td>








                                                <td className="verticalIcon" onChange={(a) => { handleUserChange(e.id, { password: [a.target.value.trim()] }); validatePass(e.id, a.target.value) }}>
                                                    <input type="text" className="AdminTableInputPassword AdminInputPassword" onWheel={(e) => e.target.blur()}
                                                    />
                                                    {passwordErrors[e.id] && <p className="error-message-admin">{passwordErrors[e.id]}</p>}

                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <button className="btnUsuarioAdminTable" onClick={() => { setEstado(true) }}>+ Usuario</button>
                                <div className="AdminSaveChangeButtons">
                                    <button className="btnSecondary" onClick={() => { navigate('/administrador') }}>Cancelar</button>
                                    <button className={saveButtonDisabled ? 'btnPrimary btnDisabledAdminTable' : 'btnPrimary'} onClick={() => { saveChanges() }}      disabled={saveButtonDisabled}>Guardar</button>
                                </div>
                            </> :
                            <>
                                <SpinnerProcesando />
                            </>
                        }
                    </main>
                </div>
            </div>
        </>
    );
};

export default AdminTable;
