import React, { useState } from 'react';
import ContenedorWalkthrough from './ContenedorWalkthrough';
import PrimerScreen from './cards/walkthroughOne';
import SegundaScreen from './cards/walkthroughTwo';
import TercerScreen from './cards/walkthroughThree';
import CuartaScreen from './cards/walkthroughFour';

const Carousel = () => {
  const [currentScreen, setCurrentScreen] = useState(0);
let handlePagination =(data)=>{setCurrentScreen(data)}
  const components = [
    <PrimerScreen  handlePagination={handlePagination} />,
    <SegundaScreen  />,
    <TercerScreen  />,
    <CuartaScreen />
  ];
  return (
    <div className='Carousel'>
      <ContenedorWalkthrough components={components}  skip={currentScreen} />
    </div>
  );
};

export default Carousel;
