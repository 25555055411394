import React, { useState } from 'react';
import './select/select.css';

const SelectToggle = ({ handleCheck }) => {
    const [value, setValue] = useState(false);

    const handleToggleChange = () => {
        const newValue = !value;
        setValue(newValue)
        handleCheck(newValue);
    };

    return (
        <div className='selectToggle'>
      
                <label className="switch">
                    <input type="checkbox" onChange={handleToggleChange} />
                    <span className="sliderSwitch round"></span>
                </label>
               
            
        </div>
    );
}

export default SelectToggle;
