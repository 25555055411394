import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './CardDatosEconomicos.css';

const CardDatosEconomicos = ({ filters, handleDataEconomic }) => {
  const [listNames, setListNames] = useState([]);
  const [selectedName, setSelectedName] = useState(null); // o '' si prefieres cadena vacía
  const [selectedLevel, setSelectedLevel] = useState(null); // o '' si prefieres cadena vacía

  useEffect(() => {
    const namesArray = filters.map(item => item.name);
    setListNames(namesArray);
  }, [filters]);

  // Llama a handleSave cuando cambia selectedName o selectedLevel
  useEffect(() => {
    handleSave();
  }, [selectedName, selectedLevel]);


  const handleNameChange = (selectedValue) => {
    setSelectedName(selectedValue);
  };

  const handleLevelChange = (selectedValue) => {
    setSelectedLevel(selectedValue);
  };
  const handleSave = () => {
    if (selectedName && selectedLevel) {
      const newData = {
        "data_gastos": [
          {
            "name": selectedName,
            "data": [
              selectedLevel
            ]
          }
        ]
      };
      handleDataEconomic(newData);
    } else {
      handleDataEconomic(undefined);
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      display: "flex",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "stretch",
      background: "#FFF",
      cursor: "pointer",
      height: "38px",
      minHeight: "24px",
      borderRadius: "8px",
      color: "#707477",
      fontFamily: "Inter",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 300,
      letterSpacing: "0.24px",
      borderColor: state.isFocused ? "#F18F49" : "#E2E4E3",
      boxShadow: state.isFocused ? "0 0 0 1px #F18F49" : "none",

    }),

    option: (provided, state) => ({
      ...provided,
      color: "#475467",
      fontSize: "12px",
      fontFamily: "Inter",
      fontWeight: "400",
      backgroundColor: "white", // Cambia el color de fondo al hacer hover
      "&:active": {
        backgroundColor: "white", // Evita que haya un color de fondo al hacer clic en la opción
      },
      "&:hover": {
        backgroundColor: "#EFF8FF", // Cambia el color de fondo al hacer hover
      },

    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#475467',
      fontSize: '12px',
      fontWeight: "400",
      fontFamily: "Inter",
      padding: "0px 8px"
    }),
    menu: (provided) => ({
      ...provided,
      height: "100px",
    })
  };

  return (
    <div className='cardDatosEconomicos'>
      <p className='cardDatosEconomicosTitulo'>Datos económicos</p>
      <p className='cardDatosEconomicosTexto'>Puedes refinar la búsqueda indicando tipo de gasto y nivel asociado.</p>
      <div className='cardDatosEconomicosSelector '>
        <p>Tipo de gasto</p>
        <Select
          name="name"
          id="name"
          value={selectedName ? { label: selectedName, value: selectedName } : null}
          onChange={(selectedOption) => handleNameChange(selectedOption.value)}
          options={listNames.sort().map(e => ({ label: e, value: e }))}
          placeholder="Selecciona un tipo de gasto de la lista"
          styles={customStyles}
        />

      </div>
      <div className='cardDatosEconomicosSelector'>
        <p>Nivel de gasto</p>
        <Select
          name="level"
          id="level"
          value={selectedLevel ? { label: selectedLevel, value: selectedLevel } : null}
          onChange={(selectedOption) => handleLevelChange(selectedOption.value)}
          options={[
            { label: 'Alto', value: 'Alto' },
            { label: 'Medio', value: 'Medio' },
            { label: 'Bajo', value: 'Bajo' },
          ]}
          placeholder="Indica nivel de gasto"
          styles={customStyles}
        />


      </div>
    </div>
  );
};

export default CardDatosEconomicos;
