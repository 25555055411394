import React from 'react';
import ImgTwo from '../assets/imgTwoWalktrough.svg';
import './walkthrough.css';
const WalkthroughSlider = ({ setNextScreen }) => {


    return (
        <div className='walkthrough Slider walkthrough_body borde-radius-gris'>
            <img src={ImgTwo} alt="imagen de hombre preocupado" />
            <div className='walkthrough_text_container'>
                <h2>Mejores decisiones</h2>
                <p>Gracias a nuestra IA, en cuestión de minutos tendrás resultados para mejorar la toma de  decisiones <strong className='strongW'>estratégicas</strong> para tu negocio.</p>
            </div>
            <div className='walkthrough_btn_container'>
                <div className="dotsContainerW">
                    <div className="dotsW"></div>
                    <div className="dotsW active"></div>
                    <div className="dotsW"></div>
                </div>
                <button className='OmitirPrimaryWalktrough' onClick={setNextScreen}>Omitir</button>
            </div>
        </div>
    );
}

export default WalkthroughSlider;
