import React from 'react';
import { Navigate } from 'react-router-dom';

export function ProtectedRouteAdmin({ children }) {

    if (localStorage.getItem('privilege') !== "true" ) {
        return <Navigate to="/inicio/buyer-persona" />;
    }

    return <>{children}</>;
}
