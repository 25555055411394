import React, { useState } from "react";
import './TooltipBP.css';

export const TooltipBP = ({ text, children, classNameContainer, classNameTool }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className={`tooltip-container ${classNameContainer}`}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && <div className={`tooltip-component ${classNameTool}`}>{text}</div>}
    </div>
  );
};
