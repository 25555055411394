import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Eye from "../../assets/image/u_eye.png";
import EyeClose from "../../assets/image/u_eye-slash.png";
import isologo from "../../assets/image/isologo.svg";
import "./NewAccount.css";

function NewAccount() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [image, setImage] = useState(EyeClose);
  const [eyeState, setEyeState] = useState("password");
  const navigate = useNavigate();
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validateName = () => {
    const nameInput = document.getElementById("name-input");
    const name = nameInput.value.trim();

    if (name.length < 4 || name.length > 100) {
      nameInput.style.borderColor = "red";
      setNameError("*La contraseña debe tener al menos 4 caracteres");
      return false;
    } else if (/[^a-zA-Z0-9\s]/.test(name)) {
      nameInput.style.borderColor = "red";
      setNameError("*El nombre no puede contener símbolos");
      return false;
    } else {
      nameInput.style.borderColor = "";
      setNameError("");
      return true;
    }
  };

  const validateEmail = () => {
    const emailInput = document.getElementById("email-input");

    if (email.length === 0 || !email.includes("@")) {
      emailInput.style.borderColor = "red";
      setEmailError("*Error en el formato de email");
      return false;
    } else {
      emailInput.style.borderColor = "";
      setEmailError("");
      return true;
    }
  };

  const validatePassword = () => {
    const InputConst = document.getElementById("password-input");
    const InputValue = InputConst.value;

    if (InputValue.length === 0) {
      InputConst.style.borderColor = "red";
      setPasswordError("*El campo de contraseña no puede estar vacío");
      return false;
    } else if (InputValue.length < 8 || InputValue.length > 100) {
      InputConst.style.borderColor = "red";
      setPasswordError("*La contraseña debe tener al menos 8 caracteres");
      return false;
    } else if (!/[A-Z]/.test(InputValue)) {
      InputConst.style.borderColor = "red";
      setPasswordError("*La contraseña debe contener al menos una letra mayúscula");
      return false;
    } else if (!/[0-9]/.test(InputValue)) {
      InputConst.style.borderColor = "red";
      setPasswordError("*La contraseña debe contener al menos un número");
      return false;
    } else if (!/[^a-zA-Z0-9]/.test(InputValue)) {
      InputConst.style.borderColor = "red";
      setPasswordError("*La contraseña debe contener al menos un símbolo");
      return false;
    } else {
      InputConst.style.borderColor = "";

      setPasswordError("");
      return true;
    } 
  };


  const validateForm = () => {
    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();
    const isNameValid = validateName();
    return isEmailValid && isPasswordValid && isNameValid;
  };

  const register = () => {
    axios
      .post(`mx.app.aisevolution.ai/api/register`, {
        username: name,
        email: email,
        password: password,
      })
      .then((e) => {
        navigate("/");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("lmnkknn");
        localStorage.removeItem("OnBoarding");
      })
      .catch(({ e }) => {
        console.log("error");
      });
  };

  const submit = () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      register();
    }
  };

  useEffect(() => {
    if (name.length > 0 && password.length > 0 && email.length > 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [name, password, email]);

  const seePassword = () => {
    if (eyeState === "password") {
      setEyeState("text");
      setImage(Eye);
    } else {
      setEyeState("password");
      setImage(EyeClose);
    }
  };

  const resetErrors = () => {
    const nameInput = document.getElementById("name-input");
    const emailInput = document.getElementById("email-input");
    const InputConst = document.getElementById("password-input");

    nameInput.style.borderColor = "";
    emailInput.style.borderColor = "";
    InputConst.style.borderColor = "";

    setNameError("");
    setEmailError("");
    setPasswordError("");
  };

  return (
    <div className="login">
      <div className="login__container borde-radius-gris">
        <div className="login__formCrearCuenta">
          <div>
            <img src={isologo} alt="" className="login__isologo" />
          </div>
          <div className="form__titles">
            <div className="form__titlesContainer_text">
              <h1 className="titles">¡Te damos la bienvenida!</h1>
              <p className="form__titles--text">
                A continuación te pediremos algunos datos para poder crear una nueva cuenta
              </p>
            </div>
          </div>

          <div id="change__screen--final">
            <div className="container_form_input">
              <label className="form__label--text">Nombre y Apellido</label>
              <input
                id="name-input"
                placeholder="Escribe tu nombre de usuario"
                type="text"
                onChange={(e) => setName(e.target.value)}
                defaultValue={name}
                className="form__input--texts"
                onFocus={resetErrors}
              />
              <div id="name-error" className="form__error-message">
                {nameError}
              </div>
            </div>
            <div className="container_form_input">
              <label className="form__label--text">Email</label>
              <input
                id="email-input"
                placeholder="Escribe tu email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                defaultValue={email}
                className="form__input--texts"
                onFocus={resetErrors}
              />
              <div id="email-error" className="form__error-message">
                {emailError}
              </div>
            </div>
            <div className="container_form_input">
              <label className="form__label--text">Contraseña</label>
              <input
                id="password-input"
                required
                type={eyeState}
                placeholder="Contraseña"
                onChange={(e) => setPassword(e.target.value)}
                className="form__inputs--password"
                onFocus={resetErrors}
              />
              <div className="form__inputs--image--containerIniciarSesion">
                <img
                  src={image}
                  alt=""
                  className="form__inputs--image"
                  onClick={seePassword}
                />
              </div>
              <div id="password-error" className="form__error-message">
                {passwordError}
              </div>
            </div>
          </div>

          <button
            onClick={() => {
              if (isDisabled) {
                submit();
              }
            }}
            className={isDisabled ? "disabled" : "login__form--buttonIniciarSesion"}
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewAccount;
