function separadorDeMiles(numero) {
    // Si es un número, lo convierte a cadena para formatearlo
    if(numero !== undefined){
    if (typeof numero === 'number') {
      numero = numero.toString();
    }
    // Si el número ya tiene puntos como separador de miles, devuelve el número sin cambios
    if (/\./.test(numero)) {
      return numero;
    }
    // Si el número tiene comas como separador de miles, los reemplaza por puntos
    if (/,/.test(numero)) {
      return numero.replace(/,/g, '.');
    }
    // Reemplaza comas por puntos para asegurar el formato con puntos como separador de miles
    if(numero.replace){
    return numero.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
  }
   }
  export default separadorDeMiles
