import React, { useContext, useState } from "react";
import { AppContext } from "../../Context/Context";
import Navbar from "../../navbar/menuVertical/navbar";
import HomeEmptyImg from "./homeEmpty.png"
import { useNavigate } from "react-router-dom";

import "./homeEmptyAutomatico.css";
const HomeEmptyAuto = () => {
  const navigate = useNavigate();
  const [navbarData, setNavbarData] = useState(false);

  useContext(AppContext);
  const estadoMenu = (data) => {
    setNavbarData(data);
  };
  return (
    <>
      <div className="home">
        <Navbar estado={estadoMenu} />
        <div
          className={`home_body_container ${navbarData ? "navbar-open" : ""}`}
          style={{ marginLeft: navbarData ? "228px" : "80px" }}
        >
          {/*     <header className="headerHome"></header> */}
          {/* Remplazar + por la imagen del proto */}
          <main className="mainHome">
            <div className="userContainer">
              <h1>
                Buyer Persona
              </h1>
            </div>

            <div className="cardHomeEmpty">
              <div className="homeEmptyImg">
                <img src={HomeEmptyImg} alt="" />
              </div>
              <div className="cardHomeEmptyContent">
                <div className="cardHomeEmptyContentContainer">
                  <p className="cardHomeEmptyTitle">¿Comenzamos?</p>
                  <p className="cardHomeEmptyText">
                    Empieza por conocer a tu buyer persona. Obtendrás información
                    valiosa para tus campañas y decisiones de negocio.
                  </p>

                </div>
                <button className="createNewBuyerPersona" onClick={() => { if (localStorage.getItem('ENRICHMENT') !== 'false') { navigate('/buyer-persona-carga-archivo') } else { navigate('/acceso-denegado') } }}>Generar Buyer persona</button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default HomeEmptyAuto;
