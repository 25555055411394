import React from "react";
import Select from "react-select";
import './SelectBox.css';

export default function SelectBox({ optionsSelect, onChangeSeleccionados, placeholder, width }) {

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: width || "200px", // Usar el valor de width si se proporciona, de lo contrario, usar el valor predeterminado.
      height: "32px",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'transparent' : provided.backgroundColor,

    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'white',  // Fondo blanco para todas las opciones en el menú
    }),
       option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#EFF8FF' : 'white',  // Cambia el color de fondo al hacer hover
      color: "#475467",
      fontSize: "12px",
      fontFamily: "Inter",
      fontWeight: "400",
    }),
  };
  
  return (
    <div className="SelectBox">
      <Select
        styles={customStyles}
        placeholder={placeholder}
        options={optionsSelect}
        onChange={(e) => {onChangeSeleccionados(e.value)}}
        isSearchable={true}
        classNamePrefix="selectBox"
      />
    </div>
  );
}
