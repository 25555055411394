import React, { useEffect, useState } from "react";
import TableContainer from "../home/tableContainer/tableContainer";
import Navbar from "../navbar/menuVertical/navbar";
import { useNavigate } from "react-router-dom";
import PopUpNewAdmin from "../AdminTable/PopUpNewCompany/PopUpNewCompany";
import axiosInstance from "../AxiosInterceptor/AxiosInterceptor";


const Admin = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [navbarData, setNavbarData] = useState(false);
    const [estado, setEstado] = useState(false);
    const [listOranizations, setListOrganization] = useState([])

    useEffect(() => {
   handleReload()
    }, [])

    const customStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "#6D7A8FFF",
            fontFamily: "Inter",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: "0.28px",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "stretch",
            cursor: "pointer",
            borderRadius: "8px",
            borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
        }),
        container: (provided, state) => ({
            ...provided,
            width: "350px",
            height: "32px",
            fontFamily: "Inter",
            fontSize: "12px",
        }),
        control: (provided, state) => ({
            ...provided,
            fontFamily: "Inter",
            fontSize: "12px",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "stretch",
            background: "#FFF",
            cursor: "pointer",
            width: "350px",

            borderRadius: "8px",
            borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
            boxShadow: state.isFocused ? "0 0 0 1px #F5A973" : "none",

            "&:hover": {
                borderColor: "#F18F49",
            },
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        menu: (provided) => ({
            ...provided,
            display: "block",
            width: "350px",
            height: "85px",
            padding: "8px",
            gap: "8px",
            borderRadius: "8px",
            cursor: "pointer",
        }),
        option: (provided, state) => ({
            ...provided,
            color: "#6D7A8FFF",
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: "400",
            backgroundColor: "white",

            "&:active": {
                backgroundColor: "white", // Evita que haya un color de fondo al hacer clic en la opción
            },
            "&:hover": {
                backgroundColor: "#EFF8FF", // Cambia el color de fondo al hacer hover
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#6D7A8FFF",
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: "400",
            backgroundColor: "white",
            paddingLeft: '5px',

        }),
    };

    const estadoMenu = (data) => {
        setNavbarData(data);
    };
    let handleClick = () => {
        setEstado(false)
        handleReload()
    }
    const handleReload = ()=>{
        axiosInstance.get(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/organization/list`,
        )
            .then((e) => {
                  setListOrganization(e.data.organization_data_list)
                  setLoading(false)
            })
            .catch((e) => {
                if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
                    localStorage.clear();
                    navigate('/iniciar-sesion')
                } else if ((e.responese && e.responese.status && e.response.status === 302)) {
                    navigate('/acceso-denegado')
                }
                setLoading(false)
            })
    }
    const selectPosition = [{ value: 'Administrador', label: 'Administrador' }, { value: 'Visualizador', label: 'Visualizador' }]

    return (
        <>
            <div className="home">

                <Navbar estado={estadoMenu} />
                {estado && <PopUpNewAdmin handleClick={handleClick} list={selectPosition} selectstyles={customStyles} />}

                <div
                    className={`home_body_container ${navbarData ? "navbar-open" : ""}`}
                    style={{ marginLeft: navbarData ? "228px" : "80px" }}
                >
                    {loading ? <div className={`spinner_container_homeProcess ${navbarData ? "navbar-open" : ""}`}>
                        <div className="spinner__circle_homeProcess">
                            <div className="spinner__circle-gradient_homeProcess"></div>
                            <div className="spinner__circle-inner_homeProcess"></div>
                        </div>
                        <p className="loader-text_homeProcess">Estamos procesando tus datos...</p>
                    </div> :
                        <>
                            <header className="headerHome"></header>

                            <main className="mainHome">

                                <div className="userContainer">

                                    <h1>
                                        Organizaciones
                                    </h1>

                                    <button
                                        onClick={() => { setEstado(true) }}
                                        className="createNewBuyerPersona"
                                    >
                                        {" "}
                                        + Organización
                                    </button>

                                </div>

                                <div className="tableContainerProcessExecuted">
                                    <TableContainer
                                        param={'/administrador'}
                                        loading={loading}
                                        company={listOranizations}
                                        reload={()=>{handleReload()}}
                                    />
                                </div>

                            </main>
                        </>
                    }
                </div>

            </div>
        </>
    );
};

export default Admin;
