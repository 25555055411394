import React from 'react';
import './SpinnerProcesando.css';

const SpinnerProcesando = () => {
  return (
    <div className="spinner_container">
      <div className="spinner__circle">
        <div className="spinner__circle-gradient"></div>
        <div className="spinner__circle-inner"></div>
      </div>
      <p  className="loader-text">Estamos procesando tus datos...</p>
    </div>
  )
};

export default SpinnerProcesando;
