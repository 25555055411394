import React, { useState, useEffect, useContext, useRef } from "react";
import Select from "react-select";
import "./AmplifyManualFiltros.css";
import axiosInterceptor from "../../AxiosInterceptor/AxiosInterceptor";
import Delete from './img/trash.svg'
import Add from './img/add.svg'
import SelectCheckBox from "../../ComponentesMoleculas/SelectCheckBox/SelectCheckBox";
import { useNavigate } from "react-router-dom";

const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    color: "#98A2B3",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0.28px",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    cursor: "pointer",
    borderRadius: "8px",
    borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
  }),
  container: (provided, state) => ({
    ...provided,
    width: "154px",
    height: "32px",
  }),
  control: (provided, state) => ({
    ...provided,
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    background: "#FFF",
    cursor: "pointer",

    borderRadius: "8px",
    borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
    boxShadow: state.isFocused ? "0 0 0 1px #F5A973" : "none",

    "&:hover": {
      borderColor: "#F18F49",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    padding: "8px",
    gap: "8px",
    borderRadius: "8px",
    cursor: "pointer",

  }),
  option: (provided, state) => ({
    ...provided,
    color: "#475467",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: "400",
    backgroundColor: "white", // Default background color
    "&:active": {
      backgroundColor: "white", // Background color when active (clicked)
    },
    "&:hover": {
      backgroundColor: "#EFF8FF", // Background color on hover (change this color)
    },
  }),
}
const customStylesInt = {
  placeholder: (provided, state) => ({
    ...provided,
    color: "#98A2B3",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0.28px",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    cursor: "pointer",
    borderRadius: "8px",
    borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
  }),
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    height: "32px",
  }),
  control: (provided, state) => ({
    ...provided,
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    background: "#FFF",
    cursor: "pointer",
    height: "38px",
    minHeight: "24px",
    borderRadius: "8px",
    borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
    boxShadow: state.isFocused ? "0 0 0 1px #F5A973" : "none",
    "&:hover": {
      borderColor: "#F18F49",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    left: 0, /* Alinear la lista a la izquierda */
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#475467",
    fontSize: "12px",
    width: "240px",
    fontFamily: "Inter",
    fontWeight: "400",
    backgroundColor: "white", // Cambia el color de fondo al hacer hover
    "&:active": {
      backgroundColor: "white", // Evita que haya un color de fondo al hacer clic en la opción
    },
    "&:hover": {
      backgroundColor: "#EFF8FF",
      width: "100%", // Cambia el color de fondo al hacer hover
    },

  }),
}

const AmplifyContenedorLista = ({ name, obj }) => {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([{ selectedFilter: null }]);
  const [intFilterData, setIntFilterData] = useState([{ operator: "", valueFrom: "", valueTo: "" }]);
  const [showAdditionalDivs, setShowAdditionalDivs] = useState(false);
  const [filtro, setFiltro] = useState({
    title_clon: "",
    organizacion: localStorage.getItem('companyFilter'),
    data: [],
  });

  const country = localStorage.getItem('selectedCountry')
  const handleSeleccionadosChange = (optionsCheckBox) => {
    setSelectedFilters(optionsCheckBox);
  }

  useEffect(() => {
    obj(filtro);
  }, [filtro, selectedFilters, intFilterData, showAdditionalDivs]);

  useEffect(() => {
    getEnrichment();
  }, []);

  useEffect(() => {
    setFiltro({
      title_clon: name,
      organizacion: filtro.organizacion,
      data: filtro.data
    })
  }, [name]);

  const getEnrichment = async () => {
    try {
      const response = await axiosInterceptor.get(
        `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/manuales`,
       
      );

      const filterOptions = response.data.filtros.data.map((item) => ({
        value: item.name,
        label: item.name,
        type: item.type,
        filters: item.filters || [],
      }));

      setOptions(filterOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }));
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 422)) {
        localStorage.clear();
        navigate("/iniciar-sesion");
      }
    }
  };

  const handleFilterChange = (selectedOption, index) => {
    const updatedSelectedFilters = [...selectedFilters];
    updatedSelectedFilters[index] = { selectedFilter: selectedOption };
    setSelectedFilters(updatedSelectedFilters);

    const updatedFiltro = { ...filtro, data: [...filtro.data] };
    updatedFiltro.data[index] = {
      name: selectedOption.value,
      data: [],
      type: selectedOption.type,
    };
    setFiltro(updatedFiltro);
  };

  const handleIntOperatorChange = (selectedOption, index) => {
    const updatedIntFilterData = [...intFilterData];
    updatedIntFilterData[index] = { ...intFilterData[index], operator: selectedOption.value };
    updatedIntFilterData[index].valueFrom = "";
    updatedIntFilterData[index].valueTo = "";
    setIntFilterData(updatedIntFilterData);

    const updatedFiltro = { ...filtro, data: [...filtro.data] };
      updatedFiltro.data[index] = {
        ...updatedFiltro.data[index],
        val: selectedOption.value,
        data: [],
        type: "int",
      }; 
    setFiltro(updatedFiltro);
  };

  useEffect(() => {
    obj(filtro);
  }, [filtro, selectedFilters, intFilterData, showAdditionalDivs]);

  const handleIntValueChange = (event, index) => {
    const updatedIntFilterData = [...intFilterData];
    updatedIntFilterData[index] = { ...updatedIntFilterData[index], [event.target.name]: event.target.value };
    setIntFilterData(updatedIntFilterData);

    const updatedFiltro = { ...filtro, data: [...filtro.data] };
    const valueFrom = parseInt(updatedIntFilterData[index].valueFrom, 10);
    const valueTo = parseInt(updatedIntFilterData[index].valueTo, 10);

    if (!isNaN(valueFrom)) {
      updatedFiltro.data[index].data = [valueFrom];
    } else {
      updatedFiltro.data[index].data = [];
    }
    if (!isNaN(valueFrom) && updatedIntFilterData[index].operator === "entre" && !isNaN(valueTo)) {
      updatedFiltro.data[index].data = [valueFrom, valueTo];
    }
    updatedFiltro.data[index].data.sort((a, b) => a - b);
    setFiltro(updatedFiltro);
  };

  const handleSelect = (e, index) => {
    // Copia del objeto filtro
    const updatedFiltro = { ...filtro };
    if (e.length > 0) {
      setShowAdditionalDivs(true);
      const selectedValues = e.map((opt) => opt.value);
      // Actualiza solo la propiedad "data" del elemento en la posición index
      updatedFiltro.data[index].data = selectedValues;
    } else {
      setShowAdditionalDivs(false);
      // Si no hay selecciones, puedes borrar los datos en ese elemento
      updatedFiltro.data[index].data = [];
    }
    // Actualiza el estado con la copia modificada
    setFiltro(updatedFiltro);
  };

  const handleAddSelector = () => {
    const updatedSelectedFilters = [...selectedFilters, { selectedFilter: null }];
    setSelectedFilters(updatedSelectedFilters);

    const updatedIntFilterData = [...intFilterData, { operator: "", valueFrom: "", valueTo: "" }];
    setIntFilterData(updatedIntFilterData);

    const updatedShowAdditionalDivs = Array.isArray(showAdditionalDivs) ? [...showAdditionalDivs, false] : [false];
    setShowAdditionalDivs(updatedShowAdditionalDivs);
  };

  const handleRemoveSelector = (index) => {
    const objFiltro = filtro
    objFiltro.data.splice(index, 1);
    setFiltro({
      title_clon: filtro.title_clon,
      organizacion: filtro.organizacion,
      data: [objFiltro]
    })

    const updatedSelectedFilters = [...selectedFilters];
    updatedSelectedFilters.splice(index, 1);
    setSelectedFilters(updatedSelectedFilters);

    const updatedIntFilterData = [...intFilterData];
    updatedIntFilterData.splice(index, 1);
    setIntFilterData(updatedIntFilterData);

    const updatedShowAdditionalDivs = [...showAdditionalDivs];
    updatedShowAdditionalDivs.splice(index, 1);
    setShowAdditionalDivs(updatedShowAdditionalDivs);
  }

  const formatOptionLabel = ({ label, value }) => {
    if (value === "entre") {
      return <div className="ValTextManuales">{label} </div>;
    } else if (value === '') {
      return <div className="placeholderSelectManualesInt">{label} </div>;
    } else {
      return <div className="ValTextManuales">{label} a</div>;
    }
  }

  return (
    <div className="amplifyManualFiltros">
      {selectedFilters.map((selectedFilter, index) => (
        <div className="amplifyManualFiltroRegion" key={index}>
          <div className="amplifyManualFiltroContainer">
            <div className="amplifyManualFiltroContainerSelect">
              {index === 0 && <label className="amplifyManualFiltroLabel">Filtro</label>}
              <Select
                placeholder={"Selecciona un filtro"}
                styles={customStyles}
                value={selectedFilter.selectedFilter}
                options={options.filter(option => !selectedFilters.sort().some(filter => filter.selectedFilter && filter.selectedFilter.value === option.value))}
                onChange={(option) => handleFilterChange(option, index)}
                classNamePrefix="primerSelectAM"
              />

            </div>
            {selectedFilter.selectedFilter && (
              <div className="amplifyManualFiltroContainerSelectCheckbox">
                {index === 0 && <label className="amplifyManualFiltroLabel">Indicador</label>}
                {selectedFilter.selectedFilter.type === "int" ? (
                  <Select
                    styles={customStylesInt}
                    className="selectManualesInt"
                    classNamePrefix="selectManualesInt"
                    options={[
                      { value: "igual", label: "Igual" },
                      { value: "mayor", label: "Mayor" },
                      { value: "menor", label: "Menor" },
                      { value: "entre", label: "Entre" },
                    ]}
                    value={intFilterData[index].operator ? { value: intFilterData[index].operator, label: intFilterData[index].operator }
                      : { value: '', label: 'Selecciona una opción' }}
                    onChange={(option) => handleIntOperatorChange(option, index)}
                    formatOptionLabel={formatOptionLabel} // Personaliza cómo se muestra cada opción

                  />
                ) : (
                  <SelectCheckBox
                    handleSelect={(e) => handleSelect(e, index)}
                    optionsCheckBox={selectedFilter.selectedFilter.filters.map((filter, i) => (
                      { value: filter, label: filter }))}
                    seleccionados={filtro.data}

                  />
                )}
              </div>
            )}
            {(selectedFilter &&
              selectedFilter.selectedFilter && intFilterData[index].operator === "entre" && selectedFilter.selectedFilter.type === "int") && (
                <>
                  <input
                    onWheel={(e) => e.target.blur()}
                    className={index === 0 ? 'inputManuales borde-inputs-generales inputManualesPrimero' : ' inputManuales borde-inputs-generales'}
                    placeholder="Desde"
                    type="number"
                    name="valueFrom"
                    value={intFilterData[index].valueFrom}
                    onChange={(event) => handleIntValueChange(event, index)}
                  />
                  <span className={index === 0 ? 'inputManualesGuion inputManualesGuionPrimero' : ' inputManualesGuion '}>-</span>
                  <input
                    onWheel={(e) => e.target.blur()}
                    className={index === 0 ? 'inputManuales inputManualesPrimero borde-inputs-generales' : ' inputManuales borde-inputs-generales'}
                    placeholder="Hasta"
                    type="number"
                    name="valueTo"
                    value={intFilterData[index].valueTo}
                    onChange={(event) => handleIntValueChange(event, index)}
                  />
                </>
              )}
            {(selectedFilter &&
              selectedFilter.selectedFilter &&
              selectedFilter.selectedFilter.type === "int" && (intFilterData[index].operator === "mayor" || intFilterData[index].operator === "menor" || intFilterData[index].operator === "igual")) && (
                <input
                  onWheel={(e) => e.target.blur()}
                  className={index === 0 ? 'inputManuales borde-inputs-generales inputManualesPrimero' : 'inputManuales borde-inputs-generales'}
                  type="number"
                  name="valueFrom"
                  value={intFilterData[index].valueFrom}
                  onChange={(event) => handleIntValueChange(event, index)}
                />
              )}
            <div className={index === 0 ? "botonManualesContenedorPrimero" : "botonManualesContenedor"}>

              {selectedFilters.length > 1 && <button onClick={() => { handleRemoveSelector(index) }} className="ButtonManual"><img src={Delete} alt="eliminar" /></button>}
              <button onClick={() => { handleAddSelector() }} className="ButtonManual ButtonManualMas"><img src={Add} alt="agregar" /></button>
            </div>
          </div>
        </div>
      ))}

    </div>
  );
};

export default AmplifyContenedorLista;
