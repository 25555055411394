import React, { useState, useEffect, useContext } from "react";
import { AppContext } from '../../../Context/Context';
import { useTablaContext } from "../contextTabla/TablaContext";
import IconExito from "../cardsTabla/assets/exito.svg";
import IconAtencion from "./assets/iconAtencion.svg";
import IconAtencionInput from "./assets/iconAtencionInput.svg";
import ModalTablaError from "../modales/modalTablaError";
import Pagination from "../../../Pagination/Pagination";
import separadorDeMiles from "../../../SeparadorDeMiles/SeparadorDeMiles";
import './cardDetalleTabla.css'

const CardDetalleTabla = ({ dataObject, dataAmplify }) => {
  const { setTotalBusqueda, setDetalleButton } = useContext(AppContext);
  const { setTotalSolicitados } = useTablaContext();
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ left: 0, top: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [showBackgroundOpacity, setShowBackgroundOpacity] = useState(false);
  const [hasAttentionIcon, setHasAttentionIcon] = useState(false);
  const [data, setData] = useState([]);
  const [inputChangeCounter, setInputChangeCounter] = useState(0);
  
  useEffect(() => {
    dataAmplify.sort((a, b) => {
      const profileA = parseInt(a.Perfil.split("_")[1]);
      const profileB = parseInt(b.Perfil.split("_")[1]);
      return profileA - profileB;
    });
  
    setData(dataAmplify);
    const profileSums = {};
    dataAmplify.forEach((rowData) => {
      const profile = rowData.Perfil;
      const nFinal = rowData.n_final || 0;
      profileSums[profile] = (profileSums[profile] || 0) + nFinal;
      
    });
    
    const profileSumArray = Object.values(profileSums);
    setTotalBusqueda(profileSumArray)
  }, [dataAmplify, inputChangeCounter])


  useEffect(() => { setDetalleButton(hasAttentionIcon) }, [hasAttentionIcon, dataAmplify])

  //// Calcular la suma inicial cuando los datos se obtienen de la API
  useEffect(() => {
    const initialTotal = data.reduce(
      (acc, rowData) => acc + (rowData.n_final || 0),
      0
    );
    setTotalSolicitados(initialTotal);
  }, [data]);


  const handleInputChange = (event, rowData) => {
    setInputChangeCounter((prevCounter) => prevCounter + 1);
    let inputValue = event.target.value.trim().replace(/\D/g, ''); // Eliminar todos los caracteres no numéricos
    inputValue = inputValue.slice(0, 5); // Limitar la longitud máxima a 5 dígitos
  
    const newSolicitados = inputValue ? parseInt(inputValue, 10) : 0;
  
    if (newSolicitados > 99999) {
      // Si el valor ingresado excede 99,999, establecerlo en el límite máximo
      rowData.solicitados = 99999;
      rowData.showWarning = true;
      setHasAttentionIcon(true);
    } else if (newSolicitados > rowData.resultados) {
      rowData.solicitados = newSolicitados;
      rowData.showWarning = true;
      setHasAttentionIcon(true);
    } else {
      rowData.solicitados = newSolicitados;
      rowData.showWarning = false;
      setShowModal(false);
      setHasAttentionIcon(false);
    }
  
    rowData.n_final = rowData.solicitados; // Asignar el valor corregido
    setData([...data]);
    setHasAttentionIcon(data.some(row => row.n_final > row.resultados));
  };
  

  // Lógica para obtener los datos de la página actual
  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  const currentPageData = data.slice(startIndex, endIndex);

  // Logica para enviar informacion en JSON de la tabla 
  const createJsonObject = (data) => {
    const jsonObject = {
      form: {},
    };
    data.forEach((e, rowIndex) => {
      const key = `${e.comuna}${e.genero}${e.Perfil}`;
      jsonObject.form[key] = e.n_final || 0;
    });
    return jsonObject;
  };

  useEffect(() => {
    const jsonObject = createJsonObject(data);
    setSelectedRow(jsonObject);
    dataObject(jsonObject)
  }, [data])
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  let nombrescomunas = []
  currentPageData.map(i => {
    nombrescomunas.push(toTitleCase(i.comuna))
  })
  return (
    <>
      <div className="amplifyCardTabla">
        <p className="amplifyTitleTabla">Detalle</p>
        <table className="amplifyTabla">
          <thead className="amplifyTablaThead">
            <tr>
              <th className="amplifyTablaFila Comuna">
                <p className="amplifyTablaHead">Comuna</p>
              </th>
              <th className="amplifyTablaFila BP">
                <p className="amplifyTablaHead">Buyer Persona</p>
              </th>
              <th className="amplifyTablaFila Genero">
                <p className="amplifyTablaHead">Género</p>
              </th>
              <th className="amplifyTablaFila Estado">
                <p className="amplifyTablaHead">Status</p>
              </th>
              <th className="amplifyTablaFila Encontrado">
                <p className="amplifyTablaHead">Encontrados</p>
              </th>
              <th className="amplifyTablaFila Solicitado">
                <p className="amplifyTablaHead">Solicitar</p>
              </th>
            </tr>
          </thead>

          <tbody className="amplifyTablaTbody ">
            {currentPageData.map((rowData, rowIndex) => (

              <React.Fragment key={rowData.id}>
                <tr

                  data-id={rowData.id}
                  data-encontrados={rowData.n_final}
                  className={`amplifyTablaRow ${selectedRow === rowIndex ? "selected-row" : ""
                    }`}
                  onClick={() => setSelectedRow(rowIndex)}
                >
                  <td className="amplifyTablaFila Comuna">
                    <p className="amplifyTablaFilaComuna">{nombrescomunas[rowIndex]}</p>
                  </td>
                  <td className="amplifyTablaFila BP">
                    <p>{rowData.Perfil}</p>
                  </td>
                  <td className="amplifyTablaFila Genero">
                    <p>{rowData.genero}</p>
                  </td>
                  <td className="amplifyTablaFila Estado">
                    {rowData.n_final <= rowData.resultados ? <img className="iconEstado" src={IconExito} alt="" /> : <img src={IconAtencion} alt="" />}

                  </td>
                  <td className="amplifyTablaFila Encontrado">
                    <p>{separadorDeMiles(rowData.resultados)}</p>
                  </td>
                  <td className="amplifyTablaFila Solicitado">
                    <div className="input-container">
                      <input
                        className="borde-inputs-generales"
                        value={separadorDeMiles(rowData.n_final)} 
                        onChange={(event) => handleInputChange(event, rowData)}
                        onFocus={(event) => {
                          event.target.select();
                        }}
                      />




                      {rowData.showWarning && (
                        < >
                          <img src={IconAtencionInput} alt="Atención" className="warning-icon"
                          //   onMouseOver={(event) => { handleVerticalIconClick(event, rowData); setShowModal(true); setShowBackgroundOpacity(true); }}
                          //   onMouseLeave={(event) => { setShowModal(false); setShowBackgroundOpacity(false); }}
                          //
                           />
                          {showBackgroundOpacity && <div className="dimmed-background"></div>}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
                {(showModal && selectedRow === rowData) && (
                  <ModalTablaError
                    position={{
                      position: 'absolute',
                      left: popupPosition.left - 280,
                      top: popupPosition.top - window.scrollY,
                      zIndex: 1100
                    }}
                  />)}
              </ React.Fragment>
            ))}
          </tbody>
        </table>
        <Pagination List={data} currentPageProp={(e) => { setCurrentPage(e) }} rowsPerPageProp={10} detalle={true} />

      </div>
    </>
  );
};

export default CardDetalleTabla;