import React from 'react';
import Body from './body'
import ButtonPrimary from './btnPrimary';
import './cardLoader.css'

const walkthroughButton = () => {
    return (
        <div className='walkthroughLoader'>
            <div className="boxLoader">
                <div className="loader1"></div>
            </div>
            <Body className="bodyTextLoader" value='Ha ocurrido un error. Intenta nuevamente' />
            <ButtonPrimary value={"Generar nuevamente"} />
        </div>

    );
}

export default walkthroughButton;
