import React from 'react';
import SinConexion from './sin_conexión.svg'
import { useNavigate } from 'react-router-dom';
import ButtonLink from '../../../ComponentesMoleculas/ButtonLink';
import './connectionErrorCard.css'

const ConnectionErrorButton = () => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1); // Regresa a la página anterior
    };

    return (
        <div className='connectionErrorCard'>
          <img src={SinConexion} alt="" />

            <div className='connectionError_container'>
                <h1>Sin conexión</h1>
                <p className='textConnectionError'>
                    Parece que ocurre un error de conexión. 
                    Intenta nuevamente en unos instantes
                </p>
            </div>

            <ButtonLink cName={'btnPrimary'} value={'Recargar página'} onClick={handleGoBack}/>
        </div>

    );
}

export default ConnectionErrorButton;
