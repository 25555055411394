import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Provider = ({ children }) => {
    const navigate = useNavigate()
    const [tkn, setTkn] = useState(undefined)
    const [token, setToken] = useState([]);
    const [myArray, setMyArray] = useState([]);
    const [country, setCountry] = useState(undefined);
    const [items, setItems] = useState(undefined);
    const [quantity, setQuantity] = useState({ cantidad: 0, solicitado: 0 })
    const [errlog, setErrLog] = useState(false)
    const [perfiles, setPerfiles] = useState([]);
    const [total, setTotal] = useState(0);
    const [dataAmplifyContext, setDataAmplifyContext] = useState(0)
    const [buttonLog, setButtonLog] = useState(false)
    const [totalBusqueda, setTotalBusqueda] = useState([])
    const [detalleButton, setDetalleButton] = useState(false)
    const [contadoresHome, setContadoresHome] = useState({}) 
    const [percent, setPercent] = useState(undefined)
    const [automatico, setAutomatico] = useState(false)
    const [companyFilter, setCompanyFilter] = useState(undefined)
    const [buyerClonTkn, setBuyerClonTkn] = useState(undefined)

    useEffect(()=>{if(percent !== undefined){localStorage.setItem('percent', JSON.stringify(percent))}},[percent])
    useEffect(() => {
        const storedArray = localStorage.getItem('cty');
        if (storedArray !== "undefined") {
            setMyArray(JSON.parse(storedArray));
        }
    }, []);


    useEffect(() => {
        if (country  && localStorage.getItem('selectedCountry') === 'undefined') {
            navigate('/iniciar-sesion')
        }
    }, [myArray])

    useEffect(() => {
       if (country !== undefined && 'selectedCountry' === "undefined") {
            localStorage.setItem('selectedCountry', country)
        }else if(localStorage.getItem('selectedCountry') !== "undefined" &&  country === undefined ){
          setCountry(Number(localStorage.getItem("selectedCountry")))
        }
    
    }, [country, myArray]);

    useEffect(() => {
        if (items  && localStorage.getItem('selectedItems') === 'undefined') {
            navigate('/iniciar-sesion')
        }
    }, [myArray])

    useEffect(() => {
       if (items !== undefined && 'selectedItems' === "undefined") {
            localStorage.setItem('selectedItems', items)
        }else if(localStorage.getItem('selectedItems') !== "undefined" &&  items === undefined ){
          setItems(Number(localStorage.getItem("selectedItems")))
        }
    }, [country, myArray]);
    useEffect(() => {
        if (companyFilter !== undefined && 'companyFilter' === "undefined") {
             localStorage.setItem('companyFilter', companyFilter)
         }else if(localStorage.getItem('companyFilter') !== "undefined" &&  companyFilter === undefined ){
           setCompanyFilter(localStorage.getItem("companyFilter"))

         }}, [companyFilter]);


    return (
        <AppContext.Provider value={{ companyFilter, setCompanyFilter,items,setItems,automatico, setAutomatico,detalleButton, setDetalleButton, buttonLog, setButtonLog, totalBusqueda, setTotalBusqueda, dataAmplifyContext, setDataAmplifyContext, perfiles, setPerfiles, total, setTotal, errlog, setErrLog, quantity, setQuantity, token, setToken, myArray, setMyArray, country, setCountry, tkn, setTkn, contadoresHome, setContadoresHome, percent, setPercent, buyerClonTkn, setBuyerClonTkn }}>
            {children}
        </AppContext.Provider>
    );
}

export default Provider;
export const AppContext = createContext();
