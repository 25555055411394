import React from "react";
import IconEncontrados from "./assets/iconEncontradosManuales.svg";
import ImgEncontrados from "./assets/imgEncontrados.png";
import IconoExito from "./assets/exitoBuenaNoticia.svg";
import ProgressBar from "../../../Amplify/AmplifyContenedor/cardsTabla/progresBarCardResumen/progressBarCardResumen";
import "./cardSolicitudAmplify.css";
import { useNavigate } from "react-router-dom";
import separadorDeMiles from "../../../SeparadorDeMiles/SeparadorDeMiles";

const AmplifyCardEncontrados = ({ clickLink, quantity, automatico }) => {
  const navigate = useNavigate();
  const formatearNumero = (numero) => {
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <>
      <div className="amplifySolicitudClonesBuenaNoticia borde-radius-gris">
        <div className="amplifySolicitudContenido">
          <div className="SolicitudClonesContenedorImg Encontrados">
            <img src={ImgEncontrados} alt="" />
          </div>

        <div className={!automatico ? "solicitudClonesContenedorInternoManuales" : "solicitudClonesContenedorInterno"} >
          <div className="SolicitudClonesContenedor">
            <div className="SolicitudClonesContenedorTitle">
              <img src={IconoExito} alt="IconoExito" />
              <h2 className="solicitudClonesTitle">¡Que buena noticia!</h2>
            </div>
            <p>
              Encontramos <strong>{separadorDeMiles(quantity.cantidad)}</strong> personas como las
              que solicitaste. <br /> <br />Haz clic para amplificar y potenciar tus
              campañas.
            </p>
          </div>
          <div className="SolicitudClonesContenedorEncontrados" >
            <div className={!automatico ? "SolicitudClonesCardDatosContenedorManuales": "SolicitudClonesCardDatosContenedor"  }>
              <div className="SolicitudClonesImgEncontrados">
              { (!automatico) ? <img src={IconEncontrados} /> : <ProgressBar cardResumen={Number(quantity.solicitado)} ultimaCard={true} />}
              </div>
            <div className="solicitudClonesContenedorDatos">
              {automatico && (
              <div className="SolicitudClonesCardDatos">
                <div className="SolicitudClonesDatosText">
                  <p>Buscados</p>
                </div>
                <p>{formatearNumero(quantity.solicitado)}</p>

              </div>
              )}

              <div className={`SolicitudClonesCardDatos ${!automatico ? 'Encontrados' : ''}`}>
                <div className="SolicitudClonesDatosText">
                  <p>Encontrados</p>
                </div>
                <p>{formatearNumero(quantity.cantidad)} </p>
              </div>

              {automatico &&
              <div className="SolicitudClonesCardDatos Solicitados">
                <div className="SolicitudClonesDatosText">
                <strong>
                  <p>Solicitados</p>
                  </strong>
                </div>
                <strong>
                <p>{formatearNumero(quantity.solicitado)}</p>
                </strong>
              </div> 
              }
            </div>
          </div>

          {automatico && (
            <div
              className="SolicitudClonesCardDetalle"
              onClick={() => {
                navigate("/amplify-detalle");
              }}
            >
                <p>Ver detalle</p>
            </div>
          )}
         

        </div>
        </div>
     </div>
      
        <div className="Confirmacion_botones botonesCardEncontrados">
          <button
            onClick={() => {
              if (automatico) {
                navigate("/amplify/1");
              } else {
                navigate("/amplify/2");
              }
            }}
            className={"btnSecondary btnCardEncontradosAmplificar"}
          >
            Atrás
          </button>

          <button className="btnPrimary btnCardEncontradosAmplificar" onClick={clickLink}>
            Amplificar
          </button>
        </div>
      </div>
    </>
  );
};

export default AmplifyCardEncontrados;
