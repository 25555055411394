import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import axios from "axios";
import Eye from "./eye-visible.svg";
import EyeClose from "./eye-no-visible.svg";
import isologo from "../../assets/image/isologo.svg";
import { useEffect } from "react";
import "./Login.css";
import BanderaChile from "../navbar/menuVertical/boxNavbar/boxNavbarAssets/chile.svg";
import BanderaMexico from "../navbar/menuVertical/boxNavbar/boxNavbarAssets/mexico.svg";
import { AppContext } from "../Context/Context";

function Login() {
  const { setCountry,setItems,setCompanyFilter,setMyArray } = useContext(AppContext);
  const [validCaptcha, changeValidCaptcha] = useState(false);
  const [eyeState, setEyeState] = useState("password");
  const [image, setImage] = useState(EyeClose);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [emptyValues, setEmptyValues] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState("");
  
  useEffect(() => { localStorage.clear(); }, [])
  let login = () => {
    axios
      .post(`${process.env.REACT_APP_URL}/api/login`, {
        username: user,
        password: password,

      })
      .then(function (response) {
        setCountry(response.data.roles[0].id)
      




        if (response.data.access_token) {
          let roles = []
          response.data.roles.map(e => {
            roles.push(e.id)
          })
          localStorage.removeItem('companies')
          localStorage.removeItem('selectedItems')
          localStorage.removeItem('companyFilter')
          localStorage.removeItem('token')
          localStorage.removeItem('cty')
          localStorage.removeItem('privilege')
          localStorage.removeItem('ENRICHMENT')
          localStorage.removeItem('CLONES')
          localStorage.removeItem('CLONES_MANUALES')


          setMyArray(roles)
          setCompanyFilter(response.data.companies[0].name)
          setItems(response.data.companies[0].country_id)
          localStorage.setItem('name',  response.data.active_role.nombre)
          localStorage.setItem('companyFilter',  response.data.companies[0].name)
          localStorage.setItem('companies', JSON.stringify(response.data.companies))
          localStorage.setItem('companyFilter', response.data.companies[0].name)
          localStorage.setItem('selectedItems', response.data.companies[0].country_id)
          roles = JSON.stringify(roles)
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem('cty', JSON.stringify(response.data.roles))
          localStorage.setItem("selectedCountry", response.data.active_role.id)
          
          const obj = response.data.active_role.privileges.admin && response.data.active_role.privileges.admin.function.filter(e => e.name === "UPDATE") ? response.data.active_role.privileges.admin.function.filter(e => e.name === "UPDATE") : [];

          localStorage.setItem("privilege", obj.length > 0 ? true : false)
       
                const privileges = response.data.active_role.privileges;
         
                if (privileges ) {
                  
                    localStorage.setItem('ENRICHMENT', privileges.enrichment.function.some(func => func.name === "CREATE"))
                    localStorage.setItem('CLONES', privileges.clones.function.some(func => func.name === "CREATE"))
                    localStorage.setItem('CLONES_MANUALES', privileges.clones_manuales.function.some(func => func.name === "CREATE"))

                }else{
                localStorage.setItem('ENRICHMENT', false)
                }
              
         
          let flagURL;
          if (response.data.companies[0].country_id === 43) {
            // Establecer la URL de la bandera para Chile
            flagURL = BanderaChile;
          } else if (response.data.companies[0].country_id === 135) {
            // Establecer la URL de la bandera para México
            flagURL = BanderaMexico;
          }
          localStorage.setItem('flag', flagURL)
          if (!response.data.remember.onboarding) {
            navigate("/comenzar");
          } else {
            navigate("/inicio/buyer-persona");

          }

        } else {
          navigate("/error2");
        }
      })
      .catch(function (e) {
        localStorage.setItem('err', JSON.stringify(e.response.data))
        navigate("/iniciar-sesion-error");
      });
  };

  const resetErrors = () => {
    const emailInput = document.getElementById("email-input");
    const InputConst = document.getElementById("password-input");

    emailInput.style.borderColor = "";
    InputConst.style.borderColor = "";
    setPasswordError("");
  };

  const seePassword = () => {
    if (user.length > 0 && password.length > 0) {
      setEmptyValues(false);
    } else {
      setEmptyValues(true);
    }
    if (eyeState === "password") {
      setEyeState("text");
      setImage(Eye);
    } else {
      setEyeState("password");
      setImage(EyeClose);
    }
  };

  const submit = () => {
    if (user.length > 0 && password.length > 0) {
      setEmptyValues(false);
    } else {
      setEmptyValues(true);
    }

    if (captcha.current.getValue()) {
      changeValidCaptcha(true);
    } else {
      changeValidCaptcha(false);
    }
    if ( validCaptcha &&  !emptyValues) { 
      login();
    }

    // const isFormValid = validateForm();
    // if (isFormValid) {
    //   login();
    // }
  };

  useEffect(() => {
    if (password.length > 0 && user.length > 0  && validCaptcha ) {  /*  validCaptcha && - */ 
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [password, user, validCaptcha]);

  const onChange = (value) => {
    if (captcha.current.getValue()) {
      changeValidCaptcha(true);
      // setTokenV2(value);
    }
  };

  const captcha = useRef(null);

  return (
    <div className="login"> 
      <header className="login__header"></header>
      <div className="login__contenido"> 
        <div className="login__container borde-radius-gris">
          <img src={isologo} alt="" className="login__isologo" />
          <div className="login__form">

            <div className="form__inputs_inicioSesion">
              <div className="form__inputs__email">
                <label htmlFor=""> Email</label>
                <input
                  id="email-input"
                  type="email"
                  placeholder="Ingresa tu usuario"
                  onChange={(e) => setUser(e.target.value)}
                  className="form__inputs--text-login"
                  onFocus={resetErrors}
                />
          
              </div>
              <div className="inputs__password">
                <div className="inputs__password__container">
                  <label htmlFor="">Contraseña</label>
                  <input
                    id="password-input"
                    type={eyeState}
                    placeholder="Contraseña"
                    onChange={(e) => setPassword(e.target.value)}
                    className="form__inputs--text-login"
                    onFocus={resetErrors}
                  />
                  <div className="form__inputs--image--container">
                    <img
                      src={image}
                      alt=""
                      className="form__inputs--image"
                      onClick={seePassword}
                    />
                  </div>
                  <div id="password-error" className="form__error-message">
                    {passwordError}
                  </div>

                </div>

              </div>

            </div>
            <div className="form_container">
              <div className="form__checkbox">
                <input className="form_checkbox_box" type="checkbox" />{" "}
                <p>Mantener sesión iniciada</p>
              </div>
              <div className="login__captcha">
                <ReCAPTCHA
                  id="captcha"
                  ref={captcha}
                  sitekey="6LesTLAjAAAAAGR0ogCrubBXtaTDO_qKfPXPJMgN"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div>
          <p className="LoginForgotPasswordText" onClick={()=>{navigate('/olvide-contraseña')}}>¿Olvidaste tu contraseña?</p>
          <button
            onClick={() => {
              if (isDisabled) {
                submit();
              }
            }}
            className={isDisabled ? "disabled" : "login__form--buttonIniciarSesion"}
          >
            Ingresar
          </button>
          </div>
        </div>
 
      </div>
    </div >
  );
}

export default Login;
