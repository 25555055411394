import React from 'react';
import { Link } from 'react-router-dom';

const ButtonLink = ({ route, cName, value, onClick, disabled }) => {
  const isDisabled = typeof disabled === 'function' ? disabled() : disabled;

  const handleClick = () => {
    if (!isDisabled && onClick) {
      onClick();
    }
  };

  return (
    <Link
      to={route}
      className={`${cName} ${isDisabled ? 'disabled' : ''}`}
      onClick={handleClick}
    >
      {value}
    </Link>
  );
};

export default ButtonLink;
