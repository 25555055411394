import React from "react";
import ImgNoEncontrados from "./assets/imgNoEncontrados.png";
import IconoExito from "./assets/iconoError.svg";
import "./cardSolicitudAmplify.css";
import { useNavigate } from "react-router-dom";

const AmplifyCardNoEncontrados = ({automatico}) => {
  const navigate = useNavigate();
  return (
    <>
    <div className="noEncontradosBody">
    <div className="amplifySolicitudClones borde-radius-gris ">
        <div className="amplifySolicitudContenedorPrincipal">
        <div className="SolicitudClonesContenedorImg NoEncontrados">
          <img src={ImgNoEncontrados} alt="" />
        </div>

        <div className="SolicitudClonesContenedor NoEncontrados">
          <div className="SolicitudClonesContenedorTitle">
            <img src={IconoExito} alt="IconoExito" />
            <h2>¡Oops!</h2>
          </div>

          <p>
           
            ¡No te preocupes, estamos aquí para ayudarte! 
            Te recomendamos mejorar tu búsqueda redefiniendo cantidades y/o filtros aplicados.
          </p>

        </div>
        </div>
        
        <div className='Confirmacion_botones NoEncontrados'>
        <button onClick={()=>{if(automatico){navigate('/amplify/1')}else{navigate('/amplify/2')}}} className={'btnPrimary'} >Volver atrás</button>
        </div>
      </div>
    </div>
    </>
  );
};

export default AmplifyCardNoEncontrados;
