import React, { useState, useEffect } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import './LoaderLottie.css'
import LoaderJson from './animation_lo36mqf0.json';

const LoaderLottie = ({text}) => {
  const frases = ["Buscando perfiles similares", "Aplicando filtros","Enriqueciendo perfiles","Preparando la información"]
  const [indice, setIndice] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndice((indice + 1) % frases.length);
    }, 2500);
    return () => clearInterval(interval); // Esto limpia el intervalo cuando el componente se desmonta
  }, [indice]);

    return (
        <div className='loaderLottieContainer borde-radius-gris' >
            <div className="containerGift">
                <div className="loaderGift">
                  <Player
                    autoplay
                    loop
                    src={LoaderJson}
                    style={{ height: '206px', width: '206px' }}
                  >
                    <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                  </Player>
              </div>
            <div className='textGiftContainer'>
            <p className="textGift">Por favor, aguarda un momento mientras procesamos tu solicitud.</p>
            <p className="textGiftBuscando ">{frases[indice]}</p>
        </div>
            </div>
            <p className="textLoaderLottie" >{text}</p>
        </div>

    );
}

export default LoaderLottie;