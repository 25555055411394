import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import './ChartsGraficsClientCL.css'

const BarChart = ({ options, data }) => {


    return (
        <div className='ChartsGraphicsCL'>
         <div className='graphicNivelPorcentual'>
            <div className='ChartsGraphicsContainerCL'>
                <h2 className='ChartsGraphicsTitleCL'>Nivel porcentual de completitud en variables del cliente</h2>
                <p className='ChartsGraphicsText'>De un total de <strong>{" " + data.resumen_carga.enrichment_consulting} </strong>registros entregados por el cliente se consideran como válidos {" "}<strong>{data.resumen_carga.enrichment_valid}</strong>.
                    {" "}   Se logra un enrichment de  <strong> {data.resumen_carga.enrichment_find}</strong>,  {" "}equivalente a <strong>{data.resumen_carga.lvl_find.toFixed(1)}% </strong> de enriquecimiento.</p>
            </div>
           
                    <Chart options={options.options} series={options.series} type="bar" height={401} />
           
            </div>
        </div>
    );
};

export default BarChart;

