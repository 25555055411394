import React from 'react';
import Chart from 'react-apexcharts';
import './ChartsGraficsClient.css'
const BarChart = ({ options}) => {


    return (
        <div className='ChartsGraphics'>
            <div className='ChartsGraphicsContainer'>
                <h2 className='ChartsGraphicsTitle'>Nivel porcentual de completitud en variables del cliente</h2>
            </div>
            <Chart options={options.options} series={options.series} type="bar" height={350} />

        </div>
    );
};

export default BarChart;

