import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from "../../navbar/menuVertical/navbar";
import ProgressBar from "../BarraProgresivaAmplify/ProgressBar";
import AmplifyCardEncontrados from "../AmplifyContenedor/cardsSolicitudClones/cardEncontrados";
import AmplifyCardEncontradosWarning from "../AmplifyContenedor/cardsSolicitudClones/cardEncontradosWarning";
import AmplifyCardNoEncontrados from "../AmplifyContenedor/cardsSolicitudClones/cardNoEncontrados";
import "./amplifyEstilosGenerales.css";
import { AppContext } from "../../Context/Context";
import axiosInstance from '../../AxiosInterceptor/AxiosInterceptor';


const AmplifySolicitudClones = () => {
  const { quantity, errlog, automatico, country } = useContext(AppContext);
  const navigate = useNavigate()
  const { tkn, buyerClonTkn } = useContext(AppContext);
  const [log, setLog] = useState(0)
  const [obj, setObj] = useState({})
  const [navbarData, setNavbarData] = useState(false);

  useEffect(() => {
    if (automatico) {
      if (errlog || quantity.cantidad === 0 || quantity.cantidad < 5000) {
        setLog(3)
      } else if (quantity.cantidad >= quantity.solicitado) {
        setLog(1)
      } else if (quantity.cantidad < quantity.solicitado) {
        setLog(2)
      };
    } else {
      if (errlog || quantity.cantidad === 0) {
        setLog(3)
      } else {
        setLog(1)
      };
    }

  }, [quantity.cantidad, quantity.solicitado])

  const transformedData = {
    form: {}
  };
  let filterFunction = (data) => {
    data.forEach(perfil => {
      const key = `${perfil.comuna}${perfil.genero}${perfil.Perfil}`;
      transformedData.form[key] = perfil.resultados;
    })
    let obj = transformedData
    setObj(obj)
  }

  useEffect(() => {

    if (automatico) {
      axiosInstance.get(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/preview/${tkn}/${buyerClonTkn}`,
      )
        .then((e) => {
          let fliter = e.data.log2
          filterFunction(fliter)
        })
        .catch((e) => {
          if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
            localStorage.clear();
            navigate('/iniciar-sesion')
          } else  if ((e.responese && e.responese.status && e.response.status === 302)){
            navigate('/acceso-denegado')
          }
        })
    } else if (!automatico) {
      axiosInstance.get(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/invoice/${tkn}`,
      )
        .then((e) => {
          let fliter = e.data.log2
          filterFunction(fliter)
        }).catch((e) => {
          if ((e.responese && e.responese.status && e.response.status === 401) || (e.responese && e.responese.status && e.response.status === 422)) {
            localStorage.clear();
            navigate('/iniciar-sesion')
          }else  if ((e.responese && e.responese.status && e.response.status === 302)){
            navigate('/acceso-denegado')
          }
          
        })
    }
  }, [automatico])


  let objData = (data) => {
    axiosInstance.post(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/preview/${tkn}/${buyerClonTkn}`,
      { "form": data },
    )
      .then((e) => { navigate('/amplify-resumen') })
      .catch((e) => {
        if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
          localStorage.clear();
          navigate('/iniciar-sesion')
        }
      })
  }
  const estadoMenu = (data) => { setNavbarData(data); };

  let handleClick = () => {
    const totalAmount = quantity.cantidad > quantity.solicitado ? quantity.solicitado : quantity.cantidad;
    const originalTotal = Object.values(obj.form).reduce((acc, curr) => acc + curr, 0);
    const scaleFactor = totalAmount / originalTotal;
    const updatedDataScaled = {};

    for (const key in obj.form) {
      const scaledValue = Math.round(obj.form[key] * scaleFactor);
      updatedDataScaled[key] = Math.max(scaledValue, 0); // Evita valores negativos
    }


    const updatedTotal = Object.values(updatedDataScaled).reduce((acc, curr) => acc + curr, 0);

    if (updatedTotal !== totalAmount) {
      const difference = totalAmount - updatedTotal;
      const maxElement = Object.keys(updatedDataScaled).reduce((maxKey, key) => {
        return updatedDataScaled[key] > updatedDataScaled[maxKey] ? key : maxKey;
      });

      updatedDataScaled[maxElement] += difference; // Distribuye la diferencia al valor máximo
    }

    if (automatico) {
      objData(updatedDataScaled);
    }
  }

  return (
    <>
      <div className="containerAmplify">
        <Navbar estado={estadoMenu} />

        <div
          className={`amplify_body_container ${navbarData ? "navbar-open" : ""}`}
          style={{ marginLeft: navbarData ? "228px" : "80px" }}
        >
          <header className="headerAmplify">
            {/* Progress Bar */}
            <ProgressBar activeBar={2} />
          </header>

          <main className="mainAmplify cardEncontradosCenter">

            {log === 1 &&
              <AmplifyCardEncontrados clickLink={() => { if (automatico) { handleClick() } else { navigate('/amplify-resumen') } }} quantity={quantity} automatico={automatico} />}
            {log === 2 &&
              <AmplifyCardEncontradosWarning clickLink={handleClick} quantity={quantity} />}
            {log === 3 &&
              <AmplifyCardNoEncontrados automatico={automatico} />}

          </main>
        </div>

      </div>
    </>
  );
};

export default AmplifySolicitudClones;
