import{ React, useState} from "react";
import "./cardBuyerPersonaHome.css";
import Chart from 'react-apexcharts';
import DescubristeImg from "./estadoDatosAssets/analizaste.svg"
import EsteMesImg from "./estadoDatosAssets/esteMes.svg"
import DisponiblesImg from "./estadoDatosAssets/disponibles.svg"
import ArrowRight from "./estadoDatosAssets/arrowRight.svg"
import CloseIcon from "./estadoDatosAssets/x-mark.svg"
import ArrowModal from "./estadoDatosAssets/ArrowModal.svg"

const CardBuyerPersona = ({ data, card, complete }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const abrirModal = () => {
    setModalVisible(true);
  };

  const cerrarModal = () => {
    setModalVisible(false);
  };

  const options = {
    series: [card && card.percentBp ? (100 - card.percentBp).toFixed(0) : 0],
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        track: {},
        hollow: {
          size: '40%',
          margin: 0,
        },
        dataLabels: {
          name: {
            show: false,
            fontSize: '22px',
          },
          value: {
            offsetY: 4,
            show: true,
            color: "#475467",
            fontFamily: "Inter",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "0.22px",
          },
        },
      },
    },
    colors: ['#F5A973']
  };

  function separadorMiles(numero) {
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }




  return (

    <>

     <div className="mainCardBuyerPersona">
     {(card && card.consumidoBp && card.planBp) &&
        <div className="cardBuyerPersona ">
    
          <div className="descubristeContainer" >
            <div className="descubristeContainerIcon">
              <img src={DescubristeImg} alt="" />
              <p>Analizaste</p>
            </div>
            <div className="descubristeContainerValue">
              <p className="descubristeValue">{separadorMiles(Number(complete))}</p>
              <p className="descubristeClientesPotenciales" >clientes</p>
            </div>
          </div>

          <div className="verticalDivider"></div>
          <div className="esteMesContainer">
            <div className="esteMesContainerIcon">
              <img src={EsteMesImg} alt="" />
              <p>Este mes</p>
            </div>
            <div className="esteMesContainerValue">
              <p className="esteMesValue">{separadorMiles(Number(data))}</p>
              <p className="esteMesClientesPotenciales" >clientes</p>
            </div>
          </div>
          <div className="verticalDivider"></div>


          <div className="disponiblesContainer">
            <div className="disponiblesContainerContent">
              <div className="disponiblesContainerIcon">
                <img src={DisponiblesImg} alt="" />
                <p >Disponibles</p>
              </div>
              <div className="disponiblesContainerSubContent">
                <div className="disponiblesCantClientes">
                  <p className="disponiblesValue">{card.enrichment_balance}</p> <p className="disponiblesClientesPotenciales">clientes</p>
                </div>
                <div className="disponiblesMasCreditosContainer">
                  <button className="disponiblesMasCreditos" onClick={abrirModal}> ¿Necesitas más créditos?</button>
                  <img src={ArrowRight} alt="" />
                </div>
              </div>

            </div>
            <div className="disponiblesChart">
              <Chart
                options={options}
                series={options.series}
                type="radialBar"
                width={130}

              />
            </div>
          </div>


        </div>}
      
     </div>
        {modalVisible && (
          <div className="modalMasCreditosTotal">
            <div className="modalMasCreditosArrow">
              <img src={ArrowModal} alt="" />
            </div>
            <div className="modalMasCreditos">
              <div className="modalMasCreditosConteiner">
                <div className="modalMasCreditosContent">
                  <p className="modalMascreditosTitle">¿Necesitas más créditos?</p>
                  <p className="modalMasCreditosText">
  Envíanos un correo a{' '}
  <button
    className="modalMasCreditosTextEnlace"
    onClick={() => (window.open('mailto:adelahoz@xbrein.com', '_blank'))}
  >
    adelahoz@xbrein.com
  </button>{' '}
  y te asistiremos en la adquisición de créditos
</p>
                </div>
                <div>
                  <img className="closeModal" src={CloseIcon} alt="" onClick={cerrarModal} />
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default CardBuyerPersona;
