import React, { useEffect, useState } from 'react';
import ChevronDerecha from './assets/chevron_derecha.svg'
import ChevronIzquierda from './assets/chevron_izquierda.svg'
import ChevronInicio from './assets/principio.svg'
import ChevronFinal from './assets/final.svg'
import ChevronInicioDisabled from './assets/chevronInicio_gris.svg'
import ChevronIzquierdaGris from "./assets/chevron_izquierdagris.svg"
import ChevronDerechaGris from "./assets/chevron_derechaGris.svg"
import ChevronFinalGris from "./assets/chevron_final_gris.svg"
import './Pagination.css'

const Pagination = ({ List,tab, currentPageProp, rowsPerPageProp, detalle }) => {
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {  if(List.length > 0 && detalle !== true && tab !== 3){(setCurrentPage(1))}}, [List])
  useEffect(() => { currentPageProp(currentPage) }, [currentPage])
  
  const goToFirstPage = () => {
    setCurrentPage(1);
  }
  const goToPage = (number) => {
    setCurrentPage(Number(number))
  }
  const goToPrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1))
  }
  const goToNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, Math.ceil(List.length / rowsPerPageProp)));
  }
  const goToLastPage = () => {
    setCurrentPage(Math.ceil(List.length / rowsPerPageProp));
  }

  return (
    <>
   { List.length > 0 &&
    <div className="amplifyTablaButtons">
      <span className={`page-link first-page ${currentPage === 1 ? 'button-disabled' : ''}`} onClick={() => { goToFirstPage() }} ><img src={currentPage === 1 ? ChevronInicioDisabled : ChevronInicio}/></span>
      <span className={`page-link prev-page ${currentPage === 1 ? 'button-disabled' : ''}`} onClick={() => { goToPrevPage() }} ><img src={currentPage === 1 ? ChevronIzquierdaGris : ChevronIzquierda}/></span>
        {(currentPage - 1) === 0 && <span className={`page-link ${(currentPage - 1) === 0 ? 'buttonActiveOrange' : 'button-disabled'}`} onClick={() => { goToFirstPage() }}>1</span>}
        {(((currentPage - 1) !== 0) && currentPage !== Math.ceil(List.length / rowsPerPageProp)) && <span className={`page-link next-page `} onClick={() => { goToPage(`${currentPage - 1}`) }}>{currentPage - 1}</span>}
        {Math.ceil(List.length / rowsPerPageProp) > 2 && (currentPage - 1 === 0 && currentPage !== Math.ceil(List.length / rowsPerPageProp)) && <span className={`page-link next-page`} onClick={() => { goToPage(2) }}>2</span>}
        {Math.ceil(List.length / rowsPerPageProp) > 2 && (currentPage - 1 !== 0 && currentPage === Math.ceil(List.length / rowsPerPageProp)) && <span className={`page-link next-page`} onClick={() => { goToPage(currentPage - 2) }}>{currentPage - 2} </span>}
        {Math.ceil(List.length / rowsPerPageProp) > 2 && (currentPage - 1) !== 0 && currentPage !== Math.ceil(List.length / rowsPerPageProp) && <span className={`page-link next-page ${(currentPage - 1) === 0 || currentPage === Math.ceil(List.length / rowsPerPageProp) ? '' : 'buttonActiveOrange'}`}  >{currentPage} </span>}
        {(currentPage !== 1 && currentPage === Math.ceil(List.length / rowsPerPageProp)) && <span className={`page-link next-page ${(currentPage - 1) === 0 || currentPage === Math.ceil(List.length / rowsPerPageProp) ? '' : 'buttonActiveOrange'}`} onClick={() => { goToPage(currentPage - 1) }} >{currentPage - 1} </span>}
        {Math.ceil(List.length / rowsPerPageProp) > 3 && <span className=" page-link ellipsis">...</span>}
        {Math.ceil(List.length / rowsPerPageProp) > 1 && <span className={`page-link next-page ${currentPage === Math.ceil(List.length / rowsPerPageProp) ? 'buttonActiveOrange' : ''}`} onClick={() => { goToLastPage() }}>{Math.ceil(List.length / rowsPerPageProp)} </span>}
      <span className={`page-link next-page ${((currentPage === Math.ceil(List.length / rowsPerPageProp )) || (0 === Math.ceil(List.length / rowsPerPageProp) )) ? 'button-disabled' : ''}`} onClick={() => { if(((currentPage === Math.ceil(List.length / rowsPerPageProp )) || (0 === Math.ceil(List.length / rowsPerPageProp) ))){}else{goToNextPage()} }}><img src={((currentPage === Math.ceil(List.length / rowsPerPageProp))|| (0 ===  Math.ceil(List.length / rowsPerPageProp)) ) ? ChevronDerechaGris : ChevronDerecha} /></span>
      <span className={`page-link last-page ${((currentPage === Math.ceil(List.length / rowsPerPageProp ) )|| ( 0 ===  Math.ceil(List.length / rowsPerPageProp))) ? 'button-disabled' : ''}`} onClick={() => { if(((currentPage === Math.ceil(List.length / rowsPerPageProp )) || (0 === Math.ceil(List.length / rowsPerPageProp) ))){}else{goToLastPage() }}}><img src={((currentPage === Math.ceil(List.length / rowsPerPageProp) )|| (0 ===  Math.ceil(List.length / rowsPerPageProp)) )? ChevronFinalGris : ChevronFinal} /></span>
    </div>}
    </>
  );
}

export default Pagination;