import React from "react";
import separadorDeMiles from "../../../SeparadorDeMiles/SeparadorDeMiles";
import ImgEncontradosWarn from "./assets/imgEncontradosWarning.png";
import IconoWarning from "./assets/iconosWarning.svg";
import "./cardSolicitudAmplify.css";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../../Amplify/AmplifyContenedor/cardsTabla/progresBarCardResumen/progressBarCardResumen";

const AmplifyCardEncontradosWarning = ({ quantity, clickLink }) => {
  const navigate = useNavigate();
  function formatNumberWithCommas(number) {
    if (number === undefined || isNaN(number)) {
      return ""; // O un valor predeterminado si number no es válido
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <>
      <div className="amplifySolicitudClones borde-radius-gris">
        <div className="amplifySolicitudContenido">
          <div className="SolicitudClonesContenedorImg Encontrados">
            <img src={ImgEncontradosWarn} alt="" />
          </div>
          <div className="solicitudClonesContenedorInterno">
            <div className="SolicitudClonesContenedor">
              <div className="SolicitudClonesContenedorTitle">
                <img src={IconoWarning} alt="IconoExito" />
                <h2>No alcanzamos la cantidad solicitada, pero...</h2>
              </div>
              <p>No alcanzamos la cantidad que solicitaste,
                pero si deseas puedes amplificar las  {separadorDeMiles(quantity.cantidad)}  personas que encontramos.</p>
            </div>
            <div >
              <div className="SolicitudClonesCardDatosContenedor">
                <div>
                  <ProgressBar cardResumen={Number(quantity.solicitado)} ultimaCard={true} />
                </div>
                <div className="solicitudClonesContenedorDatos">

                  <div className="SolicitudClonesCardDatos">
                    <div className="SolicitudClonesDatosText">
                      <p>Buscados</p>
                    </div>
                    <p>{formatNumberWithCommas(quantity.solicitado)} </p>
                  </div>

                  <div className="SolicitudClonesCardDatos">
                    <div className="SolicitudClonesDatosText">
                      <p>Encontrados</p>
                    </div>
                    <p> {formatNumberWithCommas(quantity.cantidad)}</p>
                  </div>
                  <div className="SolicitudClonesCardDatos">
                    <div className="SolicitudClonesDatosText">
                      <p>Solicitados</p>
                    </div>
                    <p>{formatNumberWithCommas(quantity.solicitado)} </p>
                  </div>
                </div>

              </div>

              <div
                className="SolicitudClonesCardDetalle"
                onClick={() => {
                  navigate("/amplify-detalle");
                }}
              >
                <p>Ver detalle</p>
              </div>

            </div>
          </div>
        </div>

        <div className='Confirmacion_botones'>
          <button onClick={() => { navigate('/amplify/1') }} className={'btnSecondary'} >Atrás</button>
          <button className='btnPrimary' onClick={clickLink}>Amplificar</button>
        </div>
      </div>
    </>
  );
};

export default AmplifyCardEncontradosWarning;
