import React, { createContext, useContext, useState } from "react";

const TablaContext = createContext();

export const TablaProvider = ({ children }) => {
  const [solicitadosData, setSolicitadosData] = useState({});
  const [totalSolicitados, setTotalSolicitados] = useState(0);

  return (
    <TablaContext.Provider value={{ solicitadosData, setSolicitadosData, totalSolicitados, setTotalSolicitados }}>
      {children}
    </TablaContext.Provider>
  );
};

export const useTablaContext = () => useContext(TablaContext);
