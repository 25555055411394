import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../Context/Context";
import ImgPerfil1 from "./assets/rectangle-violet.svg";
import ImgPerfil2 from "./assets/rectangle-red.svg";
import ImgPerfil3 from "./assets/rectangle-blue.svg";
import ImgPerfil4 from "./assets/rectangle-green.svg";
import ImgPerfil5 from "./assets/rectangle-pink.svg";
import ProgressBarCardResumen from "./progresBarCardResumen/progressBarCardResumen";
import separadorDeMiles from "../../../SeparadorDeMiles/SeparadorDeMiles";
import './cardResumenTabla.css'

const CardResumenTabla = ({ dataAmplify }) => {
  const { setButtonLog, perfiles, totalBusqueda } = useContext(AppContext);
  const [totales, setTotales] = useState([])
  const [sumaFinal, setSumaFinal] = useState([])
  const [BusqTotal, setBusqTotal] = useState([])
  const [cambio, setCambio] = useState([])
  const [cambioTotal, setCambiototal] = useState([])

  useEffect(() => {
    if (cambioTotal > 4999) {
      setButtonLog(true)
    } else {
      setButtonLog(false)
    }
  }, [cambioTotal])

  useEffect(() => {
    setCambio(totalBusqueda)
    let suma = 0;
    for (let i = 0; i < totalBusqueda.length; i++) {
      suma += totalBusqueda[i];
    }
    setCambiototal(suma)
  }, [totalBusqueda])
  // Imagenes por perfil
  const obtenerImagenPerfil = (index) => {
    const imagenesPerfiles = [ImgPerfil1, ImgPerfil2, ImgPerfil3, ImgPerfil4, ImgPerfil5];
    return imagenesPerfiles[index] || ImgPerfil1;
  };

  // Totales
  useEffect(() => {
    const sums = [];
    if (dataAmplify.length > 0) {
      const profileSums = {};

      dataAmplify.forEach(obj => {
        const perfil = obj.Perfil;
        const resultados = obj.resultados;

        if (!profileSums[perfil]) {
          profileSums[perfil] = 0;
        }

        profileSums[perfil] += resultados;
      });

      Object.values(profileSums).forEach(sum => {
        sums.push(sum);
      });
      setTotales(sums);
    }
    let suma = 0;
    for (let i = 0; i < sums.length; i++) {
      suma += sums[i];
    }
    setSumaFinal(suma)
  }, [dataAmplify]);

  useEffect(() => {
    let sumalista = 0
    for (let i = 0; i < perfiles.length; i++) {
      sumalista += Number(perfiles[i]);
      setBusqTotal(sumalista)
    }
  }, [perfiles])

  let indice = -1;
  const sumaIndice = () => {
    indice += 1; // Incrementa el índice
  };

  return (
    <div className="amplifyCardContainerResumen">
      <h2>Resumen</h2>
      <div className="amplifyCardResumenTabla">
        <div className="amplifyCardResumenContainer">
          <h3>Perfiles</h3>
          <div className="resumenBP">
            {perfiles.length > 0 && perfiles.map((perfil, index) => {
              if (perfil != 0) {
                sumaIndice()

                return (
                  <div key={index} className="resumenPerfiles">
                    <div className="titleResumenBP">
                      <img src={obtenerImagenPerfil(index)} alt={`Buyer Persona ${index + 1}`} />
                      <p>{`Buyer Persona ${index + 1}`}</p>
                    </div>
                    <div className="resumenBPCardPBDatos">
                      <div className="resumenBPCardProgressBar">
                        <ProgressBarCardResumen ultimaCard={false} />
                      </div>
                      <div className="resumenBPCardDatosContainer">
                        <div className="resumenBPCardDatos">
                          <div className="resumenBPDatosText">
                            <p>Buscados</p>
                          </div>
                          <p> {separadorDeMiles(perfil)}</p>
                        </div>
                        <div className="resumenBPCardDatos">
                          <div className="resumenBPDatosText">
                            <p>Encontrados</p>
                          </div>
                          <p>{separadorDeMiles(totales[indice])}</p>
                        </div>
                        <div className="resumenBPCardDatos">
                          <div className="resumenBPDatosText ">
                            <p className="resumenDatosTextSolicitados">Solicitados</p>
                          </div>
                          {cambio.length > 0 &&
                            <p className="resumenBPDatosText solicitados">{separadorDeMiles(cambio[indice])}</p>
                          }
                        </div>
                      </div>
                    </div>
                    {(index < (perfiles.length - perfiles.filter(value => value === "").length - 1)) && <div className="dividerResumenTabla"></div>}
                  </div>
                )
              }
              ;
            })}
          </div>
        </div>

        <div className="resumenGeneralContenedor">
          <h3>Total</h3>
          <div className={`resumenGeneralContenidoContainer ${cambioTotal > 4999 ? 'borderGreen' : 'borderOrange'}`}>
            <div className="resumenGeneral">
              <div className="resumenBPCardProgressBar">
                <ProgressBarCardResumen cardResumen={cambioTotal} ultimaCard={true} />
              </div>
              <div className="resumenGeneralDatosContainer">
                <div className="resumenGeneralDatos">
                  <div className="resumenBPDatosTextTotal">
                    <p>Buscados</p>
                  </div>
                  <p className="resumenBPDatosText buscados2">{separadorDeMiles(BusqTotal)}</p>
                </div>
                <div className="resumenGeneralDatos">
                  <div className="resumenBPDatosTextTotal">
                    <p>Encontrados</p>
                  </div>
                  <p className="resumenBPDatosText encontrados2">{separadorDeMiles(sumaFinal)}</p>
                </div>
                <div className="resumenGeneralDatos solicitadosFondo">
                  <div className="resumenBPDatosTextTotal">
                    <p className="resumenDatosTextSolicitados solicitadosBlack">Solicitados</p>
                  </div>
                  <p className={cambioTotal > 4999 ? 'resumenBPDatosText resumenDatosTextSolicitados' : "busquedaOrange"} >{separadorDeMiles(cambioTotal)}</p>
                </div>
              </div>
            </div>
            {cambioTotal < 5000 && <p className="redLegendSolicitados ">Cantidad mínima a solicitar: 5.000</p>}
          </div>

        </div>
      </div>
    </div>
  );
};

export default CardResumenTabla;