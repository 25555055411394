import React, { useEffect, useState, useContext } from 'react';
import './PopUpBP.css';
import { AppContext } from '../../Context/Context';
import { useNavigate } from 'react-router-dom';
import IconListo from './assets/spinnerListo.svg';
import IconSpinner from './assets/spinner.svg';
import IconEliminar from './assets/eliminar.svg';
import IconVisualizar from './assets/visualizar.svg';
import Descargar from "./assets/descargar.svg";
import axiosInstance from '../../AxiosInterceptor/AxiosInterceptor';

const PopUpBP = ({ position, onClose, dataArchivo, onDeleteClick, amplify }) => {
  const navigate = useNavigate();
  const { country } = useContext(AppContext);
  const [error, setError] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [descargaHabilitada, setDescargaHabilitada] = useState('ready');
  const countrySelected = localStorage.getItem('selectedItems');



  const handleImageLoad = () => {
    setImagesLoaded(true);
  };

  const handleData = (id) => {
    if (descargaHabilitada !== "ready" && descargaHabilitada !== 'complete') {

      return; // Evitar múltiples clics si la descarga ya está en proceso
    }
    let link = '';
    if (!amplify) {
      link = 'clones/list';
    } else {
      link = 'enrichment/download';
    }
    setDescargaHabilitada('loading')
    axiosInstance.get(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/${link}/${dataArchivo.token}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let linkName = '';

        if (dataArchivo.name) {
          linkName = dataArchivo.name;
        } else {
          linkName = dataArchivo.nombre_descarga;
        }
        if(response.data.type === "text/csv"){
          link.setAttribute('download', `${linkName}.csv`);
        }else{
          link.setAttribute('download', `${linkName}.xlsx`);
        }
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDescargaHabilitada("complete");
      })
      .catch((error) => {
        setDescargaHabilitada("complete");
        if (error.response.status === 401 || error.response.status === 422) {
          localStorage.clear();
          navigate('/iniciar-sesion');
        }

      });
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      const popUpBP = document.querySelector('.popUpBP');
      if (popUpBP && !popUpBP.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onClose]);

  
  if (!imagesLoaded) {
    return (
      <div>
        <img src={IconListo} onLoad={handleImageLoad} alt="IconListo" style={{ display: 'none' }} />
        <img src={IconSpinner} onLoad={handleImageLoad} alt="IconSpinner" style={{ display: 'none' }} />
        <img src={IconEliminar} onLoad={handleImageLoad} alt="IconEliminar" style={{ display: 'none' }} />
        <img src={IconVisualizar} onLoad={handleImageLoad} alt="IconVisualizar" style={{ display: 'none' }} />
        <img src={Descargar} onLoad={handleImageLoad} alt="Descargar" style={{ display: 'none' }} />
      </div>
    );
  }

  return (
    <div className="popUpBP" style={position}>
      {amplify && (
        <div className="popUpBPContent" onClick={() => navigate(`/tablero-${countrySelected === "43" ? 1 : 2}/${dataArchivo.token}`)}>
          <img src={IconVisualizar} alt="IconVisualizar" className="PopUpVisualizar" onLoad={handleImageLoad} />
          <span>Visualizar</span>
        </div>
      )}
      { ((amplify && localStorage.getItem('privilege') === 'true' ) || (!amplify)) &&
      <>
      { descargaHabilitada === 'ready'  ? (
        <div className="popUpBPContent" onClick={() => handleData()}>

          <img src={Descargar}
            alt="IconDescargar"
            className="PopUpDescargar"
            onLoad={handleImageLoad}
          />
          <span>Descargar</span>
        </div>
      ) : descargaHabilitada === 'loading' ? (
        <div className="popUpBPContentDisabled">

          <img
            src={IconSpinner}
            alt="IconDescargar"
            className="rotatingSVG"
            onLoad={handleImageLoad}
          />


          <span className='popUpBPContentDesactivado'>Descargar</span>
        </div>
      ) : (
        <div className="popUpBPContent" onClick={() => handleData()}>
          <img
            src={IconListo}
            alt="IconDescargar"
            className="PopUpDescargar"
            onLoad={handleImageLoad}
          />
          <span>Descargar</span>
        </div>
      )}
</>}

      <div className="popUpBPContent" onClick={() => { onDeleteClick(dataArchivo) }}>
        <img src={IconEliminar} alt="IconEliminar" className='IconEliminar' />
        <span>Eliminar</span>
      </div>

    </div>
  );
};

export default PopUpBP;
