import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

export function ProtectedRoute({ children }) {

    if (!localStorage.getItem('token') || !localStorage.getItem('cty')||!localStorage.getItem('selectedItems')  ||!localStorage.getItem('selectedCountry') || !localStorage.getItem('companies') || !localStorage.getItem('companyFilter') 
    ) {
        return <Navigate to="/iniciar-sesion" />;
    }

    return <>{children}</>;
}
