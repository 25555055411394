import React, { useEffect, useContext, useState } from "react";
import imgBienHecho from './assets/bienHecho.svg';
import './cardDescarga.css';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../Context/Context";
import axiosInstance from "../../../AxiosInterceptor/AxiosInterceptor";

const CardDescarga = () => {
  const { tkn, country } = useContext(AppContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(false)
  const [countdown, setCountdown] = useState(10); // Inicializar el contador en 10 segundos

  useEffect(() => {
    confirm()
  }, [country, tkn]);



  useEffect(() => {
    let timerId;
    if (countdown > 0) {
      // Reducir el contador utilizando el temporizador del sistema
      timerId = setTimeout(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    } else {
      navigate('/inicio/buyer-persona');
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [countdown, navigate]);




  const confirm = async () => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/invoice/${tkn}`,

        {
          "confirm": "y",
          "descargar": ""
        },
       );

      if (response && response.response && response.response.status && response.response.status === 302) {
        navigate('/no-credits');
      } else {
        setShow(true)
      }
    } catch (error) {
      setShow(true)
      if (error.response.status === 302) {
        navigate('/no-credits');
      } else if (error.response.status === 401 || error.response.status === 422) {
        localStorage.clear();
        navigate('/iniciar-sesion');
      }
      else if (error.response.status === 500) {
        navigate('/en-mantenimiento')
      } else {
        setShow(true)
      };
    }
  }

  return (
    <>
      {show &&
        <div className="amplifyCardDescarga">

          <>
            <img src={imgBienHecho} alt="imagen-de-festejo" />
            <div className="amplifyCardDescargaTextos">
              <h2>¡Bien hecho!</h2>
              <p>Tu solicitud está en proceso. <br />
                Desde la pantalla principal podrás ver el progreso
                de tu solicitud, y generar nuevos buyer persona para amplificar.
              </p>
            </div>
            <p>Te dirigiremos a la pantalla de inicio en <strong className="strong">{countdown}</strong> segundos...</p>
          </>
        </div>}
    </>
  );
};

export default CardDescarga;
