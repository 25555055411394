import React from "react";
import "./inputs.css";

const Inputs = ({props, handleChange}) => {
  return (
    <>
      <input type="text" className="inputGeneral borde-inputs-generales" placeholder={props.placeholder} onChange={handleChange}/>
    </>
  );
};

export default Inputs;
