import React from 'react';
import Chart from 'react-apexcharts';
import './Bar.css'
const Bar = ({ options, height, classComponent, width }) => {
    return (
        <>
            <Chart  options={options.options} series={options.series} type="bar" height={height} width={width ? width : "100%"} className={classComponent ? classComponent : ""}/>
        </>
    );
};

export default Bar;
