import React from 'react';
import Card from './connectionErrorCard/connectionErrorCard'
import './connectionError.css'
const Loader = () => {
  return (
    <>
      <div className='body' >
        <Card />
      </div>
    </>
  );
};

export default Loader;
