import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const DoughnutChart = ({ chartData, height, width,last }) => {


    return (
        <div className="doughnut-chart">
        {/* { !isLoading &&  */}
            <Chart options={chartData.options} series={chartData.series} className='.apexcharts-data-labels' type="donut" height={height ? height  : "350"} width={width ? width  : "350"} style={last && {color:'white'}}/>
        {/* } */}
        </div>
        
        
    );
};

export default DoughnutChart;
