import React from 'react';
import isoPositivoIMG from '../navbarAssets/brand.svg'
import "./positivo.css"
const Positivo = () => {
    return (
        <>
            <img className='positivo' src={isoPositivoIMG} alt="imagen iso positvo t brein" />
        </>
    );
}
export default Positivo;
