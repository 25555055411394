import React, { useState, useEffect } from 'react';
import './SliderBarGenero.css';
import InputSlider from 'react-input-slider';

const SliderBar = ({ gender }) => {
  const [masculino, setMasculino] = useState(50);
  const [femenino, setFemenino] = useState(50);
  const [isMasculinoCleared, setIsMasculinoCleared] = useState(false);
  const [isFemeninoCleared, setIsFemeninoCleared] = useState(false);

  const sliderStyles = {
    track: {
      backgroundColor: '#FFEFCF', // Lado derecho
    },
    active: {
      backgroundColor: '#F5A973', // Barra y bolita
    },
    thumb: {
      backgroundColor: '#F5A973', // Barra y bolita
    }
  };

const handleMasculinoChange = (newValue, isFromInput) => {
  if (isFromInput) {
    setIsMasculinoCleared(newValue === '');
  }

  const newMasculinoValue = newValue === '' ? '' : parseInt(newValue, 10);
  if (newValue === '' || (!isNaN(newMasculinoValue) && newMasculinoValue <= 100)) {
    setMasculino(newValue === '' ? 0 : newMasculinoValue);
    setFemenino(100 - (newValue === '' ? 0 : newMasculinoValue));
  }
};

const handleFemeninoChange = (newValue, isFromInput) => {
  if (isFromInput) {
    setIsFemeninoCleared(newValue === '');
  }

  const newFemeninoValue = newValue === '' ? '' : parseInt(newValue, 10);
  if (newValue === '' || (!isNaN(newFemeninoValue) && newFemeninoValue <= 100)) {
    setFemenino(newValue === '' ? 0 : newFemeninoValue);
    setMasculino(100 - (newValue === '' ? 0 : newFemeninoValue));
  }
};


  useEffect(() => {
    gender({ masculino: `${masculino}%`, femenino: `${femenino}%` });
  }, [masculino, femenino]);

   return (
    <div className='sliderBarGenero'>
      <div className='sliderInput '>
        <input
          type="text"
          className='borde-inputs-generales'
          value={femenino === 0 ? '0' : femenino === '' ? '' : femenino}
          onChange={(e) => handleFemeninoChange(e.target.value, true)}
          onBlur={() => {
            if (femenino < 0) setFemenino(0);
            if (femenino > 100) setFemenino(100);
          }}
        />
        <label>Femenino</label>
      </div>

      <div>
        <InputSlider
          className='sliderBar'
          axis="x"
          x={masculino}
          xmin={0}
          xmax={100}
          onChange={({ x }) => handleMasculinoChange(x, false)}
          styles={sliderStyles}
        />
      </div>

      <div className='sliderInput'>
        <input
          type="text"
          className='borde-inputs-generales'
          value={masculino === 0 ? '0' : masculino === '' ? '' : masculino}
          onChange={(e) => handleMasculinoChange(e.target.value, true)}
          onBlur={() => {
            if (masculino < 0) setMasculino(0);
            if (masculino > 100) setMasculino(100);
          }}
        />
        <label>Masculino</label>
        
      </div>
    </div>
  );
};

export default SliderBar;
