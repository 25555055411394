import React, { useState } from "react";
import './Tooltip.css';

export const Tooltip = ({ text, children, className }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div
      className={`tooltip-container tooltip-parent ${className}`}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && <div className="tooltip">{text}</div>}
    </div>
  );
};
