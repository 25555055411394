import React from 'react';
import Card from './inMaintenanceCard/inMaintenanceCard'
import './inMaintenance.css'


const InMaintenance = () => {
  return (
    <>
      <div className='body' >
        <Card />
      </div>
    </>
  );
};

export default InMaintenance;
