import React from 'react';
import './SpinnerGris.css';

const SpinnerProcesando = () => {
  return (
    <div className="spinner_containerGris">
      <div className="spinner__circleGris">
        <div className="spinner__circle-gradientGris"></div>
        <div className="spinner__circle-innerGris"></div>
      </div>
    </div>
  )
};

export default SpinnerProcesando;
