import React, { useState, useContext, useEffect } from "react";
import Navbar from '../../navbar/menuVertical/navbar';
import ProgressBar from "../BarraProgresivaAmplify/ProgressBar";
import './AmplifyResumen.css'
import Resumen from './img/Advantages-amico 1.svg'
import Ticket from './img/ticket.png'
import Building from './img/building.png'
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/Context";
import separadorDeMiles from "../../SeparadorDeMiles/SeparadorDeMiles";
import axiosInterceptor from '../../AxiosInterceptor/AxiosInterceptor'
const BuyerPersonContainer = () => {
  const [navbarData, setNavbarData] = useState(false);
  const [disponibles, setDisponibles] = useState(0)
  const [procesar, setProcesar] = useState(0)

  const { tkn } = useContext(AppContext)

  const navigate = useNavigate()
  const estadoMenu = (data) => {
    setNavbarData(data);
  };

  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = async () => {
    try {
      let response = await axiosInterceptor.get(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/invoice/${tkn}`, {
      });
      setDisponibles(parseInt(response.data.datos_invoice.restante.replace(/,/g, ''), 10))
      setProcesar(response.data.datos_invoice.solicitados)
    } catch (error) {
      if (error.response.status === 302) {
        navigate('/no-credits');
      } else if (error.response.status === 401 || error.response.status === 422) {
        localStorage.clear();
        navigate('/iniciar-sesion')
      }
    }
  }
  
  return (
    <>
      <div className="containerAmplifyResumen">
        <Navbar estado={estadoMenu} />

        <div
          className={`amplifyResumen_body_container ${navbarData ? 'navbar-open' : ''}`}
          style={{ marginLeft: navbarData ? "228px" : "80px" }}
        >
          <div className="amplifyResumen_data_container">

            <header className="headerAmplifyResumen">
              {/* Progress Bar */}
              <ProgressBar activeBar={2} />
            </header>

            <main className="mainAmplifyResumen">
              <div className="mainAmplifyResumenImgContainer">
                <img src={Resumen} alt="imagen ilustrativa de resumen" />
              </div>
              <div>
                <h2 className="TitleAmplifyResumenDatos">Resumen de tu solicitud</h2>
                <div className="AmplifyResumenDatosCardContainer">
                  <div className="AmplifyResumenDatosCard">
                    <div className="imgContainerAmplifyResumen">
                      <img src={Ticket} alt="ticket" />
                    </div>
                    <p>{localStorage.getItem('name')}</p>

                  </div>
                  <div className="AmplifyResumenDatosCard">
                    <div className="imgContainerAmplifyResumen">

                      <img src={Building} alt="ticket" />
                    </div>
                    <p>{localStorage.getItem('companyFilter')}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="lineAmplifyResumenDatosContainer"></div>
                <div className="AmplifyResumenDatosContainer">
                  <div className="AmplifyResumenDatosContainerDatosDinamicos">
                    <h2>Disponibles</h2>
                    <p>{separadorDeMiles(disponibles + procesar)}</p>
                  </div>
                  <p className="signosAmplifyResumenDatosContainer">-</p>

                  <div className="AmplifyResumenDatosContainerDatosDinamicos">
                    <h2>A procesar</h2>
                    <p>{separadorDeMiles(procesar)}</p>
                  </div>
                  <p className="signosAmplifyResumenDatosContainer">=</p>

                  <div className="AmplifyResumenDatosContainerDatosDinamicos">
                    <h2>Te quedarán</h2>
                    <p>{separadorDeMiles(disponibles )}</p>
                  </div>
                </div>
              </div>
              <div className="AmplifyResumenButtonsCard">
              <button className="AmplifyResumenButtonCancelar" onClick={() => navigate('/amplify-solicitud-clones')}>Cancelar</button>
                <button className="AmplifyResumenButtonGenerar" onClick={() => navigate('/amplify-descarga')}>Generar</button>
              </div>

            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerPersonContainer;
