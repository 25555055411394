import React, { useState } from 'react';
import NoCreditsImg from '../../Amplify/AmplifyContenedor/modales/assets/NoCredits.svg'
import { useNavigate } from 'react-router-dom';
import Warning from '../../Amplify/AmplifyContenedor/modales/assets/iconoAtencion.svg'
import '../../Amplify/AmplifyContenedor/modales/NoCredits/NoCredits'
import Navbar from '../../navbar/menuVertical/navbar'

const NoCredits = () => {
  const [navbarData, setNavbarData] = useState(false);
  const navigate = useNavigate();

  const estadoMenu = (data) => {
    setNavbarData(data);
  };

  return (
    <>
      <div className="containerAmplify ">
        <Navbar estado={estadoMenu} />

        <div
          className={`amplify_body_container ${navbarData ? "navbar-open" : ""}`}
          style={{ marginLeft: navbarData ? "228px" : "80px" }}
        >
          <main className="mainAmplify">
            {/* Titulo */}


            <div className='amplifyModalTablaNoCreditsGeneral borde-radius-gris'>
              <div className='amplifyModalContentImg'>
                <div className="imgModalExitoNoCredits">
                  <img src={NoCreditsImg} alt="exito" />
                </div>
                <div className='amplifyModalTablaNoCreditsContainer'>
                  <div className='amplifyModalTablaNoCredits'>
                    <img src={Warning} alt="Imagen de advertencia" />
                    <h2>¡Vaya! No dispones de permisos</h2>
                  </div>
                  <p className='modalNoCreditsText'>Lo sentimos, no tienes los permisos necesarios para llevar a cabo esta acción. Por favor, contacta con nuestro equipo de soporte para finalizar este proceso.</p>
                </div>
              </div>
              <div className='ConfirmacionBotonesNoCreditsContainer'>
                <button className={'ConfirmacionBotonesNoCredits'} onClick={() => { navigate('/inicio/amplify') }}>Volver atrás</button>
              </div>

            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default NoCredits;
