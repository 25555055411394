import React from "react";
import "./mensajeDeExito.css";
import Exito from "./exito.png"

const MensajeExito = () => {
  return (
    <>
     <div className="popUpMensajeExito">
     <img src={Exito} alt="" />
<div className="popUpMensajeExitoContenido">

<p className="popUpMensajeExitoContenidoTitulo">Exito</p>
<p className="popUpMensajeExitoContenidoTexto">Tus datos fueron procesados correctamente </p>
<div className="horizontal-bar"></div>
</div>

     </div>
    </>
  );
};

export default MensajeExito;
