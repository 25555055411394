import React, { useState } from "react";
import "./PopUpNewCompany.css";
import Select from "react-select";
import axiosInstance from "../../AxiosInterceptor/AxiosInterceptor";
import BanderaChile from "../../navbar/menuVertical/boxNavbar/boxNavbarAssets/banderaChile.png";
import BanderaMexico from "../../navbar/menuVertical/boxNavbar/boxNavbarAssets/banderaMexico.png";
const PopUpNewAdmin = ({ selectstyles, handleClick }) => {
    const [name, setName] = useState('');
    const [countrySelected, setCountrySelected] = useState(43);
    const [controlError, setControlError] = useState(undefined);

    const list = [{ value: 43, label: 'Chile' }, { value: 135, label: 'Mexico' }];

    const createCompany = () => {
        let postData;
        if (countrySelected === 43) {
            postData = {
                "country_id": countrySelected,
                "name": name,
                "rut": "0",
                "dv": 0
            };
        } else {
            postData = {
                "country_id": countrySelected,
                "name": name,
                "dv": 0
            };
        }

        axiosInstance.post(
            `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/organization/create`,
            postData
        )
            .then((response) => {
                handleClick();
            })
            .catch((error) => {
                if(error.response && error.response.data && error.response.data.detail){
                setControlError(error.response.data.detail)}
                if (error.response.status === 401 || error.response.status === 422) {
                    localStorage.clear();
                    navigate("/iniciar-sesion");
                }
            });
    }

    return (
        <div className="greyBackgroundModalPopUpNewCompany">
            <div className="ModalExitoDetalleNewCompany">
                <div className='amplifyModalTablaExitoPopUpNewCompany'>
                    <h2>Crear Organización</h2>
                    <div>
                        <p>Nombre</p>
                        <input type="text" className="AdminInputBPAMNewCompany" onChange={(e) => { setName(e.target.value) }} placeholder="Organización" />
                        {controlError !== undefined &&
                        <p style={{fontSize: '10px', marginLeft: '10px'}}>{controlError}</p>}
                    </div>
                    <div>
                        <p>Rol</p>
                        <Select
                            className="userOrganizationSelect"
                            styles={selectstyles}
                            defaultValue={{
                                value: 43, label: (
                                    <div className="SelectFlags">
                                        <img src={BanderaChile} alt={`Bandera de Chile`} className="bandera" />
                                        Chile
                                    </div>
                                )
                            }}
                            options={list.map((e) => ({  // e was missing as the argument here
                                value: e.value,
                                label: (
                                    <div className="SelectFlags">
                                        <img src={e.value === 43 ? BanderaChile : BanderaMexico} alt={`Bandera de ${e.label}`} className="bandera" />
                                        {e.label}
                                    </div>
                                )
                            }))}
                            isSearchable={false}
                            onChange={(e) => {
                                setCountrySelected(e.value);
                            }}
                        />

                    </div>
                    <div className="divPopUpAdminButtons">
                        <button className="btnSecondary" onClick={() => { handleClick() }}>Cancelar</button>
                        <button className="btnPrimary" onClick={() => { createCompany() }}>Continuar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopUpNewAdmin;
