import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../Context/Context';
import BuyerPersona from './boxNavbarAssets/IconBPPerfil.svg';
import Amplify from './boxNavbarAssets/IconAmplifyPerfil.svg';
import BanderaChile from "./boxNavbarAssets/chile.svg";
import BanderaMexico from "./boxNavbarAssets/mexico.svg";
import Chart from 'react-apexcharts';
import axiosInstance from '../../../AxiosInterceptor/AxiosInterceptor'
import './boxNavbar.css';

const BoxNavbar = ({ expanded }) => {
  const navigate = useNavigate();
  const [companias, setCompanias] = useState([]);
  const { contadoresHome, items, country, percent, setPercent } = useContext(AppContext);
  const [isBoxOpen, setIsBoxOpen] = useState(true);
  const [card, setCard] = useState({});
  const boxRef = useRef(null);
  const [percentStorage, setPercentStorage] = useState(undefined)
  const percentEffect = localStorage.getItem('percent')
  const cardEffect = localStorage.getItem('card')
  const [getCardData, setGetCardData] = useState({ AM: 0, BP: 0 })

  const getCard = async () => {
    if (country !== undefined || localStorage.getItem("selectedCountry")) {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/index`,

        );
          const consumidoClon = response.data.consumo['consumido_clon_%'];
          const consumidoEnrichment = response.data.consumo['consumido_enrichment_%'];
      
          const AM = 100 - (consumidoClon * 100);
          const BP = 100 - (consumidoEnrichment * 100);
      
          // Si AM o BP son negativos, se ajustan a 0
          const positiveAM = AM < 0 ? 0 : AM;
          const positiveBP = BP < 0 ? 0 : BP;
      
          setPercent({ AM: positiveAM, BP: positiveBP });
          return { AM: positiveAM, BP: positiveBP };
      
      
      } catch (error) {
        if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 422)) {
          localStorage.clear();
          navigate('/iniciar-sesion')
        }
      }
    } else {
      navigate('/iniciar-sesion')
    }
  }


  useEffect(() => {
    setGetCardData(getCard())
  }, [])
    ; // Llamamos a getCard una vez y almacenamos el resultado
  let amValue = getCardData && getCardData.AM;
  let bpValue = getCardData && getCardData.BP;
  amValue = percent && percent.AM ? percent.AM : amValue ? amValue : 0
  bpValue = percent && percent.BP ? percent.BP : bpValue ? bpValue : 0


  const optionsAM = {
    chart: {
      height: 280,
      type: "radialBar",
    },
    series: [amValue.toFixed(0)],
    plotOptions: {
      radialBar: {
        hollow: {
          size: '40%',
          margin: 0, // Añade un margen para que el "hollow" no llegue al centro
        },
        track: {
          // background: '#D1E9FF',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 4,
            show: true,
            color: "#475467",
            fontFamily: "Inter",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "0.22px",
          }

        }
      }
    },

    colors: ['#F5A973'],
    labels: ["Progress"]
  };

  const options = {
    series: [bpValue.toFixed(0)],
    chart: {


      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        track: {
          // background: '#FFEFCF',

        },
        hollow: {
          size: '40%',
          margin: 0, // Añade un margen para que el "hollow" no llegue al centro
        },
        dataLabels: {
          name: {
            show: false,
            fontSize: '22px',

          },
          value: {
            offsetY: 4,
            show: true,
            color: "#475467",
            fontFamily: "Inter",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "0.22px",


          },

        },

      },
    },

    colors: ['#F5A973']
  };



  useEffect(() => {
    if (localStorage.getItem('percent') !== null) {
      setPercentStorage(localStorage.getItem('percent'))
    }
    if (localStorage.getItem('card') !== null) {
      setCard(JSON.parse(localStorage.getItem('card')))
    }


  }, [percentEffect, cardEffect])

  const CompaniesEffect = localStorage.getItem('companies')
  useEffect(() => {

    let companies = JSON.parse(localStorage.getItem('companies'))
    const copiaArray = companies.map((company) => {
      let flagURL;
      if (company.country_id === 43) {
        // Establecer la URL de la bandera para Chile
        flagURL = BanderaChile;
      } else if (company.country_id === 135) {
        // Establecer la URL de la bandera para México
        flagURL = BanderaMexico;
      }
      return {
        value: { name: company.name, country: company.id, flag: flagURL, country_id: company.country_id },
        label: company.name,
        flag: flagURL, // Agregar la URL de la bandera a la opción
      };
    });

    setCompanias(copiaArray);
  }, [CompaniesEffect]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsBoxOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isBoxOpen) {
    return null;
  }
  return (
    <>

      {(companias && companias[0] && companias[0].flag && percentStorage !== null && percentStorage !== undefined && card) &&
        <div className={`bodyBox ${expanded ? 'expanded' : 'closed'}`} ref={boxRef}>
          <div className="bodyBoxDown" >
            <div className="bodyBoxTextItem">
              {(percent !== undefined) &&

                <div className="bodyBoxLeftItem bodyBoxLeftItemAmHover">
                  <div className="bodyBoxBuyerPersona">
                    <img src={BuyerPersona} alt="" className='imgBuyerPersona' />
                    <p>Buyer persona</p>
                  </div>
                  <div className="chartBoxNavbar" >

                    <Chart
                      options={options}
                      series={options.series}
                      type="radialBar"
                      width={130}

                    />
                  </div>
                  <div className='PopUpboxNavbarData'>
                    <p className='PopUpboxNavbarDataTitle'>Buyer persona</p>
                    <p className='PopUpboxNavbarDataText'>Clientes analizados</p>
                    <p ><span className='PopUpboxNavbarDataSpan'>{card.consumidoBp}</span> <span className='PopUpboxNavbarDataNumbers'>/ {card.planBp}</span></p>
                  </div>
                </div>
              }
              <>
                {(percent !== undefined && localStorage.getItem("selectedItems") === "43") &&
                  <div className={(contadoresHome.consumidoCl && (localStorage.getItem("selectedItems") === "43")) ? "bodyBoxLeftItem bodyBoxLeftItemAmHover" : "bodyBoxLeftItemNone bodyBoxLeftItemAmHover"}>
                    <div className="bodyBoxBuyerPersona">
                      <img src={Amplify} alt="imagen Amplify Persona" className='imgAmplifyPersona' />
                      <p>
                        Amplify
                      </p>
                    </div>
                    <div className="chartBoxNavbar" >
                      <Chart
                        options={optionsAM}
                        series={optionsAM.series}
                        type="radialBar"
                        width={130}

                      />
                    </div>
                    <div className='PopUpboxNavbarData PopUpboxNavbarDataSecond'>
                      <p className='PopUpboxNavbarDataTitle'>Amplify</p>
                      <p className='PopUpboxNavbarDataText'>Clientes descargados</p>
                      <p ><span className='PopUpboxNavbarDataSpan'>{card.consumidoCl}</span> <span className='PopUpboxNavbarDataNumbers'>/ {card.planCl}</span></p>
                    </div>
                  </div>}
              </>

            </div>
          </div>
        </div>}
    </>
  );
};



const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps} className="custom-option">
    <img src={data.flag} alt="" className="bandera" />
    {label}
  </div>
);

export default BoxNavbar;