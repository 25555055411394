import React from "react";
import { useNavigate } from 'react-router-dom';
import ImgThree from "../assets/imgThreeWalktrough.png";
import './walkthrough.css';
import axiosInstance from "../../AxiosInterceptor/AxiosInterceptor";

const WalkthroughSlider = () => {
  const navigate = useNavigate();
  let onBoarding = () => {
  axiosInstance.post(`${process.env.REACT_APP_URL}/api/functions/show`,
  {"update":"onboarding",
  "value": true},
  ).then((e) => {  navigate('/inicio/buyer-persona') })
  .catch(({ e }) => { console.log(e) }); }

  return (
    <div className="walkthrough borde-radius-gris">
      <img src={ImgThree} alt="imagen de hombre preocupado" />
      <div className="walkthrough_text_container">
        <h2>Sigue creciendo</h2>
        <p>
          Aumenta la taza de conversión acelerando tu gestión comercial y
          crecimiento de tu empresa <br /> a un menor costo.
        </p>
      </div>
      <div className='walkthrough_btn_container'>
          <div className="dotsContainerW">
              <div className="dotsW"></div>
              <div className="dotsW"></div>
              <div className="dotsW active"></div>
          </div>
          <button className='OmitirPrimaryWalktrough' onClick={()=>{onBoarding()}}>Omitir</button>
      </div>
    </div>
  );
};

export default WalkthroughSlider;
