import React, { useState } from "react";
import Navbar from "../../navbar/menuVertical/navbar";
import CardDescarga from "../AmplifyContenedor/cardDescarga/cardDescarga";
import "./amplifyEstilosGenerales.css";

const AmplifyAutomaticoDescarga = () => {
  const [navbarData, setNavbarData] = useState(false);
  const estadoMenu = (data) => {setNavbarData(data);};

  return (
    <>
      <div className="containerAmplify">
        <Navbar estado={estadoMenu} />
        <div
          className={`amplify_body_container ${navbarData ? "navbar-open" : ""}`}
          style={{ marginLeft: navbarData ? "228px" : "80px" }}
        >
          <header className='headerAmplify'>         
          </header>
          <main className="mainAmplify">
            <CardDescarga />
          </main>
        </div>
      </div>
    </>
  );
};

export default AmplifyAutomaticoDescarga;
