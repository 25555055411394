import React, { useEffect, useState } from "react";
import Circle from "./Circle";
import "./ProgressBar.css";

const ProgressBar = ({ activeBar }) => {
  const [circle] = useState(3);
  const [widthBar, setWidthBar] = useState(0);

  useEffect(() => {
    setWidthBar((100 / (circle - 1)) * activeBar);
  }, [circle, activeBar]);

  const arrBar = [];
  for (let i = 0; i < circle; i++) {
    arrBar.push(
      <Circle
        cName={
          i < activeBar
            ? "circleBar lastActiveBar"
            : i === activeBar
            ? "circleBar activeBar"
            : "circleBar"
        }
        key={i}
      ></Circle>
    );
  }

  const progressText = ["Seleccionar Lista", "Definir Público", "Amplificar Lista"];

  return (
    <div className="progresBarAmplify">
      <div className="progressBar">
        <div className="progress" style={{ width: widthBar + "%" }}></div>
        {arrBar}
      </div>

      <div className="progressContainerText">
        {progressText.map((text, index) => (
          <p
            className={
              index < activeBar
                ? "lastActiveBar"
                : index === activeBar
                ? "activeBar"
                : ""
            }
            key={index}
          >
            {text}
          </p>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
