import React from 'react';
import Card from './card404/card404'
import './error404.css'

const Error404 = () => {
  return (
    <>
      <div className='body' >
      <Card/>
      </div>
    </>
  );
};

export default Error404;