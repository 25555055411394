import React, { useEffect, useState } from "react";
import "./BarsNoLibrary.css";

const BarChart = ({ percentagesRutF, percentagesRutM, percentages, percentagesF, categories, colors, labels, height }) => {
  const totalPercentage = percentages.reduce((a, b) => a + b, 0);
  const [last, setLast] = useState(1)
  const [lastF, setLastF] = useState(1)
  const [hiddenCategories, setHiddenCategories] = useState([]);
  const [hoveredBar, setHoveredBar] = useState(null);
  const [hoveredBarBottom, setHoveredBarBottom] = useState(null);

  const handleMouseEnter = (index, category) => {
    setHoveredBar(index);
  };

  const handleMouseLeave = () => {
    setHoveredBar(null);
  };
  const handleMouseEnterBottom = (index, category) => {
    setHoveredBarBottom(index);
  };

  const handleMouseLeaveBottom = () => {
    setHoveredBarBottom(null);
  };


  const toggleCategoryVisibility = index => {
    if (hiddenCategories.includes(index)) {
      setHiddenCategories(hiddenCategories.filter(item => item !== index));
    } else {
      setHiddenCategories([...hiddenCategories, index]);
    }
  };


  const visibleCategories = categories.filter((category, index) => !hiddenCategories.includes(index));

  const visiblePercentages = percentages.filter((percentage, index) => !hiddenCategories.includes(index));
  const visiblePercentagesF = percentagesF.filter((percentage, index) => !hiddenCategories.includes(index));

  const totalVisiblePercentage = visiblePercentages.reduce((a, b) => a + b, 0);
  const scaledVisiblePercentages = visiblePercentages.map(p => (p / totalVisiblePercentage) * 100);
  const totalVisiblePercentageF = visiblePercentagesF.reduce((a, b) => a + b, 0);
  const scaledVisiblePercentagesF = visiblePercentagesF.map(p => (p / totalVisiblePercentageF) * 100);

  useEffect(() => {
    let lastselected = (val) => {
      if (val > 0.2) {
        return 1
      } else {
        return 2
      }
    }
    setLast(lastselected(scaledVisiblePercentages.at(-1)))
    setLastF(lastselected(scaledVisiblePercentagesF.at(-1)))
  })

  return (
    // style={{ height: (labels !== false && !height) ? '5em' : (height || '3em') }}
    <div className="divChartContainer">
      <div className="chart">
        <div className="chartPropioDiv">
          {scaledVisiblePercentages.length > 0 &&
            <p className="textChartPropioDivBars">Masculino</p>}
          <div className={`${scaledVisiblePercentages.length > 0 ? "chartPropioDivBars chartPropioDivPrimerElemento" : 'chartPropioDivBarsNone' }`} >


            {scaledVisiblePercentages.length > 0 && visibleCategories.map((category, index) => {
              const originalIndex = categories.indexOf(category);
              return (
                <div
                  key={category}
                  className={`bar ${originalIndex === 0 ? 'first-bar' : ''} ${index === visibleCategories.length - last ? 'last-bar' : ''}`}
                  style={{
                    width: `${scaledVisiblePercentages[index]}%`,
                    backgroundColor: colors[originalIndex],
                  }}
                  onClick={() => toggleCategoryVisibility(categories.indexOf(category))}

                  onMouseEnter={() => handleMouseEnter(originalIndex, category)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="bar-label">
                    {percentages[originalIndex].toFixed(1) > 0.4 && <p>{percentages[originalIndex].toFixed(1)}%</p>}
                  </div>
                  {hoveredBar === originalIndex && (
                    <div className="tooltipBarrasPropio">
                      <div style={{ backgroundColor: colors[originalIndex] }} className="dotTooltipPropio"></div>
                      <p>Cantidad: </p>
                      <p className="quantityLabelPropio">{percentagesRutM[originalIndex]}</p>
                    </div>
                  )}
                </div>
              );
            })}

          </div>
          {scaledVisiblePercentagesF.length > 0 &&
            <p className="textChartPropioDivBars">Femenino</p>
          }
          <div className={`${scaledVisiblePercentagesF.length > 0 ? "chartPropioDivBars" : 'chartPropioDivBarsNone' }`}>
            {scaledVisiblePercentagesF.length > 0 && visibleCategories.map((category, index) => {
              const originalIndex = categories.indexOf(category);
              return (
                <div
                  key={category}
                  className={`bar ${originalIndex === 0 ? 'first-bar' : ''} ${index === visibleCategories.length - lastF ? 'last-bar' : ''}`}
                  style={{
                    width: `${scaledVisiblePercentagesF[index]}%`,
                    backgroundColor: colors[originalIndex],
                  }}
                  onClick={() => toggleCategoryVisibility(categories.indexOf(category))}
                  onMouseEnter={() => handleMouseEnterBottom(originalIndex, category)}
                  onMouseLeave={handleMouseLeaveBottom}
                >
                  <div className="bar-label">
                    {percentagesF[originalIndex].toFixed(1) > 0.4 && <p>{percentagesF[originalIndex].toFixed(1)}%</p>}
                  </div>
                  {hoveredBarBottom === originalIndex && (
                    <div className="tooltipBarrasPropio">
                      <div style={{ backgroundColor: colors[originalIndex] }} className="dotTooltipPropio"></div>
                      <p>Cantidad: </p>
                      <p className="quantityLabelPropio">{percentagesRutF[originalIndex]}</p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {categories.map((category, index) => (
            hiddenCategories.includes(index) && (
              <div
                key={category}
                className={`bar ${index === 0 ? 'first-bar' : ''} ${index === categories.length - 1 ? 'last-bar' : ''}`}
                style={{
                  width: `0%`,
                  backgroundColor: colors[index],
                  visibility: 'hidden',
                }}
              >
                <div className="bar-label">
                  <p>0%</p>
                </div>
              </div>
            )
          ))}

        </div>
        {labels !== false &&
          <div className="barDotsContainer">
            {categories.map((category, index) => (
              <div
                key={category + index}
                className={`barDots ${hiddenCategories.includes(index) && "barDotsDisabled"}`}
                onClick={() => toggleCategoryVisibility(index)}
              >
                <div className="barDotsElement" style={{ backgroundColor: colors[index] }}></div>
                <p className="barDotsElementText"    >{category}</p>
              </div>
            ))}
          </div>}
      </div>
    </div>
  );
};
export default BarChart;