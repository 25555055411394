import React, { useState,useContext, useEffect } from "react";
import Navbar from "../../navbar/menuVertical/navbar";
import Form from "../AmplifyContenedor/AmplifyContenedor";
import ProgressBar from "../BarraProgresivaAmplify/ProgressBar";
import "./amplifyEstilosGenerales.css";

const AmplifyAutomatico = () => {
  const [navbarData, setNavbarData] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [circle] = useState(3);
  const estadoMenu = (data) => {
    setNavbarData(data);
  };

  const handleNext = (step) => {
    if (step >= 0 && step < circle) {
      setCurrentStep(step);
    }
  };

  return (
    <>
      <div className="containerAmplify">
        <Navbar estado={estadoMenu} />

        <div
          className={`amplify_body_container ${navbarData ? "navbar-open" : ""}`}
          style={{ marginLeft: navbarData ? "217px" : "80px" }}
        >
          <header className="headerAmplify">
            {/* Progress Bar */}
            <ProgressBar activeBar={currentStep} />
          </header>

          <main className="mainAmplify">

            {/* Contenido dinamico */}
            <Form updateProgress={handleNext} navbarData={navbarData} />
          </main>
        </div>
      </div>
    </>
  );
};

export default AmplifyAutomatico;
