import React from 'react';
import './body.css'
const Body = ({value}) => {
    return (
        <>
            <p className='bodyTextLoader'>{value}</p>
        </>
    );
}

export default Body;
