import React, { useState, useEffect } from "react";
import "./PopUpAdmin.css"
import Expand from "../../home/tableContainer/tableAssets/expand_more.svg"
import Select from "react-select";
import axiosInstance from "../../AxiosInterceptor/AxiosInterceptor";
import { useParams } from "react-router-dom";
import Eye from "../../Login/eye-visible.svg";
import EyeClose from "../../Login/eye-no-visible.svg";
import reactSelect from "react-select";
const customStyles = {
    placeholder: (provided, state) => ({
        ...provided,
        color: "#6D7A8FFF",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        letterSpacing: "0.28px",
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "stretch",
        cursor: "pointer",
        borderRadius: "8px",
        borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
    }),
    container: (provided, state) => ({
        ...provided,
        width: "140px",
        height: "40px",
        fontFamily: "Inter",
        fontSize: "12px",
        backgroundColor: "white",
    }),
    control: (provided, state) => ({
        ...provided,
        fontFamily: "Inter",
        fontSize: "12px",
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "stretch",
        background: "#FFF",
        cursor: "pointer",
        width: "140px",

        borderRadius: "8px",
        borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
        boxShadow: state.isFocused ? "0 0 0 1px #F5A973" : "none",

        "&:hover": {
            borderColor: "#F18F49",
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    menu: (provided) => ({
        ...provided,
        display: "block",
        width: "140px",
        height: "120px",
        padding: "8px",
        gap: "8px",
        borderRadius: "8px",
        backgroundColor: "white",
        cursor: "pointer",
    }),
    option: (provided, state) => ({
        ...provided,
        color: "#6D7A8FFF",
        fontSize: "12px",
        fontFamily: "Inter",
        fontWeight: "400",
        backgroundColor: "white",

        "&:active": {
            backgroundColor: "white", // Evita que haya un color de fondo al hacer clic en la opción
        },
        "&:hover": {
            backgroundColor: "#EFF8FF", // Cambia el color de fondo al hacer hover
        },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: "#6D7A8FFF",
        fontSize: "12px",
        fontFamily: "Inter",
        fontWeight: "400",
        backgroundColor: "white",
        paddingLeft: '5px',

    }),
};
const PopUpAdmin = ({ handleClick, userList, close, PopUpAdminRol, }) => {
    let { id, country } = useParams();
    const [estado, setEstado] = useState(true)
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [position, setPosition] = useState(1)
    const [org, setOrg] = useState([])
    const [target, setTarget] = useState(false)
    const [idUser, setIdUser] = useState(undefined)
    const [passwordErrors, setPasswordErrors] = useState(false)
    const [validMail, setValidMail] = useState(false)
    const [dropdownStates, setDropdownStates] = useState(false);
    const [userData, setUserData] = useState([]);
    const [alreadyExist, setAlreadyExist] = useState([]);
    const [eyeState, setEyeState] = useState("password");
    const [image, setImage] = useState(EyeClose);
    const [rol, setRol] = useState([])
    const toggleDropdown = () => {
        setDropdownStates(!dropdownStates); // Invierte el estado de los desplegables
    };
    useEffect(() => { setUserData(userList.map((e) => e.email)) }, [])
    useEffect(() => { setAlreadyExist(userData.filter((e) => { return e.trim() === user.trim() }).length > 0) }, [user, userData])

    const seePassword = () => {
        if (eyeState === "password") {
            setEyeState("text");
            setImage(Eye);
        } else {
            setEyeState("password");
            setImage(EyeClose);
        }
    };
    const find = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(user);
        setValidMail(isValid);
        if (isValid) {
            axiosInstance.get(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/user/find/${user}`)
                .then((e) => {
                    setTarget(true)
                    setOrg(e.data.user.organizations)
                    setIdUser(e.data.user.id)
                    setRol(e.data.user.Rol)
                    setAlreadyExist(false) 
                    setValidMail(true)
                    setPasswordErrors(false)
                })
                .catch((e) => {
                    setTarget(false)
                    if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
                        localStorage.clear();
                        navigate('/iniciar-sesion')
                    } else if ((e.responese && e.responese.status && e.response.status === 302)) {
                        navigate('/acceso-denegado')
                    }
                    // setLoading(false)
                })
        }
    }

    const create = () => {
        let organizations = []
        if (org !== undefined && org.length > 0) {
            organizations = org
        }
        organizations.push({ id: Number(id), country_id: Number(country) })
        let power = []
        if (rol !== undefined && rol.length > 0 ) {
            power = arrayDeObjetos.filter(objeto => objeto.country_id === localStorage.getItem('selectedItems'));

        }
        power.push(Number(position))

        if (!target) {
            axiosInstance.post(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/user/create`,
                {
                    "email": user,
                    "password": password,
                    "organization_id": Number(id),
                    "country_id": localStorage.getItem('selectedItems'),
                    "role": [Number(position)],
                    "enrichment_plan": 0,
                    "clon_plan": 0,
                    'active': estado
                }
            )
                .then((e) => {
                    handleClick()

                })
                .catch((e) => {
                    if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
                        localStorage.clear();
                        navigate('/iniciar-sesion')
                    } else if ((e.responese && e.responese.status && e.response.status === 302)) {
                        navigate('/acceso-denegado')
                    }
                    // setLoading(false)
                })
        } else {

            axiosInstance.post(
                `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/user/update/${idUser}`,
                {
                    "organization": organizations,
                    "role": power
                }
            )
                .then((response) => {
                    handleClick()
                })
                .catch((error) => {
                    if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 422)) {
                        localStorage.clear();
                        navigate("/iniciar-sesion");
                    }
                });

        }
    }
    useEffect(() => {
        find();
    }, [user]);

    const validatePass = (pass) => {
        if (!pass.trim()) {
            setPasswordErrors(true);
            return;
        }

        const validRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#^><¿/|_\-&])[A-Za-z\d@$!%*?#^><¿/|_\-&]{8,30}$/;
        const isValid = validRegex.test(pass);
        if (isValid) {
            setPasswordErrors(false);
            setPassword(pass);
        } else {
            setPasswordErrors(true);
        }
    }


    return (
        <div className="greyBackgroundModalAdminPopUp">
            <div className="ModalExitoDetallePopUpAdmin">

                <div className={!target ? "amplifyModalTablaExitoPopUpAdminTarget" : "amplifyModalTablaExitoPopUpAdminTarget amplifyModalTablaExitoPopUpAdminTargetHeight"} >
                    {!target ?
                        <h2>Crear Usuario</h2> :
                        <h2>Agregar Usuario Existente</h2>
                    }
                    <div>
                        <p className="amplifyModalTablaExitoPopUpAdminP">Correo electrónico</p>
                        <input type="text"
                            className={target || !validMail ? "AdminInputBPAMPopUp AdminInputBPAMPopUpInput" : "AdminInputBPAMPopUp"}
                            onChange={(e) => { setUser(e.target.value) }} />
                        {(!target && !validMail) && <p className="AdminInputBPAMPopUptext">Ingresa un mail valido</p>}
                        {alreadyExist && <p className="AdminInputBPAMPopUptext">Esta cuenta ya es parte de la organización</p>}
                    </div>
                    {!target &&
                        <>
                            <div>
                                <p className="amplifyModalTablaExitoPopUpAdminP">Contraseña</p>
                                <div className="EyePasswordAdmin">
                                    <img
                                        src={image}
                                        alt=""
                                        className="EyeAdmin"
                                        onClick={seePassword}
                                    />
                                </div>
                                <input type={eyeState} className="AdminInputPasswordPopUp" onChange={(e) => { validatePass(e.target.value) }} />
                                {passwordErrors && <p className="error-message-admin-popupadmin">La contraseña debe tener de 8 a 30 caracteres, una letra mayúscula, minúscula,un número y un carácter especial.</p>}

                            </div>
                            <div>
                                <p className="amplifyModalTablaExitoPopUpAdminP">Estado</p>
                                <Select
                                    className="userOrganizationSelect"
                                    styles={customStyles}
                                    defaultValue={{ value: true, label: (<div className="activeUserOrganization "><p> Activo</p></div>) }}

                                    options={[
                                        { value: true, label: (<div className="activeUserOrganization "><p> Activo</p></div>) },
                                        { value: false, label: (<div className=" inactiveUserOrganization "><p> Inactivo</p></div>) }
                                    ]}
                                    isSearchable={false}
                                    onChange={(selectedOption) => {
                                        setEstado(selectedOption.value) // Llamar a la función para activar directamente si se está intentando activar
                                    }}
                                />
                            </div></>}
                    <div>

                        <p className="amplifyModalTablaExitoPopUpAdminP">Rol</p>
                        {PopUpAdminRol.length > 0 &&
                            <Select
                                className="userOrganizationSelect"
                                styles={customStyles}
                                defaultValue={{ value: PopUpAdminRol[0].id, label: PopUpAdminRol[0].nombre }} // Establece el valor predeterminado
                                options={PopUpAdminRol.map((e) => ({
                                    value: e.id,
                                    label: e.nombre,
                                }))}
                                isSearchable={false}
                                onChange={(e) => { setPosition(e.value) }}
                            />}

                    </div>
                    <div className="divPopUpAdminButtons">
                        <button className="btnSecondary" onClick={handleClick} >Cancelar</button>
                        <button
                            className={alreadyExist || !validMail || passwordErrors ? "btnDisabled" : "btnPrimary"}
                            onClick={() => { create() }}
                            disabled={alreadyExist || !validMail || passwordErrors}
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default PopUpAdmin;
