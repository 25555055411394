import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../Context/Context";
import SearchImg from "./tableAssets/IconSearch.svg";
import SearchImgActive from "./tableAssets/search-active.svg";
import Badged from "./tableAssets/components/badged";
import Vertical from "../components/icons/verticalActive.svg";
import Icon from "../components/icon";
import VerticalDisabled from "../components/icons/verticalDisabled.svg";
import axiosInstance from "../../AxiosInterceptor/AxiosInterceptor";
import SpinnerProcesando from "../../ComponentesMoleculas/Loader/SpinnerProcesando";
import PopUpBP from "../PopUp/PopupBP";
import "./tableContainer.css";
import Pagination from "../../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import Edit from './tableAssets/edit.png'
import BanderaChile from "../../navbar/menuVertical/boxNavbar/boxNavbarAssets/banderaChile.png";
import BanderaMexico from "../../navbar/menuVertical/boxNavbar/boxNavbarAssets/banderaMexico.png";
import Expand from "./tableAssets/expand_more.svg"
import separadorDeMiles from "../../SeparadorDeMiles/SeparadorDeMiles";
import ReactSelect from "react-select";
const placeholder = (provided, state) => ({
  ...provided,
  color: "#6D7A8FFF",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  letterSpacing: "0.28px",
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  cursor: "pointer",
  borderRadius: "8px",
  borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
})
const containerWidth = (provided, state) => ({
  ...provided,
  width: "125px",
  height: "40px",
  fontFamily: "Inter",
  fontSize: "12px",
})
const controlWidth = (provided, state) => ({
  ...provided,
  fontFamily: "Inter",
  fontSize: "12px",
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  background: "#FFF",
  cursor: "pointer",
  width: "125px",
  borderRadius: "8px",
  borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
  boxShadow: state.isFocused ? "0 0 0 1px #F5A973" : "none",

  "&:hover": {
    borderColor: "#F18F49",
  },
})
const indicatorSeparator = (provided) => ({
  ...provided,
  display: "none",
})
const menuWidth = (provided) => ({
  ...provided,
  display: "block",
  width: "125px",
  height: "105px",
  padding: "0px",
  gap: "0px",
  borderRadius: "8px",
  cursor: "pointer",
})
const optionWidth = (provided, state) => ({
  ...provided,
  color: "#6D7A8FFF",
  fontSize: "12px",
  fontFamily: "Inter",
  fontWeight: "400",
  backgroundColor: "white",
  paddingLeft: '2px',
  "&:active": {
    backgroundColor: "white", // Evita que haya un color de fondo al hacer clic en la opción
  },
  "&:hover": {
    backgroundColor: "#EFF8FF", // Cambia el color de fondo al hacer hover
  },
})
const singleValueWidth = (provided, state) => ({
  ...provided,
  color: "#6D7A8FFF",
  fontSize: "12px",
  fontFamily: "Inter",
  fontWeight: "400",
  backgroundColor: "white",


})
const customStylesSelect = {
  placeholder: placeholder,
  container: containerWidth,
  control: controlWidth,
  indicatorSeparator: indicatorSeparator,
  menu: menuWidth,
  option: optionWidth,
  singleValue: singleValueWidth
}

const TableContainer = ({ data, amplify, company, reload, loading, param, }) => {

  const [dataArchivo, setDataArchivo] = useState("");
  const [dataReverseDate, setDataReverseDate] = useState([]);
  const [amplifyReverseDate, setAmplifyReverseDate] = useState([])
  const [companies, setCompanies] = useState([]);
  const { country, items } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPageBuyer, setCurrentPageBuyer] = useState(1);
  const itemsPerPageBuyer = 10;
  const [currentPageAmplify, setCurrentPageAmplify] = useState(1);
  const itemsPerPageAmplify = 10;
  const [currentPageCompanies, setCurrentPageCompanies] = useState(1);
  const itemsPerPageCompanies = 10;
  const [verLupa, setVerLupa] = useState(true);
  const [cambiarLupa, setCambiarLupa] = useState(false);
  const [listBuyer, setListBuyer] = useState([]);
  const [listAmplify, setListAmplfy] = useState([]);
  const [listCompanies, setListCompanies] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [eliminarPopup, setEliminarPopUp] = useState(false);
  const [nameListFile, setNameListFile] = useState("false");
  const [selectedRow, setSelectedRow] = useState(null);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const companyFilter = localStorage.getItem('companyFilter')
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [idOrganization, setIdOrganization] = useState(false);
  const [idCountry, setIdCountry] = useState(undefined)
  const [nameOrganization, setNameOrganization] = useState(undefined)
  const [searching, setSearching] = useState(false)
  const [dropdownStates, setDropdownStates] = useState({});



  const toggleDropdown = (id) => {
    setDropdownStates(prevState => {
      const newState = { ...prevState };

      // Cierra todos los dropdowns que no sean el que se está abriendo
      Object.keys(newState).forEach(key => {
        if (key !== id) {
          newState[key] = false;
        }

      });

      // Invierte el estado del desplegable correspondiente al ID
      newState[id] = !prevState[id];

      return newState;
    });
  };

  const navigate = useNavigate();

  const handleSearchChange = (event, activeTab) => {
    const query = event.target.value;
    setSearching(query.length > 0)
    setSearchQuery(query);

    if (query.trim().length > 0) {
      setVerLupa(false);
    } else {
      setVerLupa(true);
    }
    if (activeTab === 1) {
      setCurrentPageBuyer(1);
    } else if (activeTab === 2) {
      setCurrentPageAmplify(1);
    } else if (activeTab === 3) {
      setCurrentPageCompanies(1);
    }
    setNoResultsFound(query.length === 0 && searching);

  };
  useEffect(() => {
    setNoResultsFound(searching);
  }, [searching])

  const applyDateFilterCompanies = () => {
    let filteredData = companies; // Utiliza tus datos de "Amplify" aquí

    let List = filteredData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      ));
    if (listCompanies[0] !== List[0]) {
      setListCompanies(List);
    }
    return List.slice(
      (currentPageCompanies - 1) * itemsPerPageCompanies,
      currentPageCompanies * itemsPerPageCompanies
    );
  };
  const applyDateFilterAmplify = () => {
    let filteredData = amplifyReverseDate; // Utiliza tus datos de "Amplify" aquí
    filteredData = filteredData.filter((item) => item.org === companyFilter);
    if (!loading && param === "amplify" && filteredData.length === 0) {
      navigate("/inicio-nuevo-amplify");
    }

    let List = filteredData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    if (listAmplify[0] !== List[0]) {
      setListAmplfy(List);
    }
    return List.slice(
      (currentPageAmplify - 1) * itemsPerPageAmplify,
      currentPageAmplify * itemsPerPageAmplify
    );
  };
  const applyDateFilterBuyer = () => {
    let filteredData = dataReverseDate; // Utiliza tus datos de "Buyer Persona" aquí
    filteredData = filteredData.filter((item) => item.action_ !== 0);
    if (!loading && param === "buyer-persona" && filteredData.length === 0) {
      navigate("/inicio-nuevo-buyer-persona");
    }

    let List = filteredData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    if (listBuyer[0] !== List[0]) {
      setListBuyer(List);
    }

    return List.slice(
      (currentPageBuyer - 1) * itemsPerPageBuyer,
      currentPageBuyer * itemsPerPageBuyer
    );
  };

  useEffect(() => {
    if (param !== "/administrador") {
      setDataReverseDate(
        data.slice().filter((item) => item.organization === companyFilter)
      );
      setAmplifyReverseDate(
        amplify.slice().filter((item) => item.org === companyFilter)
      );
      if (param === "buyer-persona") {
        handleTabClick(1);
        setActiveTab(1);
      } else if (param === "amplify") {
        handleTabClick(2);
        setActiveTab(2);
      }
    } else {
      setCompanies(company);
      handleTabClick(3);
      setActiveTab(3);
    }

  }, [data, amplify, company, items, companyFilter, param]);
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  const handleClosePopUp = () => {
    setSelectedRow(null);
    setPopUpVisible(false);
  };
  const handleVerticalIconClick = (event, rowData) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setSelectedRow(rowData);
    setPopUpVisible(true);
  };
  const deletePopup = (data) => {
    setDataArchivo(data);
    setEliminarPopUp(true);
  };
  const inactive = () => {
    axiosInstance.post(
      `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/organization/delete/${idOrganization}`,
      {
        "country_id": idCountry
      }
    )
      .then((response) => {
        reload()
        setIsDeleting(false);
        setEliminarPopUp(false);
        // setButtonDisabled(false)
      })
      .catch((error) => {
        setIsDeleting(false);
        alert("Ocurrio un error");

        if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 422)) {
          localStorage.clear();
          navigate("/iniciar-sesion");
        }
      });

  }
  const handleDeleteData = () => {
    setIsDeleting(true);
    let link = '';
    if (activeTab === 2) {
      link = "clones/delete";
    } else {
      link = "enrichment/delete";
    }

    axiosInstance.get(
      `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/${link}/${dataArchivo}`,
    )
      .then((response) => {
        reload();
        setIsDeleting(false);
        setEliminarPopUp(false);
        setDropdownStates(prevState => {
          const newState = { ...prevState };

          // Cierra todos los dropdowns que no sean el que se está abriendo
          Object.keys(newState).forEach(key => {

            newState[key] = false;

          });
          return newState;
        });
      })
      .catch((error) => {
        setIsDeleting(false);
        alert("Ocurrio un error");

        if (error.response.status === 401 || error.response.status === 422) {
          localStorage.clear();
          navigate("/iniciar-sesion");
        }
      });
  };
  const handleActiveData = (id,org) => {


    axiosInstance.get(
      `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/organization/${org}/${id}/activate`,
    )
      .then((response) => {
        reload();
        setDropdownStates(prevState => {
          const newState = { ...prevState };

          // Cierra todos los dropdowns que no sean el que se está abriendo
          Object.keys(newState).forEach(key => {

            newState[key] = false;
          }
          );



          return newState;
        });
      })
      .catch((error) => {
        alert("Ocurrio un error");

        if (error.response.status === 401 || error.response.status === 422) {
          localStorage.clear();
          navigate("/iniciar-sesion");
        }
      });
  };
  useEffect(() => {
    const handleScroll = () => {
      // Verificar si el PopUp está abierto
      if (popUpVisible) {
        // Cerrar el PopUp cuando se hace scroll
        setSelectedRow(null);
        setPopUpVisible(false);
      }
    };

    // Agregar un event listener al evento de desplazamiento global (window)
    window.addEventListener("scroll", handleScroll);

    // Remover el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [popUpVisible]);
  const renderTable = (tableData, isBuyerPersona) => (
    <div className={`table${isBuyerPersona ? 'BuyerPerson' : 'Amplify'}`}>
      <div className="tableHeader">
        <p>{`${(activeTab === 1) ? 'Tus últimos Buyer persona' : (activeTab === 2) ? ' Tus últimos Amplify' : "Lista de organizaciones"}`}</p>
        <div
          className="search-input"
          onFocus={() => setCambiarLupa(true)}
          onMouseLeave={() => setCambiarLupa(false)}
        >
          {verLupa && (
            <>
              {cambiarLupa ? (
                <div className="imgLupaBuscador">
                  <img className="imgSearchInput" src={SearchImgActive} alt="" />
                </div>
              ) : (
                <div className="imgLupaBuscador">
                  <img
                    className="imgSearchInput imgSearchInput"
                    src={SearchImg}
                    alt="imagen lupa buscador"
                  />
                </div>
              )}
            </>
          )}
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder={`Buscar ${(activeTab === 1) ? 'Buyer persona' : (activeTab === 2) ? 'Amplify' : "Compañias"}`}
            className="inputSearchHome borde-inputs-generales"
            id="searchInput"
          />

        </div>
      </div>
      <div className={activeTab === 3 ? "tablaHomeAdmin" : "tablaHome"}>
        {tableData.length < 1 && !searching && <SpinnerProcesando />}
        {activeTab === 1 &&
          <table>
            <thead>
              <tr>
                <th>
                  <div
                    className={`thHeader`}
                  >
                    {" "}
                    <p className="thFilter">Nombre</p>
                  </div>
                </th>

                <th>
                  <div
                    className={`thHeader`}>
                    <p className="thFilter">Industria</p>
                  </div>
                </th>
                <th className="thRegistro">
                  <div
                    className={`thHeader`}>
                    <p className="thFilter">Registros</p>
                  </div>
                </th>
                <th className="thFecha">
                  <div
                    className={`thHeader`}>
                    <p className="thFilter">Fecha</p>
                  </div>
                </th>
                <th className="thEstado">
                  <div
                    className={`thHeader`}
                  >
                    <p className="thFilter">Estado</p>
                  </div>
                </th>
                <th className="thAction">
                  <p className="thFilter">Acciones</p>
                </th>
              </tr>
            </thead>

            {applyDateFilterBuyer().map((e, i) => (
              <tbody className="tbody" key={i + "buyer"}>
                <tr>
                  <td>
                    <p className="tdName">{e.namelist}</p>
                  </td>

                  <td>
                    {e.industry === "Natural" || e.industry === "" ? (
                      <p>-</p>
                    ) : (
                      <p className="tdIndustry">{e.industry}</p>
                    )}
                  </td>
                  <td>
                    <p className="tdAmount">
                      {e.amount.toLocaleString("es-ES", {
                        useGrouping: true,
                      })}
                    </p>
                  </td>
                  <td>
                    <p className="tdCreated">
                      {new Date(e.created_at).toLocaleDateString(
                        "es-ES",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }
                      )}
                    </p>
                  </td>
                  {e.action_ === -1 && (
                    <td className="tdBadged">
                      <Badged
                        value="Procesando datos..."
                        style="procesandoDatos"
                      />
                    </td>
                  )}
                  {e.action_ === -2 && (
                    <td className="tdBadged">
                      <Badged
                        value="Modelando datos…"
                        style="modelandoDatos"
                      />
                    </td>
                  )}
                  {e.action_ === 0 && (
                    <td className="tdBadged">
                      <Badged
                        value="Error de Compra"
                        style="badgedSinExito"
                      />
                    </td>
                  )}
                  {e.action_ === 1 && (
                    <td className="tdBadged">
                      <Badged
                        value="Procesando datos…"
                        style="procesandoDatos"
                      />
                    </td>
                  )}
                  {e.action_ === 2 && (
                    <td className="tdBadged">
                      <Badged
                        value="Modelando datos…"
                        style="modelandoDatos"
                      />
                    </td>
                  )}
                  {e.action_ === 3 && (
                    <td className="tdBadged">
                      <Badged
                        value="Analizando perfiles..."
                        style="analizandoPerfiles"
                      />
                    </td>
                  )}
                  {(e.action_ === 4 || e.action_ === 5) && (
                    <td className="tdBadged">
                      <Badged value="¡Listo!" style="badgedConExito" />
                    </td>
                  )}
                  {(e.action_ === 99 || e.action_ === -5 || (e.action_ < -899 && e.action_ > -951 ) ) && (
                    <td className="tdBadged">
                      <Badged value="Error" style="badgedSinExito" />
                    </td>
                  )}
                  {e.action_ === 4 || e.action_ === 5 ? (
                    <td className="verticalIcon">
                      <Icon
                        value={Vertical}
                        onClick={(event) =>
                          handleVerticalIconClick(event, e)
                        }
                      />
                    </td>
                  ) : (
                    <td className="verticalIcon">
                      <Icon value={VerticalDisabled} />
                    </td>
                  )}
                </tr>
                {selectedRow === e && popUpVisible && (
                  <PopUpBP
                    amplify={true}
                    position={{
                      position: "fixed",
                      left: "103%",
                      top: (e) => { return e.clientX },
                    }}
                    onClose={() => {
                      handleClosePopUp();
                    }}
                    dataArchivo={e}
                    onDeleteClick={(dataArchivo) => {
                      setNameListFile(dataArchivo.namelist)
                      deletePopup(dataArchivo.token);
                    }}
                  />
                )}
              </tbody>
            ))}
          </table>}
        {activeTab === 2 &&
          <table>
            <thead>
              <tr>
                <th>
                  <div
                    className={`thHeader `}
                  >
                    <p className="thFilter">Nombre</p>
                  </div>
                </th>
                <th className="thProceso">
                  <div
                    className={`thHeader`}
                  >
                    <p className="thFilter">Proceso</p>
                  </div>
                </th>
                <th className="thRegistro">
                  <div
                    className={`thHeader `}
                  >
                    <p className="thFilter">Registros</p>
                  </div>
                </th>
                <th className="thFecha">
                  <div
                    className={`thHeader`}
                  >
                    <p className="thFilter">Fecha</p>
                  </div>
                </th>
                <th className="thEstado">
                  <div
                    className={`thHeader`}
                  >
                    <p className="thFilter">Estado</p>
                  </div>
                </th>
                <th className="thAction">
                  <p className="thFilter">Acciones</p>
                </th>
              </tr>
            </thead>

            {applyDateFilterAmplify().map((e, i) => (
              <tbody className="tbody" key={i + "amplify"}>
                <tr>
                  <td>
                    <p className="tdName">{e.name}</p>
                  </td>
                  <td>
                    <p className="tdIndustry">
                      {e.tipo === "buyer"
                        ? "Automático"
                        : e.tipo.charAt(0).toUpperCase() +
                        e.tipo.slice(1)}
                    </p>
                  </td>
                  <td>
                    <p className="tdAmount">
                      {e.amount.toLocaleString("es-ES", {
                        useGrouping: true,
                      })}
                    </p>
                  </td>
                  <td>
                    <p className="tdCreated">
                      {new Date(e.fecha_solicitud).toLocaleDateString(
                        "es-ES",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }
                      )}
                    </p>
                  </td>
                  {(Number(e.procesado) === -1 ||
                    Number(e.procesado) === -2) && (
                      <td className="tdBadged">
                        <Badged
                          value="Procesando datos..."
                          style="procesandoDatos"
                        />
                      </td>
                    )}
                  {Number(e.procesado) === 0 && (
                    <td className="tdBadged">
                      <Badged
                        value="Error de Compra"
                        style="badgedSinExito"
                      />
                    </td>
                  )}
                  {Number(e.procesado) === 1 && (
                    <td className="tdBadged">
                      <Badged
                        value="Modelando datos..."
                        style="modelandoDatos"
                      />
                    </td>
                  )}

                  {Number(e.procesado) === 3 && (
                    <td className="tdBadged">
                      <Badged
                        value="Analizando perfiles..."
                        style="analizandoPerfiles"
                      />
                    </td>
                  )}
                  {Number(e.procesado) === 4 ||
                    Number(e.procesado) === 5 ||
                    (Number(e.procesado) === 2 && (
                      <td className="tdBadged">
                        <Badged value="¡Listo!" style="badgedConExito" />
                      </td>
                    ))}
                 
                  {(Number(e.procesado) === 99 ||
                    Number(e.procesado) === 5 || (e.procesado < -959 && e.procesado > -1001 )) && (
                      <td className="tdBadged">
                        <Badged value="Error" style="badgedSinExito" />
                      </td>
                    )}
                  {Number(e.procesado) === 4 ||
                    Number(e.procesado) === 5 ||
                    Number(e.procesado) === 2 ? (
                    <td className="verticalIcon">
                      <Icon
                        value={Vertical}
                        onClick={(event) =>
                          handleVerticalIconClick(event, e)
                        }
                      />
                    </td>
                  ) : (
                    <td className="verticalIcon">
                      <Icon value={VerticalDisabled} />
                    </td>
                  )}
                </tr>
                {selectedRow === e && popUpVisible && (
                  <PopUpBP
                    amplify={false}
                    position={{
                      position: "fixed",
                      left: "103%",
                      top: (e) => { return e.clientX },
                    }}
                    onClose={() => {
                      handleClosePopUp();
                    }}
                    dataArchivo={e}
                    onDeleteClick={(dataArchivo) => {
                      setNameListFile(dataArchivo.name)
                      deletePopup(dataArchivo.token);
                    }}
                  />
                )}
              </tbody>
            ))}
          </table>
        }
        {activeTab === 3 &&
          <table>
            <thead>
              <tr>
                <th className="thAdminName nombreAdminThHeader">
                  <div
                    className={`thHeader `}
                  >
                    <p className="thFilter">Nombre</p>
                  </div>
                </th>
                <th className="thAdminPais">
                  <div
                    className={`thHeader`}
                  >
                    <p className="thFilter">Pais</p>
                  </div>
                </th>
                <th className="thFechaDeCreacion">
                  <div
                    className={`thHeader `}
                  >
                    <p className="thFilter">Fecha de creación</p>
                  </div>
                </th>
                <th className="thAdminEstado">
                  <div
                    className={`thHeader`}
                  >
                    <p className="thFilter">Estado</p>
                  </div>
                </th>
                <th className="thAdminBuyerPerson">
                  <div
                    className={`thHeader`}
                  >
                    <p className="thFilter">Buyer persona</p>
                  </div>
                </th>
                <th className="thAdminAmplify">
                  <div
                    className={`thHeader`}
                  >
                    <p className="thFilter">Amplify</p>
                  </div>
                </th>
                <th className="thAdminUserActive">
                  <div
                    className={`thHeader`}
                  >
                    <p className="thFilter">Usuarios activos</p>
                  </div>
                </th>
                <th className="thAdminActions iconAdminThHeader">
                  <p className="thFilter">Acciones</p>
                </th>
              </tr>
            </thead>
            <tbody className="tbody">
              {applyDateFilterCompanies().map((e, i) => (
                <tr key={i + "companies"} className={i === applyDateFilterCompanies().length - 1 ? "lastItemClass" : ``}>

                  <td>
                    <p className="tdName">{e.name}</p>
                  </td>
                  <td className="tdFlagTextAdmin">
                    <img src={e.country === "Chile" ? BanderaChile : BanderaMexico} alt={e.country === "Chile" ? "Bandera Chile" : "Bandera Mexico"} srcset="" />

                    <p className="tdIndustry">
                      {e.country}
                    </p>
                  </td>
                  <td className="tdShortPadding">
                    <p className="tdCreated">

                      {e.created_at.slice(0, 10)}
                    </p>
                  </td>

                  <td className='tdShortPadding'>
                    {/* <div className={`${e.active ? "activeUserOrganization" : "inactiveUserOrganization"}`} onClick={() =>{ toggleDropdown(e.id); }}>
                      <p>{e.active === 1 ? "Activo" : "Inactivo"}</p>
                      <img src={Expand} alt="flecha" srcset="" />
                    </div>
                    {dropdownStates[e.id] && (
                      <div className={`${!e.active ? "activeUserOrganizationPopUp" : "inactiveUserOrganizationPopUp"}`} style={{ position: "absolute",
                      paddingLeft: '2px',paddingRight: "7px", }} onClick={() => { setIdOrganization(e.id); setIdCountry(e.country_id); setNameOrganization(e.name); if (e.active) { setEliminarPopUp(true) } else { handleActiveData() } }}>
                        <p>{e.active !== 1 ? "Activar" : "Desactivar"}</p>
                      </div>
                    )} */}

<ReactSelect
  className="userOrganizationSelect"
  styles={customStylesSelect}
  value={{
    value: e.active,
    label: (
      <div className={`${e.active ? "activeUserOrganization" : "inactiveUserOrganization"}`}>
        <p>{e.active ? "Activo" : "Inactivo"}</p>
      </div>
    )
  }}
  options={[
    { value: true, label: (<div className="activeUserOrganization "><p> Activo</p></div>) },
    { value: false, label: (<div className=" inactiveUserOrganization "><p> Inactivo</p></div>) }
  ]}
  isSearchable={false}
  onChange={(selectedOption) => {
    setIdOrganization(e.id);
    console.log(e.id)
    setIdCountry(e.country_id);
    setNameOrganization(e.name);

    // Mostrar el popup solo si se está intentando desactivar el elemento
    if (!selectedOption.value) {
      setEliminarPopUp(true);
    } else {
      handleActiveData(e.country_id, e.id); // Llamar a la función para activar directamente si se está intentando activar
    }
  }}
  // Aquí evitamos que el select cambie cuando el popup está activo
  isDisabled={isDeleting}
/>


                  </td>

                  <td className="verticalIcon">
                    <p>{separadorDeMiles(e.buyer_persona)}</p>
                  </td>
                  <td className="verticalIcon">
                    <p>{separadorDeMiles(e.amplify)}</p>
                  </td>
                  <td className="verticalIcon">
                    <p>{e.cantidad_usuarios}</p>
                  </td>
                  <td className="verticalIcon" onClick={() => { navigate(`/administrador/${e.name}/${e.id}/${e.country_id}`) }}>
                    <img src={Edit} alt="imagen de lapiz" />
                  </td>
                </tr>

              ))} </tbody>
          </table>

        }

      </div>
      <Pagination
        List={companies.length > 0 ? listCompanies : isBuyerPersona ? listBuyer : listAmplify}
        currentPageProp={(e) => {
          setCurrentPageBuyer(companies.length > 0 ? undefined : isBuyerPersona ? e : undefined);
          setCurrentPageAmplify(companies.length > 0 ? undefined : isBuyerPersona ? undefined : e);
          setCurrentPageCompanies(companies.length > 0 ? e : undefined);
        }}
        tab={activeTab}
        rowsPerPageProp={companies.length > 0 ? itemsPerPageCompanies : isBuyerPersona ? itemsPerPageBuyer : itemsPerPageAmplify}
      />
    </div>
  );


  return (
    <>
      <div className="tablePrincipalContainer">
        <div className="tableContainer borde-radius-gris">
          {activeTab === 1 && renderTable(applyDateFilterBuyer(), true)}
          {activeTab === 2 && renderTable(applyDateFilterAmplify(), false)}
          {activeTab === 3 && renderTable(applyDateFilterCompanies(), false)}
        </div>
      </div>
      {eliminarPopup &&
        <div className="PopUpEliminar">
          <div className="PopUpEliminarContainer">
            {activeTab === 1 ?
              <>
                <h2>¿Seguro que quieres eliminar la lista {nameListFile}?</h2>
                <p>Ten en cuenta que esta acción eliminará permanentemente la lista seleccionada. ¿Estás seguro de que deseas continuar?</p>
              </>
              : activeTab === 2 ?
                <>
                  <h2>¿Seguro que quieres eliminar el amplify {nameListFile}?</h2>
                  <p>Ten en cuenta que esta acción eliminará permanentemente el Amplify seleccionado. ¿Estás seguro de que deseas continuar?</p>
                </>
                :
                <>
                  <h2>¿Seguro que quieres desactivar {nameOrganization}?</h2>
                  <p>Ten en cuenta que esta acción inhabilitará la organización seleccionada. ¿Estás seguro de que deseas continuar?</p>
                </>
            }<div className="PopUpEliminarContainerButton">

              <button className="PopUpEliminarContainerButtonElementCancelar" onClick={() => { setEliminarPopUp(false) }}>Cancelar</button>
              {!isDeleting ?
                <button className="PopUpEliminarContainerButtonElement" onClick={() => {
                  if (activeTab === 3) {
                    inactive()
                  } else { handleDeleteData() }
                }}>Desactivar</button> :
                <SpinnerProcesando />
              }
            </div>
          </div>
        </div>}
    </>
  );
};

export default TableContainer;
