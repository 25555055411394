import React from 'react';
import AcordeonText from './acordeonText/AcordeonText';
import AcordeonTextList from './acordeonText/AcordeonTextList';
import './CardFAQs.css';


const cardFAQs = () => {

  // Contenido AcordeonList 1
  const titleAcordeonListOne= '¿Que se necesita para implementar Tbrein en mi negocio?';
  const listContentAcordeonListOne = [
    'Tener claro que quieren acelerar comercialmente con campañas de marketing digital.',
    'Tener agencia de marketing (sino también contamos con el servicio de agencia).',
    'Presupuesto para la campaña de marketing digital.',
    'Base de clientes con RUT (para Chile) o CURP (para México) de lo definido que quieran acelerar comercialmente.',
  ];
  
  // Contenido AcordeonList 2
  const titleAcordeonListTwo= '¿Cuánto se demora en implementar esta herramienta?';
  const optionalTextListTwo= 'En solo 24 horas. Luego obtendrás:'
  const listContentAcordeonListTwo = [
    'Conocimiento de tu cliente/cc Person gracias al potente motor de IA que tenemos.',
    'Generación de target relacionado con tu objetivo comercial reflejado en Amplify.',
    'Carga en meta de tu audiencia hipersegmentada para aumentar las ventas.',
  ];

  return (
    <>
      <AcordeonText 
        title='¿Qué es un Buyer persona?' 
        text='Es el tipo de cliente ideal. Éste reúne todas las características que te garantizan una mayor probabilidad de atraer, convertir y generar acciones con menos esfuerzo y dinero.' />
      <AcordeonText 
        title='¿Qué es el Buyer persona?' 
        text='El Buyer persona o Buyer persona de datos, es la completitud de datos que se pueden obtener por rut, por ejemplo, renta presunta, edad, nivel de gastos, cantidad de propiedades, marca de vehículos, etc.'  />
      <AcordeonTextList title={titleAcordeonListOne} listItems={listContentAcordeonListOne} />
      <AcordeonTextList title={titleAcordeonListTwo} listItems={listContentAcordeonListTwo} optionalText={optionalTextListTwo} />
      <AcordeonText 
        title='¿Qué son los Amplify?' 
        text='Los Amplify son personas que comparten la misma descripción del Buyer persona, pero no son clientes actuales.'  />
      <AcordeonText 
        title='¿Para qué tipo de campañas me sirven los Amplify?' 
        text='Para una empresa de ecommerce, lo ideal sería de conversión que puede ser agregar al carro o ventas. Para una empresa como inmobiliaria y/o automotriz, lo ideal sería un formulario o bien tráfico hacia un formulario dentro de una página o landing.'  />
      <AcordeonText 
        title='¿Cuál es la cantidad mínima de Amplify?' 
        text='Para Meta la cantidad mínima son 5.000 mientras que para Google Ads son 30.000.'  />
      <AcordeonText 
        title='¿Cuanto es el presupuesto mínimo para una campaña en meta de Amplify?' 
        text='Lo ideal es que el presupuesto mensual sea de al menos $400.000.CLP'  />
      <AcordeonText 
        title='Si tengo una agencia ¿en que se diferencia una campaña de Amplify?' 
        text='Las campañas con uso de Amplify permite focalizar la inversión en marketing digital consiguiendo mejores resultados a un menor costo y esto se da ya que los Amplify vienen con variables que Meta no puede obtener como por ejemplo: Renta presunta, Nivel de consumo en un producto y/o servicio, cantidad de propiedades, marca de vehículos que manejan, etc.'  />
      <AcordeonText 
      hipervinculo={true}
        title='¿Con quién me puedo contactar en caso de que mis dudas no estén aquí?' 
        text='Si tienes otra duda, puedes conectarte con nosotros a'  />
      
    </>
  );
}

export default cardFAQs;
