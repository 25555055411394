import React from 'react';
import OhNoIMG from './Error404.svg'
import './ohnoimg.css'
const OhNOimg = () => {
    return (
        <>
            <img className='imgError404' src={OhNoIMG} alt="imagen de hombre preocupado" />
        </>
    );
}
export default OhNOimg;
