import React from 'react';

const Etiqueta = ({ cName, value}) => {
    return (
        <>
            <p className={cName}>{value}</p>
        </>
    );
}

export default Etiqueta;
