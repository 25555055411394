import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import './Bar.css'
const Bar = ({ options, height }) => {
    return (
        <Chart options={options.options} series={options.series} type="bar" height={height} className="rounded-chart" />
    );
};

export default Bar;
