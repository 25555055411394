import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";


export function Redirect() {
    const navigate = useNavigate();
    let token = localStorage.getItem('token')
    useEffect(() => {
        if (token === undefined) {
            navigate('/iniciar-sesion')
        } else if (window.location.pathname === '/' && token) {
            navigate('/inicio/buyer-persona')
        }
    }, [])
}

