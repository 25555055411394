import React, { useState } from "react";
import Navbar from "../navbar/menuVertical/navbar";
import Form from "./CargaDeArchivo/CargaDeArchivos";
import ProgressBar from "./BarraProgresiva/ProgressBar";
import "./BuyerPersonContainer.css";

const BuyerPersonContainer = () => {
  const [navbarData, setNavbarData] = useState(false);

  const estadoMenu = (data) => {
    setNavbarData(data);
  };

  return (
    <>
      <div className="containerBuyerPerson">
        <Navbar estado={estadoMenu} />

        <div
          className={`bayerPerson_body_container ${navbarData ? "navbar-open" : ""}`}
          style={{ marginLeft: navbarData ? "228px" : "80px" }}
        >
          <header className="headerBuyerPerson">
            {/* Progress Bar */}
            <ProgressBar activeBar={0} />
          </header>

          <main className="mainBuyerPerson">


            {/* Contenido dinamico */}
            <Form />
          </main>
        </div>
      </div>
    </>
  );
};

export default BuyerPersonContainer;
