import React, { useState } from 'react';
import downArrow from '../acordeonText/downArrow.png'

import './AcordeonText.css';

const Card = ({ title, text, hipervinculo}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="cardAcordeon">
      <div className="card-header">
        <h2>{title}</h2>
        <button onClick={() => setOpen(!open)}>
          <img src={downArrow} alt="Fecha abajo" />
        </button>
      </div>
      <div className="card-body" hidden={!open}>
        {hipervinculo ? 
        <div className='card-body-hipervinculo'> 
        <p>{text}</p>
        <button
    className="modalMasCreditosTextEnlace"
    id='card-body-hipervinculo-text'
    onClick={() => (window.open('mailto:adelahoz@xbrein.com', '_blank'))}
  >{" " +  "adelahoz@xbrein.com"}
  </button>
  </div>
       : <p>{text}</p>}
      </div>
    </div>
  );
};

export default Card;