import React from "react";
import SliderBar from "./sliderBarGenero/sliderBarGenero"
import "./cardSliderBarGeneroAmplify.css";

const CardSliderBarGenero = ({genderData}) => {
  const handleDataGenerated = (data) => {
    genderData(data);
  };

  return (
    <>
      <div className="cardSliderBarGeneroContainer">
        <p className="cardSliderBarGeneroTitle">Proporción de genero</p>
        <p className="cardSliderBarGeneroTexto">Indica la proporción de género para potenciar tu búsqueda</p>

        <SliderBar gender={handleDataGenerated}/>
      </div>
    </>
  );
};

export default CardSliderBarGenero;
