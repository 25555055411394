import React from 'react';
import OhNoImage from './ohNOimg'
import { useNavigate } from 'react-router-dom';
import ButtonLink from '../../../ComponentesMoleculas/ButtonLink';
import './inMaintenanceCard.css'

const InMaintenanceCard = () => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1); // Regresa a la página anterior
    };

    return (
        <div className='inMaintenanceCard'>
            <OhNoImage className='img' />
            <div className='inMaintenance_container'>
                <h1>En mantenimiento</h1>
                <p className='textInMaintenance'>
                    Disculpa las molestias. <br />
                    Nuestro sitio esta actualmente bajo mantenimiento
                    programado. Agradecemos tu comprensión.
                </p>
            </div>
            <ButtonLink cName={'btnPrimary'} value={'Volver atrás'} onClick={handleGoBack}/>
        </div>
    );
}

export default InMaintenanceCard;
