import React from 'react';
import './modalTablaError.css';

const ModalTablaError = ({ position }) => {
  return ( 
    <>
      <div className='amplifyModalTablaError' style={position}>
        <p>
          La cantidad solicitada es mayor a la encontrada. <br /> 
          Debes modificarla para poder avanzar.
        </p>
      </div>
    </>
  );
};

export default ModalTablaError;
