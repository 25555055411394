import React from 'react';
import Card from './cardLoader/cardLoader'
import './loader.css'
const Loader = () => {
  return (
    <>
      <div className='bodyLoader' >
        <Card />
      </div>
    </>
  );
};
export default Loader;