import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../Context/Context';
import imgConfirmacionBP from './img/ImgConfirmacionBP.svg';
import IconNameList from './img/iconNameList.svg';
import IconOrganization from './img/IconOrganization.svg';
import separadorDeMiles from '../../SeparadorDeMiles/SeparadorDeMiles';
import './Confirmacion.css';
import axiosInstance from '../../AxiosInterceptor/AxiosInterceptor';

const Confirmacion = () => {
  const { country } = useContext(AppContext);
  const [cursor, setCursor] = useState([]);
  const [xlsxData, setXlsxData] = useState([]);
  const [data, setData] = useState('');
  const [isNegative, setIsNegative] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('xlsx')) {
      setCursor(JSON.parse(localStorage.getItem('xlsx')).cursor);
    }
    if (localStorage.getItem('enrData')) {
      setXlsxData(JSON.parse(localStorage.getItem('enrData')))
    }
    if (localStorage.getItem('columnsSelection')) {
      setData(JSON.parse(localStorage.getItem('columnsSelection')))
    }
  }, []);
  useEffect(() => {
    // Verificar si data y data.datos_invoice están definidos
    if (data && data.datos_invoice) {
      if (parseFloat(data.datos_invoice.restante) < 0) {
        setIsNegative(true);
      } else {
        setIsNegative(false);
      }
    }
  }, [data]);

  let confirm = () => {
    axiosInstance.post(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/enrichment/${localStorage.getItem('lmnkknn')}`,
      {
        'csrf_token': localStorage.getItem('csrf'),
        'confirm': 'y',
        'descargar': '',
      },
    
    ).then((e) => { navigate('/inicio/buyer-persona') }).catch((e) => {
      if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
        localStorage.clear();
        navigate('/iniciar-sesion')
      }
      navigate('/inicio/buyer-persona')
    })
  }

  return (
    <div className=''>
      <div className='Confirmacion borde-radius-gris'>
        <div className='Confirmacion_FormContainer'>
          {cursor.reset_day &&
            <>
              <div className={`imgConfirmacionBP ${isNegative ? 'negativeBackground' : ''}`}>
                <img src={imgConfirmacionBP} alt="" />
              </div>
              <div className='Confirmacion_resumen'>
                <h1 className='Confirmacion_title'>Resumen de tu solicitud</h1>
                <div className='Confirmacion_box'>
                  <div className='Confirmacion_boxText'>
                    <div className='Confirmacion_text'>
                      <div className='Confirmacion_textIcon'>
                        <img src={IconNameList} alt="Name_list" />
                      </div>
                      <p>{xlsxData.fname}</p>
                    </div>
                    <div className='Confirmacion_text'>
                      <div className='Confirmacion_textIcon'>
                        <img src={IconOrganization} alt="Organización" />
                      </div>
                      <p>{xlsxData.organization}</p>
                    </div>
                  </div>
                  <div className='Confimacion_line'></div>
                  <div className='Confirmacion_boxCreditos'>
                    <div className='Confirmacion_Creditos'>
                      <p className='TextCred'>Disponibles</p>
                      <p className='Creditos'>{separadorDeMiles(parseFloat(data.datos_invoice.restante) + data.datos_invoice.auxiliar2)}</p>
                    </div>
                    <p className='signsCred'>-</p>
                    <div className='Confirmacion_Creditos'>
                      <p className='TextCred'>A procesar</p>
                      <p className='Creditos'>{separadorDeMiles(data.datos_invoice.a_descargar)}</p>
                    </div>
                    <p className='signsCred'>=</p>
                    <div className='Confirmacion_Creditos'>
                      <p className='TextCred'>Te quedarán</p>
                      <p className={`Creditos ${isNegative ? 'negativeCred' : ''}`}>{separadorDeMiles(data.datos_invoice.restante)}</p>
                    </div>
                  </div>
                  {isNegative && (
                    <p className={isNegative ? 'negativeText' : ''}>
                      Tus créditos no son suficientes
                    </p>
                  )}
                </div>
              </div>
            </>}
        </div>

        <div className='Confirmacion_botones ConfirmacionBP'>
          <button onClick={() => { navigate('/buyer-persona-carga-archivo') }} className={'btnSecondary_ConfirmacionBP'} value={'Cancelar'}  >Cancelar</button>
          <button className={'btnPrimary_ConfirmacionBP'} onClick={(() => { confirm() })}>Generar</button>
        </div>
      </div>
    </div>
  );
}

export default Confirmacion;
