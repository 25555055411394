import Img from "./loginError.svg"
import React, { useEffect, useState } from 'react';
import ButtonLink from '../../ComponentesMoleculas/ButtonLink';
import { useNavigate } from 'react-router-dom';
import './loginError.css'

const LoginError = () => {
  const [detail, setDetail] = useState({})
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // Regresa a la página anterior
  };
  useEffect(()=>{setDetail(JSON.parse(localStorage.getItem('err'))); console.log(localStorage.getItem('err'))},[localStorage.getItem('err')])
  return (
    <div className="loginError">
      {detail &&
      <div className='loginErrorCard'>
        <img src={Img} alt="" />
        <div className='loginError_container'>
          <h1>{detail.detail}</h1>

          <p className='textLoginError'>
            {detail.text} 
          </p>
       
        </div>
        <ButtonLink cName={'btnPrimaryLoginError'} value={'Volver atrás'} onClick={handleGoBack} />
      </div>}
    </div>

  );
}

export default LoginError;
