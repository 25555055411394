import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/Context";
import Etiqueta from "../../ComponentesMoleculas/Etiqueta";
import Toggle from "../../enrichment/inputs/selectToggle";
import InfoIcon from "./img/infoIcon.png";
import InputItem from "../../ComponentesMoleculas/InputItem";
import NubeDeCarga from "./img/upload.png";
import Select from "react-select";
import "./CargaDeArchivos.css";
import SpinnerGris from "../../ComponentesMoleculas/Loader/SpinnerGris";
import { TooltipBP } from "./tooltips/TooltipBP";
import axiosInstance from "../../AxiosInterceptor/AxiosInterceptor";
const CargaDeArchivos = () => {
  const axios = require('axios').default
  const navigate = useNavigate();
  const { country, items } = useContext(AppContext);
  const [value, setValue] = useState("");
  const [company, setCompany] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [segment, setSegment] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [forceSearch, SetForceSearch] = useState(false);
  const [file, setFile] = useState("");
  const [object, setObject] = useState({});
  const [isNameEntered, setIsNameEntered] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const fileInputRef = useRef(null);
  const [filteredSegments, setFilteredSegments] = useState([]);

 
  

  useEffect(() => {
    if (
      (selectedIndustry === "Natural" || forceSearch === false) &&
      items === 43
    ) {
      setObject({
        fname: value,
        file: file,
        industry: "Natural",
        segment: "Natural",
        check_segment: "on",
        organization:localStorage.getItem("companyFilter"),

      });
    } else {
      setObject({
        fname: value,
        file: file,
        industry: selectedIndustry ? selectedIndustry.value : "Natural",
        segment: selectedSegment ? selectedSegment.value : "Natural",
        check_segment: "off",
        organization: localStorage.getItem("companyFilter"),
      });
    }
  }, [value, selectedIndustry, selectedSegment, forceSearch, file]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#475467",
      fontSize: "12px",
      fontFamily: "Inter",
      fontWeight: "400",
      backgroundColor: "white", // Cambia el color de fondo al hacer hover
      "&:active": {
        backgroundColor: "white", // Evita que haya un color de fondo al hacer clic en la opción
      },
      "&:hover": {
        backgroundColor: "#EFF8FF", // Cambia el color de fondo al hacer hover
      },

    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#98A2B3',
      fontSize: '12px',
      fontWeight: "400",
      fontFamily: "Inter",
    }),
    control: (base, state) => ({
      ...base,
      textTransform: 'capitalize', // Esto hará que el texto en el control también tenga la primera letra en mayúscula
      borderColor: state.isFocused ? '#F5A973' : '#D0D5DD',
      outline: 'none',
    }),
  };
  const formatOptionLabel = ({ value, label }, { context }) => (
    <div style={{ textTransform: 'capitalize' }}>
      {label}
    </div>
  );

  const handleInputChange = (event) => {
    const name = event.target.value;
    setValue(name);
    setIsNameEntered(name !== "");
  };

  const handleSelectIndustryChange = (selectedOption) => {
    setSelectedIndustry(selectedOption);

    const filteredSegmentOptions = segment.filter(
      (seg) => seg.industry === selectedOption.value
    );
    setFilteredSegments(filteredSegmentOptions);
    setSelectedSegment(null);
  };

  const handleCheck = (event) => {
    SetForceSearch(!forceSearch);
  };

  const handleSelectSegmentChange = (selectedOption) => {
    setSelectedSegment(selectedOption);
  };

  useEffect(() => {
    if (country) {
      axiosInstance.get(
        `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/enrichment/uploads`,
)
        .then((e) => {
          setCompany(e.data.df_organization);
          setIndustry(e.data.df_indust);
          setSegment(e.data.df_segment);
          setSelectedSegment(e.data.df_segment[0].segment);
        })
        .catch((e) => {
          if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
            localStorage.clear();
            navigate("/iniciar-sesion");
          }else if ( e.response && e.response.status && e.response.status === 302){
            navigate('/acceso-denegado')
        }
          console.log(e.response.status);
    });
    }
  }, [country]);

  const xlsx = () => {
    axios.post(
        `${
          process.env.REACT_APP_URL
        }/api/role/${localStorage.getItem('selectedCountry')}/enrichment/mapping/${localStorage.getItem(
          "lmnkknn"
        )}`,
        object,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((e) => {
        localStorage.setItem("enrData", JSON.stringify(object));
        localStorage.setItem("xlsx", JSON.stringify(e.data));
        navigate("/buyer-persona-configuracion");
      })
      .catch((e) => {
        if (e.response.status === 401 || e.response.status === 422) {
          localStorage.clear();
          navigate("/iniciar-sesion");
        }
        setUploadError(true);
      });
  };


  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.classList.add("drag-over");
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.classList.remove("drag-over");
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.classList.remove("drag-over");

    const droppedFile = event.dataTransfer.files[0];
    if (
      droppedFile.type === "application/vnd.ms-excel" || droppedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setExcelFile(droppedFile);
      setFile(droppedFile);
    } else {
      alert("Por favor, selecciona un archivo de Excel válido.");
    }
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile.type === "application/vnd.ms-excel" || selectedFile.type ==="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setExcelFile(selectedFile);
      setFile(selectedFile);
    } else {
      alert("Por favor, selecciona un archivo de Excel válido.");
    }
  };

  return (
    <div className="formContainer borde-radius-gris">
      {company.length > 0 ? (
        <>
          <div className="contentContainer">
            <div className="contenedorIngresaTuBaseDeDatos">
              <div className="segmentacionContenedorPrincipal">
                <div className="containerBPTitleSelects">
                  <div className="containerTextOne">
                    <h1 className="buyerPersona-title">
                      Ingresa tu base de datos
                    </h1>
                    <div className="inputsBuyerPersona">
                      <div className="inputContainer">
                        <Etiqueta cName="textInputContainerBP" value="Nombre" />
                        <InputItem
                          cNameLabel="InputConfig borde-inputs-generales"
                          value={"Nombre de tu lista"}
                          onInputChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  {items !== 135 && (
                    <div className="toggleContainer">
                      <Toggle handleCheck={handleCheck} />
                      <p className="toggleText">
                        Quiero indicar la industria y segmento de mis clientes
                      </p>
                      <TooltipBP
                        classNameContainer={"TooltipBP"}
                        classNameTool={"TooltipBPText"}
                        text={
                          "Indicarnos la industria y segmento al que perteneces nos permitirá entregarte un Buyer persona consistente con tu mercado."
                        }
                      >
                        <img
                          src={InfoIcon}
                          alt="Icono de información"
                          className="infoIcon"
                        />
                      </TooltipBP>
                    </div>
                  )}
                </div>

                <div className="segmentacionContainer">
                  {(forceSearch || items === 135) && (
                    <>
                      <div className="inputContainer">
                        <Etiqueta
                          cName="textInputContainer"
                          value="Industria"
                        />
                        <Select
                          options={industry.map((ind) => ({
                            value: ind.industry,
                            label: ind.industry.toLowerCase(),
                          }))}
                          value={selectedIndustry}
                          onChange={handleSelectIndustryChange}
                          placeholder="Selecciona una industria"
                          styles={customStyles}
                          formatOptionLabel={formatOptionLabel}

                        />
                      </div>
                      <div className="inputContainer">
                        <Etiqueta
                          cName="textInputContainer "
                          value="Segmento"
                        />
                        <Select
                          options={filteredSegments.map((seg, index) => ({
                            value: seg.segment,
                            label: seg.segment.toLowerCase(),
                          }))}
                          value={selectedSegment}
                          onChange={handleSelectSegmentChange}
                          placeholder="Selecciona un segmento"
                          styles={customStyles}
                          formatOptionLabel={formatOptionLabel}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="uploadContainer"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleFileInputClick}
          >
            <p className="textCargaTuArchivo">Carga tu archivo aquí</p>
            <div className="boxCargarArchivos">
              <img src={NubeDeCarga} alt="imagen de nube con flecha" />
              <div className="textContainerUpload">
                <div className="textUpload">
                  <p className="textUploadSelect">Selecciona el archivo</p>
                  <p className="textUpload">XLSX para cargar</p>
                </div>
                <p className="fileName">
                  {excelFile
                    ? excelFile.name
                    : "Tu archivo no debe superar los 120mb."}
                </p>
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
              </div>
            </div>
          </div>
          {uploadError && <p>Se produjo un error, vuelve a intentarlo</p>}

          <div className="buttonsContainerPaso">
            <button
              onClick={() => {
                navigate("/inicio/buyer-persona");
              }}
              className={"btnSecondary"}
            >
              Atrás
            </button>
            {!isNameEntered ||
              !file ||
              (items === 135 &&
                (!selectedIndustry ||
                  selectedIndustry.value === "Natural" ||
                  !selectedSegment ||
                  selectedSegment.value === "Natural")) ? (
              <button className={"btnDisabled"} disabled>
                Siguiente
              </button>
            ) : (
              <button
                className={"btnPrimaryBuyerPersona"}
                onClick={() => {
                  xlsx();
                }}
                disabled={!isNameEntered || !file}
              >
                Siguiente
              </button>
            )}
          </div>
        </>
      ) : (
        <SpinnerGris />
      )}
    </div>
  );
};

export default CargaDeArchivos;
