import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../Context/Context';
import BanderaChile from '../navbarAssets/chile.png';
import BanderaMexico from "../navbarAssets/mexico.png";
import './EnterpriceList.css'

const BoxNavbar = ({ expanded }) => {
  const navigate = useNavigate();
  const [companias, setCompanias] = useState([]);
  const { setCountry, setCompanyFilter, setItems } = useContext(AppContext);
  const [isBoxOpen, setIsBoxOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const boxRef = useRef(null);


  const CompaniesEffect = localStorage.getItem('companies')
  useEffect(() => {

    let companies = JSON.parse(localStorage.getItem('companies'))
    const copiaArray = companies.map((company) => {
      let flagURL;
      if (company.country_id === 43) {
        // Establecer la URL de la bandera para Chile
        flagURL = BanderaChile;
      } else if (company.country_id === 135) {
        // Establecer la URL de la bandera para México
        flagURL = BanderaMexico;
      }
      return {
        value: { name: company.name, country: company.id, flag: flagURL, country_id: company.country_id },
        label: company.name,
        flag: flagURL, // Agregar la URL de la bandera a la opción
      }
    });


    setCompanias(copiaArray.sort((a, b) => a.value.name.localeCompare(b.value.name)));
  }, [CompaniesEffect]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsBoxOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isBoxOpen) {
    return null;
  }

  const handleChangeCompany = ({ value }) => {
    setItems(value.country_id);
    setCompanyFilter(value.name)
    localStorage.setItem('companyFilter', value.name)
    localStorage.setItem('companyFilter', value.name)
    localStorage.setItem('flag', JSON.stringify(value.flag))
    localStorage.setItem('selectedItems', value.country_id)
    const ctydato = JSON.parse(localStorage.getItem("cty"))

    const objetoEncontrado = ctydato.find(obj => obj.country_id ===  value.country_id);
    if (objetoEncontrado) {
      localStorage.setItem('selectedCountry', objetoEncontrado.id)
    }

    navigate('/inicio/buyer-persona');
  };
  function capitalizarTexto(cadena) {
    const palabrasExcepcion = ["de", "organización"];
    const palabras = cadena.toLowerCase().split(' ');

    const resultado = palabras.map(palabra => {
      if (palabra === "s.a.") {
        return "S.A.";
      } else if (palabra === 'org') {
        return "Org."
      } else if (!palabrasExcepcion.includes(palabra)) {
        return palabra.charAt(0).toUpperCase() + palabra.slice(1);
      } else {
        return palabra;
      }
    });

    return resultado.join(' ');
  }


  const filteredCompanies = companias.filter(company =>
    company.value.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {(companias && companias[0] && companias[0].flag) &&
        <div className={`ListEnterpricesBox ${expanded ? 'expanded' : 'closed'}`} ref={boxRef}>
          <div className='ListEnterpricesInputs'>
            <input
              type="text"
              placeholder="Buscar Organización"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className='InputListEnterpricesBox'
            />
          </div>
          {filteredCompanies.length > 0 && filteredCompanies.map((e) => {
            return (
              <div className='ListEnterprices'>
                <button
                  className='ListEnterpricesButton'
                  onClick={() => { handleChangeCompany(e) }}
                >
                  {e.value.country_id === 43 ? <img src={BanderaChile} alt='bandera de Chile' className='imagenNavEnterPrice' /> : <img src={BanderaMexico} alt='Bandera de Mexico' className='imagenNavEnterPrice' />}
                  <p>   {e.value.name.length > 32 ? `${capitalizarTexto(e.value.name).slice(0, 32)}...` : capitalizarTexto(e.value.name)}</p>
                </button>
              </div>

            )
          })}
        </div>
      }
    </>
  )
}

export default BoxNavbar;
