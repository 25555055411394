import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const DoughnutChart = ({ chartData }) => {
    return (
        <div className="doughnut-chart">
            <Chart options={chartData.options} series={chartData.series} className='.apexcharts-data-labels' type="donut" height="350" />
        </div>
    );
};

export default DoughnutChart;
