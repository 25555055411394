import React, { useEffect } from "react";
import ImgFour from "../assets/imgFourWalktrough.svg";
import { useNavigate } from "react-router-dom";
import "./walkthrough.css";
import axiosInstance from "../../AxiosInterceptor/AxiosInterceptor";

const WalkthroughSlider = () => {
  const navigate = useNavigate()
    
  let onBoarding = () => {
    axiosInstance.post(`${process.env.REACT_APP_URL}/api/functions/show`,
    {"update":"onboarding",
    "value": true},
    ).then((e) => {  navigate('/inicio/buyer-persona') })
    .catch(({ e }) => { console.log(e) });
  }
  
  useEffect(() => {
    localStorage.setItem("onBoarding", true);
  }, []);


  return (
    <div className="walkthrough Slider body_flex screenFour borde-radius-gris">
      <img src={ImgFour} alt="imagen de hombre preocupado" />
      <div className="walkthrough_text_container">
        <h2>¿Comenzamos?</h2>
        <p>
          Logremos resultados increíbles. <br /> ¡Tbrein y tu negocio juntos para
          seguir creciendo!
        </p>
      </div>
  
      <button className="buttonWalkthrough" onClick={()=>{ onBoarding()}}>Comenzar</button>

    </div>
  );
};

export default WalkthroughSlider;
