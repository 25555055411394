import React, { useState,useContext } from "react";
import Navbar from "../../navbar/menuVertical/navbar";
import ProgressBar from "../BarraProgresivaAmplify/ProgressBar";
import AmplifyContenedorTabla from "../AmplifyContenedor/AmplifyTablaContenedor";
import "./amplifyEstilosGenerales.css";

const  AmplifyAutomaticoTabla = () => {

  const [navbarData, setNavbarData] = useState(false);
  const estadoMenu = (data) => {setNavbarData(data);};
  let handleBackground = (e) =>{
  }
  return (
    <>
      <div className="containerAmplify">
        <Navbar estado={estadoMenu} />
        
        <div
          className={`amplify_body_container ${navbarData ? "navbar-open" : ""}`}
          style={{ marginLeft: navbarData ? "228px" : "80px" }}
        >
          <header className="headerAmplify">
            {/* Progress Bar */}
            <ProgressBar activeBar={2} />
          </header>

          <main className="mainAmplify">

            {/* Contenido dinamico */}
            <AmplifyContenedorTabla background={handleBackground}/>
          
          </main>
        </div>
      </div>
    </>
  );
};

export default AmplifyAutomaticoTabla;
