import React from 'react';

const InputItem = ({ cNameLabel, value, onInputChange }) => {
  const handleInput = (event) => {
    onInputChange(event);
  };

  const placeholderStyle = {
    color: 'var(--gris-textos, #707477)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    letterSpacing: '0.24px',
  };

  return (
    <>
      <input
        type="text"
        className={cNameLabel}
        placeholder={value}
        onChange={handleInput}
        style={placeholderStyle}
      />
    </>
  );
};

export default InputItem;
