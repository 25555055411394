import React from 'react';
import Chart from 'react-apexcharts';

const MixedChart = ({ chartData }) => {
    // const [isLoading, setIsLoading] = useState(true);
    
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setIsLoading(false);
    //     }, 1000); 
    //     return () => clearTimeout(timer);
    // }, []);

    return (
        <div className="mixed-chart">
        {/* { !isLoading &&  */}
            <Chart options={chartData.options} series={chartData.series} className='.apexcharts-data-labels' type="line" height={350} />
        {/* } */}
        </div>
    );
};

export default MixedChart;
