import React, { useContext, useEffect, useState } from "react";
import { TablaProvider } from "../AmplifyContenedor/contextTabla/TablaContext";
import { AppContext } from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import CardResumenTabla from "./cardsTabla/cardResumenTabla";
import CardDetalleTabla from "./cardsTabla/cardDetalleTabla";
import axiosInterceptor from '../../AxiosInterceptor/AxiosInterceptor'
import ModalTablaExito from "./modales/modalTablaExito";
import "./AmplifyTablaContenedor.css";

const AmplifyContenedorTabla = ({ background }) => {
  const navigate = useNavigate();
  const { tkn, country, buttonLog, detalleButton, buyerClonTkn } = useContext(AppContext);
  const [dataObj, setDataObj] = useState(undefined);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [dataAmplify, setDataAmplify] = useState([]);
  const [buttonState, setButtonState] = useState(true);
  const [modal, setModal] = useState(false)
  
  useEffect(() => { background(modal) }, [modal])
  let handleClose = () => {
    setModal(false)
  }
  useEffect(() => {
    setButtonDisabled(detalleButton);
    setButtonState(buttonLog);
  }, [detalleButton, buttonLog]);

  const getAmplify = async () => {
    if (tkn !== undefined && buyerClonTkn !== undefined) {
      try {
        const response = await axiosInterceptor.get(
          `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/preview/${tkn}/${buyerClonTkn}`,
        
        );
        setDataAmplify(response.data.log2);
        if (response.data.log2 < 1) {
          navigate("/amplify-solicitud-clones");
        }
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 422) {
          localStorage.clear();
          navigate('/iniciar-sesion')
        }
      }
    }
  };

  useEffect(() => {
    if (country) {
      getAmplify();
    }
  }, [country]);

  const handleData = (data) => {
    setDataObj(data);
  };


  const actualizarObjetos = () => {
    const nuevosObjetos = dataAmplify.map((objeto) => {
      if (objeto.n_final > objeto.resultados) {
        return { ...objeto, n_final: objeto.resultados };
      } else {
        return objeto;
      }
    });

    setDataAmplify(nuevosObjetos);
  };

  useEffect(() => {
    actualizarObjetos();
  }, [dataAmplify[0]]);

  return (
    <>
    <TablaProvider>
      <div className="amplifyTablaContainer borde-radius-gris">
        <div className="amplifyTablaContainerText">
          <h2>Detalle de solicitud</h2>
          <p>
            A continuación te detallamos el resultado de tu búsqueda. Confirma
            las cantidades para potenciar tu campaña.
          </p>
        </div>
        <div className="amplifyTablaContainerCards">
          <CardResumenTabla dataAmplify={dataAmplify} />
          <CardDetalleTabla dataObject={handleData} dataAmplify={dataAmplify} />
        </div>
        <div className="amplifyTablaContainerButtons">
          <button onClick={() => { navigate('/amplify-solicitud-clones') }} className={'btnSecondary'} >Atrás</button>

          {!buttonDisabled && buttonState ? (
            <button onClick={() => { setModal(true) }} className="btnPrimary">Confirmar </button>
          ) : (
            <button className="btnDisabled" disabled> Confirmar</button>
          )}
        </div>
    
      </div>

    </TablaProvider >
        {modal && (
          <div className={"greyBackgroundModal"}>
            <div className={"ModalExitoDetalle"}>
              <ModalTablaExito dataObj={dataObj} handleClose={handleClose} />
            </div>
          </div>
        )}
    </>
  );
};

export default AmplifyContenedorTabla;
