import React, { useEffect, useState, useRef } from "react";
import ChartGraphClient from "./ChartsGraphics/ChartsGraphicsCL";
import ChartDataDoughnut from "./ChartsDobleDonut/ChartsDobleDonut";
import Pie from "./PieCharts/PieCharts";
import Select from "react-select";
import separadorDeMiles from "../SeparadorDeMiles/SeparadorDeMiles";
import MixedChart from "./MixedChart/MixedChart";
import { useParams } from "react-router-dom";
import Bar from "./Bar/Bar";
import Navbar from "../navbar/menuVertical/navbar";
import ChartsBarsNoLibrary from './BarsNoLibrary/BarsNoLibrary'
import "./ChartsCL.css";
import axiosInstance from "../AxiosInterceptor/AxiosInterceptor";
import ReactApexChart from "react-apexcharts";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import DownloadImg from '../home/PopUp/assets/descargar.svg'
import SpinnerSVG from '../home/PopUp/assets/spinner.svg'
import accountCircle from './imagesCl/account_circle.svg'
import accountCircleOne from './imagesCl/account_circle-one.svg'
import accountCircleTwo from './imagesCl/account_circle-two.svg'
import accountCircleThree from './imagesCl/account_circle-three.svg'
import accountCircleFour from './imagesCl/account_circle-four.svg'
import accountCircleFive from './imagesCl/account_circle-five.svg'
import IconCLChartButton from './imagesCl/IconCLChartButton.svg'
import barChartSvg from './imagesCl/bar_chart.svg'
import barChartSvgColor from './imagesCl/bar_chart_color.svg'
import domtoimage from 'dom-to-image';


const ChartsCL = () => {
  const ref = useRef(null);
  let { id } = useParams();
  const [values, setValues] = useState([]);
  const [profesionNumberText, setProfesionNumberText] = useState(0);
  const [chartData, setChartData] = useState({});
  const [chartDataNse, setChartDataNse] = useState({});
  const [chartDataDoughnut, setChartDataDoughnut] = useState({});
  const [chartDataSexo, setChartDataSexo] = useState({});
  const [chartDataBenchmark, setChartDataBenchmark] = useState({});
  const [selectGraph, setSelectGraph] = useState({});
  const [chartEdadBenchmark, setChartEdadBenchmark] = useState({});
  const [selectBenchEdad, setselectBenchEdad] = useState([]);
  const [handleSelectChange, setHandleSelectChange] = useState("TOTAL");
  const [chartComunaBenchmark, setChartComunaBenchmark] = useState({});
  const [vehiculos, setVehiculos] = useState({});
  const [tasacion_comercial, setTasacion_comercial] = useState({});
  const [region, setRegion] = useState({});
  const [vehiculosGse, setVehiculosGse] = useState({});
  const [viviendasBar, setViviendasBar] = useState({});
  const [vehiculoBar, setVehiculoBar] = useState({});
  const [viviendasDoughnut, setViviendasDoughnut] = useState({});
  const [viviendasDoughnutMonto, setViviendasDoughnutMonto] = useState({});
  const [buyerPerson, setBuyerPerson] = useState("");
  const [canastaSearch, setCanastaSearch] = useState([]);
  const [stackedColumn, setStackedColumn] = useState({});
  const [profile, setProfile] = useState({});
  const [colorsGraphics] = useState(["#051F63", "#313EAD", "#4361EE", "#8338EC", "#7209B7", "#B54AA6", "#ED448A", "#FF5563", "#FF7E46", "#FFC700", "#FFD60A"]);
  const [colorsGraphicsOrder] = useState(["#FFD60A", "#FFC700", "#FF7E46", "#FF5563", "#ED448A", "#B54AA6", "#7209B7", "#8338EC", "#4361EE", "#313EAD", "#051F63"])
  const [colorsGSE] = useState(["#155ADF", "#15CEE8", "#FFD80B", "#FF9D30", "#ED0574"]);
  const [colorsGSEReverse] = useState(["#ED0574", "#FF9D30", "#FFD80B", "#15CEE8", "#155ADF"]);
  const [navbarData, setNavbarData] = useState(false);
  const [barAgedGse, setBarAgeGse] = useState({});
  const [barProfesiondGse, setBarProfesionGse] = useState({});
  const [distribucionComunasText, setDistribucionComunasText] = useState(0);
  const [distribucionPComunasText, setDistribucionPComunasText] = useState(0)
  const [selectBarGraph, setSelectBarGraph] = useState({});
  const [vehiculosGseText, setVehiculosGseText] = useState(0);
  const [percentF, setPercentF] = useState([])
  const [percentM, setPercentM] = useState([])
  const [rutM, setRutM] = useState([])
  const [rutF, setRutF] = useState([])

  const [textState, setTextState] = useState(true)
  const [clickState, setClickState] = useState(0)
  const [ultimoPeso, setUltimoPeso] = useState(0)
  const [sumaComuna, setSumaComuna] = useState({})

  // const downloadPdf = () => {
  //   setTextState(false);
  
  //   const input = document.getElementById('pdf-content');
  //   const captureWidth = input.scrollWidth;
  //   const captureHeight = input.scrollHeight;
  
  //   // Esperar a que las fuentes se carguen
  //   document.fonts.ready.then(() => {
  //     html2canvas(input, {
  //       scale: 2, 
  //       useCORS: true
  //     }).then((canvas) => {
  //       const imgData = canvas.toDataURL('image/jpeg');
  //       const pdf = new jsPDF('p', 'px', [captureWidth, captureHeight]);
  //       pdf.addImage(imgData, 'JPEG', 0, 0, captureWidth, captureHeight);
  //       pdf.save(`Tablero_${values.resumen_carga.enrichment_name}.pdf`);
  //       setTextState(true);
  //     });
  //   });
  // };
  const downloadPdf = () => {
    setTextState(false);

    if (ref.current) {
      domtoimage.toJpeg(ref.current, { 
        quality: 1, // Opcional: Ajusta la calidad de la imagen JPEG (0 a 1)
        bgcolor: '#ffffff', // Opcional: Fondo blanco para la imagen
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight
      }).then((dataUrl) => {
        // Crea un enlace para descargar la imagen
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `Tablero_${values.resumen_carga.enrichment_name}.jpeg`;

        // Simula el clic en el enlace para iniciar la descarga
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setTextState(true);
      }).catch((error) => {
        console.error('Error al generar la imagen:', error);
      });
    }
  };
  const normalizer = (val) => {
    return val.replace(",", "").replace(".", "");
  };

  const estadoMenu = (data) => {
    setNavbarData(data);
  };

  useEffect(() => {
    axiosInstance.get(
      `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem(
        "selectedCountry"
      )}/enrichment/summary/${id}`,
    )
      .then((e) => {
        setValues(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [id]);

  useEffect(() => {
    if (values.resumen_carga) {
      //--------------  Nivel porcentual de completitud  --------------
      let nombresValoresOrdenados = [];
      let valoresOrdenados = [];



      if (values.resumen_carga && values.resumen_carga.completitud) {

        const completitud = values.resumen_carga.completitud;
        const objetos = Object.keys(completitud).map((key) => completitud[key]);
        const nombresPropiedades = objetos.map((objeto) => objeto.name);
        const valoresPropiedades = objetos.map((objeto) => objeto.value);

        nombresValoresOrdenados = nombresPropiedades.sort((a, b) => {
          const valorA = valoresPropiedades[nombresPropiedades.indexOf(a)];
          const valorB = valoresPropiedades[nombresPropiedades.indexOf(b)];
          return valorB - valorA;
        });

        valoresOrdenados = nombresValoresOrdenados.map(
          (nombre) => valoresPropiedades[nombresPropiedades.indexOf(nombre)]
        );

        valoresOrdenados.sort((a, b) => b - a);
      }
      const rutPropiedades = Object.keys(values.resumen_carga.completitud).map((key) => values.resumen_carga.completitud[key]).map((objeto) => objeto.rut);

      setChartData({
        options: {
          chart: {
            id: "barchart",
          },
          xaxis: {
            categories: nombresValoresOrdenados,
            max: 100,
          },
          grid: {
            show: false,
          },

          fill: {
            opacity: 0.7,
            type: 'solid',
          },
          plotOptions: {
            bar: {
              borderRadius: 5,
              borderRadiusApplication: "end",
              borderRadiusWhenStacked: "last",
              horizontal: true,
            },
          },
          stroke: {
            width: 1,
            colors: [colorsGraphics[4]],
          },
          colors: colorsGraphics[3],
          tooltip: {
            y: {
              formatter: function (val, { seriesIndex, dataPointIndex }) {
                const regionName = rutPropiedades[dataPointIndex];
                return `${regionName}`;
              },
            }
          },
          dataLabels: {
            position: "center",
            offsetX: 0,
            offsetY: 0,
            style: {
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: "400",
              lineHeight: "18px",
            },
            formatter: function (val) {
              if (val > 9) {
                return val.toFixed(1) + "%";
              } else {
                return " ";
              }
            },
            dropShadow: {
              enabled: true,
              color: "5px 5px 5px #c2bec2",
            },
          },
        },
        series: [
          {
            name: "Porcentaje",
            data: valoresOrdenados,
          },
        ],
      });

      const listNames = values.gse_pie.map((i) => i.gse);
      const listaPeso = values.gse_pie.map((i) => i.peso);
      const listaEnteros = values.gse_pie.map((i) => i.rut);

      //--------------  Características Sociodemográficas  --------------
      setChartDataNse({
        options: {
          colors: colorsGSE,
          chart: {

            id: "doughnut",
            toolbar: {
              show: true,
            },
          },
          plotOptions: {
            donut: {
              size: "10%",
            },
          },
          dataLabels: {
            enabled: true,
            maxWidth: 100,
            formatter: function (val) {
              let valor = val;
              return valor.toFixed(1) + "%";
            },
            style: {
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: "18px",
              colors: ["#ffffff"],
              textShadow: "none",
              dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                opacity: 0.5,
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            lineHeight: "18px",
            fontSize: "10px",
            fontFamily: "Inter",
            fontWeight: 400,
            offsetY: 0,
          },

          labels: listNames,
          tooltip: {
            y: {
              formatter: function (val, { seriesIndex, dataPointIndex }) {
                const regionName = listaEnteros[dataPointIndex];
                return `${regionName}`;
              },
            }
          },
          stroke: {
            width: 0,
          },
        },
        series: listaPeso,
      });

      const list = values.renta.map((i) => {
        const parts = i.sueldo_promedio_rango.split(' ');
        return parts.length > 1 ? parts : parts[0];
      });
      const pesoRenta = values.renta.map((i) => i.peso);
      const mayorRenta = values.renta.map((i) => i.rut);

      setChartDataDoughnut({
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 350,
            type: 'bar',
          },

          plotOptions: {
            bar: {

              borderRadius: 10,
              dataLabels: {
                position: 'top', // o 'inside'
                maxItems: 100 // Establece el máximo número de elementos mostrados
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"],
            }
          },

          xaxis: {
            categories: list,
            labels: {
              rotate: -60, // Rota las etiquetas del eje X
              offsetX: 0,

              style: {
                fontSize: '10px',
                width: '5px',
                overFlow: 'hidden'
              }
            },
            position: 'botom',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            tooltip: {
              enabled: false,
            },
            tickAmount: Math.min(list.length, 10) // Ajusta el número máximo de etiquetas
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "%";
              }
            }
          },
          title: {
            text: '',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444'
            }
          },
          tooltip: {
            y: {
              formatter: function (val, { seriesIndex, dataPointIndex }) {
                const regionName = mayorRenta[dataPointIndex];
                return `${regionName}`;
              },
            }
          },
        },
        series: [{ name: 'Cantidad', data: pesoRenta }],
      });
      const sexoGraph = values.sexo
      setChartDataSexo({
        series: [values.sexo[0].peso, values.sexo[1].peso],
        options: {
          chart: {
            width: 410,
            type: "donut",
            toolbar: {
              show: true,
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "10px",
            fontFamily: "Inter",
            lineHeight: "18px",
            fontWeight: 400,
            offsetY: 0,
          },
          colors: ["#FF49D7", "#0791F9"],
          labels: ["Femenino", "Masculino"],
          dataLabels: {
            formatter: function (val) {
              return val.toFixed(1) + "%";
            },
            style: {
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: "400",
              colors: ["#ffffff", "#ffffff", "black", "#ffffff"],

            },
          },
          tooltip: {
            y: {
              formatter: function (val, { seriesIndex, dataPointIndex }) {
                // Obtener el nombre de la región correspondiente al punto de datos
                const regionName = sexoGraph[dataPointIndex].rut;
                return `${regionName}`;
              },
            }
          },
          stroke: {
            width: 1,
            color: "#d0d5dd",
          },
          fill: {
            opacity: 0.8,
            type: "solid",
          },

          plotOptions: {
            pie: {
              startAngle: 180,
              endAngle: -180,
            },
          },
        },
      });
      const sexoBenchmarkGraph = values.sexo_benchmark

      setChartDataBenchmark({
        series: [values.sexo_benchmark[0].peso, values.sexo_benchmark[1].peso],
        options: {
          chart: {
            width: 400,
            type: "donut",
            toolbar: {
              show: true,
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "10px",
            fontFamily: "Inter",
            fontWeight: 400,
            offsetY: 0,
          },
          colors: ["#FF49D7", "#0791F9"],
          labels: ["Femenino", "Masculino"],
          dataLabels: {
            formatter: function (val) {
              return val.toFixed(1) + "%";
            },
            style: {
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: "400",
              lineHeight: "18px",
            },
            offsetX: 0,
            offsetY: 0,
          },
          tooltip: {
            y: {
              formatter: function (val, { seriesIndex, dataPointIndex }) {
                // Obtener el nombre de la región correspondiente al punto de datos
                const regionName = sexoBenchmarkGraph[dataPointIndex].formatted_data;
                return `${regionName}`;
              },
            }
          },
          stroke: {
            width: 1,
            color: "#d0d5dd",
          },
          fill: {
            opacity: 0.8,
            type: "solid",
          },

          plotOptions: {
            pie: {
              startAngle: 180,
              endAngle: -180,
            },
          },
        },
      });

      let searchGse = values.sexo_gse.filter((e) => e.sexo === "F");
      let searchGseM = values.sexo_gse.filter((e) => e.sexo === "M");
      setPercentF(searchGse.map((i) => i.peso))
      setPercentM(searchGseM.map((i) => i.peso))
      setRutF(searchGse.map((i) => i.rut_x))
      setRutM(searchGseM.map((i) => i.rut_x))



      //--------------  Distribución por grupo socioeconómico  --------------
      const ageLine = values.gse_edad.reduce((acc, { edad }) => {
        if (!acc.includes(edad)) {
          acc = acc.concat(edad);
        }
        return acc;
      }, []);
      ageLine.sort(function (a, b) {
        const aMin = parseInt(a.split("-")[0]);
        const bMin = parseInt(b.split("-")[0]);
        return aMin - bMin;
      });
      const gseAgeABC1 = values.gse_edad.filter((e) => e.gse === "ABC1").map((e) => e.rut);
      const gseAgeC2 = values.gse_edad.filter((e) => e.gse === "C2").map((e) => e.rut);
      const gseAgeC3 = values.gse_edad.filter((e) => e.gse === "C3").map((e) => e.rut);
      const gseAgeD = values.gse_edad.filter((e) => e.gse === "D").map((e) => e.rut);
      const gseAgeE = values.gse_edad.filter((e) => e.gse === "E").map((e) => e.rut);

      setBarAgeGse({
        options: {
          colors: colorsGSEReverse,
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
          },

          dataLabels: {
            style: {
              colors: ['transparent'],

            },
            dropShadow: {
              enabled: true,
              color: "1px 1px 1px 0px #00000059",
            }
          },
          plotOptions: {

            bar: {
              borderRadius: 0,
              horizontal: false,
              dataLabels: {

                total: {
                  formatter: function (w) {
                    return separadorDeMiles(w)

                  },
                  enabled: true,
                  offsetX: 0,
                  style: {
                    colors: ['#475467'],
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "0.26px",
                    // Agregar sombra al texto del data label
                    textShadow: '1px 1px 2px rgba(0,0,0,0.5)' // Puedes ajustar los valores según tu preferencia
                  },
                },
              },

            },
          },
          // Eliminando la configuración de stroke para las barras
          stroke: {
            width: 0,
          },
          xaxis: {
            categories: ageLine,

          },
          yaxis: {
            title: {
              text: undefined,
            },
            labels: {
              formatter: function (val) {
                return separadorDeMiles(val);
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return separadorDeMiles(val);
              },
            },
          },
          fill: {
            opacity: 1,
          },
          title: {
            text: "",
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            inverseOrder: true
          },
          grid: {
            show: false,
          },
        },
        series: [
          {
            name: "E",
            data: gseAgeE
          },
          {
            name: "D",
            data: gseAgeD,
          },
          {
            name: "C3",
            data: gseAgeC3,
          },
          {
            name: "C2",
            data: gseAgeC2,
          },
          {
            name: "ABC1",
            data: gseAgeABC1,
          },
        ],
      });

      let selectList = values.gse_comuna.map((i) => i.comuna_minuscula);

      function removeDuplicates(arr) {
        return arr.filter((item, index) => {
          return arr.indexOf(item) === index;
        });
      }

      const unicos = removeDuplicates(selectList);

      let ABC1filter = [];
      let C2filter = [];
      let C3filter = [];
      let Dfilter = [];
      let Efilter = [];
      values.gse_comuna.forEach((e) => {
        if (e.gse === "ABC1") {
          ABC1filter.push(e.rut);
        } else if (e.gse === "C2") {
          C2filter.push(e.rut);
        } else if (e.gse === "C3") {
          C3filter.push(e.rut);
        } else if (e.gse === "D") {
          Dfilter.push(e.rut);
        } else if (e.gse === "E") {
          Efilter.push(e.rut);
        }
      });

      setSelectGraph({
        options: {
          colors: colorsGSE,
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
          },
          dataLabels: {
            dropShadow: {
              enabled: true,
              color: "1px 1px 1px 0px #00000059",
            }
          },
          plotOptions: {
            bar: {
              borderRadius: 0,
              horizontal: true,
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    colors: ['#475467'],
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "0.26px",
                  },
                }
              }
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },

          xaxis: {
            categories: ageLine,
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          yaxis: {
            title: {
              text: undefined,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          fill: {
            opacity: 1,
          },
          title: {
            text: "",
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            offsetX: 0,
          },
          grid: {
            show: false,
          },
        },

        series: [
          {
            name: "ABC1",
            data: ABC1filter,
          },
          {
            name: "C2",
            data: C2filter,
          },

          {
            name: "C3",
            data: C3filter,
          },
          {
            name: "D",
            data: Dfilter,
          },
          {
            name: "E",
            data: Efilter,
          },
        ],
      });
      setDistribucionComunasText(unicos.length)
      setSelectBarGraph({
        options: {
          colors: colorsGSE,
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
          },
          dataLabels: {
            style: {
              colors: ['transparent'],

            },
            dropShadow: {
              enabled: true,
              color: "5px 5px 5px #c2bec2",
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 0,
              dataLabels: {
                total: {

                  formatter: function (val) {
                    return separadorDeMiles(val);
                  },

                  enabled: true,
                  offsetX: 2,
                  style: {
                    colors: ['#475467'],
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "0.26px",
                  },
                }
              }
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          xaxis: {
            categories: unicos,
            labels: {
              formatter: function (val) {
                return separadorDeMiles(val);
              },
            }
          },
          yaxis: {
            title: {
              text: undefined,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return separadorDeMiles(val);
              },
            },
          },
          fill: {
            opacity: 1,
          },
          title: {
            text: "",
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            offsetX: 0,
          },
          grid: {
            show: false,
          },
        },
        series: [
          {
            name: "ABC1",
            data: ABC1filter,
          },
          {
            name: "C2",
            data: C2filter,
          },
          {
            name: "C3",
            data: C3filter,
          },
          {
            name: "D",
            data: Dfilter,
          },
          {
            name: "E",
            data: Efilter,
          },
        ],
      });
      const profesiontList = values.gse_profesion.map((i) => i.giro);
      const profesiones = profesiontList.filter((valor, indice) => {
        return profesiontList.indexOf(valor) === indice;
      });


      let gseProfesionABC1 = [];
      let gseProfesionC2 = [];
      let gseProfesionC3 = [];
      let gseProfesionD = [];
      let gseProfesionE = [];
      values.gse_profesion.forEach((e) => {
        if (e.gse === "ABC1") {
          gseProfesionABC1.push(e.rut);
        } else if (e.gse === "C2") {
          gseProfesionC2.push(e.rut);
        } else if (e.gse === "C3") {
          gseProfesionC3.push(e.rut);
        } else if (e.gse === "D") {
          gseProfesionD.push(e.rut);
        } else if (e.gse === "E") {
          gseProfesionE.push(e.rut);
        }
      });
      setProfesionNumberText(profesiones.length)

      setBarProfesionGse({
        options: {
          colors: colorsGSE,
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
          },
          dataLabels: {
            style: {
              colors: ['transparent'],

            },
            dropShadow: {
              enabled: true,
              color: "5px 5px 5px #c2bec2",
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 0,
              dataLabels: {
                total: {

                  formatter: function (val) {
                    return separadorDeMiles(val);
                  },

                  enabled: true,
                  offsetX: 2,
                  style: {
                    colors: ['#475467'],
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "0.26px",
                  },
                }
              }
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          xaxis: {
            categories: profesiones,
            labels: {
              formatter: function (val) {
                return separadorDeMiles(val);
              },
            }
          },
          yaxis: {
            title: {
              text: undefined,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return separadorDeMiles(val);
              },
            },
          },
          fill: {
            opacity: 1,
          },
          title: {
            text: "",
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            offsetX: 0,
          },
          grid: {
            show: false,
          },
        },
        series: [
          {
            name: "ABC1",
            data: gseProfesionABC1,
          },
          {
            name: "C2",
            data: gseProfesionC2,
          },
          {
            name: "C3",
            data: gseProfesionC3,
          },
          {
            name: "D",
            data: gseProfesionD,
          },
          {
            name: "E",
            data: gseProfesionE,
          },
        ],
      });
      let RegionArray = values.region.map((i) => i.peso);
      let RegionArrayData = values.region.map((i) => i.formatted_data);
      let RegionArrayTitle = values.region.map((i) => i.region_nombre);
      setRegion(

        {
          options: {
            chart: {
              id: "barchart",
            },
            xaxis: {
              categories: RegionArrayTitle.map((palabra) => {
                  // Dividir la palabra en palabras individuales
                  let palabras = palabra.split(" ");
                  // Capitalizar la primera letra de cada palabra y unirlas nuevamente
                  let titulo = palabras.map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(" ");
                  return titulo;
              }),
          },
          
            grid: {
              show: false,
            },

            fill: {
              opacity: 0.7,
              type: 'solid',
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "last",
                horizontal: true,
              },
            },
            stroke: {
              width: 1,

            },

            tooltip: {
              y: {
                formatter: function (val, { seriesIndex, dataPointIndex }) {
                  // Obtener el nombre de la región correspondiente al punto de datos
                  const regionName = RegionArrayData[dataPointIndex];
                  return `${regionName}`;
                },
              }
            },
            dataLabels: {
              position: "center",
              offsetX: 0,
              offsetY: 0,
              style: {
                fontSize: "13px",
                fontFamily: "Inter",
                fontWeight: "400",
                lineHeight: "18px",
              },
              formatter: function (val) {
                if (val > 4) {
                  return val.toFixed(1) + "%";
                } else {
                  return " ";
                }
              },

              // dropShadow: {
              //   enabled: true,
              //   color: "5px 5px 5px #c2bec2",
              // },
            },
          },
          series: [
            {
              name: "Cantidad",
              data: RegionArray,
            },
          ],
        });
      const suma_comuna_array = values.suma_comuna.map((i) => i.peso);
      const suma_comuna_data = values.suma_comuna.map((i) => i.comuna_minuscula);
      const suma_comuna_val = values.suma_comuna.map((i) => i.rut);
      setSumaComuna(

        {
          options: {
            chart: {
              id: "barchart",
            },
            xaxis: {
              categories: suma_comuna_data.map((palabra) => {
                  // Dividir la palabra en palabras individuales
                  let palabras = palabra.split(" ");
                  // Capitalizar la primera letra de cada palabra y unirlas nuevamente
                  let titulo = palabras.map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(" ");
                  return titulo;
              }),
          },
            grid: {
              show: false,
            },

            fill: {
              opacity: 0.7,
              type: 'solid',
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "last",
                horizontal: true,
              },
            },
            stroke: {
              width: 1,

            },

            tooltip: {
              y: {
                formatter: function (val, { seriesIndex, dataPointIndex }) {
                  const regionName = suma_comuna_val[dataPointIndex];
                  return `${regionName}`;
                },
              }
            },
            dataLabels: {
              position: "center",
              offsetX: 0,
              offsetY: 0,
              style: {
                fontSize: "13px",
                fontFamily: "Inter",
                fontWeight: "400",
                lineHeight: "18px",
              },
              formatter: function (val) {
                if (val > 4) {
                  return val.toFixed(1) + "%";
                } else {
                  return " ";
                }
              },

              // dropShadow: {
              //   enabled: true,
              //   color: "5px 5px 5px #c2bec2",
              // },
            },
          },
          series: [
            {
              name: "Cantidad",
              data: suma_comuna_array,
            },
          ],
        });


      //--------------  TENENCIA DE BIENES  --------------
      let vehiculosArray = values.vehiculos.map((i) => i.peso);
      let vehiculosRut = values.vehiculos.map((i) => i.rut);

      let vehiculosArrayTitle = values.vehiculos.map((i) => i.marca);
      // Sacar el último elemento de vehiculosArray y almacenarlo en otra variable
      setUltimoPeso(vehiculosArray.pop())
      // Sacar el último elemento de vehiculosArrayTitle
      let ultimoTitulo = vehiculosArrayTitle.pop();


      setVehiculos(

        {
          options: {
            chart: {
              id: "barchart",
            },
            xaxis: {
              categories: vehiculosArrayTitle.map((palabra) => {
                palabra = palabra.toLowerCase();
                palabra = palabra.replace(/\b\w/g, (char) => char.toUpperCase()); // Esta línea convierte la primera letra de cada palabra en mayúscula
                return palabra;
              }),
            },
            
            grid: {
              show: false,
            },

            fill: {
              opacity: 0.7,
              type: 'solid',
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "last",
                horizontal: true,
              },
            },
            stroke: {
              width: 1,

            },

            tooltip: {
              y: {
                formatter: function (val, i) {
                  return vehiculosRut[i.dataPointIndex]
                },
              },
            },
            dataLabels: {
              position: "center",
              offsetX: 0,
              offsetY: 0,
              style: {
                fontSize: "13px",
                fontFamily: "Inter",
                fontWeight: "400",
                lineHeight: "18px",
              },
              formatter: function (val) {
                if (val > 4) {
                  return val.toFixed(1) + "%";
                } else {
                  return " ";
                }
              },

              // dropShadow: {
              //   enabled: true,
              //   color: "5px 5px 5px #c2bec2",
              // },
            },
          },
          series: [
            {
              name: "Porcentaje",
              data: vehiculosArray,
            },
          ],
        });
      setTasacion_comercial({
        options: {
          chart: {
            toolbar: {
              show: true,
            },
            height: 350,
            type: 'bar',
          },

          plotOptions: {
            bar: {

              borderRadius: 10,
              dataLabels: {
                position: 'top', // o 'inside'
                maxItems: 100 // Establece el máximo número de elementos mostrados
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"],
            }
          },

          xaxis: {
            categories: values.tasacion_comercial.map((palabra) => {
              let titulo = palabra.tasacion_rango.toLowerCase();
              titulo = palabra.tasacion_rango.charAt(0).toUpperCase() + palabra.tasacion_rango.slice(1);
              return titulo;
            }),

          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
              formatter: function (val) {
                return val + "%";
              }
            }
          },
          title: {
            text: '',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444'
            }
          },
          tooltip: {
            y: {
              formatter: function (val, i) {
                return values.tasacion_comercial[i.dataPointIndex].formatted_data
              },
            },
          },
        },
        series: [{
          name: "Porcentaje",
          data: values.tasacion_comercial.map(i => { return i.peso }),
        }],
      });

      const vehiculosArrayTitleBARRAS = values.vehiculos_gse.map(
        (i) => i.marca
      );
      const marcasUnicas = [...new Set(vehiculosArrayTitleBARRAS)];

      let vehGseABC1 = [];
      let vehGseC2 = [];
      let vehGseC3 = [];
      let vehGseD = [];
      let vehGseE = [];
      values.vehiculos_gse.forEach((e) => {
        if (e.gse === "ABC1") {
          vehGseABC1.push({ rut: e.rut, peso: e.peso });
        } else if (e.gse === "C2") {
          vehGseC2.push({ rut: e.rut, peso: e.peso });
        } else if (e.gse === "C3") {
          vehGseC3.push({ rut: e.rut, peso: e.peso });
        } else if (e.gse === "D") {
          vehGseD.push({ rut: e.rut, peso: e.peso });
        } else if (e.gse === "E") {
          vehGseE.push({ rut: e.rut, peso: e.peso });
        }
      });

      setVehiculosGseText(marcasUnicas.length)
      setVehiculosGse({
        options: {
          colors: colorsGSE,
          dataLabels: {
            position: "center",
            style: {
              colors: ['transparent'],
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: "400",
              lineHeight: "18px",
            },
            enabled: true,
            formatter: function (val) {
              return val.toFixed(0) + "%";
            },
            dropShadow: {
              enabled: true,
              color: "5px 5px 5px #c2bec2",
            },
          },
          title: {
            style: {
              fontSize: "13px",
              fontWeight: "500",
              color: "#4b4b5a",
              fontFamily: "Inter",
            },
          },
          chart: {
            id: "barchart",
            stacked: true,
            toolbar: {
              show: true,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              // borderRadius: [5], // Ajusta este valor según tu preferencia
            },
          },
          grid: {
            show: false,
          },
          xaxis: {
            min: 0,
            categories: vehiculosArrayTitle.map((palabra) => {
              palabra = palabra.toLowerCase();
              palabra = palabra.replace(/\b\w/g, (char) => char.toUpperCase()); // Esta línea convierte la primera letra de cada palabra en mayúscula
              return palabra;
            }),
          },
          
          tooltip: {
            y: {
              formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                const vehiculos = [vehGseABC1, vehGseC2, vehGseC3, vehGseD, vehGseE][seriesIndex];
                const vehiculo = vehiculos[dataPointIndex];
                const rut = vehiculo ? vehiculo.rut : "N/A";
                return rut;
              },
            },
          },
        },
        series: [
          { name: "ABC1", data: vehGseABC1.map(item => item.peso) },
          { name: "C2", data: vehGseC2.map(item => item.peso) },
          { name: "C3", data: vehGseC3.map(item => item.peso) },
          { name: "D", data: vehGseD.map(item => item.peso) },
          { name: "E", data: vehGseE.map(item => item.peso) },
        ],
      });
      

      let viviendaSin = [];
      let unaVivienda = [];
      let dosVivienda = [];
      let tresCincoVivienda = [];
      let cincoSienteVivienda = [];
      let masSienteVivienda = [];
      values.viviendas2.forEach((e) => {
        if (e.vivienda_rango === "Sin viviendas") {
          viviendaSin.push(e.rut);
        } else if (e.vivienda_rango === "1 vivienda") {
          unaVivienda.push(e.rut);
        } else if (e.vivienda_rango === "2 viviendas") {
          dosVivienda.push(e.rut);
        } else if (e.vivienda_rango === "3 a 5 viviendas") {
          tresCincoVivienda.push(e.rut);
        } else if (e.vivienda_rango === "5 a 7 viviendas") {
          cincoSienteVivienda.push(e.rut);
        } else if (e.vivienda_rango === "7 +") {
          masSienteVivienda.push(e.rut);
        }
      });
      function formatearConSeparadorDeMiles(numero) {
        return numero.toLocaleString("es-ES");
      }
      setViviendasBar({
        options: {
          colors: [
            colorsGraphicsOrder[0],
            colorsGraphicsOrder[2],
            colorsGraphicsOrder[4],
            colorsGraphicsOrder[7],
            colorsGraphicsOrder[8],
            colorsGraphicsOrder[9],
          ],
          value: {
            formatter: function (value) {
              return separadorDeMiles(value);

            },
          },
          chart: {
            type: "bar",
            stacked: false,
            toolbar: {
              show: true,
            },
          },
          plotOptions: {

            bar: {
              formatter: function (value) {
                return separadorDeMiles(value);

              },
              horizontal: false,
              columnWidth: "85%",
              borderRadius: 5,
              borderRadiusApplication: "end",
              borderRadiusWhenStacked: "last",
              dataLabels: {
                position: 'top',
              }
            },
          },

          dataLabels: {
            formatter: function (value) {
              return separadorDeMiles(value);
            },
            style: {
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: "400",
              lineHeight: "18px",
              colors: ['#475467'],
            },
            enabled: true,
            dropShadow: {
              enabled: false,
              // color: "5px 5px 5px #c2bec2",
            },
            offsetY: -30

          },

          xaxis: {
            formatter: function (value) {
              return separadorDeMiles(value);

            },
            categories: [["Cantidad"]],
            type: "category",
            tickPlacement: "between",

          },
          yaxis: {

            show: true,
            labels: {
              formatter: function (value) {
                return separadorDeMiles(value);
              }
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return separadorDeMiles(val);
              },
            },
          },
          grid: {
            show: false,
          },
        },
        series: [
          {
            name: "Sin viviendas",
            data: viviendaSin,
          },
          {
            name: "1 vivienda",
            data: unaVivienda,
          },
          {
            name: "2 viviendas",
            data: dosVivienda,
          },
          {
            name: "3 a 5 viviendas",
            data: tresCincoVivienda,
          },
          {
            name: "5 a 7 viviendas",
            data: cincoSienteVivienda,
          },
          {
            name: "7 +",
            data: masSienteVivienda,
          },
        ],
      });

      setVehiculoBar({
        options: {
          colors: [
            colorsGraphicsOrder[0],
            colorsGraphicsOrder[2],
            colorsGraphicsOrder[4],
            colorsGraphicsOrder[7],
            colorsGraphicsOrder[8],
            colorsGraphicsOrder[9],
          ],
          value: {
            formatter: function (value) {
              return value + "%"

            },
          },
          chart: {
            type: "bar",
            stacked: false,
            toolbar: {
              show: true,
            },
          },
          plotOptions: {

            bar: {
              formatter: function (value) {
                return value + "%"

              },
              horizontal: false,
              columnWidth: "85%",
              borderRadius: 5,
              borderRadiusApplication: "end",
              borderRadiusWhenStacked: "last",
              dataLabels: {
                position: 'top',
              }
            },
          },

          dataLabels: {
            formatter: function (value) {
              return value + "%"

            },
            style: {
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: "400",
              lineHeight: "18px",
              colors: ['#475467'],
            },
            enabled: true,
            dropShadow: {
              enabled: false,
              // color: "5px 5px 5px #c2bec2",
            },
            offsetY: -30

          },

          xaxis: {
            formatter: function (value) {

              return value + "%"

            },
            categories: [["Cantidad"]],
            type: "category",
            tickPlacement: "between",

          },
          yaxis: {

            show: true,
            labels: {
              formatter: function (value) {
                return value + "%"

              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val, i) {
                return values.q_vehiculos[i.seriesIndex].formatted_data
              },
            },
          },
          grid: {
            show: false,
          },
        },
        series: values.q_vehiculos.map((e) => {
          return ({ name: e.vehiculo_rango, data: [e.peso] }
          )
        })
      })

      let rangoViviendas = values.viviendas.map((i) => i.vivienda_rango);
      let viviendasArray = values.viviendas.map((i) => i.peso);
      let viviendasArrayRut = values.viviendas.map((i) => i.rut);

      setViviendasDoughnut({
        options: {
          colors: [
            colorsGraphicsOrder[2],
            colorsGraphicsOrder[4],
            colorsGraphicsOrder[7],
            colorsGraphicsOrder[8],
            colorsGraphicsOrder[9],
          ],
          chart: {

            id: "doughnut",
            toolbar: {
              show: true,
            },
          },
          plotOptions: {
            donut: {
              size: "10%",
            },
          },
          dataLabels: {
            enabled: true,
            maxWidth: 100,
            formatter: function (val) {
              let valor = val;
              return valor.toFixed(1) + "%";
            },
            style: {
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: "400",
              lineHeight: "18px",
              colors: ["#ffffff"],
              textShadow: "none",
              dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                opacity: 0.5,
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            lineHeight: "18px",
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: 400,
            offsetY: 0,
          },

          labels: rangoViviendas,
          tooltip: {
            y: {
              formatter: function (val, i) {
                return viviendasArrayRut[i.seriesIndex]
              },
            },
          },
          stroke: {
            width: 0,
          },
        },
        series: viviendasArray,
      });

      let rangoViviendasMonto = values.viviendas_monto.map(
        (i) => i.vivienda_monto_rango
      );
      let viviendasArrayMonto = values.viviendas_monto.map((i) => i.peso);
      let viviendasArrayMontoRut = values.viviendas_monto.map((i) => i.rut);

      setViviendasDoughnutMonto({
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 350,
            type: 'bar',
          },

          plotOptions: {
            bar: {

              borderRadius: 10,
              dataLabels: {
                position: 'top', // o 'inside'
                maxItems: 100 // Establece el máximo número de elementos mostrados
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"],
            }
          },

          xaxis: {
            categories: rangoViviendasMonto,
            labels: {
              rotate: -60, // Rota las etiquetas del eje X
              offsetX: 0,

              style: {
                fontSize: '10px',
                width: '5px',
                overFlow: 'hidden'
              }
            },
            position: 'botom',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            tickAmount: Math.min(list.length, 10) // Ajusta el número máximo de etiquetas
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "%";
              }
            }
          },
          title: {
            text: '',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444'
            }
          },
          tooltip: {
            y: {
              formatter: function (val, { dataPointIndex, seriesIndex }) {
                return viviendasArrayMontoRut[dataPointIndex]
              },
            },
          },
        },
        series: [{ name: 'Porcentaje', data: viviendasArrayMonto }],
      });



      //--------------  DETALLE BUYER PERSON  --------------
      let tiltleProfile = values.profile.map((i) => i.peso);
      // let valuesPerfil = values.profile.map((i) => "Buyer persona " + i.Perfil.at(-1));

      setProfile(tiltleProfile);
    }
  }, [values.length, values]);

  //--------------  GSE DE CLIENTES VERSUS BENCHMARK --------------
  useEffect(() => {
    if (values.resumen_carga) {
      const firstDataSelectGse = values.gse_edad_benchmark.reduce(
        (acc, { gse }) => {
          if (!acc.includes(gse)) {
            acc = acc.concat(gse);
          }
          return acc;
        },
        []
      );
      const firstDataSelectEdad = values.gse_edad_benchmark.reduce(
        (acc, { edad }) => {
          if (!acc.includes(edad)) {
            acc = acc.concat(edad);
          }
          return acc;
        },
        []
      );

      setselectBenchEdad(firstDataSelectGse);

      const graphFilterMixEdad = values.gse_edad_benchmark.filter((e) => {
        return e.gse === handleSelectChange;
      });
      const dataRut = graphFilterMixEdad.map((i) =>
        i.formatted_data.replace(",", "").replace(".", "")
      );
      const dataMercado = graphFilterMixEdad.map((i) => parseFloat(i.Total));

      setChartEdadBenchmark({
        options: {
          chart: {
            height: 350,
            type: "line",
            toolbar: {
              show: true,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                customIcons: [],
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 5,
              borderRadiusApplication: "end",
              borderRadiusWhenStacked: "last",
            },
          },
          grid: {
            show: false,
          },
          stroke: {
            width: [0, 4],
            curve: "smooth",
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
            formatter: function (val) {
              return separadorDeMiles(val);
            },
          },
          labels: firstDataSelectEdad,
          tooltip: {
            y: {
              formatter: function (val) {
                return separadorDeMiles(val);
              },
            },
          },
          xaxis: {
            type: "category",
          },
          yaxis: [
            {
              opposite: false,
              title: {
                text: "Clientes",
              },
              labels: {
                formatter: function (val) {
                  if (val === Math.floor(val)) {
                    return separadorDeMiles(val);
                  } else {
                    return " ";
                  }
                },
              },
            },

            {
              opposite: true,
              title: {
                text: "Población",
              },
              labels: {
                formatter: function (val) {
                  return separadorDeMiles(val);
                },
              },
            },
          ],
        },
        series: [
          {
            name: "Clientes",
            type: "column",
            data: dataRut,
            color: colorsGraphics[4],
          },
          {
            name: "Población",
            type: "line",
            data: dataMercado,
            color: colorsGraphics[0],
          },
        ],
      });
    }

    if (values.resumen_carga) {
      const secondDataSelectComuna = values.gse_comuna_benchamrk.reduce(
        (acc, { comuna }) => {
          if (!acc.includes(comuna)) {
            acc = acc.concat(comuna);
          }
          return acc;
        },
        []
      );

      const graphFilterMixComuna = values.gse_comuna_benchamrk.filter((e) => {
        return e.gse === handleSelectChange;
      });
      const dataRutComuna = graphFilterMixComuna.map((i) =>
        normalizer(i.formatted_rut)
      );
      const dataMercadoComuna = graphFilterMixComuna.map((i) =>
        normalizer(i.formatted_cantidad)
      );
      setDistribucionPComunasText(secondDataSelectComuna.length)
      setChartComunaBenchmark({
        options: {
          chart: {
            height: 350,
            type: "line",
            toolbar: {
              show: true,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                customIcons: [],
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 5,
              borderRadiusApplication: "end",
              borderRadiusWhenStacked: "last",
            },
          },
          grid: {
            show: false,
          },
          stroke: {
            width: [0, 4],
            curve: "smooth",
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
            formatter: function (val) {
              return separadorDeMiles(val);
            },
          },

          labels: secondDataSelectComuna,

          tooltip: {
            y: {
              formatter: function (val) {
                return separadorDeMiles(val);
              },
            },
          },
          xaxis: {
            type: "category",
            formatter: function (value) {
            }
          },
          yaxis: [
            {
              title: {
                text: "Clientes",
              },
              labels: {
                formatter: function (val) {
                  if (val === Math.floor(val)) {
                    return separadorDeMiles(val);
                  } else {
                    return " ";
                  }
                },
              },
            },

            {
              opposite: true,
              title: {
                text: "Población",
              },
              labels: {
                formatter: function (val) {
                  return separadorDeMiles(val);
                },
              },
            },
          ],
        },
        series: [
          {
            name: "Clientes",
            type: "column",
            data: dataRutComuna,
            color: colorsGraphics[5],
          },
          {
            name: "Población",
            type: "line",
            data: dataMercadoComuna,
            color: colorsGraphics[0],
          },
        ],
      });
    }
  }, [handleSelectChange, values.length, values]);

  //--------------  GASTO GSE SEGÚN INDUSTRIA Y SEGMENTO --------------
  useEffect(() => {
    let gastoCanasta = [];
    if (values.canasta) {
      values.canasta.map((e) => {
        let search = gastoCanasta.find((element) => element === e.gasto);
        if (search === undefined) {
          gastoCanasta.push(e.gasto);
        }
        if (!buyerPerson[0]) {
          setBuyerPerson(gastoCanasta[0]);
        }
        return undefined;
      });

      setCanastaSearch(gastoCanasta);

      let canastaAlto = [];
      let canastaMedio = [];
      let canastaBajo = [];

      let filtroCanasta = values.canasta.filter((e) => e.gasto === buyerPerson);

      for (let i = 0; i < filtroCanasta.length; i++) {
        if (
          filtroCanasta[i].peso === undefined ||
          filtroCanasta[i].peso === 0
        ) {
          filtroCanasta[i].peso = 0;
        }
      }
      filtroCanasta.forEach((e) => {
        if (e.nivel === "Alto") {
          canastaAlto.push(e.peso);
        } else if (e.nivel === "Medio") {
          canastaMedio.push(e.peso);
        } else if (e.nivel === "Bajo") {
          canastaBajo.push(e.peso);
        }
      });

      let gseFiltroCanasta = [];
      filtroCanasta.forEach((e) => {
        if (!gseFiltroCanasta.includes(e.gse)) {
          gseFiltroCanasta.push(e.gse);
        }
      });

      setStackedColumn({
        options: {
          colors: [
            "#12B76A",/* Alto */
            "#FDB022",/* Medio */
            "#F04438",/* Bajo */],
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(1) + "%";
              },
            },
          },
          dataLabels: {
            formatter: function (val) {
              if (val === 0) {
                return ""; // Devuelve una cadena vacía para ocultar los valores que son 0%
              } else {
                return val.toFixed(1) + "%";
              }
            },
            dropShadow: {
              enabled: true,
              color: "5px 5px 5px #c2bec2",
            },
            total: {
              enabled: true,
              style: {
                colors: ['#475467'],
                fontFamily: "Inter",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.26px",
              },
            },
            style: {
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: "400",
              lineHeight: "18px",
            },
          },
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },

          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              endingShape: "rounded",
              horizontal: false,
              borderRadius: 10,
              borderRadiusApplication: "end", // "around" / "end"
              borderRadiusWhenStacked: "last", // "all"/"last"
            },
          },
          legend: {
            position: "top",
            offsetY: 4,
          },
          fill: {
            opacity: 1,
            pattern: "none",
          },
          xaxis: {
            categories: gseFiltroCanasta,
          },

          yaxis: {
            show: true,
            max: 100,
            labels: {
              formatter: function (value) {
                if (value) {
                  return value.toFixed(0);
                }
              },
            },
          },
          grid: {
            show: false,
          },
        },
        series: [
          {
            name: "Alto",
            data: canastaAlto,
          },
          {
            name: "Medio",
            data: canastaMedio,
          },
          {
            name: "Bajo",
            data: canastaBajo,
          },
        ],
      });
    }
  }, [buyerPerson, values]);

  const customStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      color: "#98A2B3",
      fontFamily: "Inter",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.28px",
      display: "flex",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "stretch",
      cursor: "pointer",
      borderRadius: "8px",
      borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
    }),
    container: (provided, state) => ({
      ...provided,
      width: "481px",
      height: "32px",
    }),
    control: (provided, state) => ({
      ...provided,
      display: "flex",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "stretch",
      background: "#FFF",
      cursor: "pointer",
      width: "481px",
      borderRadius: "8px",
      borderColor: state.isFocused ? "#F5A973" : "#D0D5DD",
      boxShadow: state.isFocused ? "0 0 0 1px #F5A973" : "none",

      "&:hover": {
        borderColor: "#F18F49",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      display: "block",
      width: "100%",
      padding: "8px",
      gap: "8px",
      borderRadius: "8px",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#475467",
      fontSize: "12px",
      fontFamily: "Inter",
      fontWeight: "400",
      backgroundColor: "white",
      "&:active": {
        backgroundColor: "white", // Evita que haya un color de fondo al hacer clic en la opción
      },
      "&:hover": {
        backgroundColor: "#EFF8FF", // Cambia el color de fondo al hacer hover
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#475467",
      fontSize: "12px",
      fontFamily: "Inter",
      fontWeight: "400",
      backgroundColor: "white",
    }),
  };

  return (
    <>
      {chartData.series && (
        <>
          <div className="containerCharts" >
            <div>
              <Navbar estado={estadoMenu} />
            </div>
            <div
              className={`amplify_body_container tablero ${navbarData ? "navbar-open" : ""
                }`}
              style={{ marginLeft: navbarData ? "220px" : "150px" }}
            >
              <div className="dashboardVerticalMenuContainer" id="pdf-content"  ref={ref}>
                <div className="Dashboard">
                  <h2 className="DashboardTitleCL">
                    Tablero {values.resumen_carga.enrichment_name}
                    {chartData.series &&
                      <button className={"btnSecondary"} onClick={() => { if (textState) { downloadPdf() } }} >
                        {textState ? (
                          <div className="ChartClDownload">
                            <p>Descargar</p>
                            <img src={DownloadImg} alt="" />
                          </div>
                        ) : (
                          <div className="ChartClDownload">
                            <p>Descargando</p>
                            <img src={SpinnerSVG} alt="" className="rotatingSVG" />
                          </div>
                        )}
                      </button>}
                  </h2>
                  {/* <button onClick={()=>{downloadPdf()}}>Descargar PDF</button> */}


                  <div className="dobleDoughnutContainerCL">
                    <div className="dobleDoughnutContentTextCL">
                      <h2 className="DashboardTitleCL">
                        Características Sociodemográficas
                      </h2>
                      <p className="dobleDoughnutTextCL">
                        A partir del enrichment Tbrein en los clientes de{" "}
                        <strong>{values.organizacion} </strong> es posible
                        obtener las principales características
                        sociodemográficas en relación a nivel socioeconómicos,
                        distribución de género, ingreso promedio mensual, edad,
                        lugar de residencia y estilos de consumo más relevantes
                        dentro del presupuesto mensual de los clientes. De esta
                        manera se obtiene un perfil de clientes que permite
                        seleccionar grupos objetivos que calcen con la oferta
                        que se desea potenciar.
                      </p>
                    </div>
                    <div className="dobleDoughnut">
                      <div className="dobleDoughnutdivCL dobleDoughnutdivCLdonut">
                        <div className="dobleDoughnutContentCLGraficos">
                          <h3>Grupo socioeconómico</h3>
                          <p>
                            De acuerdo con la distribución obtenida se observa
                            una predominancia de clientes en el segmento
                            <strong> {values.textos.text_gse_pie_max}</strong>.
                          </p>
                        </div>
                        <div className="chartDataContenedor chartDataContenedordonut">
                          {chartDataNse.series && (
                            <>
                              <ChartDataDoughnut chartData={chartDataNse} width={"300"} height={"330"} />

                            </>
                          )}
                        </div>
                        <p className="chartDataNseTextCL">
                          Completitud de un
                          <span>&nbsp;</span>
                          <strong>
                            {
                              values.resumen_carga.completitud
                                .comp_gse_gse_int.value
                            }
                            %
                          </strong>
                          <span>&nbsp;</span>
                          de los datos cargados.
                        </p>
                      </div>

                      <div className="dobleDoughnutdivCL dobleDoughnutdivCLdonutBars">
                        <div className="dobleDoughnutContentCLGraficos">
                          <h3 className="DashboardTitleCL">Ingreso promedio mensual</h3>
                          <p>
                            Los clientes de
                            <strong> {values.organizacion}</strong> concentran
                            un ingreso promedio mensual entre los
                            <strong> {values.textos.text_renta_2}</strong>, con
                            una participación de un
                            <strong>
                              {" "}
                              {values.textos.text_renta_1.toFixed(1)}%
                            </strong>{" "}
                            con respecto a la base total de registros válidos.
                          </p>
                        </div>
                        <div className="chartDataContenedor">
                          {chartDataDoughnut.series && (
                            <>
                              <div>
                                <div id="chart">
                                  <ReactApexChart options={chartDataDoughnut.options} series={chartDataDoughnut.series} type="bar" height={350} width={550} />
                                </div>
                                <div id="html-dist"></div>
                              </div>


                            </>
                          )}
                        </div>


                        <p className="chartDataNseTextCL">
                          Completitud de un
                          <span>&nbsp;</span>
                          <strong>
                            {
                              values.resumen_carga.completitud
                                .comp_renta_int.value
                            }
                            %
                          </strong>
                          <span>&nbsp;</span>
                          de los datos cargados.
                        </p>
                      </div>
                    </div>{" "}

                    <div className="divGraphicCL">
                      <h3>Distribución por Regiones</h3>
                      <div className="chartDataContenedorGraphicCL">
                        {region.series && (
                          <ReactApexChart options={region.options} series={region.series} type="bar" height={550} width={850} />
                        )}
                      </div>
                    </div>
                    <div className="divGraphicCL">

                      <h3>Distribución por Comuna</h3>
                      <div className="chartDataContenedorGraphicCL">

                        {sumaComuna && (
                          <ReactApexChart options={sumaComuna.options} series={sumaComuna.series} type="bar" height={550} width={850} />

                        )}
                      </div>
                    </div>

                    {/* cierro dobleDoughnut */}
                    <div className="dobleSpineChartsWithTitleGender">
                      <div className="dobleDoughnutdivCLWithTitleCL">
                        <div className="divDoughnutGeneroCL">
                          <h3>Género</h3>

                          {values.textos.text_genero_max && (
                            <p>
                              Un{" "}
                              <strong>
                                {" "}
                                {values.textos.text_genero_max.peso}{" "}
                              </strong>
                              de los clientes de{" "}
                              <strong>{values.organizacion} {" "} </strong>
                              se concentran en el género
                              <strong>
                                {" "}
                                {values.textos.text_genero_max.genero}
                              </strong>
                              . Si este dato se apertura según Nivel
                              socioeconómico, se observa que en el género
                              Masculino predominan el segmento
                              <strong>
                                <>{" "} {values.textos.text_genero_gse_max_M}</>
                              </strong>{" "}
                              y en el caso del género Femenino{" "}
                              <strong>
                                {" "}
                                <>{values.textos.text_genero_gse_max_F}</>
                              </strong>
                              .
                            </p>
                          )}
                        </div>{" "}
                        <div className="chartDataContenedor">
                          {chartDataSexo.series && (
                            <ChartDataDoughnut chartData={chartDataSexo} height={"337"} width={"400"} />
                          )}
                        </div>
                        <p className="chartDataNseTextCL">
                          Completitud de un
                          <span>&nbsp;</span>
                          <strong>
                            {
                              values.resumen_carga.completitud.comp_sexo_int
                                .value
                            }
                            %
                          </strong>
                          <span>&nbsp;</span>
                          de los datos cargados.
                        </p>
                      </div>
                      {/* Cierra dobleDoughnutdivCLWithTitle */}

                      <div className="dobleDoughnutdivCLWithTitleCL">
                        <div className="dobleDoughnutdivCLContentCL">
                          <h3>Población</h3>

                          <p>
                            Como referencia, en el mercado predomina el segmento
                            <strong>
                              {" "}
                              {
                                values.textos.text_genero_benchmark_max.genero
                              }
                            </strong>,
                            con una participación del{" "}
                            <strong>
                              {" "}
                              {
                                values.textos.text_genero_benchmark_max.peso
                              }.{" "}
                            </strong>{" "}
                          </p>
                        </div>

                        <div className="chartDataContenedor">
                          {chartDataBenchmark.series && (
                            <ChartDataDoughnut chartData={chartDataBenchmark} height={"338"} width={"400"} />
                          )}
                        </div>
                      </div>
                      {/* Cierra dobleDoughnutdivCLWithTitle */}
                    </div>{" "}
                    {/* Cierra dobleSpineChartsWithTitle */}
                    <div className="barsBenchamarkGeneroPropio">
                      <div className="barsBenchamarkGeneroConTituloCl">
                        <h3>Segmentación de Género según Nivel Socioeconómico</h3>

                        <div className="barTitle">

                          <ChartsBarsNoLibrary
                            percentagesRutF={rutF}
                            percentagesRutM={rutM}
                            percentages={percentM}
                            percentagesF={percentF}
                            categories={['ABC1', 'C2', 'C3', 'D', 'E']}
                            colors={colorsGSE}
                            labels={true}
                            height={'5em'} />
                        </div>

                      </div>
                    </div>{" "}
                  </div>
                </div>{" "}
                <div className="dobleSpineChartsContainerWithTitleCL">
                  <h2>Distribución por grupo socioeconómico</h2>
                  <div className="dobleSpineChartsWithTitle columnsDobleSpineChartsWithTitle">
                    <div className="dobleDoughnutdivCLWithTitle">
                      <div className="dobleDoughnutContentCL">
                        <h3>Distribución por edad</h3>
                        <p>
                          Es el tramo etario entre los
                          <span>&nbsp;</span>
                          <strong>{values.textos.text_edad_max.edad} </strong>
                          dónde predominan los clientes de{" "}
                          <strong>{values.organizacion}</strong>,
                          pertenecientes principalmente al segmento
                          <span>&nbsp;</span>
                          <strong>{values.textos.text_edad_max.gse}</strong>.
                        </p>
                      </div>
                      {barAgedGse.series && (
                        <div className="verticalBarGraphicsCL">
                          <Bar options={barAgedGse} height={450} />
                        </div>
                      )}

                      <p className="chartDataNseTextCL">
                        Completitud de un
                        <span>&nbsp;</span>
                        <strong>
                          {values.resumen_carga.completitud.comp_edad_gse_int
                            .value &&
                            values.resumen_carga.completitud.comp_edad_gse_int
                              .value}
                          %
                        </strong>
                        <span>&nbsp;</span>
                        de los datos cargados.
                      </p>
                    </div>{" "}
                    <div className="dobleDoughnutdivCLWithTitle">
                      <div className="dobleDoughnutContentCL">
                        <h3>Distribución de las principales profesiones y oficios
                        </h3>
                        <p>
                          A continuación, se observan las principales <strong> {profesionNumberText} </strong>
                          profesiones u oficios de los clientes de
                          <strong> {values.organizacion}</strong>,
                          entre las que destacan
                          <strong>
                            {" "}
                            {values.textos.text_max_profesion_3[0]}
                          </strong>
                          ,
                          <strong>
                            {" "}
                            {values.textos.text_max_profesion_3[1]}
                          </strong>
                          ,{" "}
                          <strong>
                            {" "}
                            {values.textos.text_max_profesion_3[2]}
                          </strong>
                          .
                        </p>
                      </div>

                      {/* {profesionGraph.series && (
                          <SplineChart chartData={profesionGraph} />
                        )} */}

                      {barProfesiondGse.series && (

                        <Bar options={barProfesiondGse} height={450} />

                      )}

                      <p className="chartDataNseTextCL">
                        Completitud de un
                        <span>&nbsp;</span>
                        <strong>
                          {values?.resumen_carga?.completitud?.comp_giro_gse_int?.value && values.resumen_carga.completitud.comp_giro_gse_int.value}
                          %
                        </strong>
                        <span>&nbsp;</span>
                        de los datos cargados.
                      </p>
                    </div>
                    {/* cierro seccion dobleDoughnutdivCLWithTitle */}
                  </div>
                  {/* cierro seccion dobleSpineChartsWithTitle */}
                  <div className="dobleSpineChartsContainer">
                    <div className="dobleDoughnutdivCLCL distribucionporColonia">
                      <div className="dobleDoughnutdivCLTextAndTitleCL">
                        <h3>Distribución de las principales comunas</h3>
                        <p>
                          A continuación, se observan las principales{" "}
                          <strong> {distribucionComunasText} </strong>
                          comunas de residencia de los clientes de
                          <strong> {values.organizacion}</strong>.
                          Un <strong>{values.textos.text_comuna_gse_2}%</strong>{" "}
                          se concentran en <strong>
                            {values.textos.text_comuna_gse_1}
                          </strong>{" "}
                          comunas del pais.
                        </p>
                      </div>
                      {/* cierro seccion dobleDoughnutdivCLTextAndTitle */}
                      {/*     <div className="selectMunicipioContainer"></div> */}
                      {/* cierro seccion selectMunicipioContainer */}

                      {/* {selectGraph.series && (
                          <SplineChart chartData={selectGraph} />
                        )} */}

                      <div className="chartDataContenedorBar">
                        {selectGraph.series && (
                          <Bar options={selectBarGraph} height={450} />
                        )}
                      </div>
                      <p className="chartDataNseTextCL">
                        Completitud de un
                        <span>&nbsp;</span>
                        <strong>
                          {values.resumen_carga.completitud
                            .comp_comuna_gse_int.value &&
                            values.resumen_carga.completitud
                              .comp_comuna_gse_int.value}
                          %
                        </strong>
                        <span>&nbsp;</span>
                        de los datos cargados.
                      </p>
                    </div>
                    {/* cierro seccion dobleDoughnutdivCL distribucionporColonia */}
                  </div>{" "}
                  {/* cierro seccion dobleSpineChartsContainer */}
                </div>
                {/* cierro seccion dobleSpineChartsContainerWithTitle*/}
                <div className="dobleSpineChartsContainerWithTitleCL">
                  <div className="consumoContainer">
                    <h2 id="consumoContainerTitle">
                      Distribución por grupo socioeconómico de cliente versus
                      población
                    </h2>
                    <p className="dobleSpineChartsContainerTextCL">
                      De acuerdo con el grupo socioeconómico se obtienen
                      distintos comportamientos en las concentraciones de
                      clientes de <strong>{values.organizacion}</strong> según
                      edad y comuna de residencia los cuales pueden ser
                      comparados con la población.
                    </p>
                  </div>

                  <div className="dobleSpineChartsWithTitleFlex">
                    <div className="dobleDoughnutdivCLWithTitle">
                      <div className=" dobleDoughnutContentCL dobleDoughnutContentCLEdad">
                        <h3>Distribución por edad</h3>
                      </div>
                      <div className="selectGrupoSocioeconomico">
                        <p>Seleccionar Grupo Socioeconómico</p>

                        {selectBenchEdad !== undefined && (
                          <>
                            <Select
                              styles={customStyles}
                              defaultValue={{
                                value: selectBenchEdad.includes("TOTAL")
                                  ? "TOTAL"
                                  : selectBenchEdad[0],
                                label: selectBenchEdad.includes("TOTAL")
                                  ? "TOTAL"
                                  : selectBenchEdad[0],
                              }}
                              options={selectBenchEdad.map((e) => ({
                                value: e,
                                label: e,
                              }))}
                              onChange={(selectedOption) =>
                                setHandleSelectChange(selectedOption.value)
                              }
                            />
                          </>
                        )}
                      </div>
                      <div className=" anchoContenedorBarEdad">
                        {chartEdadBenchmark.series && (
                          <MixedChart chartData={chartEdadBenchmark} />
                        )}
                      </div>
                    </div>{" "}
                    {/* cierro dobleDoughnutdivCLWithTitle */}
                    <div className="dobleDoughnutdivCLWithTitle">
                      <div className="dobleDoughnutContentCL ">
                        <h3>Distribución en principales comunas</h3>
                        <p>
                          A continuación, se observan las principales  <strong> {distribucionPComunasText} </strong>
                          comunas donde residen los clientes de  <strong> {values.organizacion} </strong>
                        </p>
                      </div>
                      <div className="anchoContenedorBarEdad">
                        {chartComunaBenchmark.series && (
                          <MixedChart chartData={chartComunaBenchmark} />
                        )}
                      </div>
                    </div>{" "}
                    {/* cierro dobleDoughnutdivCLWithTitle */}
                  </div>{" "}
                  {/* cierro dobleSpineChartsWithTitle */}
                </div>
                {/* cierro seccion dobleSpineChartsContainerWithTitle*/}
                <div className="dobleSpineChartsContainerWithTitleCL">
                  <div className="consumoContainer">
                    <h2>Tenencia de Bienes</h2>
                  </div>
                  <div className="vehiculosContainer">


                    <div className="dobleSpineChartsContainerCL">
                      <div className="vehiculosContainerContent">
                        <div className="vehiculosContainerContentText">
                          <h3 className="vehiculosContainerTitle">Marca de Vehículos</h3>
                          <p className="dobleSpineChartsContainerTextCL">
                            A continuación, se enumeran las principales 10 marcas preferidas por los clientes de{" "}  <strong>{values.organizacion}</strong>, según los datos del gráfico. El  <strong>
                              {" "}
                              {values.textos.text_max_vehiculos.peso.toFixed(1)}%
                            </strong>{" "} de los clientes que poseen vehículos eligen la marca   <strong>
                              {" "}
                              {values.textos.text_max_vehiculos.marca}
                            </strong>{" "}, con el grupo   <strong>
                              {" "}
                              {values.textos.text_max_gse_vehiculos}
                            </strong> destacándose como el segmento predominante para esta preferencia. Además,
                            se observa que el   <strong>
                              {" "}
                              {ultimoPeso}%{" "}
                            </strong>de los clientes muestran preferencia por <strong>Otras Marcas</strong>.
                          </p>
                        </div>
                        <div className="chartDataContenedor contenedorFlexFondo">

                          {vehiculos.series && (
                            <ReactApexChart options={vehiculos.options} series={vehiculos.series} type="bar" height={350} width={850} />

                            // <Pie
                            //   classComponent="doughnut-chart-complete-CL"
                            //   chartData={vehiculos}
                            //   width={"100%"}
                            //   height={330}
                            // />
                          )}
                        </div>
                      </div>

                      <div className="barDivContainerGraphCL">
                        <h3>
                          Segmentación marca de vehículos según Grupo Socioeconómico
                        </h3>
                        <p>A continuación, se detallan las <strong> {vehiculosGseText} </strong> principales marcas de preferencia por los clientes de <strong> {values.organizacion}</strong>.</p>

                        <div className="anchoContenedorBarEdad SMVGSAlto">
                          {vehiculosGse.series && (

                            <Bar options={vehiculosGse} height={250} classComponent="doughnut-chart-bar-cars-CL"
                            />
                          )}
                        </div>
                        <div className="chartDataNseTextCL">
                          <p className="chartDataNseTextCL">
                            Completitud de un
                            <span>&nbsp;</span>
                            <strong>
                              {
                                values.resumen_carga.completitud
                                  .comp_vehiculos_int.value
                              }
                              %
                            </strong>
                            <span>&nbsp;</span>
                            de los datos cargados.
                          </p>
                        </div>
                      </div>

                    </div>{" "}
                    <div className="divGraphicCLQVehiculos">
                      <h3>Cantidad de vehiculos</h3>

                      {vehiculoBar.series && (
                        <Bar options={vehiculoBar} height={332} width={0} />
                      )}
                      <h3>Tasación Comercial de Vehiculos</h3>
                      {tasacion_comercial.series && (
                        <ReactApexChart options={tasacion_comercial.options} series={tasacion_comercial.series} type="bar" height={350} width={850} />

                      )}
                    </div>
                  </div>
                  <div className="viviendasContainer">
                    <h3 className="viviendasContainerTitle">
                      Cantidad de Viviendas
                    </h3>
                    <div className="dobleSpineChartsWithTitle">
                      <div className="dobleDoughnutdivCLWithTitle">
                        <div className="dobleDoughnutContentCL dobleDoughnutVivienda">
                          <p>
                            Se identifica la tenencia de viviendas en un
                            <span>&nbsp;</span>
                            <strong>
                              {values.resumen_carga.completitud.comp_viviendas_int.value.toFixed(
                                1
                              )}
                              %
                            </strong>
                            <span>&nbsp;</span>
                            de los clientes de{" "}
                            <strong>{values.organizacion}.</strong>
                          </p>
                        </div>

                        {viviendasBar.series && (
                          <Bar options={viviendasBar} height={332} width={400} />
                        )}

                        <p className="chartDataNseTextCL">
                          Completitud de un
                          <span>&nbsp;</span>
                          <strong>
                            {
                              values.resumen_carga.completitud
                                .comp_viviendas_int.value
                            }
                            %
                          </strong>
                          <span>&nbsp;</span>
                          de los datos cargados.
                        </p>
                      </div>{" "}

                      <div className="dobleDoughnutdivCLWithTitleCLdonut">
                        <div className="dobleDoughnutContentCL dobleDoughnutVivienda">
                          <p>
                            Se identifica la tenencia de viviendas en un
                            <span>&nbsp;</span>
                            <strong>
                              {values.resumen_carga.completitud.comp_viviendas_int.value.toFixed(
                                1
                              )}
                              %
                            </strong>
                            <span>&nbsp;</span>
                            de los clientes de{" "}
                            <strong>{values.organizacion}. </strong>
                            <span>&nbsp;</span>
                            Un{" "}
                            <strong>
                              {values.textos.text_viviendas_1.toFixed(1)}%{" "}
                            </strong>{" "}
                            de ellas registran
                            <span>&nbsp;</span>
                            <strong>
                              {" "}
                              {values.textos.text_viviendas_2}{" "}
                            </strong>
                            <span>&nbsp;</span>propiedad.
                          </p>
                        </div>

                        {viviendasDoughnut.series && (
                          <ChartDataDoughnut
                            classComponent="doughnut-chartCL-Pie-viviendas"
                            chartData={viviendasDoughnut}
                            width={"100%"}
                            height={365}
                          />
                        )}

                      </div>{" "}
                    </div>
                  </div>

                  <div className="viviendaTercerGraficoContainer">
                    <div className="dobleDoughnutdivCLWithTitleCLdonutTasacion paddingTasacion">
                      <div className="dobleDoughnutContentCLTasacion ">
                        <h3>Tasación Comercial de Viviendas</h3>
                        <p>
                          El valor promedio comercial de las viviendas
                          aquellos clientes que registran propiedad es{" "}
                          <strong>
                            {values.textos.text_viviendas_monto_2}
                          </strong>{" "}
                          con una participación de
                          <strong>
                            {" "}
                            {values.textos.text_viviendas_monto_1.toFixed(1)}
                            %.
                          </strong>
                        </p>
                      </div>
                      <div className="chartDataContenedor contenedorFlexFondo">
                        {viviendasDoughnutMonto.series && (
                          <ReactApexChart options={viviendasDoughnutMonto.options} series={viviendasDoughnutMonto.series} type="bar" height={350} width={850} />

                        )}
                      </div>
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                {values.resumen_carga.enrichment_industry !== "Natural" && (
                  <div className="verticalBarCL">
                    <div className="chartsSelectContainer">
                      <div className="chartDataContenedorDisposicionGastos">
                        <div className="verticalBarText">
                          <h2>
                            Disposición de gasto por grupo socioeconómico según{" "}
                            {values.resumen_carga.enrichment_industry} y{" "}
                            {values.resumen_carga.enrichment_segment}
                          </h2>
                          <p>
                            Se determinaron los gastos más importantes
                            correspondientes a la industria y segmento definido
                            para el cliente
                            <strong> {values.organizacion}</strong>, {" "}
                            desagregados según grupo socioeconómico.
                          </p>
                        </div>
                        <Select
                          styles={customStyles}
                          className="chartsSelectTwo"
                          name="BuyerPerson"
                          onChange={(selectedOption) => {
                            setBuyerPerson(selectedOption.value);
                          }}
                          options={canastaSearch.map((e) => ({
                            value: e,
                            label: e,
                          }))}
                          value={{ value: buyerPerson, label: buyerPerson }} />
                        {stackedColumn.series && (
                          <div className="verticalBarGraphicsCL">
                            <Bar options={stackedColumn} height={450} />
                          </div>
                        )}
                        <div className="chartDataNseTextCL">
                          <p>
                            El Gasto definido para la industria y segmento que
                            se observa en el gráfico corresponde a una
                            segmentación de 3 niveles que permite entender a los
                            clientes de acuerdo a su conducta de consumo de una
                            canasta promedio de productos seleccionados para la
                            industria/segmento específicos y que además pueden
                            ser comparados por grupo socioeconómico.
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                )}
                <div className="finalGraph">
                  <div className="finalGraphContenedor">


                    <div className="buyerPersonTextCl">

                      <h2>Detalle de Buyer persona</h2>
                      <p>
                        A continuación, te presentamos los diferentes <strong>buyer personas</strong> encontrados a partir del análisis de los clientes de
                        <strong >
                          {" "}
                          {values.organizacion}
                        </strong>.</p>
                      <p>¡Llegó el momento de conocer a tus clientes!</p>

                    </div>

                    {profile.length > 0 && (
                      <div className="ProfileGraphText">

                        <div className="ProfileGraphTextDivContainer">
                          <div className="ButtonsProfileGraphTextCl">
                            {values.profile.map((i, x) => {
                              return (
                                <div onClick={() => { setClickState(x) }} className={"ButtonsProfileGraphButtonCl" + (clickState === 0 && x === 0 ? " firstButtonGraphCl" : clickState === 1 && x === 1 ? " secondButtonGraphCl" : clickState === 2 && x === 2 ? " threeButtonGraphCl" : clickState === 3 && x === 3 ? " fourButtonGraphCl" : clickState === 4 && x === 4 ? " fiveButtonGraphCl" : clickState === 5 && x === 5 ? " sixButtonGraphCl" : "")} >
                                  <div className="ButtonsProfileGraphButtonClContainer">
                                    <div style={{ display: 'flex', marginBottom: "0.5em" }}>

                                      <img src={clickState === x ? accountCircle : x === 0 ? accountCircleOne : x === 1 ? accountCircleTwo : x === 2 ? accountCircleThree : x === 3 ? accountCircleFour : x === 4 && accountCircleFive} alt="" />
                                      <p ><strong>Buyer persona {x + 1}</strong></p>

                                    </div>
                                    <div style={{ display: 'flex', alignContent: 'center' }}>
                                      <img src={clickState !== x ? barChartSvgColor : barChartSvg} alt="" />
                                      <p><strong>{profile[x]}%</strong> tiene este perfil</p>

                                    </div>
                                  </div>
                                  <img src={IconCLChartButton} alt="" srcset="" />
                                </div>
                              );
                            })}
                          </div>
                          {values.profile.map((i, x) => {
                            if (clickState === x) {
                              return (

                                <div key={i.Perfil + "finalGraphText"} className="finalGraphText" >

                                  <div >
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      <div className="finalGraphTextTxtImg">
                                        <img src={x === 0 ? accountCircleOne : x === 1 ? accountCircleTwo : x === 2 ? accountCircleThree : x === 3 ? accountCircleFour : x === 4 && accountCircleFive} alt="" />
                                        <p className="finalGraphTextTxtImgBp"><strong>Buyer persona {x + 1}</strong></p>
                                      </div>
                                      <div className="finalGraphTextTxtImg">
                                        <img src={barChartSvgColor} alt="" style={{ marginRight: "0.3em" }} />
                                        <p><strong>{profile[x]}%</strong> tiene este perfil</p>
                                      </div>
                                    </div>
                                    <p className="StrongTitleGraphCl">
                                      <strong
                                        className="StrongTxtTitleGraphCl"
                                      >
                                        ¿Cómo es éste perfil?
                                      </strong>
                                    </p>
                                    <p>{i.perfil_ampliado}</p>
                                  </div>
                                </div>
                              )
                            }
                          })}

                        </div>
                      </div>
                    )}

                  </div>

                </div>
                <div className="caracteristicasSocioDemograficas">
                  {chartData.series && <ChartGraphClient options={chartData} data={values} />}
                </div>
                {/* cierro Dashboard */}
              </div>
              {/* cierro dashboardVerticalMenuContainer */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChartsCL;