import React from "react";
import "./mensajeDeErrorAPI.css";
import Cancel from "./cancel.png"

const MensajeDeErrorAPI = () => {
  return (
    <>
      <div className="popUpMensajeErrorBody">
        <div className="popUpMensajeError ">
          <div className="popUpMensajeErrorContenido">
            <img src={Cancel} alt="" />
            <p className="popUpMensajeErrorContenidoTitulo">Sucedió un error</p>
            <p className="popUpMensajeErrorContenidoTexto">Tus datos no fueron procesados correctamente. <br />
              Vuelve a intentarlo por favor.</p>
          </div>
          <div className="popUpMensajeErrorBotonContenedor">
            <button className="popUpMensajeErrorBoton">Volver a intentar </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MensajeDeErrorAPI;
