import React, { useContext } from 'react';
import imgModalTablaExito from './assets/ModalExitoTabla.svg';
import './modalTablaExito.css';
import axiosInterceptor from "../../../AxiosInterceptor/AxiosInterceptor";
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../Context/Context';
import separadorDeMiles from '../../../SeparadorDeMiles/SeparadorDeMiles';

const ModalTablaExito = ({dataObj, handleClose}) => {
  const navigate = useNavigate();
  const { tkn, buyerClonTkn} = useContext(AppContext);
  let objData = () => {
    if (dataObj ) {
      axiosInterceptor
        .post(
          `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/preview/${tkn}/${buyerClonTkn}`,
          dataObj,
        
        )
        .then((e) => {
          navigate("/amplify-resumen");
        })
        .catch((e) => {
           if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
        localStorage.clear();
        navigate('/iniciar-sesion')
    }
        });
    }
  };
  function calcularSuma() {
    let suma = 0;
  
    for (const key in dataObj.form) {
      suma += dataObj.form[key];
    }
  
    return separadorDeMiles(suma);
  }
  return (

  
      <div className='amplifyModalTablaExito'>
        <div className="imgModalExito">
          <img src={imgModalTablaExito} alt="exito" />
        </div>
     <div className='modalExitoCntenido'>
     <h2>Un pequeño paso te separa de una gran audiencia</h2>
        <p>Confirma y llega a <strong className='strong'>{calcularSuma()}</strong>  personas con tu campaña</p>
     </div>
        <div className='Confirmacion_botones cardExitoBotones'>
        
        <button onClick={()=> {handleClose()}} className={'btnSecondary'}>Atrás</button>
            <button onClick={objData} className={'btnPrimary'}>Confirmar</button>
          </div>
      </div>

  );
};

export default ModalTablaExito;