import React, { useState, useEffect, useContext } from "react";
import Automatico from "./assets/iconAAutomatico.svg";
import Manual from "./assets/iconAManual.svg";
import CardCantidadBP from "./cardsAmplifyAutomatico/cardCantBuyerPersona/cardCantidadBuyerPersona";
import TextoPerfiles from "./cardsAmplifyAutomatico/cardTextoPerfiles/textoPerfiles";
import CardSliderBarGenero from "./cardsAmplifyAutomatico/cardSliderBarGeneroAmplify/cardSliderBarGeneroAmplify";
import CardDatosEconomicos from "./cardsAmplifyAutomatico/cardDatosEconomicos/cardDatosEconomicos";
import CardDatosGeograficos from "./cardsAmplifyAutomatico/cardDatosGeograficos/CardDatosGeograficos";
import "./AmplifyAutomaticoContenedor.css";
import "./AmplifyManualContenedor.css";
import SpinnerGris from "../../ComponentesMoleculas/Loader/SpinnerGris";
import LoaderLottie from "../../loaderLottie/LoaderLottie";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/Context";
import AmplifyManualFiltros from "../AmplifyManual/amplifyManualFiltros"
import SelectBox from "../../ComponentesMoleculas/SelectBox/SelectBox";
import Inputs from "../../ComponentesMoleculas/Inputs/input"
import axiosInstance from '../../AxiosInterceptor/AxiosInterceptor';

const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    color: "#98A2B3",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0.28px",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    cursor: "pointer",
    borderRadius: "8px",
    borderColor: state.isFocused ? "#F18F49" : "#E2E4E3",
  }),
  container: (provided, state) => ({
    ...provided,
    width: "154px",
    height: "32px",
  }),
  control: (provided, state) => ({
    ...provided,
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    background: "#FFF",
    cursor: "pointer",
    height: "38px",
    minHeight: "24px",
    borderRadius: "8px",
    color: "#707477",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 300,
    letterSpacing: "0.24px",
    borderColor: state.isFocused ? "#F18F49" : "#E2E4E3",
    boxShadow: state.isFocused ? "0 0 0 1px #F18F49" : "none",
    "&:hover": {
      borderColor: "#F18F49",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#707477",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 300,
    letterSpacing: "0.24px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    padding: "8px",
    gap: "8px",
    borderRadius: "8px",
    cursor: "pointer",
    background: "#FFF",
    color: "#0C0C0F",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "12.5px",
    letterSpacing: "0.2px",

  }),
  
  option: (provided) => ({
    ...provided,
    background: "#FFF",
    color: "#0C0C0F",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "12.5px",
    letterSpacing: "0.2px",
    "&:active": {
      background: "#ffffff",
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'transparent', // Fondo de los elementos seleccionados
  }),
}

const AmplifyContenedorLista = ({updateProgress }) => {
  const { setTkn, setQuantity, setErrLog, setTotal, setAutomatico, setBuyerClonTkn } = useContext(AppContext);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectValue, setSelectValue] = useState("false");
  const [showAdditionalContent, setShowAdditionalContent] = useState(false);
  const [profiles, setProfiles] = useState(undefined);
  const [dataProfileSelected, setDataProfileSelected] = useState(undefined);
  const [profileSelected, setProfileSelected] = useState(0);
  const [filters, setFilters] = useState([]);
  const [region, setRegion] = useState({});
  const [generatedData, setGeneratedData] = useState([]);
  const [dataNumbers, setDataNumbers] = useState([]);
  const [economics, setEconomics] = useState([]);
  const [gender, setGender] = useState([]);
  const [status, setStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [percent, setPercent] = useState(true);
  const [name, setName] = useState('')
  let { id } = useParams()

  const country = localStorage.getItem('selectedCountry')

  useEffect(() => {
    if (id === "1") {
      setActiveTab(1)
    } else if (id === "2") {
      setActiveTab(2)
    }
  }, [id])
  const [objManuales, setObjManuales] = useState({
    title_clon: "",
    organizacion: localStorage.getItem('companyFilter'),
    data: [],
  })
  // Para el SELECT
  const [list, setList] = useState([]);
  const handleSeleccionadosChange = (optionsCheckBox) => {
    setSelectValue(optionsCheckBox);
  };
  // 
  const handleDataGenerated = (data) => {
    setGeneratedData(data);
  };
  const handleStatus = (data) => {
    setStatus(data);
  };
  const handleDataInput = (data) => {
    setDataNumbers(data);
  };
  const handleDataEconomic = (data) => {
    setEconomics(data);
  };


  let datafilter = () => {
    const dataObject = {
      isocrona: "isocrona_true",
      force: "large_search_true",
      title_clon: inputValue,
      lista: selectValue,
      organization: "Ais Spa",
      // "organization": company
    };
    for (var i = 0; i < dataNumbers.length; i++) {
      var perfilNombre = "perfil_" + (i + 1); // Nombre del perfil
      var valor = dataNumbers[i].toString(); // Valor del perfil como string
      dataObject[perfilNombre] = valor;
    }
    let sum = 0;
    for (let i = 0; i < dataNumbers.length; i++) {
      if (dataNumbers[i] !== "") {
        sum += Number(dataNumbers[i]);
      }
    }
    setTotal(sum);
    dataObject.contador = Number(sum);
    dataObject.number_profiles = dataNumbers.length;

    if (economics !== undefined) {
      delete dataObject.data_gastos;
      dataObject.data_gastos = economics.data_gastos;
    } else {
      delete dataObject.data_gastos;
      dataObject.data_gastos = [];
    }
    if (generatedData.length > 0) {
      delete dataObject.data;
      dataObject.data = generatedData;
    } else {
      delete dataObject.data;
      dataObject.data = [];
    }
    dataObject.Masculino = gender.masculino;
    dataObject.Femenino = gender.femenino;
    sendData(dataObject);
  };

  const sendData = (object) => {
    axiosInstance.post(
        `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/buyer/consulting`,
        object,
       
      )
      .then((e) => {
     
        const receivedToken = e.data.token;
        confirmData(receivedToken, object);
        setTkn(receivedToken);
      })
      .catch((e) => {
        if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
          localStorage.clear();
          navigate('/iniciar-sesion')
        }
      });
  };

  const confirmData = (token, object) => {
    axiosInstance.post(
        `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/buyer/insert/${token}`,
        object,
       
      )
      .then((e) => {
        let quantity = {
          solicitado: e.data.solicitado,
          cantidad: e.data.cantidad,
        };
        setQuantity(quantity);
        setErrLog(false);
        setBuyerClonTkn(object.lista)
        navigate("/amplify-solicitud-clones");
        setAutomatico(true)
      })
      .catch((e) => {
        if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
          localStorage.clear();
          navigate('/iniciar-sesion')
        }
        setErrLog(true);
      });
  };
  function parseDate(timestamp) {
    const options = new Date(timestamp);
    const dia = options.getDate().toString().padStart(2, '0'); // Asegura que siempre tenga dos dígitos
    const mes = (options.getMonth() + 1).toString().padStart(2, '0'); // Los meses comienzan desde 0, por lo que sumamos 1
    const anio = options.getFullYear();
    return `${dia}/${mes}/${anio}`;
}
useEffect(() => {
  axiosInstance.get(`${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/buyer-person`, )
    .then((e) => {
      setData(e.data.df_organizations);
      setFilters(e.data.filtros_2.data);
      setRegion(e.data.filtros.data);
      let companyFilter = localStorage.getItem('companyFilter');
      let listaparamodificar = e.data.df_organizations.filter((i) => i.organization === companyFilter)
        .map((i) => ({
          value: i.token,
          label: `${parseDate(i.created_at)} - ${i.namelist}`,
          created_at: i.created_at,
        }));
      
listaparamodificar.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      })
      setList(listaparamodificar);
    })
    .catch((e) => {
      if (e && e.response && e.response && e.response.status && e.response.status && (e.response.status === 401 ||  e.response.status === 422)) {
        localStorage.clear();
        navigate('/iniciar-sesion');
      }else if ( e.response && e.response.status  && e.response.status === 302){
        navigate('/acceso-denegado')
      }
      // navigate("/amplify-solicitud-clones");
    });
}, []);

// ...
  const postObjManuales = () => {
    axiosInstance.post(
        `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/insert`,
        objManuales,
       
      )
      .then((e) => {

        const receivedToken = e.data.token;
        setTkn(receivedToken);
        setQuantity({ cantidad: e.data.cantidad });
        setErrLog(false)
        navigate('/amplify-solicitud-clones')
        setAutomatico(false)
      })
      .catch((e) => {
        if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
          localStorage.clear();
          navigate('/iniciar-sesion')
        }else  if ((e.responese && e.responese.status && e.response.status === 302)){
          navigate('/acceso-denegado')
        }
        setErrLog(false)
      });
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    if (selectValue !== "false") {
      axiosInstance.get(
          `${process.env.REACT_APP_URL}/api/role/${localStorage.getItem('selectedCountry')}/clones/find_enrichment/${selectValue}`,
         
        )
        .then((e) => {
          setProfiles(e.data.df_graph);
          setProfileSelected(0);
          setDataProfileSelected(e.data.df_graph.perfil_ampliado);
        })
        .catch((e) => {
          if (e.response && e.response.status && e.response.status === 401 || e.response && e.response.status && e.response.status === 422) {
            localStorage.clear();
            navigate('/iniciar-sesion')
          }
        });
    }
  }, [showAdditionalContent, selectValue]);

  useEffect(() => {
    if (inputValue !== '' && selectValue !== "false") {
      setShowAdditionalContent(true);
      updateProgress(1);
    } else {
      setShowAdditionalContent(false);
    }
  }, [inputValue, selectValue, updateProgress]);

  const handleInputChange = (value, data) => {
    setProfileSelected(value);
    setDataProfileSelected(data);
  };
  const handleGenderData = (data) => {
    setGender(data);
  };
  const handlePercent = (data) => {
    setPercent(data);
  };
  let handleManual = (e) => {
    localStorage.setItem('name', e.target.value)
    setName(e.target.value)
  }
  let hanldeObjManuales = (e) => {
    setObjManuales(e)
  }

  return (
    <>
      {loader ? (
        <LoaderLottie />
      ) : (
        <>
          <div className="amplifyPrincipalContainer" >
            <div className="amplifyTabs " >
              <button
                className={activeTab === 1 ? "tabsActive" : ""}
                onClick={() => {
                  handleTabClick(1);
                }}
              >
                <img src={Automatico} alt="" />
                Automatico
              </button>
              <button
                className={activeTab === 2 ? "tabsActive" : ""}
                onClick={() => handleTabClick(2)}
              >
                <img src={Manual} alt="" />
                Manual
              </button>
            </div>

            <div className={`amplifyContainer `}>
              {activeTab === 1 && (
                <div className="automaticoContainer">
                  <div className="automaticoTexts">
                    <h1>Solicitud en base a Buyer Persona</h1>
                    <p className="automaticoContainerText">
                      Selecciona el proceso de Buyer Persona con el que vas a
                      trabajar, define el público por perfil y si deseas ajusta
                      tu búsqueda con filtros.
                    </p>

                  </div>
                  {/* {showAdditionalContent && ()} */}
                  <div className={`solicitudBuyerPersonaAutomatico " "}`}>
                    {data ? (
                      <>
                        <div className="containerInputAmplify">
                          <p>Nombre</p>
                          <Inputs
                            className="borde-inputs-generales"
                            type="text"
                            props={{ placeholder: "Indica el nombre para tu solicitud" }}
                            value={inputValue}
                            handleChange={(e) => {setInputValue(e.target.value) ; localStorage.setItem('name', e.target.value )}} />
                        </div>
                        <div className="containerInputAmplify">
                          <p>Listas disponibles</p>
                          <SelectBox
                            optionsSelect={list}
                            placeholder={'Selecciona una lista disponible'}
                            seleccionados={selectValue}
                            onChangeSeleccionados={handleSeleccionadosChange}
                            styles={customStyles}
                            menuPlacement="auto" // Configura el menú para que se ajuste automáticamente al ancho de la opción más larga
                            isSearchable={true} // Opcional, si deseas habilitar la búsqueda
                            isClearable={true}
                          />
                        </div>

                      </>
                    ) : (
                      <p>
                        <SpinnerGris />
                      </p>
                    )}
                  </div>

                  {showAdditionalContent && selectValue !== false && (
                    <>
                      <div className="amplifyDesplegableContainer">
                        <div className="cardCantidadPerfiles">
                          <div className="cardCantidadPerfilesHeader">
                            <h2>Cantidad de perfiles</h2>
                            <p className="inline-paragraph">
                              ¿Cuántos Buyer Persona necesitas? Indica la cantidad de personas necesarias para tus campañas.
                              <span className="TextSecondPerfiles"> ¡Define tu búsqueda para llegar más lejos!</span>
                            </p>
                          </div>
                          {selectValue && profiles && profiles.length > 0 ? (
                            <div className="cardCantidadPerfilesBody">
                              <CardCantidadBP
                                profiles={profiles}
                                onInputChange={handleInputChange}
                                dataProfiles={handleDataInput}
                                buttonStatus={handleStatus}
                              />
                              <TextoPerfiles
                                profileSelected={profileSelected}
                                dataProfileSelected={dataProfileSelected}
                                profiles={profiles[0]}
                              />
                            </div>
                          ) : (
                            <p className="cargandoPergilesAmplify" >Cargando Perfiles...</p>
                          )}
                        </div>
                        <div className="cardContainerDatosEcoGen">
                          <div className="cardDatosEcoGeo">
                            <CardDatosEconomicos
                              filters={filters}
                              handleDataEconomic={handleDataEconomic}
                            />
                            <CardSliderBarGenero genderData={handleGenderData} />
                          </div>
                          <div className="cardContainerDatosGeo">
                            <CardDatosGeograficos
                              region={region}
                              onDataGenerated={handleDataGenerated}
                              percent={handlePercent}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="Confirmacion_botones">
                        <button
                          onClick={() => {
                            navigate("/inicio/buyer-persona");
                          }}
                          className={"btnSecondary"}
                        >
                          Atrás
                        </button>
                        {status && percent ? (
                          <button
                            className="btnPrimary"
                            value={"Continuar"}
                            onClick={() => {
                              datafilter();
                              setLoader(true);
                            }}
                          >
                            Continuar
                          </button>
                        ) : (
                          <button className="btnDisabled">Continuar</button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
              {activeTab === 2 && (

                <div className="manualesContainer">
                  <h1>Solicitud Amplify manual</h1>
                  <p className="manualesContainerText">
                    Para crear nuevos perfiles, ingresa el nombre a tu lista y
                    aplica los filtros sociodemográficos y de mercado
                    disponibles.
                  </p>
                  <div className="manualesContainerInputSolicitud">
                    <p className="manualesContainerLabelSolicitudText">Nombre de la solicitud</p>
                    <input className="manualesContainerInputSolicitudText" type="text" onChange={handleManual} placeholder="Nombre de lista" />
                  </div>
                  <div>
                    <AmplifyManualFiltros name={name} obj={hanldeObjManuales} />

                  </div>
                  <div className="manualesBotonesContainer">
                    {(name !== '' && objManuales.data && !(objManuales.data.filter((e) => e.data.length === 0).length > 0)) ? (
                      <button className="btnPrimary" onClick={() => { postObjManuales(); setLoader(true) }}>Continuar</button>
                    ) : (
                      <button className="btnDisabled">Continuar </button>
                    )}

                  </div>
                </div>
              )}
            </div>
          </div>
        </>

      )}
    </>
  );

};

export default AmplifyContenedorLista;
